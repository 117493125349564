import { getDownloadFiles } from '../services/downloadFile';

export const downloadFile = async (
  action,
  download = false,
  target = '_parent'
) => {
  if (download) {
    const windowOpen = window.open('', target);
    const {
      data: { data },
    } = await getDownloadFiles(action);
    if (data) {
      windowOpen.location.href = data;
    }
  }
};
