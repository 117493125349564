import indefinite from 'indefinite';
import pageRoutes from './page-routes';

const mainTitle = 'MDsave - Your Medical Procedure for Less';
const mainDescription =
  'MDsave connects patients with medical providers who offer pre-negotiated savings on medical services. ' +
  'Save an average of 50%. No membership fees. No surprise bills.';

export const home = {
  helmetTitle: mainTitle,
  helmetCareConnectTitle:
    'Tendo Care Connect - Your Medical Procedure for Less',
  helmetDescription: mainDescription,
};

export const careers = {
  helmetTitle: 'Careers - MDsave',
  helmetDescription:
    'Join MDsave and help bring affordable, reliable healthcare to all.',
};

export const error = {
  helmetTitle: 'Page Not Found - MDsave',
  helmetDescription:
    'Shopping online for healthcare has arrived. Compare local prices, buy procedures online, ' +
    'and save an average of 50%. No membership fees. No surprise bills.',
};

export const emailSignup = {
  helmetTitle: 'Join our email list and save $25 - MDsave',
  helmetDescription:
    'Get $25 off when you join the MDsave email list. Pay upfront and save on medical procedures.',
};

export const learn = {
  helmetTitle: 'Affordable Out-of-Pocket Healthcare - MDsave',
  helmetDescription:
    'Your medical provider partners with MDsave to help you afford your medical care not covered by insurance.',
};

export const signIn = {
  helmetTitle: 'Account Sign In - MDsave',
  helmetDescription:
    'Access your MDsave account quickly and securely. ' +
    'Sign in to explore affordable medical services, your purchase history, and to manage your account.',
};

export const signUp = {
  helmetTitle: 'Sign Up For MDsave and Save Up to 60% on Medical Procedures',
  helmetDescription:
    'MDsave is the world’s first online healthcare marketplace. ' +
    'Get access to procedure prices, provider reviews and locations, and pay your bills - all in one place.',
};

export const accountRecovery = {
  helmetTitle: mainTitle,
  helmetDescription:
    'Shopping for healthcare has arrived. Compare local prices, ' +
    'save money and buy your procedure — all in one place. Feels better already.',
};

export const forgotPassword = {
  helmetTitle: 'Password Recovery - MDsave',
  helmetDescription:
    'Enter your email to reset your password for MDsave.com. Please call us if you need assistance.',
};

export const faq = {
  helmetTitle: 'Frequently Asked Questions (FAQ) - MDsave',
  helmetCareConnectTitle:
    'Frequently Asked Questions (FAQ) - Tendo Care Connect',
  helmetDescription:
    'Find answers to common questions. ' +
    'Learn about our payment options and how to shop for a procedure and place an order on MDsave.',
};

export const about = {
  helmetTitle: 'About MDsave: Shopping online for healthcare has arrived',
  helmetDescription:
    'MDsave makes medical procedures transparent, affordable, and instantly shoppable. ' +
    'Get upfront costs, no surprise bills, and special reduced prices.',
};

export const heartHealth = {
  helmetTitle: 'Cardiac Screenings — Save Up to 70% | MDsave',
  helmetDescription:
    'These savings can’t be beat! In honor of American Heart Month, ' +
    'save up to 70% on your cardiac CT calcium scoring all February long. Find a provider near you and get your screening today.',
};

export const colonoscopy = {
  metaDescription:
    "If you're 45+ or have a family history of cancer, ask your doctor if a colonoscopy is right for you.",
  ogTitle: 'Detect and Prevent Colon Cancer',
  helmetTitle: 'Colorectal Cancer Screenings: Save Up to 50% - MDsave',
  helmetDescription: 'Save up to 50% on your next colonoscopy with MDsave.',
};

export const mammogram = {
  helmetTitle: 'Save on Mammogram Screenings in October - MDsave',
  helmetDescription:
    'Get limited-time savings on Mammograms during Breast Cancer Awareness Month.',
};

export const searchResult = (title) => ({
  helmetTitle: title && `Save up to 60% on ${title}`,
  helmetDescription:
    title &&
    `How much is the cost of ${indefinite(
      title
    )} in your city. Best Price for ${title} in your city`,
});

export const providers = {
  helmetTitle: 'Risk-Free, Shoppable Episodes of Care - MDsave Direct',
  helmetDescription:
    "Improve transparency, patient satisfaction & collections with MDsave's patented technology.",
};

export const patients = {
  helmetTitle: 'MDsave - How it Works',
  helmetCareConnectTitle: 'Tendo Care Connect - How it Works',
  helmetDescription:
    'MDsave works directly with healthcare providers to offer affordable prices' +
    ' for medical procedures. Shop our site to avoid surprise bills and save money.',
};

export const BlogHelmetTexts = {
  [pageRoutes.MEDIA]: {
    title: 'MDsave Resource Center - Feels Better Already',
    description:
      'Discover the latest press releases, whitepapers, infographics, case studies, ' +
      'blog posts, and videos about MDsave. ' +
      'Stay informed about innovative solutions in healthcare and how MDsave is transforming the patient experience.',
  },
  [pageRoutes.MEDIA_PRESS_RELEASES]: {
    title: 'Press Releases: MDsave Press Releases | Latest News and Updates',
    // eslint-disable-next-line max-len
    description: `Explore the latest press releases from MDsave. Stay up-to-date with all the content in MDsave's Resource Center and learn how we're revolutionizing healthcare.`,
  },
  [pageRoutes.MEDIA_WHITEPAPERS]: {
    title: 'Whitepapers: MDsave Whitepapers | Healthcare Insights and Research',
    description: `Explore the latest whitepapers from MDsave.
    Stay up-to-date with all the content in MDsave's Resource Center and learn how we're revolutionizing healthcare.`,
  },
  [pageRoutes.MEDIA_INFOGRAPHICS]: {
    title: 'Infographics: MDsave Infographics | Visualizing Healthcare Trends',
    // eslint-disable-next-line max-len
    description: `Explore the latest infographics from MDsave. Stay up-to-date with all the content in MDsave's Resource Center and learn how we're revolutionizing healthcare.`,
  },
  [pageRoutes.MEDIA_CASE_STUDIES]: {
    title: 'Case Studies: MDsave Case Studies | Customer Success Stories',
    // eslint-disable-next-line max-len
    description: `Explore the latest case studies from MDsave. Stay up-to-date with all the content in MDsave's Resource Center and learn how we're revolutionizing healthcare.`,
  },
  [pageRoutes.MEDIA_BLOG_ENTRIES]: {
    title:
      'Blog Posts: MDsave Blog | Insights, Trends, and Analysis on Healthcare',
    // eslint-disable-next-line max-len
    description: `Explore the latest blog entries from MDsave. Stay up-to-date with all the content in MDsave's Resource Center and learn how we're revolutionizing healthcare.`,
  },
  [pageRoutes.MEDIA_VIDEOS]: {
    title: 'Videos: MDsave Videos | Healthcare Explainer Videos and More',
    // eslint-disable-next-line max-len
    description: `Explore the latest videos from MDsave. Stay up-to-date with all the content in MDsave's Resource Center and learn how we're revolutionizing healthcare.`,
  },
};

export const BlogTagHelmetTexts = (title) => ({
  helmetTitle: `${title} - MDsave Blog`,
  helmetDescription: mainDescription,
});

export const BlogSingleTexts = (
  title,
  category,
  shortDescription,
  isPortalUser
) => ({
  helmetTitle: `${title} - ${category} - ${
    isPortalUser ? 'Tendo Care Connect' : 'MDsave'
  }`,
  helmetDescription: shortDescription,
});

export const training = {
  helmetTitle: 'Training Videos - MDsave',
  helmetDescription:
    'Get your Healthcare Ecommerce Certification from MDsave by watching our short training videos and passing a quiz.',
};

export const trainingCarePackages = {
  helmetTitle: 'Collect Payment from Patients via Text or Email - MDsave',
  helmetDescription:
    'If your patient isn’t ready to pay in full on the spot, send a secure care package via text' +
    ' or email to enable them to purchase the right care from anywhere.',
};

export const trainingFinancing = {
  helmetTitle: 'CareCredit Promotional Financing - MDsave',
  helmetDescription:
    'MDsave is pleased to accept CareCredit, a health, wellness, and personal' +
    ' care credit card that provides promotional financing with flexible monthly payments.',
};

export const trainingPurchase = {
  helmetTitle: 'How to Collect Self-Pay in Full at Point of Service - MDsave',
  helmetDescription:
    'Find procedures on MDsave.com and complete a purchase on a patient’s behalf.',
};

export const trainingDeductible = {
  helmetTitle: 'Applying Patient Purchases to Their Deductible - MDsave',
  helmetDescription:
    'Teach your patients what they need to submit to their insurance ' +
    'to see if their purchase is eligible for application to their deductible.',
};

export const trainingEHR = {
  helmetTitle: 'How to Register an MDsave Patient in Your EHR - MDsave',
  helmetDescription:
    'Properly registering patients in your system prevents them from receiving bills for prepaid services.',
};

export const trainingWaysToPay = {
  helmetTitle: 'Collect Payment from Credit/Debit, FSA Cards & More - MDsave',
  helmetDescription:
    'Patients can prepay for services using credit cards including CareCredit,' +
    ' debit cards, FSA/HSA cards, or multiple payment methods.',
};

export const trainingWhatIsMdsave = {
  helmetTitle: 'Improve Patient Satisfaction at Point of Service - MDsave',
  helmetDescription:
    'Hospitals partner with MDsave to make payment collection simpler and faster while improving patient satisfaction with upfront,' +
    'understandable bundled prices.',
};

export const trainingWhenToOffer = {
  helmetTitle: 'Full Payment Option for Out-of-Pocket Patients - MDsave',
  helmetDescription:
    'Help out-of-pocket patients understand their financial responsibility and pay in full, up front.',
};

export const groupSystem = (title, isPortalUser) => ({
  helmetTitle: `${title} - ${isPortalUser ? 'Tendo Care Connect' : 'MDsave'}`,
  helmetDescription: `Save money on procedures offered from ${title} physicians by paying upfront with MDsave.`,
});

export const priceList = (title) => ({
  helmetTitle: `${title} Procedure Price List - MDsave`,
  helmetCareConnectTitle: `${title} Procedure Price List - Tendo Care Connect`,
  helmetDescription: `View specialties, physicians, and procedure prices for ${title}. Save up to 50% when paying upfront on MDsave.`,
});

export const carePackage = {
  helmetTitle: mainTitle,
  helmetDescription:
    'Shopping for healthcare has arrived. Compare local prices,' +
    ' save money and buy your procedure — all in one place. Feels better already.',
};

export const emailPreferences = {
  helmetTitle: 'Email Preferences - MDsave',
  helmetDescription:
    'Adjust your email preferences and subscriptions to MDsave newsletters and shopping cart emails.',
};

export const contentfulTemplate = (data) => ({
  helmetTitle: data.metaTitle,
  helmetDescription: data.metaDescription,
});

export const financing = {
  helmetTitle: 'CareCredit Credit Card Healthcare Financing - MDsave.com',
  helmetCareConnectTitle:
    'CareCredit Credit Card Healthcare Financing - Tendo Care Connect',
  helmetDescription:
    'Pay over time with promotional financing options through MDsave & CareCredit.',
};

export const postPurchase = {
  helmetTitle: 'Order Confirmation - MDsave',
  helmetDescription:
    'Your MDsave order is confirmed. Find information on next steps, your MDsave voucher, and your order summary.',
};

export const deductibleChecker = {
  helmetTitle: 'What’s My Health Insurance Deductible?',
  helmetDescription:
    `Use MDsave's free health insurance deductible checker to find out the details of your health ` +
    `insurance plan including your remaining deductible and out-of-pocket maximums.`,
};

export const deductibleCheckerDirect = {
  helmetTitle: 'Deductible Checker - MDsave',
  helmetDescription:
    `Use MDsave's free health insurance deductible checker to find out the details of your health ` +
    `insurance plan including your remaining deductible and out-of-pocket maximums.`,
};
export const providerSearch = {
  helmetTitle: 'MDsave - Feels Better Already',
  helmetDescription:
    'Shopping online for healthcare has arrived. Compare local prices, buy procedures online, ' +
    'and save an average of 50%. No membership fees. No surprise bills.',
  pageName: 'Search Provider',
  pageCategory: 'Search Provider',
};

export const aToZMeta = {
  [pageRoutes.A_TO_Z_PROCEDURES_URL]: {
    title: 'All Procedures - MDsave',
    careConnectTitle: 'All Procedures - Tendo Care Connect',
    description:
      'Compare upfront prices with local doctors on more than 1,680+ medical procedures at MDsave.',
  },
  [pageRoutes.A_TO_Z_PROVIDERS_URL]: {
    title: 'All Medical Providers - MDsave',
    careConnectTitle: 'All Medical Providers - Tendo Care Connect',
    description: `From bariatrics to urology, view our complete list of participating physicians and medical providers. 
    Compare local prices, save money, and book your procedure — all in one place.`,
  },
  [pageRoutes.A_TO_Z_HOSPITALS_URL]: {
    title: 'All Hospitals - MDsave',
    careConnectTitle: 'All Hospitals - Tendo Care Connect',
    description: `Get predictable, upfront pricing and no surprise bills on out-of-pocket medical services at these 
    MDsave partner hospitals.`,
  },
};

export const patientCarts = {
  helmetTitle: mainTitle,
  helmetCareConnectTitle: 'Patient Carts - Tendo Care Connect',
  helmetDescription: mainDescription,
};

export const regionalProcedure = (
  name,
  indefiniteName,
  location,
  isPortalUser
) => ({
  title: `${name} Cost in ${location} - ${
    isPortalUser ? 'Tendo Care Connect' : 'MDsave'
  }`,
  description: `Learn how much ${indefiniteName} costs in ${location}. Get the best price for ${indefiniteName} with MDsave.`,
});

export const checkout = {
  helmetTitle: 'Check Out - MDsave',
  helmetCareConnectTitle: 'Check Out - Tendo Care Connect',
  helmetDescription:
    `Compare local prices, save money, and book your procedure — all in one place. ` +
    `Choose from over 1,000 procedures near you. ` +
    `From imaging to cardiac assessments, view our complete list of services and participating physicians.`,
};

export const employers = {
  helmetTitle:
    'Employers and Care Navigators: Information and Enrollment - Tendo Care Connect',
  helmetDescription:
    'Employers, Care Navigators, Brokers, TPAs, and Benefits Advisors who manage healthcare costs can save money on' +
    ' self-funded health plans by offering employees out-of-pocket savings through Tendo Care Connect.',
};

export const importProviders = {
  helmetTitle: 'MDsave - Feels Better Already',
  helmetDescription:
    'Shopping online for healthcare has arrived. Compare local prices, buy procedures online, ' +
    'and save an average of 50%. No membership fees. No surprise bills.',
};

export const dataImport = {
  helmetTitle: 'MDsave - Feels Better Already',
  helmetDescription:
    'Shopping online for healthcare has arrived. Compare local prices, buy procedures online, ' +
    'and save an average of 50%. No membership fees. No surprise bills.',
};
