import React from 'react';
import { Badge, Themes, Icon } from 'md-styled-components';
import propTypes from 'prop-types';

const ItemBadge = ({ shoppingCartQuantity }) => (
  <Badge
    count={shoppingCartQuantity}
    bgColor={Themes['secondary-main-color']}
    textColor={Themes.white}
  >
    <Icon
      type='shopping-cart'
      color={Themes.indigo}
      iconClassName='mr--0'
      size={20}
    />
  </Badge>
);

ItemBadge.propTypes = {
  shoppingCartQuantity: propTypes.number,
};

export default ItemBadge;
