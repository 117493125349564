import { Highlighter, Typography } from 'md-styled-components';

const { Text } = Typography;

export const renderMenu = (results, inputValue) =>
  results
    .map((item) => ({
      value: item.text,
      key: item.value,
      label: (
        <div className='info-wrapper'>
          <Text level={3}>
            <Highlighter
              searchWords={[inputValue]}
              textToHighlight={item.text}
              autoEscape
            />
          </Text>
        </div>
      ),
      data: item,
    }));
