import React from 'react';
import { NavigationMenu, Button } from 'md-styled-components-v2';
import { common } from 'constants';
import { useSelector } from 'react-redux';
import { getTabsByUserType } from 'selectors/users';
import '../../_styles/menu-inline.less';

const { Item } = NavigationMenu;

const Menu = () => {
  const tabsByUserType = useSelector(getTabsByUserType);

  return (
    <NavigationMenu mode='inline'>
      {tabsByUserType
        ?.filter(
          (tab) => (tab.href || tab.to).indexOf(common.signOutUrl) === -1
        )
        .map((item) => (
          <Item key={item.href}>
            <Button
              type='link'
              href={item.href}
              className='mh--24 menu-inline__link'
            >
              {item.name}
            </Button>
          </Item>
        ))}
    </NavigationMenu>
  );
};

export default Menu;
