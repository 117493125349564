import useAxios from 'axios-hooks';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { requestGetItems } from 'actions/shopping-cart';
import { getIsProxyUser } from 'selectors/users';
import { getSelectedPatientCart } from 'selectors/shopping-cart';
import { EDIT_KEY, SET_INITIAL_DATA, SET_SELECTED_PATIENT } from '../constants';
import { getValuesFromSession } from '../Form/_actions/actions';
import { someOfRequiredIsEmpty } from '../_helper';
import { FIRST_NAME, LAST_NAME } from '../Form/_constants/fieldNames';

/**
 * Custom hook to initialize the checkout process by setting up initial data
 * and managing patient-related logic based on session and user status.
 *
 * @param {Object} params - Parameters for initialization.
 * @param {Function} params.dispatch - The dispatch function from the checkout context.
 * @param {Object} params.state - The current state of the checkout.
 *
 * This hook performs the following actions:
 * 1. Fetches initialization data from the server using an `authKey`.
 * 2. Dispatches an action to request items if the initialization data is available.
 * 3. Sets initial data in the checkout context, including handling whether the checkout is for editing or someone else.
 * 4. Updates the selected patient if the user is a proxy and a patient cart is available.
 * 5. Sets the selected patient data based on session values and form fields if the user is a proxy and no patient is currently selected.
 */
const useInitCheckout = ({ dispatch: checkoutDispatch, state }) => {
  const dispatch = useDispatch();
  const isProxyUser = useSelector(getIsProxyUser);
  const { authKey } = useParams();
  const selectedPatientCart = useSelector(getSelectedPatientCart);
  const checkoutFieldsValues = getValuesFromSession();
  const { selectedPatient } = state;

  const [{ data: response }] = useAxios({
    url: '/mainCheckout/initCheckout',
    method: 'GET',
    params: { authKey },
  });
  const { data } = { ...response };

  useEffect(
    () => {
      if (!data) return;
      if (data.carePackageLink) {
        window.location = data.carePackageLink;
      }
      dispatch(requestGetItems.request());
      const hasEmptyValue = someOfRequiredIsEmpty(data.selectedPatient);
      checkoutDispatch({
        type: SET_INITIAL_DATA,
        data: { ...data, editOrForSomeone: hasEmptyValue ? EDIT_KEY : false },
      });
    },
    // eslint-disable-next-line
    [data]
  );

  useEffect(() => {
    if (isProxyUser && authKey && selectedPatientCart?.id) {
      checkoutDispatch({
        type: SET_SELECTED_PATIENT,
        selectedPatient: selectedPatientCart,
        customerId: selectedPatientCart?.id,
      });
    }
  }, [isProxyUser, authKey, selectedPatientCart?.id, checkoutDispatch]);

  useEffect(() => {
    const patientName = [FIRST_NAME, LAST_NAME];
    const fieldKeys = Object.keys(checkoutFieldsValues);
    if (
      isProxyUser &&
      !selectedPatient &&
      fieldKeys.length &&
      patientName.some((item) => fieldKeys.includes(item))
    ) {
      checkoutDispatch({
        type: SET_SELECTED_PATIENT,
        selectedPatient: checkoutFieldsValues,
        createNewPatient: !checkoutFieldsValues.id,
      });
    }
  }, [isProxyUser, checkoutDispatch, selectedPatient, checkoutFieldsValues]);
};

export default useInitCheckout;
