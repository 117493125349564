import Theme from 'md-styled-components/Themes';

export default `
  color: ${Theme.red};
  path {
    fill: ${Theme.red};
  }
    
  &:hover {
    color: ${Theme['tall-poppy']};
    path {
      fill: ${Theme['tall-poppy']};
    }
  } 
    
  &[disabled] {
    color: ${Theme.I50};
    path {
      fill: ${Theme.I50};
    }
  }
`;
