import { Form } from 'md-styled-components-v2';
import dayjs from 'dayjs';

const futureDateErrorMessage = 'Date of birth cannot be a date in the future.';

export const useCheckFutureDate = (form) => {
  const bMonth = Form.useWatch('b_month', form);
  const bYear = Form.useWatch('b_year', form);
  const bDay = Form.useWatch('b_day', form);

  const { setFields } = form;

  const checkFutureDate =
    bDay &&
    bMonth &&
    bYear &&
    dayjs().isBefore(`${bYear}-${bMonth}-${bDay}`, 'day');

  const monthValidator = () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.reject(new Error('Month cannot be blank.'));
      }
      if (checkFutureDate) {
        return Promise.reject(new Error(futureDateErrorMessage));
      }
      return Promise.resolve();
    },
  });

  const futureDateValidation = () => {
    if (checkFutureDate) {
      setFields([
        {
          name: 'b_month',
          errors: [futureDateErrorMessage],
        },
      ]);
      return false;
    }
    setFields([
      {
        name: 'b_month',
        errors: [],
      },
    ]);
    return true;
  };

  return { monthValidator, futureDateValidation };
};
