import React, { useMemo } from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Icon, Logo } from 'md-styled-components';
import { Link } from 'md-styled-components-v2';
import { getIsCmsUser } from 'redux/selectors/users';
import { getCurrentUser } from 'selectors/users';
import { isGroupPage } from 'utils/pages';
import TendoLogo from 'components/TendoLogo';
import { useGetDirectUrlByUserType } from '../../hooks';
import '../_styles/logo.less';

const LogoComponent = ({
  isInFooter = false,
  showTendo = false,
  blockClass = '',
}) => {
  const location = useLocation();
  const groupId = new URLSearchParams(location.search).get('GroupId');
  const isCmsUser = useSelector(getIsCmsUser);
  const groupPage = isGroupPage(location);

  const currentUser = useSelector(getCurrentUser);
  const logoUrl = useGetDirectUrlByUserType(
    groupId || currentUser?.groupInfo?.GroupId,
    groupPage
  );

  const mdSaveLogo = useMemo(() => {
    switch (true) {
      case isInFooter:
        return {
          logoIcon: 'provider-logo',
          logoClass: 'provider-logo-box flex-display flex-align-items-center',
          logoType: groupPage ? 'mdsave-care-connect' : 'logo-color',
        };
      case groupPage:
        return {
          logoIcon: '',
          logoClass:
            'pr--32 logo__providers flex-display flex-align-items-center',
          logoType: 'mdsave-care-connect',
        };
      case isCmsUser:
        return {
          logoIcon: 'logo__internal-icon',
          logoClass: '',
          logoType: 'internal-logo',
        };
      default:
        return {
          logoIcon: 'logo__base-icon',
          logoClass: '',
          logoType: 'logo-color',
        };
    }
  }, [isCmsUser, isInFooter, groupPage]);

  return (
    <div className={`flex-display flex-align-items-center ${blockClass}`}>
      <Link
        type='standardWithLogo'
        className='flex-display-inline flex-align-items-center'
        reloadDocument
        to={logoUrl}
      >
        <Logo className={mdSaveLogo.logoClass}>
          <Icon
            iconClassName={mdSaveLogo.logoIcon}
            size={groupPage ? 280 : 48}
            type={mdSaveLogo.logoType}
          />
        </Logo>
        {showTendo && !groupPage && (
          <TendoLogo logo='a-tendo-marketplace' className='tendo-logo ml--8' />
        )}
      </Link>
    </div>
  );
};

LogoComponent.propTypes = {
  isInFooter: propTypes.bool,
  showTendo: propTypes.bool,
  blockClass: propTypes.string,
};

export default LogoComponent;
