import React from 'react';
import { useTillTablet } from 'hooks';
import UserInfoLg from './UserInfoLg/index';
import UserInfoSm from './UserInfoSm';
import './_styles/index.less';

const UserInfo = () => {
  const isTillTablet = useTillTablet();

  return isTillTablet ? <UserInfoSm /> : <UserInfoLg />;
};

export default UserInfo;
