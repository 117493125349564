import React from 'react';
import SearchIcon from 'components/Navigation/SearchIcon';
import UserSection from 'components/Navigation/UserSection';
import ShoppingCartDropdown from 'components/Navigation/ShoppingCart/ShoppingCartDropdown';
import { useNavigationContext } from 'components/Navigation/_context/NavigationProvider';
import ConsumerLogo from '../../_partials/Logo';
import Item from '../../_partials/Item';
import Menu from './Menu';

const NavbarLg = () => {
  const { isVisibleSearchIcon } = useNavigationContext();

  return (
    <div className='flex-display flex-justify-space-between pv--16 ph--32 relative'>
      <div className='consumer-navigation__first-container flex-display flex-align-items-center'>
        <ConsumerLogo blockClass='mr--32' />
        <Menu />
      </div>
      <div className='consumer-navigation__second-container flex-display flex-align-items-center flex-justify-content-end'>
        <Item>
          <UserSection />
        </Item>
        {isVisibleSearchIcon && (
          <Item>
            <SearchIcon />
          </Item>
        )}
        <Item>
          <ShoppingCartDropdown />
        </Item>
      </div>
    </div>
  );
};

export default NavbarLg;
