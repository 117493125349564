import { useRef } from 'react';
import { Form } from 'md-styled-components-v2';
import { Row, Col } from 'md-styled-components';
import Procedure from '../../Autocompletes/Procedure';
import Location from '../../Autocompletes/Location';
import useOnFinish from './useOnFinish';

const ProcedureForm = ({ SubmitButton, suffix = '' }) => {
  const [form] = Form.useForm();
  const onFinish = useOnFinish(form);
  const procedureRef = useRef(null);
  const locationRef = useRef(null);

  return (
    <Form form={form} onFinish={onFinish} layout='horizontal'>
      <Row className='form-wrapper'>
        <Col xs={24} md={12} className='without-button pr--4 pr-sm--0'>
          <Form.Item name='procedure'>
            <Procedure suffix={suffix} ref={procedureRef} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} className='right-input-item with-button pt-sm--8'>
          <Form.Item name='location'>
            <Location suffix={suffix} ref={locationRef} />
          </Form.Item>
          <div className='button-container flex-display flex-align-self-top flex-align-items-center'>
            <SubmitButton id='search-procedure-btn' />
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default ProcedureForm;
