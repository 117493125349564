import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'md-styled-components';
import { Link } from 'md-styled-components-v2';
import SupportPhoneLink from 'components/Support/SupportPhoneLink';

const { Paragraph } = Typography;

const OverlayItem = ({
  phone,
  name,
  email,
  label,
  className,
  showPhone,
  loadNumber,
}) => (
  <div className={className}>
    <Paragraph
      className='mds-text--nowrap'
      color='paleSky'
      fontFamily='heavy'
      level={4}
      letterSpacing={3}
      uppercase
    >
      {label}
    </Paragraph>
    {name && <Paragraph className='mt--8'>{name}</Paragraph>}
    {showPhone && (
      <SupportPhoneLink
        className='text-left'
        renderCustomText={(children) => (
          <Paragraph className='mt--8'>{phone || children}</Paragraph>
        )}
        loadNumber={loadNumber}
      />
    )}
    {email && (
      <Link className='text-left' to={`mailto:${email}`} reloadDocument>
        <Paragraph className='mt--8'>{email}</Paragraph>
      </Link>
    )}
  </div>
);

OverlayItem.propTypes = {
  phone: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  showPhone: PropTypes.bool,
  loadNumber: PropTypes.bool,
};

OverlayItem.defaultProps = {
  className: 'mb--32',
  showPhone: true,
};

export default memo(OverlayItem);
