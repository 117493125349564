import React from 'react';
import { Modal } from 'md-styled-components';
import ShoppingCart from 'components/ShoppingCart';
import { enableFields } from 'utils/form';
import { useOptionsShoppingCart } from './_hooks';
import './_styles/index.less';

const ShoppingCartModal = () => {
  const { openCartState, toggleCart } = useOptionsShoppingCart();

  return (
    <Modal
      footer={null}
      open={openCartState}
      onCancel={() => {
        enableFields();
        toggleCart(false);
      }}
      className='p--0 m--0 shopping-cart__modal'
      destroyOnClose
    >
      <ShoppingCart mobile />
    </Modal>
  );
};

export default ShoppingCartModal;
