import { Tabs as TabsStyled } from 'md-styled-components';
import { searchTypes } from '../../constants';
import ProcedureForm from '../../Forms/ProcedureForm';
import ProviderForm from '../../Forms/ProviderForm';
import SearchButtonDefault from '../../SearchButton/SearchButtonDefault';
import './_styles/index.less';

const { PROCEDURES, PROVIDERS } = searchTypes;

const Tabs = () => (
  <div className='fields-tab-search'>
    <TabsStyled
      shape='rounded'
      tabType='figured'
      newFigured={false}
      className='search-tabs'
      items={[
        {
          label: PROCEDURES,
          key: 1,
          children: (
            <ProcedureForm
              SubmitButton={SearchButtonDefault}
              suffix='-navbar-tab'
            />
          ),
          className: 'tab tab-first p-sm--16',
        },
        {
          label: PROVIDERS,
          key: 2,
          children: (
            <ProviderForm
              SubmitButton={SearchButtonDefault}
              suffix='-navbar-tab'
            />
          ),
          className: 'tab p-sm--16 tab-second',
        },
      ]}
    />
  </div>
);

export default Tabs;
