import { Typography } from 'antd';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import themes from '../Themes';

const { Paragraph: Component } = Typography;

const defaultColor = 'indigo';
const defaultFontFamily = 'medium';

const levels = {
  1: { fontSize: 18, lineHeight: 24 },
  2: { fontSize: 16, lineHeight: 22 },
  3: { fontSize: 14, lineHeight: 18 },
  4: { fontSize: 12, lineHeight: 16 },
};

const fontFamilies = {
  heavy: themes['font-sans-bold'],
  [defaultFontFamily]: themes['font-sans-medium'],
  bookOblique: themes['font-sans-oblique'],
  book: themes['font-sans-book'],
  black: themes['font-sans-black'],
};

const colors = {
  secondary: themes.I60,
  grey: themes.I90,
  paleSky: themes.I70,
  [defaultColor]: themes.indigo,
  white: themes.white,
  linkColor: themes['link-color'],
  danger: themes.red,
  astral: themes.astral,
  green: themes['accent-green'],
  mdsaveBlue: themes['mdsave-blue'],
  gold: themes.gold,
};

const Paragraph = styled(
  ({ level, color, fontFamily, letterSpacing, uppercase, ...props }) => (
    <Component {...props} />
  )
)`
  && {
    margin: 0;
    font-family: ${(props) => fontFamilies[props.fontFamily]};
    font-size: ${(props) => levels[props.level].fontSize}px;
    line-height: ${(props) => levels[props.level].lineHeight}px;
    color: ${(props) => colors[props.color]};
    ${(props) =>
      props.letterSpacing &&
      css`
        letter-spacing: ${props.letterSpacing}px;
      `};
    ${(props) =>
      props.uppercase &&
      css`
        text-transform: uppercase;
      `};

    .ant-typography-expand {
      display: block;
      color: ${themes.astral};
      text-decoration: underline;
      margin-left: 0;
    }
  }
`;

Paragraph.propTypes = {
  /** paragraph level */
  level: PropTypes.oneOf(Object.keys(levels).map(Number)),
  fontFamily: PropTypes.oneOf(Object.keys(fontFamilies)),
  color: PropTypes.oneOf(Object.keys(colors)),
  letterSpacing: PropTypes.number,
  uppercase: PropTypes.bool,
};

Paragraph.defaultProps = {
  level: 2,
  fontFamily: defaultFontFamily,
  color: defaultColor,
  uppercase: false,
  as: 'p', // Note: for ellipsis prop the value of `as` have to be false,
};

/** @component */
export default Paragraph;
