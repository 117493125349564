import React from 'react';
import styled, { css } from 'styled-components';
import { Checkbox as Component } from 'antd';
import propTypes from 'prop-types';
import theme from '../Themes';
import './index.less';

const Group = styled(
  React.forwardRef(({ vertical, ...props }, ref) => (
    <Component.Group ref={ref} {...props} />
  ))
)`
  ${(props) =>
    props &&
    props.vertical &&
    css`
      display: inline-block;
      font-size: 16px;
      line-height: 1.15 !important;

      .ant-checkbox-wrapper {
        display: block !important;
        margin-inline-start: 0 !important;
        font-family: ${theme['font-sans-book']} !important;
        font-size: ${theme['font-size-base']} !important;
        line-height: 22px !important;

        .ant-checkbox {
          display: inline-block;
          vertical-align: middle;

          + span {
            vertical-align: middle;
          }

          &-inner {
            &:after {
              inset-inline-start: 25% !important;
            }
          }
        }
      }
    `};
`;

const CheckboxWrapper = styled(Component)`
  width: auto;
  font-family: ${theme['font-sans-book']} !important;
  line-height: 1.15 !important;

  .ant-checkbox {
    min-width: 20px;

    + span {
      flex: 1;
      padding-right: 0;
      line-height: initial;
    }

    &-inner:after {
      inset-inline-start: 25.5%;
    }
  }
`;

/** @type {Component} */
const Checkbox = (props) => <CheckboxWrapper {...props} />;

Checkbox.propTypes = {
  /** get focus when component mounted */
  autoFocus: propTypes.bool,
  /** Specifies whether the checkbox is selected. */
  checked: propTypes.bool,
  /** Specifies the initial state: whether or not the checkbox is selected. */
  defaultChecked: propTypes.bool,
  /** Disable checkbox */
  disabled: propTypes.bool,
  /** indeterminate checked state of checkbox */
  indeterminate: propTypes.bool,
  /** The callback function that is triggered when the state changes. */
  onChange: propTypes.func,
};

Checkbox.Group = Group;

Checkbox.Group.propTypes = {
  vertical: propTypes.bool,
};

Checkbox.Group.defaultProps = {
  vertical: false,
};

/** @component */
export default Checkbox;
