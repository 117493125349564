import styled from 'styled-components';
import { Drawer as drawer } from 'antd';
import propTypes from 'prop-types';

const Drawer = styled(drawer)``;

Drawer.propTypes = {
  /** Whether a close (x) button is visible on top right of the modal dialog or not */
  closable: propTypes.bool,
  /** Whether to unmount child components on onClose */
  destroyOnClose: propTypes.bool,
  /** Return the mount node for Modal */
  getContainer: propTypes.instanceOf(HTMLElement),
  /** Whether show mask or not */
  mask: propTypes.bool,
  /** Whether to close the modal dialog when the mask (area outside the modal) is clicked */
  maskClosable: propTypes.bool,
  /** Style for modal's mask element */
  maskStyle: propTypes.object,
  /** Style of floating layer, typically used at least for adjusting the position */
  style: propTypes.object,
  /** Style of popup layer, typically used at least for adjusting the position */
  drawerStyle: propTypes.object,
  /** Style of header part, typically used at least for adjusting the position */
  headerStyle: propTypes.object,
  /** Body style for modal body element. Such as height, padding etc. */
  bodyStyle: propTypes.object,
  /** The modal dialog's title */
  title: propTypes.oneOfType([propTypes.node, propTypes.string]),
  /** Whether the modal dialog is visible or not */
  open: propTypes.bool,
  /** Width of the modal dialog */
  width: propTypes.oneOfType([propTypes.number, propTypes.string]),
  /** Height of the modal dialog */
  height: propTypes.oneOfType([propTypes.number, propTypes.string]),
  /** The class name of the container of the modal dialog */
  className: propTypes.string,
  /** The z-index of the Modal */
  zIndex: propTypes.number,
  /** The placement of the Drawer. */
  placement: propTypes.string,
};

/** @component */
export default Drawer;
