import React from 'react';
import { Header, Typography } from 'md-styled-components';
import MenuContainer from './MenuContainer';

const {
  AutoComplete: { Highlighter },
} = Header;
const { Paragraph } = Typography;

const renderContent = (item, inputValue) => (
  <>
    <Paragraph className='wrap-text'>
      <Highlighter
        highlightClassName='rbt-highlight-strong'
        search={inputValue}
      >
        {item.name}
      </Highlighter>
    </Paragraph>
  </>
);

export default (props) => (
  <MenuContainer {...props} renderContent={renderContent} />
);
