import React from 'react';
import propTypes from 'prop-types';
import { Checkbox, Typography } from 'md-styled-components';
import { Form } from 'md-styled-components-v2';
import { phoneValidation } from 'utils/phoneHelper';
import errors from 'utils/messages/errors';
import { MaskedInput } from '../../../Form/Phone';

const { Text } = Typography;

const ShareByPhone = ({ mobilePhone, inputEl }) => (
  <>
    <Form.Item
      hasFeedback
      className='mb--8'
      label='Phone'
      name='newPhoneNumber'
      initialValue={mobilePhone}
      validateTrigger='onSubmit'
      validateFirst
      rules={[
        {
          required: true,
          message: 'Phone number cannot be blank.',
        },
        {
          validator() {
            return phoneValidation(inputEl)
              ? Promise.reject(errors.phoneNumberNotValid)
              : Promise.resolve();
          },
        },
      ]}
    >
      <MaskedInput ref={inputEl} name='newPhoneNumber' />
    </Form.Item>
    <Form.Item
      name='phonePermission'
      className='mb--0'
      validateTrigger='onSubmit'
      valuePropName='checked'
      rules={[
        {
          required: true,
          transform: (value) => value || undefined,
          type: 'boolean',
          message: 'Please confirm if the patient has given permission.',
        },
      ]}
    >
      <Checkbox className='flex-display-inline'>
        <Text level={2} color='grey' fontFamily='book'>
          Patient has given permission to communicate via text message
        </Text>
      </Checkbox>
    </Form.Item>
  </>
);

ShareByPhone.propTypes = {
  mobilePhone: propTypes.string,
  inputEl: propTypes.object,
};

export default ShareByPhone;
