import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import propTypes from 'prop-types';
import { Icon, Typography, DynamicList as List } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import { createGroupUserAllHospitalsLoading } from 'selectors/groups';
import { setActiveGroupApi } from 'services/groups';

const { Text, Paragraph } = Typography;

const handleClick = async ({
  item,
  setGroupInfo,
  hideDropdown,
  navigate,
  location,
  groupId,
}) => {
  if (item.groupId === groupId) {
    return;
  }
  const info = {
    GroupId: item.groupId,
    GroupName: item.name_of_practice,
  };
  const { status } = await setActiveGroupApi(info);
  if (status === 200) {
    setGroupInfo(info);
    hideDropdown();
    navigate(`${location.pathname}?GroupId=${item.groupId}`);
  }
};

const HospitalsOverlay = ({
  setGroupInfo,
  groupInfo,
  hideDropdown,
  groupId,
  hospitals,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const loading = useSelector(createGroupUserAllHospitalsLoading);
  const isCurrentGroup = useMemo(
    () => (currentGroupId) => currentGroupId === groupInfo.GroupId,
    [groupInfo.GroupId]
  );
  const hospitalList = useMemo(
    () =>
      hospitals.sort((a, b) =>
        a.name_of_practice > b.name_of_practice ? 1 : -1
      ),
    [hospitals]
  );

  return (
    <List
      noPadding
      className='direct-navbar__hospitals-overlay-list'
      loading={loading}
      locale={{
        emptyText: (
          <Paragraph className='pt--16 text-left' color='linkColor'>
            No match found
          </Paragraph>
        ),
      }}
      dataSource={hospitalList}
      renderItem={(item) => (
        <div className='direct-navbar__hospitals-overlay-container--item pv--8'>
          <List.Item key={item.groupId}>
            <List.Item.Meta
              avatar={
                isCurrentGroup(item.groupId) && <Icon type='check' size={12} />
              }
              title={
                <Button
                  type='link'
                  className={isCurrentGroup(item.groupId) ? 'ml--12' : 'ml--24'}
                  onClick={() =>
                    handleClick({
                      item,
                      setGroupInfo,
                      hideDropdown,
                      navigate,
                      location,
                      dispatch,
                      groupId,
                    })
                  }
                >
                  <Text color='grey'>
                    {item.third_party_unique_id} {item.name_of_practice}
                  </Text>
                </Button>
              }
            />
          </List.Item>
        </div>
      )}
    />
  );
};

HospitalsOverlay.propTypes = {
  groupInfo: propTypes.object,
  setGroupInfo: propTypes.func,
  hideDropdown: propTypes.func,
  groupId: propTypes.string,
  hospitals: propTypes.object,
};

export default React.memo(HospitalsOverlay);
