import React from 'react';
import { Typography, Icon, Themes } from 'md-styled-components';
import propTypes from 'prop-types';

const { Text } = Typography;

const SubMenuTitle = ({ title }) => (
  <div className='menu-inline__item flex-display flex-justify-space-between flex-align-items-center ph--24'>
    <Text>{title}</Text>
    <Icon size={12} color={Themes.indigo} type='chevron-down' />
  </div>
);

SubMenuTitle.propTypes = {
  title: propTypes.string.isRequired,
};

export default SubMenuTitle;
