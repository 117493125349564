import { useLocation, useSearchParams } from 'react-router-dom';
import { requestSaveCart } from 'actions/patients';
import { isGroupPage } from 'utils/pages';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getSaveCart } from 'selectors/patients';
import { requestGetItems } from 'actions/shopping-cart';
import { useAlert } from 'contexts/AlertProvider';
import { messages, sesStorage } from 'constants/index';
import { clearSesStorageItem } from 'utils/storage';
import { SuccessFooterData } from '../../modal';

const { CHECKOUT_FIELDS_KEY, DUPLICATE_FIELDS } = sesStorage;

export const useSaveCartRequest = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  return ({ data, serviceDate, icdCode }) => {
    const requestData = {
      data: {
        ehr_number: data.ehr_number || null,
        isChange: false,
        isPublicPage: !isGroupPage(location),
        token: data.token || null,
        user: data,
        user_id: data.id,
        service_date: serviceDate,
        icd_code: icdCode || null,
      },
      groupId: searchParams.get('groupId') || '',
    };

    dispatch(requestSaveCart.request(requestData));
    clearSesStorageItem(CHECKOUT_FIELDS_KEY);
    clearSesStorageItem(DUPLICATE_FIELDS);
    return requestData;
  };
};

export const useSaveCart = ({ cb }) => {
  const saveCart = useSelector(getSaveCart);
  const dispatch = useDispatch();
  const { success, error, close } = useAlert();
  const { somethingHappened } = messages;
  const [successModalMessage, setSuccessModalMessage] = useState({
    title: 'Saved!',
    text: 'Patient cart has been successfully saved.',
  });
  useEffect(() => {
    if (saveCart && saveCart.data) {
      if (saveCart.success) {
        cb();
        success({
          title: successModalMessage.title,
          text: successModalMessage.text,
          footerTopSpace: 16,
          footerData: <SuccessFooterData dispatch={dispatch} onClose={close} />,
        });
        dispatch(requestGetItems.request());
      } else {
        cb();
        error({
          title: 'Thanks!',
          text: somethingHappened,
        });
      }
    }
    return () =>
      dispatch(requestSaveCart.actions.success({ params: {} }, false));
  }, [saveCart, dispatch]);

  return { setSuccessModalMessage };
};
