import React from 'react';
import { Typography } from 'md-styled-components';
import { common } from 'constants/index';
import SupportPhoneLink from 'components/Support/SupportPhoneLink';
import { SPANISH_SPIKING_TEXT } from './_constants';

const { Paragraph, Text } = Typography;
const { workHours } = common;

const PhoneNumber = () => (
  <>
    <SupportPhoneLink
      renderCustomText={(content) => (
        <Text>
          Call <Text color='linkColor' children={content} />
        </Text>
      )}
    />
    <Paragraph level={4} color='grey' className='mt--4 mb--16 mr--32'>
      {workHours}
    </Paragraph>
    <Paragraph level={4} fontFamily='bookOblique' color='grey'>
      {SPANISH_SPIKING_TEXT}
    </Paragraph>
  </>
);

export default PhoneNumber;
