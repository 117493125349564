import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import useAxios from 'axios-hooks';
import { useLocation } from 'react-router-dom';
import {
  getIsPortalUser,
  getCurrentUser,
  getIsGroupUser,
} from 'selectors/users';
import { isAllHospitals } from 'utils/privateSite';
import { getPrivateHospitalData } from 'selectors/privateHospitalData';
import { useGetParams } from 'utils/url';

const STORAGE_ITEM_NAME = 'phone_number_1764255470';
const DEFAULT_NUMBER = '(844) 256-7696';
const DEFAULT_GROUP_NUMBER = '(888) 522-7570';
const AFFILIATE = 'affiliate';

export const showNumber = (phoneNumber) => {
  if (!phoneNumber) {
    const storageItem = JSON.parse(localStorage.getItem(STORAGE_ITEM_NAME));
    phoneNumber = storageItem?.phoneNumber;
  }
  if (!phoneNumber) {
    phoneNumber = DEFAULT_NUMBER;
  }
  const elements = Array.from(document.getElementsByClassName('phone-number'));
  if (elements.length) {
    const regexp = /\([0-9]{3}\) [0-9]{3}-[0-9]{4}/i;
    for (const element of elements) {
      element.href = `tel:${phoneNumber}`;
      element.innerHTML = element.innerHTML.replace(regexp, phoneNumber);
    }
  }
};

const checkUTMParams = (item, utmSource, utmMedium, utmCampaign) =>
  (utmSource && utmSource !== item.utmSource) ||
  (utmMedium && utmMedium !== item.utmMedium) ||
  (utmCampaign && utmCampaign !== item.utmCampaign);

const isValidParams = (utmSource, utmMedium, utmCampaign) =>
  (utmMedium === AFFILIATE && utmSource) ||
  (utmSource && utmMedium && utmCampaign);

const usePhoneNumber = () => {
  const [, requestNumber] = useAxios({}, { manual: true });
  const currentUser = useSelector(getCurrentUser);
  const isPortalUser = useSelector(getIsPortalUser);
  const isGroupUser = useSelector(getIsGroupUser);
  const privateSiteData = useSelector(getPrivateHospitalData);
  const { search } = useLocation();
  const {
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
  } = useGetParams();

  const isValid = useCallback(
    (item) => {
      if (
        !item ||
        (isGroupUser && currentUser?.groupInfo?.GroupId !== item.groupId) ||
        (isPortalUser && currentUser?.portalName !== item.portalName) ||
        (isAllHospitals(privateSiteData?.type) &&
          privateSiteData.privateHospitalName !== item.portalName) ||
        checkUTMParams(item, utmSource, utmMedium, utmCampaign)
      ) {
        return false;
      }
      if ((Date.now() - item.expirationDate) / (1000 * 60 * 60 * 24) > 90) {
        localStorage.removeItem(STORAGE_ITEM_NAME);
        return false;
      }
      return true;
    },
    [
      currentUser?.groupInfo?.GroupId,
      currentUser?.portalName,
      isGroupUser,
      isPortalUser,
      privateSiteData.privateHospitalName,
      privateSiteData?.type,
      utmCampaign,
      utmMedium,
      utmSource,
    ]
  );

  const getNumber = useCallback(() => {
    const storageItem = JSON.parse(localStorage.getItem(STORAGE_ITEM_NAME));
    if (isValid(storageItem)) {
      showNumber(storageItem.phoneNumber);
    } else {
      const item = {
        phoneNumber: null,
        portalName:
          currentUser?.portalName || privateSiteData?.privateHospitalName,
        groupId: currentUser?.groupInfo?.GroupId,
        utmSource,
        utmMedium,
        utmCampaign,
        expirationDate: Date.now(),
      };
      let phoneNumber = storageItem?.phoneNumber || DEFAULT_NUMBER;

      switch (true) {
        case !!isGroupUser:
          item.phoneNumber = DEFAULT_GROUP_NUMBER;
          localStorage.setItem(STORAGE_ITEM_NAME, JSON.stringify(item));
          showNumber(DEFAULT_GROUP_NUMBER);
          break;
        case !!isPortalUser:
        case !!isAllHospitals(privateSiteData?.type):
        case !!isValidParams(utmSource, utmMedium, utmCampaign):
          requestNumber({
            url: `/api/publicSite/getPhoneNumber${search}`,
          }).then((data) => {
            if (data.data?.phoneNumber) {
              phoneNumber = data.data.phoneNumber;
              item.phoneNumber = phoneNumber;
              localStorage.setItem(STORAGE_ITEM_NAME, JSON.stringify(item));
            }
            showNumber(phoneNumber);
          });
          break;
        default:
          showNumber(phoneNumber);
          break;
      }
    }
  }, [
    currentUser?.groupInfo?.GroupId,
    currentUser?.portalName,
    isGroupUser,
    isPortalUser,
    isValid,
    privateSiteData?.privateHospitalName,
    privateSiteData?.type,
    requestNumber,
    search,
    utmCampaign,
    utmMedium,
    utmSource,
  ]);

  useEffect(() => {
    const onloadEvent = () => getNumber();
    window.addEventListener('load', onloadEvent);
    return () => window.removeEventListener('load', onloadEvent);
  }, [getNumber]);
};

export default usePhoneNumber;
