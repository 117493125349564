import Dropdown from './Dropdown';
import SubMenu from './SubMenu';
import TopDropdown from './TopDropdown';
import List from './List';
import PhoneNumber from './PhoneNumber';
import Info from './Info';

const types = {
  dropdown: <Dropdown />,
  submenu: <SubMenu />,
  topDropdown: <TopDropdown />,
  info: <Info />,
  list: <List />,
  phoneNumber: <PhoneNumber />,
};

const ContactUs = ({ type }) => types[type];

export default ContactUs;
