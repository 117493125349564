import { COVERED, EXCLUSIVE_PRICE, VIDEO_VISIT } from 'constants/procedures';

export const DATA = {
  [VIDEO_VISIT]: {
    text: 'Video Visit',
    type: 'info',
    iconName: 'camcorder',
    tooltipText:
      'Video visits occur over the internet on a computer or mobile device.',
  },
  [COVERED]: {
    text: 'Covered',
    type: 'primary',
    iconName: 'shield-checked-v2',
    tooltipText:
      'This procedure is likely covered under the employer plan. Purchasers should verify coverage according to the Plan Document.',
  },
  [EXCLUSIVE_PRICE]: {
    text: 'Exclusive Price',
    type: 'success',
    iconName: 'salary',
    tooltipText: 'This is an exclusive price for MDsave for Employers users.',
  },
};
