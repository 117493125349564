import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Typography from '../Typography';
import themes from '../Themes';

const { Title, Text } = Typography;

const numberWithCommas = (price) =>
  price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const styles = (props) => `
  text-decoration: ${props.remove && 'line-through'};

    &:before {
      content: '$';
      font-family: ${themes['font-sans-medium']} !important;
      font-size: ${props.dollarIsSuper ? '0.5em' : '1em'} !important;
      display: ${props.dollarIsSuper && 'inline-block'};
      vertical-align: ${props.dollarIsSuper && 'super'};
    }
`;

const PriceAreaTitle = styled(({ remove, ...props }) => (
  <Title as='span' {...props} />
))`
  ${(props) => styles(props)}
`;

const PriceAreaText = styled(({ remove, ...props }) => <Text {...props} />)`
  ${(props) => styles(props)}
`;

const FormattedPrice = ({ price, small = false, ...props }) => {
  const PriceArea = small ? PriceAreaText : PriceAreaTitle;
  return <PriceArea {...props}>{numberWithCommas(price)}</PriceArea>;
};

FormattedPrice.propTypes = {
  price: PropTypes.string,
  remove: PropTypes.bool,
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  dollarIsSuper: PropTypes.bool,
  small: PropTypes.bool,
};

export default FormattedPrice;
