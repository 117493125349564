import React from 'react';
import { Link, Button } from 'md-styled-components-v2';
import Item from './Item';

const Patients = () => (
  <div className='flex-display flex-direction-column m--16 pv--4'>
    <Item>
      <Button type='link' href='/patients'>
        How it Works
      </Button>
    </Item>
    <Item>
      <Link to='/faq' size='large'>
        FAQ
      </Link>
    </Item>
    <Item>
      <Button type='link' href='/reviews'>
        Testimonials
      </Button>
    </Item>
    <Item>
      <Link to='/whats-my-health-insurance-deductible' size='large'>
        Deductible Checker
      </Link>
    </Item>
    <Item>
      <Button type='link' href='/healthcare-prices-explained'>
        Healthcare Prices
      </Button>
    </Item>
    {/* <Item>
      <Button type='link' href='/rx'>
        MDsave Rx
      </Button>
    </Item> */}
  </div>
);

export default Patients;
