import { useLocation, useSearchParams } from 'react-router-dom';

export const useGetParamByKey = ({ key }) => {
  const [searchParams] = useSearchParams();
  return +searchParams.get(key) || 1;
};

export const useExcludePageFromUrl = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  searchParams.delete('page');

  if ([...searchParams.keys()].length) {
    return `${pathname}?${searchParams.toString()}`;
  }

  return `${pathname}`;
};
