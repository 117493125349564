import React from 'react';
import { Button } from 'md-styled-components-v2';
import { isPrivateHospital } from 'utils/privateSite';
import propTypes from 'prop-types';
import useAxios from 'axios-hooks';
import { useAlert } from 'contexts/AlertProvider';
import ButtonForm from './Forms/ButtonForm';
import FormWrapper from './Forms/FormWrapper';
import {
  BUTTON_TEXT_LOGIN_WITH_PASSWORD,
  BUTTON_TEXT_SEND_LOGIN_LINK,
} from '../_constants/formTexts';
import {
  changeEmailInitialValueAction,
  changeLoginTypeAction,
} from '../_actions';
import { LOGIN_SECTION } from '../_constants/loginTypes';
import EmailForm from './Forms/EmailForm';
import { getEmailInitialValue } from '../_helpers';
import { USER_ONE_TIME_LOGIN_LINK } from '../_constants/endpoints';

const emailInitialValue = getEmailInitialValue();

const LoginLinkSection = ({ form, dispatch, options }) => {
  const alert = useAlert();
  const { setFields, getFieldValue } = form;
  const [{ loading }, submitUserOneTimeLoginLink] = useAxios(
    {
      url: USER_ONE_TIME_LOGIN_LINK,
      method: 'POST',
    },
    { manual: true }
  );

  const resetErrors = () => {
    setFields([{ name: 'username', errors: [] }]);
  };

  const showLoginSection = () => {
    resetErrors();
    dispatch(changeLoginTypeAction(LOGIN_SECTION));
    dispatch(changeEmailInitialValueAction(getFieldValue('username')));
  };

  const onSubmit = () => {
    const email = getFieldValue('username');
    if (!email) {
      setFields([
        {
          name: 'username',
          errors: ['Email cannot be blank.'],
        },
      ]);
    } else {
      submitUserOneTimeLoginLink({ data: { email } }).then((res) => {
        if (res.data.success) {
          alert.success({ text: 'Great! We sent you an email.' });
        } else {
          setFields([
            {
              name: 'username',
              errors: ['Email is not a valid email address.'],
            },
          ]);
        }
      });
    }
  };

  return (
    <FormWrapper form={form} options={options} onFinish={onSubmit}>
      <EmailForm
        onChangeEmail={resetErrors}
        initialValue={emailInitialValue}
        options={options}
        className='mb-md--24 mb--16'
      />
      <Button
        size='large'
        form={options.form.id}
        htmlType='submit'
        block
        loading={loading}
        type='primary'
      >
        {BUTTON_TEXT_SEND_LOGIN_LINK}
      </Button>
      {!isPrivateHospital() && (
        <ButtonForm
          onClick={showLoginSection}
          options={options}
          title={BUTTON_TEXT_LOGIN_WITH_PASSWORD}
        />
      )}
    </FormWrapper>
  );
};

LoginLinkSection.propTypes = {
  form: propTypes.object.isRequired,
  dispatch: propTypes.func.isRequired,
  options: propTypes.object.isRequired,
};

export default LoginLinkSection;
