import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getIsProxyUser } from 'selectors/users';
import { Form } from 'md-styled-components-v2';
import { getPlaceSuggestions, getPlaceDetails } from 'utils/locationService';
import { matchEmptySpaces } from 'utils/stringHelper';
import { isSearchResultPage } from 'utils/pages';
import { useGetParams } from 'utils/url';
import { getSesStorageItem } from 'utils/storage';
import { sesStorage, common } from 'constants';

const { searchMinLength } = common;

export const useInputChange = (setInputValue) => {
  const form = Form.useFormInstance();
  const [data, setData] = useState([]);
  const isProxyUser = useSelector(getIsProxyUser);

  const onInputChange = useCallback(
    async (event) => {
      const { value: searchText } = event.target;
      if (matchEmptySpaces(searchText)) {
        return;
      }
      setInputValue(searchText);
      if (searchText.length < searchMinLength) {
        form.setFields([{ name: 'location', value: {}, errors: [] }]);
        if (data.length) {
          setData([]);
        }
        return;
      }
      const suggestions = await getPlaceSuggestions({
        searchText,
        isProxyUser,
      });
      setData(suggestions);
      form.setFields([
        {
          name: 'location',
          value: {
            searchText,
            searchData: suggestions,
          },
          errors: [],
        },
      ]);
    },
    [data.length, form, isProxyUser, setInputValue]
  );

  return { onInputChange, data };
};

export const useOnSelect = (setInputValue) => {
  const form = Form.useFormInstance();

  return useCallback(
    async (_, selectedOption) => {
      const { searchText } = form.getFieldValue('location');
      const location = selectedOption.data;
      const selectedLocation = await getPlaceDetails(location.placeId);

      form.setFields([
        {
          name: 'location',
          value: {
            ...selectedLocation,
            name: location.name,
            placeId: location.placeId,
            searchText,
          },
          errors: [],
        },
      ]);
      setInputValue(location.name);
    },
    [form, setInputValue]
  );
};

export const useInit = (setInputValue) => {
  const form = Form.useFormInstance();
  const { zipCode } = useParams();
  const { latLng, city = '', state = '', zip = '' } = useGetParams();

  useEffect(() => {
    let location = getSesStorageItem(sesStorage.locationKey);
    let selectedLocation;
    if (location) {
      location = JSON.parse(location);
      selectedLocation = { ...location, searchText: location.name };
    }
    if (isSearchResultPage()) {
      const locationUri = `&latLng=${latLng}&city=${city}&state=${state}&zip=${zip}`;
      if (
        (city || state || zip) &&
        locationUri !== selectedLocation?.locationUri
      ) {
        const name =
          (city ? `${city}, ` : '') + (state ? `${state} ` : '') + zip;
        selectedLocation = {
          locationUri,
          placeId: zipCode,
          searchText: name,
          name,
        };
      }
    }
    form.setFields([
      {
        name: 'location',
        value: { ...selectedLocation },
        errors: [],
      },
    ]);
    if (selectedLocation) {
      setInputValue(selectedLocation.name);
    }
  }, [city, form, latLng, setInputValue, state, zip, zipCode]);
};

export const useAutocompleteProps = () => {
  const form = Form.useFormInstance();
  const fieldValue = form.getFieldValue('location');

  return {
    placeholder: fieldValue?.placeholder || 'Address, City, or ZIP Code',
    emptyLabel:
      fieldValue?.searchText && !fieldValue?.name
        ? 'Sorry, we could not find a match for that location'
        : '',
    errorClassName: fieldValue?.placeholder ? 'input-error' : '',
  };
};
