import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'md-styled-components';
import { groupUserAccountManagerInfo } from 'selectors/groups';
import { requestGroupUserAccountManager } from 'actions/groups';
import { getIsCmsUser } from 'selectors/users';
import { common } from '../../../../../constants';
import OverlayItem from './OverlayItem';
import GiveFeedback from './GiveFeedback';
import SendUsMessage from './SendUsMessage';

const { supportEmail, MARKETING_TEAM_EMAIL, PAYMENT_TEAM_EMAIL } = common;

const ContactUsOverlay = () => {
  const dispatch = useDispatch();
  const accountManager = useSelector(groupUserAccountManagerInfo);
  const isCmsUser = useSelector(getIsCmsUser);

  useEffect(() => {
    dispatch(requestGroupUserAccountManager.request());
  }, [dispatch]);

  return (
    <>
      <div className='p--24'>
        {accountManager?.show_am && (
          <OverlayItem
            label='Account Manager'
            name={accountManager.name}
            phone={accountManager.phone}
            showPhone={!!accountManager.phone}
            email={accountManager.email}
          />
        )}
        <OverlayItem label='Customer Success' email={supportEmail} loadNumber />
        <OverlayItem
          label='Provider Payment Questions'
          email={PAYMENT_TEAM_EMAIL}
          loadNumber
        />
        <OverlayItem
          className='mb--0'
          label='Marketing'
          showPhone={false}
          email={MARKETING_TEAM_EMAIL}
        />
      </div>
      {!isCmsUser && (
        <>
          <Divider margin='0' />
          <div className='p--24'>
            <SendUsMessage />
            <GiveFeedback />
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(ContactUsOverlay);
