import { withAPIKey } from '@aws/amazon-location-utilities-auth-helper';
import {
  LocationClient,
  SearchPlaceIndexForSuggestionsCommand,
  GetPlaceCommand,
} from '@aws-sdk/client-location';
import { getSesStorageItem } from './storage';
import { sesStorage } from '../constants';

const IndexName = 'MDsavePlaceIndexEsri';
const { REACT_APP_AWS_LOCATION_PUBLIC_KEY } = process.env;

let placeIndex;
(async () => {
  const authHelper = await withAPIKey(REACT_APP_AWS_LOCATION_PUBLIC_KEY);

  placeIndex = new LocationClient({
    region: 'us-east-1',
    ...authHelper.getLocationClientConfig(),
  });
})();

export const getPlaceSuggestions = async ({ searchText, isProxyUser }) => {
  const params = {
    IndexName,
    Text: searchText,
    FilterCountries: ['USA'],
    FilterCategories: [
      'AddressType',
      'StreetType',
      'RegionType',
      'MunicipalityType',
      'PostalCodeType'
    ],
  };

  setBiasPosition(params, isProxyUser);

  try {
    const result = await placeIndex.send(
      new SearchPlaceIndexForSuggestionsCommand(params)
    );
    return result?.Results.reduce((response, item) => {
      if (item.PlaceId) {
        response.push({
          name: item.Text.replace(/(, USA$)/, ''),
          placeId: item.PlaceId,
        });
      }
      return response;
    }, []);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('AWS Location Service Error: ', e);
    return [];
  }
};

export const getPlaceDetails = async (placeId) => {
  const params = {
    IndexName,
    PlaceId: placeId,
  };

  try {
    const result = await placeIndex.send(new GetPlaceCommand(params));
    const { Place } = result;
    const { Geometry, Municipality = '', PostalCode = '', Region = '' } = Place;
    const location = getLatLng(Geometry);
    const latLng = `${location.lat},${location.lng}`;
    return {
      location,
      zipCode: PostalCode,
      city: Municipality,
      state: Region,
      latLng,
      locationUri: `&latLng=${latLng}&city=${Municipality}&state=${Region}&zip=${PostalCode}`,
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('AWS Location Service Error: ', e);
    return [];
  }
};

const getLatLng = (Geometry) => ({
  lat: Geometry.Point[1],
  lng: Geometry.Point[0],
});

const setBiasPosition = (params, isProxyUser) => {
  if (!isProxyUser) {
    let location = getSesStorageItem(sesStorage.locationKey);
    if (location) {
      location = JSON.parse(location);
      params.BiasPosition = [location.location.lng, location.location.lat];
    }
  }
};
