import React from 'react';
import { Breadcrumb, Typography } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import propTypes from 'prop-types';

const { Item } = Breadcrumb;
const { Text } = Typography;

const BreadcrumbComponent = ({ groupInfo }) => (
  <Breadcrumb className='hospital-info__breadcrumb'>
    <Item>
      <Button type='link' href='/bbcms'>
        Home
      </Button>
    </Item>
    <Item>
      <Button type='link' href='/groups'>
        Groups
      </Button>
    </Item>
    <Item>
      <Text level={3}>{groupInfo.GroupName}</Text>
    </Item>
  </Breadcrumb>
);

BreadcrumbComponent.propTypes = {
  groupInfo: propTypes.object,
};

export default React.memo(BreadcrumbComponent);
