import React, { useMemo } from 'react';
import propTypes from 'prop-types';
import { Themes as themes } from 'md-styled-components';
import cloneDeep from 'lodash/cloneDeep';
import useSearch from 'components/SearchTabs/useSearch';
import { Search as SearchTab } from 'components/SearchTabs/Search';
import SystemProcedureOptions from 'components/SearchTabs/_options/SystemProcedureOptions';
import ProcedureInsuranceOptions from 'components/SearchTabs/_options/ProcedureInsuranceOptions';
import { isSystem } from 'utils/groupSystemHelpers';
import { useBreakpoint as breakpoint } from '../../../hooks';

export const setSearchOptions = (optionData, newOptions) => {
  Object.keys(newOptions).forEach((key) => {
    optionData[key] = newOptions[key];
  });
};

export const useSearchOptions = (
  showTransparencyPrice,
  transparencyOptions,
  regularOptions
) =>
  useMemo(
    () =>
      cloneDeep(showTransparencyPrice ? transparencyOptions : regularOptions),
    [showTransparencyPrice, transparencyOptions, regularOptions]
  );

const Search = ({ slug, groupId, insuranceExists, roundedBorder }) => {
  const {
    renderAutoComplete: procedureAutocomplete,
    setAutocompleteClass: procedureSetAutocompleteClass,
  } = useSearch();
  const {
    renderAutoComplete: insuranceAutocomplete,
    setAutocompleteClass: insuranceSetAutocompleteClass,
  } = useSearch();
  const isMobile = breakpoint(themes.breakpoints.xs, themes.breakpoints.sm);
  const procedureOptions = useSearchOptions(
    insuranceExists,
    ProcedureInsuranceOptions,
    SystemProcedureOptions
  );
  const searchOptions = useMemo(
    () =>
      [procedureOptions].map((option, key) => {
        setSearchOptions(option.data[0], {
          params: { group_id: groupId },
          slug,
          border: !isMobile,
          renderAutoComplete: procedureAutocomplete,
          setAutocompleteClass: procedureSetAutocompleteClass,
        });
        if (insuranceExists) {
          setSearchOptions(option.data[1], {
            border: !isMobile,
            params: { groupId: isSystem(groupId) ? groupId : [groupId] },
            rounded: roundedBorder && 'right',
            renderAutoComplete: insuranceAutocomplete,
            setAutocompleteClass: insuranceSetAutocompleteClass,
          });
        }
        return option;
      }),
    [
      slug,
      groupId,
      procedureAutocomplete,
      insuranceAutocomplete,
      isMobile,
      insuranceExists,
      roundedBorder,
      procedureOptions,
      insuranceSetAutocompleteClass,
      procedureSetAutocompleteClass,
    ]
  );
  return <SearchTab options={searchOptions} />;
};

Search.propTypes = {
  slug: propTypes.string.isRequired,
  groupId: propTypes.oneOfType([propTypes.number, propTypes.array]),
  insuranceExists: propTypes.bool,
  roundedBorder: propTypes.bool.isRequired,
};

Search.defaultProps = {
  roundedBorder: false,
};

export default Search;
