import Axios from 'axios';
import { configure } from 'axios-hooks';
import { APP_APTIBLE_URL } from '../config/env-vars';
import initCsrf from '../csrf';

const client = Axios.create({
  baseURL: APP_APTIBLE_URL, // url of the api
  withCredentials: true,
  headers: {
    'React-App': true,
    'X-Requested-With': 'XMLHttpRequest',
  },
});

export const redirectToUrl = (url, externalUrl = 0) => {
  window.location.href =
    APP_APTIBLE_URL && !externalUrl ? `${APP_APTIBLE_URL}${url}` : url;
};

configure({ axios: client });

export default client;
