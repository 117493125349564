import { Input as Component } from 'antd';
import { Icon } from 'md-styled-components';
import React from 'react';
import styled, { css } from 'styled-components';
import themes from '../../Themes';
import useDisableScrollOnWheel from './useDisableScrollOnWheel';

export const defaultStyles = css`
  box-shadow: none !important;
  border: 1px solid ${themes.I50};
  border-radius: 0;
  color: ${themes.I90} !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  padding: 10px 16px;
  transition: all 0.3s;
  font-family: ${themes['font-sans-book']} !important;
  width: 100%;

  &:hover,
  &:focus {
    border-color: ${themes.astral} !important;
  }

  .ant-input-prefix {
    padding-right: 8px;
  }

  .ant-input {
    font-size: 16px !important;
    color: ${themes.I90} !important;
    font-family: ${themes['font-sans-book']} !important;

    &-number-input {
      font-family: ${themes['font-sans-book']} !important;
    }

    &:-webkit-autofill,
    &:hover,
    &:focus,
    &:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    &-suffix {
      .ant-input-clear-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        line-height: 10px;
        margin-left: 0;
        margin-inline-end: 0;
        background-color: ${themes.I05};
        border-radius: 50%;

        &:hover {
          background-color: ${themes.I10};
        }
      }
    }

    &-prefix {
      margin-inline-end: 8px;

      span {
        display: flex;
      }
    }
  }

  &.ant-input-affix-wrapper-disabled {
    .ant-input {
      -webkit-box-shadow: none !important;
    }
  }

  .ant-input-number {
    &-input {
      padding: 0;
      border-radius: 0;
      line-height: 1;
      height: auto;
      color: ${themes.I90};
    }
  }
`;

const StyledInput = styled(Component)`
  height: ${themes['input-height-base']} !important;
  ${defaultStyles}
`;

/** @type {Component} */
const Input = React.forwardRef(({ allowClear, ...props }, ref) => {
  useDisableScrollOnWheel();

  return (
    <StyledInput
      {...props}
      ref={ref}
      allowClear={
        allowClear === true
          ? {
              clearIcon: (
                <Icon
                  color={themes.I90}
                  type='cancel'
                  size={12}
                  aria-label='clear'
                />
              ),
            }
          : allowClear
      }
    />
  );
});

export default Input;
