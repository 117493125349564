import React from 'react';
import { BaseSelect as Select } from 'md-styled-components-v2';
import PropTypes from 'prop-types';

const { Option } = Select;

const BaseSelect = ({
  setIsReset,
  placeholder,
  onSelect,
  options,
  getOptionValue,
  onBlur,
  onChange,
  value,
  setValue,
}) => {
  const handleSelectChange = (selectedValue) => {
    setValue(selectedValue);
    if (onChange) {
      setIsReset(false);
      onChange(selectedValue);
    }
    if (onSelect) {
      onSelect(selectedValue);
    }
  };

  return (
    <Select
      className='phi'
      placeholder={placeholder}
      value={value}
      onChange={(e) => handleSelectChange(e.target.value)}
      onBlur={() => {
        if (onBlur) {
          setIsReset(false);
          onBlur();
        }
      }}
    >
      {options.map((option, index) => (
        <Option
          value={getOptionValue ? getOptionValue(option) : option}
          key={index}
        >
          {option}
        </Option>
      ))}
    </Select>
  );
};

BaseSelect.propTypes = {
  setIsReset: PropTypes.func,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  options: PropTypes.array,
  getOptionValue: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  setValue: PropTypes.func,
};

export default BaseSelect;
