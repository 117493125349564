import Theme from 'md-styled-components/Themes';

export default `
  &&& {
    color: ${Theme['primary-cta']};
    path {
      fill: ${Theme['primary-cta']};
    }
        
    &:hover {
      color: ${Theme['mdsave-blue']};
      path {
        fill: ${Theme['mdsave-blue']};
      }
    }
      
    &[disabled] {
      color: ${Theme.I50};
      path {
        fill: ${Theme.I50};
      }
    }
  }
`;
