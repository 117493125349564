import React from 'react';
import { Typography } from 'md-styled-components';
import { useSelector } from 'react-redux';
import { getCompanyUserCompanies } from 'selectors/users';
import Accounts from '../Accounts';
import Networks from '../Networks';
import AccountWithNetwork from '../AccountsWithNetwork';

const { Paragraph } = Typography;

const Title = ({ children }) => (
  <Paragraph fontFamily='heavy' letterSpacing={2} className='text-center'>
    {children}
  </Paragraph>
);

export const useViewCompanies = () => {
  const companies = useSelector(getCompanyUserCompanies);
  const networks = companies?.companiesList.filter(
    (item) => item.pricingGroups.length > 1
  );

  switch (true) {
    case !networks.length:
      return {
        Title: () => <Title>SELECT AN ACCOUNT</Title>,
        View: () => <Accounts />,
      };
    case companies.companiesList.length === 1:
      return {
        Title: () => <Title>SELECT A NETWORK</Title>,
        View: () => <Networks />,
      };
    case networks.length && companies.companiesList.length > 1:
      return {
        Title: () => <Title>SELECT A NETWORK</Title>,
        View: () => <AccountWithNetwork />,
      };
    default:
      return { Title: () => <></>, View: () => <></> };
  }
};
