import React from 'react';
import { Dropdown, Typography, Icon, Themes } from 'md-styled-components';
import Info from './Info';
import './_styles/contact-us-info.less';

const { Text } = Typography;

const TopDropdown = () => (
  <Dropdown
    className='contact-us-info__dropdown pointer'
    overlayClassName='contact-us-info__dropdown-overlay'
    placement='bottomRight'
    trigger={['hover']}
    dropdownRender={() => (
      <div className='contact-us-info p--24'>
        <Info />
      </div>
    )}
    getPopupContainer={(trigger) => trigger.parentNode}
  >
    <div className='flex-display flex-align-items-center'>
      <Text level={3}>Contact Us</Text>
      <Icon
        size={12}
        color={Themes.indigo}
        type='chevron-down'
        className='contact-us-info__dropdown-icon ml--8 mb--2'
      />
    </div>
  </Dropdown>
);

export default TopDropdown;
