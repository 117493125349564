import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Row, Col, Divider, Typography } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import { createGetSaveCartLoading } from 'selectors/patients';
import { getAllItems, getSelectedPatientCart } from 'selectors/shopping-cart';
import { getIsCmsUser } from 'selectors/users';
import { requestClearCart } from 'actions/shopping-cart';
import { requestSaveCart } from 'actions/patients';
import { closeCart } from 'components/Cart/modal';
import SaveCart from 'components/Cart/save';
import ContactInfoModal from 'components/Modals/ShopingCardModals/ContacInfoModal';
import DefaultModal from 'components/Modals/DefaultModal';
import ShareCart from 'components/Cart/share';
import { shareTabKeys } from 'components/Cart/share/constants';
import { usePermission } from 'utils/permissions/hooks';
import RemoveConfirmation from './RemoveConfirmation';
import './_styles/direct-cart.less';

const { Text } = Typography;

const CartButton = (props) => (
  <Button type='tertiary' size='small' block {...props}>
    {props.children}
  </Button>
);

export default (props) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const selectedPatientCart = useSelector(getSelectedPatientCart);
  const saveCart = usePermission({ permissionName: 'saveCart' });
  const isCmsUser = useSelector(getIsCmsUser);
  const shoppingCart = useSelector(getAllItems);
  const saveCartLoading = useSelector(createGetSaveCartLoading);
  const [visibleInfoModal, setVisibleInfoModal] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [visibleRemoveModal, setVisibleRemoveModal] = useState(false);
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);
  const { totalData, openNoteModal } = props;
  const manage = [
    {
      name: 'print',
      onClick: (event) => {
        event.preventDefault();
        setIsShareModalVisible(true);
      },
      title: 'Print',
    },
    {
      name: 'compose',
      onClick: (event) => {
        event.preventDefault();
        openNoteModal();
      },
      title: 'Add Note',
    },
    {
      name: 'trash',
      onClick: (event) => {
        event.preventDefault();
        setVisibleRemoveModal(true);
      },
      title: 'Delete',
    },
  ];
  const showSaveCloseCart = useMemo(
    () => saveCart === true || isCmsUser,
    [isCmsUser, saveCart]
  );

  return (
    <div className='ph--24'>
      <Row>
        <Col span={24}>
          {showSaveCloseCart &&
            (selectedPatientCart ? (
              <CartButton
                loading={saveCartLoading}
                onClick={() => closeCart(dispatch, searchParams.get('groupId'))}
                children='Save & Close Cart'
              />
            ) : (
              <CartButton
                onClick={() => setIsSaveModalVisible(true)}
                children='Save Cart'
              />
            ))}
        </Col>
      </Row>
      <div className='ph--4 mt--16 flex-display flex-justify-space-between'>
        {manage.map(({ name, onClick, title }) => (
          <Button
            className='flex-display flex-align-items-center direct-cart__actions'
            type='link'
            onClick={onClick}
            size='large'
            key={name}
            iconType={name}
            iconSize={20}
          >
            {title}
          </Button>
        ))}
      </div>
      {totalData.note && (
        <div className='shopping-cart__note p--8'>
          <div className='shopping-cart__note-text pb--4'>
            <Text level={3} color='paleSky' fontFamily='heavy'>
              Note:{' '}
            </Text>
            <Text level={3} color='paleSky'>
              {totalData.note}
            </Text>
          </div>
          <Text
            color='linkColor'
            level={3}
            onClick={openNoteModal}
            className='shopping-cart__edit-note'
          >
            View / Edit
          </Text>
        </div>
      )}
      <Divider className='direct-cart__divider' />
      <ContactInfoModal
        visible={visibleInfoModal}
        onCancel={() => setVisibleInfoModal(false)}
      />
      <ShareCart
        isModalVisible={isShareModalVisible}
        setIsModalVisible={setIsShareModalVisible}
        data={{ ...shoppingCart, activeTabKey: shareTabKeys.pdf }}
      />
      <DefaultModal
        visible={visibleRemoveModal}
        onSuccess={() => {
          dispatch(
            requestClearCart.request({
              cb: () =>
                dispatch(
                  requestSaveCart.request({ data: { closeCart: true } })
                ),
            })
          );
        }}
        onCancel={() => setVisibleRemoveModal(false)}
        cancelButtonText='Cancel'
        successButtonText='Delete'
        text='Are you sure you want to delete this cart? This can’t be undone.'
        title='Delete Cart?'
        buttonClassName='error'
      />
      <RemoveConfirmation
        visible={visibleRemoveModal}
        onSuccess={() => {
          dispatch(
            requestClearCart.request({
              cb: () =>
                dispatch(
                  requestSaveCart.request({ data: { closeCart: true } })
                ),
            })
          );
        }}
        onCancel={() => setVisibleRemoveModal(false)}
        text='Are you sure you want to delete this cart? This can’t be undone.'
        title='Delete Cart?'
        buttonClassName=''
      />
      <SaveCart
        isModalVisible={isSaveModalVisible}
        setIsModalVisible={setIsSaveModalVisible}
      />
    </div>
  );
};
