import React from 'react';
import propTypes from 'prop-types';
import './_styles/index.less';

const TopNavigation = ({ navFixed = false, children }) => (
  <div
    className={`top-navigation flex-display flex-justify-space-between flex-align-items-center ph--32 ph-sm--16 ${
      navFixed && 'top-navigation--fixed pr--16'
    }`}
  >
    {children}
  </div>
);

TopNavigation.propTypes = {
  navFixed: propTypes.bool,
};

export default TopNavigation;
