import propTypes from 'prop-types';
import { Typography } from 'md-styled-components';

const { Text } = Typography;

const EmptyLabel = ({ patientSearchText }) => (
  <Text
    level={3}
    color='paleSky'
    className='save-cart__for-patient-menu-no-result'
  >
    Sorry, we could not find a match for "{patientSearchText}"
  </Text>
);

EmptyLabel.propTypes = {
  patientSearchText: propTypes.string.isRequired,
};

export default EmptyLabel;
