export const GROUP_USER_PERMISSIONS = {
  // permissions
  SAVED_CARTS: 14,
  VOUCHERS: 1,
  // subPermissions
  VIEW: 0,
  EDIT_PATIENT_ACCOUNT_NUMBER: 4,
  PAYMENT_PAGE: 5,
  EDIT_PATIENT_INFO: 6,
  EDIT: 1,
  UNREDEEM: 3,
};
