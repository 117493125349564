import { getPermissions } from 'selectors/users';
import { useSelector } from 'react-redux';
import { GROUP_USER_PERMISSIONS } from '../constants/index';

export const usePermission = ({ permissionName = '' }) => {
  const currentUserPermissions = useSelector(getPermissions);
  const isPermitted = (currentAction, actionType) =>
    currentUserPermissions?.some(
      (permission) =>
        permission.permission_parent === currentAction &&
        permission.permission_child === actionType
    );

  switch (permissionName) {
    case 'saveCart': {
      return isPermitted(
        GROUP_USER_PERMISSIONS.SAVED_CARTS,
        GROUP_USER_PERMISSIONS.VIEW
      );
    }
    case 'voucherEditAccountNumber': {
      return isPermitted(
        GROUP_USER_PERMISSIONS.VOUCHERS,
        GROUP_USER_PERMISSIONS.EDIT_PATIENT_ACCOUNT_NUMBER
      );
    }
    case 'voucherPaymentPage': {
      return isPermitted(
        GROUP_USER_PERMISSIONS.VOUCHERS,
        GROUP_USER_PERMISSIONS.PAYMENT_PAGE
      );
    }
    case 'voucherEditPatientInfo': {
      return isPermitted(
        GROUP_USER_PERMISSIONS.VOUCHERS,
        GROUP_USER_PERMISSIONS.EDIT_PATIENT_INFO
      );
    }
    case 'voucherEdit': {
      return isPermitted(
        GROUP_USER_PERMISSIONS.VOUCHERS,
        GROUP_USER_PERMISSIONS.EDIT
      );
    }
    case 'voucherUnredeem': {
      return isPermitted(
        GROUP_USER_PERMISSIONS.VOUCHERS,
        GROUP_USER_PERMISSIONS.UNREDEEM
      );
    }
    default:
      return false;
  }
};
