import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'selectors/users';
import AuthorizationItem from './AuthorizationItem';
import UserInfo from './UserInfo';

const UserSection = () => {
  const currentUser = useSelector(getCurrentUser);
  const userComponent = currentUser?.guest ? (
    <AuthorizationItem />
  ) : (
    <UserInfo />
  );
  return currentUser ? userComponent : null;
};

export default UserSection;
