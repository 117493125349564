import React from 'react';
import { procedureOptionsFields } from '../../save/_partials/Form';
import PatientFormItem from '../../save/_partials/PatientFormItem';

const ServiceDetails = ({ getFieldError, inputEl }) =>
  procedureOptionsFields(true).map((item) => (
    <PatientFormItem
      formItem={item}
      getFieldError={getFieldError}
      inputEl={inputEl}
      key={item.name}
    />
  ));

export default ServiceDetails;
