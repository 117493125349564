import { formatQuery } from 'utils/url';
import { existingData } from '../helpers';
import { ProceduresOption } from './DefaultOptions';

const SystemProcedureOptions = {
  name: 'Procedures',
  data: [
    {
      ...ProceduresOption,
      rounded: 'right',
      border: true,
      renderMenuItemClass: 'search-form-wrapper--non-border procedure-item',
      className:
        'search-form-wrapper--non-margin search-form-wrapper--non-border procedure-autocomplete',
      takeFirstOnEnter: true,
    },
  ],
  redirectOnChange: true,
  redirect: (items, callback) => {
    const { dataSource, optionsSelected, search, slug } = items[0];
    if (!search.text) {
      return;
    }
    const getSearchUri = (searchSubDirectory, encodedQuery, selectedItem) =>
      selectedItem
        ? `${searchSubDirectory}?q=${encodedQuery}&uri=${selectedItem.uri.replace(
            'f/procedure/',
            ''
          )}&p=${selectedItem.id}`.replace('/', '')
        : `${searchSubDirectory}?q=${encodedQuery}`;
    const searchSubDirectory = `${slug}-price-list`;
    const encodedQuery = formatQuery(search.text);
    let url = optionsSelected
      ? getSearchUri(searchSubDirectory, optionsSelected.name, optionsSelected)
      : getSearchUri(searchSubDirectory, encodedQuery);
    const procedure = existingData(dataSource, 'name', search.text);
    if (procedure) {
      url = getSearchUri(searchSubDirectory, procedure.name, procedure);
    }
    callback(`/${url}`);
  },
};

export default SystemProcedureOptions;
