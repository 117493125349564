import React from 'react';
import propTypes from 'prop-types';
import { Form } from 'md-styled-components-v2';
import Label from '../../../Form/Label';
import { renderFormItem } from './Form';

const PatientFormItem = ({ formItem, getFieldError, inputEl }) => (
  <Form.Item
    className={`save-cart__form-item ${formItem.formItemClassName || ''} ${
      formItem.fullWidth ? 'save-cart__form-item--full' : ''
    }`}
    key={`form-item-${formItem.name}`}
    hasFeedback
    label={
      formItem.label && (
        <Label
          children={formItem.label}
          bottomOffset={formItem.labelBottomOffset}
        />
      )
    }
    name={formItem.name}
    validateTrigger={
      getFieldError(formItem.name).length ? 'onBlur' : 'onSubmit'
    }
    valuePropName={formItem.type === 'checkbox' ? 'checked' : 'value'}
    validateFirst
    rules={formItem.rules}
  >
    {renderFormItem(formItem, inputEl)}
  </Form.Item>
);

PatientFormItem.propTypes = {
  formItem: propTypes.object.isRequired,
  getFieldError: propTypes.func.isRequired,
  inputEl: propTypes.object,
};

export default PatientFormItem;
