import React from 'react';
import propTypes from 'prop-types';
import useAxios from 'axios-hooks';
import { emailValidation } from 'utils/emailHelper';
import LoginForm from './LoginForm';
import { CHECK_SSO_LINK } from '../../_constants/endpoints';
import SsoForm from './SsoForm';
import { getEmailInitialValue } from '../../_helpers';
import { changeIsSsoDomainAction } from '../../_actions';
import EmailForm from './EmailForm';

const FormContainer = ({ form, dispatch, options, state }) => {
  const { setFields } = form;
  const [{ data }, checkSsoDomain] = useAxios(
    {
      url: CHECK_SSO_LINK,
      method: 'POST',
    },
    { manual: true }
  );
  const ssoData = data?.data.ssoData;
  const emailInitialValue = getEmailInitialValue();

  const changeEmail = (value) => {
    const email = emailValidation(value);
    setFields([{ name: 'username', errors: [] }]);
    if (email) {
      checkSsoDomain({
        data: { email: value },
      }).then((res) => {
        if (res.data.data.isSsoDomain) {
          dispatch(
            changeIsSsoDomainAction(true, res.data.data.ssoData.ssoName)
          );
        } else {
          dispatch(changeIsSsoDomainAction(false, ''));
        }
      });
    } else {
      state.isSsoDomain && dispatch(changeIsSsoDomainAction(false, ''));
    }
  };

  const loginForms = {
    LOGIN_FORM: <LoginForm form={form} options={options} />,
    SSO_FORM: (
      <SsoForm
        form={form}
        options={options}
        ssoData={ssoData}
        dispatch={dispatch}
      />
    ),
  };

  return (
    <>
      <EmailForm
        onChangeEmail={(e) => changeEmail(e.target.value)}
        onEffectFunction={() =>
          changeEmail(emailInitialValue || state.emailInitialValue)
        }
        initialValue={emailInitialValue}
        options={options}
        className={options.formEmail.className}
      />
      {loginForms[state.loginFormType]}
    </>
  );
};

FormContainer.propTypes = {
  form: propTypes.object.isRequired,
  dispatch: propTypes.func.isRequired,
  options: propTypes.object.isRequired,
  state: propTypes.object.isRequired,
};

export default FormContainer;
