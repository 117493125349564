import React, { Fragment, useState } from 'react';
import propTypes from 'prop-types';
import { Divider, Typography } from 'md-styled-components';
import { Input } from 'md-styled-components-v2';
import { debounce } from 'lodash';
import { getSystemFilteredData } from '../_helpers';
import HospitalsOverlay from './HospitalsOverlay';

const { Paragraph } = Typography;

const SystemOverlay = ({
  systems,
  groupInfo,
  setIsVisible,
  setGroupInfo,
  groupId,
}) => {
  const [term, setTerm] = useState('');

  const handleChange = debounce((value) => setTerm(value), 500, {
    leading: false,
    trailing: true,
  });
  const filteredData = getSystemFilteredData(systems, term);

  return (
    <>
      <div className='ph--16 pt--16'>
        <Input onChange={(e) => handleChange(e.target.value)} />
      </div>
      <div className='direct-navbar__hospitals-overlay-container custom-scrollbar'>
        {filteredData.length ? (
          filteredData
            .sort((a, b) =>
              a.custom_system_name > b.custom_system_name ? 1 : -1
            )
            .map((system) => (
              <Fragment key={`hospital-${system.id}`}>
                <div className='p--16'>
                  {system.custom_system_name && !!system.groupData.length && (
                    <Paragraph
                      color='gray'
                      fontFamily='heavy'
                      className='ml--24 pv--8'
                    >
                      {system.custom_system_name}
                    </Paragraph>
                  )}
                  <HospitalsOverlay
                    groupInfo={groupInfo}
                    hideDropdown={setIsVisible}
                    setGroupInfo={setGroupInfo}
                    groupId={groupId}
                    hospitals={system.groupData}
                  />
                </div>
                <Divider
                  margin='0'
                  className='direct-navbar__systems-overlay'
                />
              </Fragment>
            ))
        ) : (
          <Paragraph className='p--16' color='linkColor'>
            No match found
          </Paragraph>
        )}
      </div>
    </>
  );
};

SystemOverlay.propTypes = {
  groupInfo: propTypes.object,
  setGroupInfo: propTypes.func,
  setIsVisible: propTypes.func,
  systems: propTypes.object,
  groupId: propTypes.string,
};

export default React.memo(SystemOverlay);
