import common from 'constants/common';
import { useSearchParams } from 'react-router-dom';

const { mdsaveBaseUrl } = common;

/**
 * creates canonical URL for blog and media pages
 * @param {string} path
 * @returns {string}
 *
 * @example
 * // returns '/media/name'
 * isBlogPath('/blog/name')
 * @example
 * // returns '/media/name'
 * isBlogPath('/media/name')
 */
export const isBlogPath = (path) => {
  const pathSlug = path.includes('/blog/');
  return pathSlug
    ? `${mdsaveBaseUrl}${path.replace('/blog/', '/media/')}`
    : `${mdsaveBaseUrl}${path}`;
};

export const isValideUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const useGetParams = () => {
  const [searchParams] = useSearchParams();
  return Object.fromEntries([...searchParams]);
};

export const formatQuery = (query) => {
  const formattedSpace = query.replace(/\s+/g, '+').toLowerCase();
  const formattedQuery = formattedSpace
    .replace(/%|^\.+/g, '')
    .replaceAll('/', '_');
  return encodeURI(formattedQuery);
};

export const recoverQuery = (query) =>
  query.replace(/\++/g, ' ').replaceAll('_', '/');

export const getUrlifyId = (name) => name.toLowerCase().split(' ').join('_');

export const formatAnchorLink = (str) =>
  str.replace(/\s+/g, '-').replace('?', '').toLowerCase();

export const getQueryUrl = (str) => str.replaceAll(' ', '+');

export const getQueryString = (params) => {
  const queryString = Object.keys(params).reduce(
    (result, currentValue) =>
      params[currentValue]
        ? `${result}&${currentValue}=${params[currentValue]}`
        : result,
    ''
  );
  return `?${queryString.substring(1)}`;
};
