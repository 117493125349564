import { useEffect, useCallback, useMemo } from 'react';
import useAxios from 'axios-hooks';
import debounce from 'lodash/debounce';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getProcedure } from 'selectors/procedures';
import { Form } from 'md-styled-components-v2';
import { matchEmptySpaces } from 'utils/stringHelper';
import {
  isSearchResultPage,
  isProcedurePage,
  isPriceListPage,
} from 'utils/pages';
import { common } from 'constants';
import { getIsPortalUser } from 'selectors/users';
import {
  autofillOnPriceList,
  autofillOnProcedure,
  autofillOnResults,
} from './helpers';

const { searchMinLength, searchMaxLength } = common;

export const useInputChange = (setInputValue) => {
  const form = Form.useFormInstance();
  const [{ data = { data: [] }, loading }, makeRequest] = useAxios(
    {
      url: '/api/publicSearch/getProcedureByText',
      method: 'POST',
    },
    { manual: true, autoCancel: false }
  );
  const procedureSearch = useCallback(
    debounce(async (searchText) => {
      const response = await makeRequest({
        data: { count: 30, data: searchText },
      });
      form.setFields([
        {
          name: 'procedure',
          value: { searchText, searchData: response.data.data },
          errors: [],
        },
      ]);
    }, 500),
    []
  );

  const onInputChange = useCallback(
    (event) => {
      const { value: searchText } = event.target;

      if (searchText.length > searchMaxLength) {
        return;
      }

      if (matchEmptySpaces(searchText)) {
        return;
      }
      setInputValue(searchText);
      if (searchText.length < searchMinLength) {
        form.setFields([{ name: 'procedure', value: undefined, errors: [] }]);
        if (data.data.length) {
          data.data = [];
        }
        return;
      }
      procedureSearch(searchText);
    },
    [data.data, form, makeRequest, setInputValue]
  );

  return { onInputChange, data: data.data, loading };
};

export const useOnSelect = (setInputValue) => {
  const form = Form.useFormInstance();

  return useCallback(
    (_, selectedOption) => {
      const { data } = selectedOption;
      form.setFields([{ name: 'procedure', value: data, errors: [] }]);
      setInputValue(data.name);
    },
    [form, setInputValue]
  );
};

export const useAutocompleteProps = () => {
  const form = Form.useFormInstance();
  const fieldValue = form.getFieldValue('procedure');
  const isPortalUser = useSelector(getIsPortalUser);
  const mainPlaceholder = useMemo(
    () =>
      isPortalUser
        ? 'Office Visit, Procedure, or CPT/DRG'
        : 'Office Visit, Procedure, or CPT Code',
    [isPortalUser]
  );

  return {
    placeholder: fieldValue?.placeholder || mainPlaceholder,
    emptyLabel:
      fieldValue?.searchText && !fieldValue?.name
        ? 'Sorry, we could not find a match for that procedure'
        : '',
    errorClassName: fieldValue?.placeholder ? 'input-error' : '',
  };
};

export const useAutoFill = (setInputValue) => {
  const form = Form.useFormInstance();
  const { search } = useLocation();
  const { procedureUri } = useParams();
  const procedure = useSelector(getProcedure);

  useEffect(() => {
    switch (true) {
      case Boolean(isSearchResultPage()):
        autofillOnResults(search, procedureUri, form, setInputValue);
        break;
      case Boolean(isProcedurePage()):
        autofillOnProcedure(procedure?.name, procedureUri, form, setInputValue);
        break;
      case Boolean(isPriceListPage()):
        autofillOnPriceList(search, form, setInputValue);
        break;
      default:
        break;
    }
  }, [form, procedure?.name, procedureUri, search, setInputValue]);
};
