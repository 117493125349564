import { Input as Component } from 'antd';
import styled from 'styled-components';
import { defaultStyles } from './Input';
import themes from '../../Themes';

const Password = styled(Component.Password)`
  height: ${themes['input-height-base']} !important;
  ${defaultStyles}

  .ant-input {
    line-height: 1;
    padding: 0 !important;

    &-suffix {
      margin-left: 0;
      margin-right: 4px;
    }
  }

  &.ant-input-affix-wrapper-status-error {
    .ant-input-password-icon {
      margin-inline-end: 16px;
    }
  }
`;

export default Password;
