import React from 'react';
import { useTillTablet } from 'hooks';
import AuthorizationSm from './AuthorizationSm';
import AuthorizationLg from './AuthorizationLg';
import './_styles/authorization.less';

const AuthorizationItem = () => {
  const isTillTablet = useTillTablet();

  return isTillTablet ? <AuthorizationSm /> : <AuthorizationLg />;
};

export default AuthorizationItem;
