import {
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { Themes } from 'md-styled-components';
import { isPrivateHospital } from 'utils/privateSite';
import { useBreakpoint as breakpoint } from 'hooks';
import { scrollTo } from 'utils/common';
import support from 'constants/support';
import { common } from 'constants/index';

const PaginationContext = createContext({});

const PaginationProvider = ({ children, limitOnPage, scrollToId }) => {
  const isMobile = !breakpoint(Themes.breakpoints.sm)
  const dataOnEachPage = limitOnPage || support.dataOnEachPage;

  const [dataLength, setDataLength] = useState(0);
  const [minPageValue, setMinPageValue] = useState(0);
  const [maxPageValue, setMaxPageValue] = useState(dataOnEachPage);
  const [current, setCurrent] = useState(1);
  const changePage = useCallback(
    (value) => {
      setCurrent(value);
      setMinPageValue((value - 1) * dataOnEachPage);
      setMaxPageValue(value * dataOnEachPage);
    },
    [dataOnEachPage]
  );

  const offsetTop = useMemo(() => {
    switch (true) {
      case isPrivateHospital():
        return common.hospitalNavOffsetTop;
      case !isMobile:
        return common.navOffsetTop;
      default:
        return null;
    }
  }, [isMobile]);

  const handleChange = useCallback(
    (value) => {
      changePage(value);
      const scrollToElement = document.getElementById(scrollToId);
      scrollToElement && scrollTo(scrollToElement, false, offsetTop);
    },
    [changePage, offsetTop, scrollToId]
  );

  return (
    <PaginationContext.Provider
      value={{
        setDataLength,
        setMinPageValue,
        setMaxPageValue,
        setCurrent,
        maxPageValue,
        minPageValue,
        changePage,
        dataLength,
        handleChange,
        current,
        dataOnEachPage,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export default PaginationProvider;

export const usePagination = () => {
  const {
    setDataLength,
    setMinPageValue,
    setMaxPageValue,
    setCurrent,
    maxPageValue,
    minPageValue,
    changePage,
    dataLength,
    handleChange,
    current,
    dataOnEachPage,
  } = useContext(PaginationContext);

  return {
    setDataLength,
    setMinPageValue,
    setMaxPageValue,
    setCurrent,
    maxPageValue,
    minPageValue,
    changePage,
    dataLength,
    handleChange,
    current,
    dataOnEachPage,
  };
};
