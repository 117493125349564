import styled, { css } from 'styled-components';
import { Tag as AntTag } from 'antd';
import propTypes from 'prop-types';
import { Themes } from 'md-styled-components';

const Tag = styled(AntTag)`
  &.ant-tag {
    padding: ${(props) => (props.children ? '4px 12px' : '5px')};
    margin-right: 0;
    border-radius: 20px;
    border: none;
    display: inline-flex;
    align-items: center;
    font-family: ${Themes['font-sans-book']};
    font-size: ${Themes['font-size-base']};
    line-height: 22px;
    color: ${Themes.I90};

    &:hover {
      opacity: 1;
    }

    .ant-tag-close-icon {
      margin-left: 4px;
      font-size: 14px;

      svg {
        margin-right: 0 !important;
        width: 14px !important;
        height: 14px !important;
      }
    }

    span:nth-of-type(1) {
      line-height: 12px;

      svg {
        margin-right: ${(props) => props.children && '4px'};
        font-size: 20px;
        width: 20px;
        height: 20px;
      }
    }

    ${(props) => {
      switch (true) {
        case props.type === 'success':
          return css`
            background-color: ${Themes['light-green']};
          `;
        case props.type === 'outlined':
          return css`
            background-color: transparent;
            border: 1px solid ${Themes.I90};
            line-height: 20px !important;
            padding: 4px 11px !important;
          `;
        case props.type === 'danger':
          return css`
            background-color: ${Themes['light-red']};
          `;
        case props.type === 'warning':
          return css`
            background-color: ${Themes['gold-light']};
          `;
        case props.type === 'grey':
          return css`
            background-color: ${Themes.I10};
          `;
        case props.type === 'blue':
          return css`
            background-color: ${Themes.B10};
          `;
        case props.type === 'primary':
          return css`
            background-color: ${Themes.B20};
          `;
        case props.type === 'info':
          return css`
            background-color: ${Themes.magnolia};
          `;
        default:
          return '';
      }
    }}
  }
`;

Tag.propTypes = {
  type: propTypes.oneOf([
    'outlined',
    'success',
    'danger',
    'warning',
    'grey',
    'blue',
  ]),
};

Tag.defaultProps = {
  type: 'success',
};

export default Tag;
