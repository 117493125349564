import React from 'react';
import { Button } from 'md-styled-components-v2';

const SearchButtonDefault = () => (
  <Button
    htmlType='submit'
    type='primary'
    size='large'
    className='button mds-full--width mt-sm--16'
  >
    Search
  </Button>
);

export default SearchButtonDefault;
