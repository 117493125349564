import { useCallback, useState } from 'react';
import useAxios from 'axios-hooks';
import debounce from 'lodash/debounce';
import { matchEmptySpaces } from 'utils/stringHelper';
import { common } from 'constants/index';

const { searchMinLength } = common;

export const useInputChange = (setInputValue, handleSelectProvider) => {
  const [isTyped, setIsTyped] = useState(false);
  const [{ data: providersList = null, loading }, checkInsuranceProvider] =
    useAxios(
      {
        url: '/patientIdentifier/getInsuranceProvider',
        method: 'POST',
      },
      { manual: true, autoCancel: false }
    );

  const debouncedResults = useCallback(
    debounce((value) => checkInsuranceProvider(value), 500, {
      leading: false,
      trailing: true,
    }),
    []
  );

  const onInputChange = useCallback(
    (e) => {
      handleSelectProvider('', { data: {} });
      const { value } = e.target;

      if (matchEmptySpaces(value)) {
        return;
      }
      setInputValue(value);
      if (value.length >= searchMinLength) {
        !isTyped && setIsTyped(true);
        debouncedResults({
          data: {
            typed: value,
          },
        });
      } else {
        setIsTyped(false);
      }
    },
    [debouncedResults, isTyped]
  );

  return { onInputChange, providersList, loading, isTyped };
};
