/**
 * CheckoutProvider.js
 *
 * This file defines the context and reducer for managing the checkout process in the application.
 *
 * It includes:
 * - CheckoutContext: React context for managing checkout state.
 * - CheckoutProvider: Context provider component that wraps the application.
 * - Reducer: Function to handle state changes based on dispatched actions.
 * - Initial state and action types for managing checkout-related data.
 */

import React, { createContext, useContext, useReducer } from 'react';
import {
  SET_SELECTED_COMPANY,
  SET_SELECTED_PATIENT,
  SET_INITIAL_DATA,
  SET_DISCOUNT_DATA,
  SET_EDIT_OR_FOR_SOMEONE,
  CREATE_NEW_PATIENT,
  SET_CREDIT_CARD_INFO,
  SET_CREDIT_CARD_INFO_LOADING,
  SET_DUPLICATE_MODAL,
  RESET_PATIENT,
  SET_PAGE_LOADING,
  SET_PATIENT_BALANCE,
  SET_COMPANY_BALANCE,
  RESET_SELECTED_COMPANY,
} from '../constants';
import useInitCheckout from '../_hooks/useInitCheckout';

/**
 * Context for managing checkout state and actions.
 * @type {React.Context}
 */
const CheckoutContext = createContext({});

/**
 * The default values for the checkout state.
 * @type {Object}
 * @property {Object|null} selectedPatient - The currently selected patient.
 * @property {boolean} creditCardInfoLoading - Loading state for credit card information.
 * @property {boolean} createNewPatient - Flag indicating if a new patient is being created.
 * @property {Object} duplicateData - Data related to duplicate patient checks.
 * @property {string[]} duplicateData.oldEmail - List of old emails for duplicate checking.
 * @property {boolean} duplicateData.isProxyDuplicate - Indicates if the duplicate is a proxy.
 * @property {boolean} duplicateData.hasErrorMessage - Flag for error message display.
 * @property {boolean} duplicateData.formOnBlurDisabled - Flag for form blur state.
 */
const mainObj = {
  patientBalance: null,
  companyBalance: null,
  selectedPatient: null,
  pageLoading: false,
  creditCardInfoLoading: false,
  createNewPatient: false,
  duplicateData: {
    oldEmail: [],
    isProxyDuplicate: false,
    hasErrorMessage: false,
    formOnBlurDisabled: false,
  },
};

/**
 * Initial state for the checkout reducer.
 * @type {Object}
 * @property {boolean} allowCash - Flag for cash payment allowance.
 * @property {boolean} isPayPalEnabled - Flag indicating if PayPal is enabled.
 * @property {boolean} isIHSCareCreditEnabled - Flag indicating if IHSCareCredit is enabled.
 * @property {Object|null} creditCardInfo - The credit card information.
 * @property {Object|null} referringDoctor - Information about the referring doctor.
 * @property {Object|null} selectedCompany - The selected company information.
 * @property {boolean} ifProxyConfirmedCashModal - Flag for proxy confirmation cash modal.
 * @property {string} paypalClientId - PayPal client ID.
 * @property {boolean} editOrForSomeone - Flag indicating edit or for someone else.
 * @property {Object} duplicateModal - State for duplicate modal.
 * @property {Object|null} customerId - The customer ID.
 * @property {string|null} errorMessage - Error message if any.
 * @property {Object} mainObj - Default state values.
 */
const initialState = {
  allowCash: false,
  isPayPalEnabled: false,
  isIHSCareCreditEnabled: false,
  creditCardInfo: null,
  referringDoctor: null,
  selectedCompany: null,
  ifProxyConfirmedCashModal: true,
  paypalClientId: '',
  editOrForSomeone: false,
  duplicateModal: {},
  customerId: null,
  errorMessage: null,
  ...mainObj,
};

/**
 * Reducer function to manage the checkout state based on dispatched actions.
 * @param {Object} state - The current state.
 * @param {Object} action - The action to be processed.
 * @param {string} action.type - The type of action to process.
 * @returns {Object} - The new state after applying the action.
 */
const reducer = (state, action) => {
  switch (action.type) {
    /**
     * Handles the initialization of the state with provided data.
     * This data comes from MainCheckoutController actionInitCheckout.
     *
     * @param {Object} action.data - Data to merge into the current state.
     * @returns {Object} - New state with merged initial data.
     */
    case SET_INITIAL_DATA:
      return { ...state, ...action.data };

    /**
     * Updates the state with the selected patient information.
     *
     * @param {Object} action.selectedPatient - The selected patient object to set in the state.
     * @param {boolean} [action.createNewPatient] - Flag indicating if a new patient is being created.
     * @param {string} [action.customerId] - Customer ID to update in the state.
     * @returns {Object} - New state with updated selected patient and related information.
     */
    case SET_SELECTED_PATIENT:
      return {
        ...state,
        selectedPatient: action.selectedPatient,
        createNewPatient: action.createNewPatient || false,
        customerId: action.customerId || state.customerId,
      };

    /**
     * Updates the patient balance in the state.
     * @param {number} action.balance - The patient balance to set in the state.
     * @returns {Object} - New state with updated patient balance.
     */
    case SET_PATIENT_BALANCE:
      return { ...state, patientBalance: action.balance };

    /**
     * Updates the company balance in the state.
     * @param {number} action.balance - The company balance to set in the state.
     * @returns {Object} - New state with updated company balance.
     */
    case SET_COMPANY_BALANCE:
      return { ...state, companyBalance: action.balance };

    /**
     * Updates the loading state for credit card information.
     *
     * @param {boolean} action.creditCardInfoLoading - Loading state for credit card info.
     * @returns {Object} - New state with updated credit card info loading state.
     */
    case SET_CREDIT_CARD_INFO_LOADING:
      return {
        ...state,
        creditCardInfoLoading: action.creditCardInfoLoading,
      };

    /**
     * Sets or clears the credit card information in the state.
     *
     * @param {Object} [action.creditCardInfo] - Credit card information to set. If invalid, defaults to an empty array.
     * @returns {Object} - New state with updated credit card information and loading state.
     */
    case SET_CREDIT_CARD_INFO:
      return {
        ...state,
        creditCardInfo: action.creditCardInfo,
        creditCardInfoLoading: false,
      };

    /**
     * Resets the state to prepare for creating a new patient.
     *
     * Clears selected patient and credit card information while setting the flag for creating a new patient.
     * @returns {Object} - New state with cleared patient and credit card info, and createNewPatient flag set to true.
     */
    case CREATE_NEW_PATIENT:
      return {
        ...state,
        selectedPatient: null,
        creditCardInfo: null,
        createNewPatient: true,
      };

    /**
     * Updates the selected company in the state.
     *
     * @param {Object} action.selectedCompany - The selected company information to set in the state.
     * @returns {Object} - New state with updated selected company.
     */
    case SET_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.selectedCompany,
      };

    /**
     * Resets the selected company and company balance in the state.
     * @returns {Object} - New state with cleared selected company and company balance.
     */
    case RESET_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: null,
        companyBalance: null,
      };

    /**
     * Updates discount data in the state.
     *
     * @param {Object} action.discountData - Discount data to set in the state.
     * @returns {Object} - New state with updated discount data.
     */
    case SET_DISCOUNT_DATA:
      return { ...state, discountData: action.discountData };

    /**
     * Sets the flag indicating if the checkout is for editing or someone else.
     *
     * @param {boolean} action.editOrForSomeone - Flag to indicate editing or checkout for someone else.
     * @returns {Object} - New state with updated editOrForSomeone flag.
     */
    case SET_EDIT_OR_FOR_SOMEONE:
      return { ...state, editOrForSomeone: action.editOrForSomeone };

    /**
     * Updates the duplicate modal data in the state.
     *
     * This case allows for updating various properties of the duplicate modal state.
     *
     * @param {Object} action - Action containing properties to update in the duplicate modal.
     * @returns {Object} - New state with updated duplicate modal data.
     */
    case SET_DUPLICATE_MODAL: {
      const { type, ...rest } = action;
      return {
        ...state,
        ...rest,
      };
    }

    /**
     * Resets the state to default values related to patient information and duplicate data.
     *
     * @returns {Object} - New state with reset values for patient and duplicate data.
     */
    case RESET_PATIENT:
      return {
        ...state,
        ...mainObj,
      };
    case SET_PAGE_LOADING:
      return { ...state, pageLoading: action.pageLoading };

    /**
     * Default case returns the current state unchanged.
     *
     * @returns {Object} - Current state if action type is not matched.
     */
    default:
      return state;
  }
};

/**
 * Provider component to manage and provide the checkout context.
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - Child components.
 * @returns {JSX.Element} - The provider component.
 */
const CheckoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {}, () => initialState);
  useInitCheckout({ dispatch, state });

  return (
    <CheckoutContext.Provider value={{ state, dispatch }}>
      {children}
    </CheckoutContext.Provider>
  );
};

/**
 * Custom hook to access the checkout context.
 * @returns {Object} - The checkout context including state and dispatch.
 */
export const useCheckout = () => {
  const { state, dispatch } = useContext(CheckoutContext);

  return { state, dispatch };
};

export default React.memo(CheckoutProvider);
