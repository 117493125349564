import propTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon';

const ExpandIcon = ({ isActive }) => (
  <div className='mt--4'>
    <Icon
      type='chevron-next'
      size={12}
      className={isActive ? 'arrow-rotate-90' : 'arrow-rotate-270'}
    />
  </div>
);

ExpandIcon.propTypes = {
  isActive: propTypes.bool,
};

export default ExpandIcon;
