import React from 'react';
import { useSelector } from 'react-redux';
import { getIsCmsUser } from 'selectors/users';
import ShoppingCartDropdown from 'components/Navigation/ShoppingCart/ShoppingCartDropdown';
import SignOut from 'components/Navigation/UserSection/UserInfo/SignOut';
import NavigationProvider from 'components/Navigation/_context/NavigationProvider';
import { useIsDesktop } from 'hooks';
import UserInfoLg from 'components/Navigation/UserSection/UserInfo/UserInfoLg';
import DirectLogo from './_partials/Logo';
import Item from './_partials/Item';
import Menu from './_partials/Menu';
import { useContainerClass } from './_hooks/index';
import GoToMDSaveLink from './_partials/GoToMdsaveLink';
import SearchIcon from './_partials/Search';
import './_styles/navigation.less';

const Direct = ({ groupId, isGroupPage, isCmsPage, navFixed }) => {
  const isCmsUser = useSelector(getIsCmsUser);
  const isDesktop = useIsDesktop();
  const { className } = useContainerClass(navFixed, isCmsPage);

  return (
    <NavigationProvider>
      <div className={`flex-display flex-align-items ${className}`}>
        <div className='flex-display flex-justify-space-between mds-full--width ph--32 relative'>
          <div className='direct-navigation__first-item flex-display flex-align-items-center'>
            <DirectLogo blockClass='mr--32 pv--16' />
            {isGroupPage && <Menu groupId={groupId} />}
          </div>
          <div className='direct-navigation__second-item flex-display flex-align-items-center flex-justify-content-end'>
            {isCmsPage && <GoToMDSaveLink />}
            {isDesktop && !isCmsUser && (
              <Item>
                <SignOut />
              </Item>
            )}
            <Item>
              <UserInfoLg />
            </Item>
            {isCmsPage && (
              <Item>
                <SearchIcon />
              </Item>
            )}
            <Item>
              <ShoppingCartDropdown isTextVisible />
            </Item>
          </div>
        </div>
      </div>
    </NavigationProvider>
  );
};

export default Direct;
