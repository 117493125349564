import { useNavigate } from 'react-router-dom';
import { formatQuery } from 'utils/url';

const useOnFinish = (form) => {
  const { setFields } = form;
  const navigate = useNavigate();

  return (values) => {
    const { provider } = values;
    if (!provider) {
      setFields([
        {
          name: 'provider',
          value: { placeholder: 'Please enter a provider' },
        },
      ]);
      return;
    }
    if (provider.searchData?.length) {
      navigate(`/search/provider?searchText=${provider.searchText}`);
    }
  };
};

export default useOnFinish;
