import React, { memo } from 'react';
import { Dropdown } from 'md-styled-components';
import propTypes from 'prop-types';
import ShoppingCart from 'components/ShoppingCart';
import { useOptionsShoppingCart } from './_hooks';
import ItemBadge from './_partials/ItemBadge';
import ItemSelectedPatientCard from './_partials/ItemSelectedPatientCard';
import './_styles/index.less';

const ShoppingCartDropdown = ({ isTextVisible = false }) => {
  const {
    shoppingCartNotEmpty,
    shoppingCartQuantity,
    openCartState,
    toggleCart,
  } = useOptionsShoppingCart();

  return (
    <div className='flex-display flex-align-items-center'>
      <Dropdown
        className='pointer'
        overlayClassName='shopping-cart__overlay'
        trigger={['click']}
        dropdownRender={() => <ShoppingCart />}
        onOpenChange={(val) => toggleCart(val)}
        getPopupContainer={(trigger) => trigger.parentNode}
        disabled={!shoppingCartNotEmpty}
        open={openCartState}
      >
        <div className='flex-display flex-align-items-center'>
          <ItemBadge shoppingCartQuantity={shoppingCartQuantity} />
          {isTextVisible && <ItemSelectedPatientCard />}
        </div>
      </Dropdown>
    </div>
  );
};

ShoppingCartDropdown.propTypes = {
  isTextVisible: propTypes.bool,
};

export default memo(ShoppingCartDropdown);
