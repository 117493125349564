import { forwardRef, useState } from 'react';
import { Spin, Icon, Themes } from 'md-styled-components';
import { AutoComplete, Input } from 'md-styled-components-v2';
import renderMenu from './renderMenu';
import {
  useAutocompleteProps,
  useInputChange,
  useOnSelect,
  useAutoFill,
} from './hooks';
import useZIndex from '../hooks';

const Procedure = forwardRef(({ suffix = '' }, ref) => {
  const inputId = `procedure-input${suffix}`;
  const [inputValue, setInputValue] = useState('');
  const getZIndex = useZIndex();
  const zIndex = getZIndex(suffix);

  const { placeholder, emptyLabel, errorClassName } = useAutocompleteProps();
  const { onInputChange, data, loading } = useInputChange(setInputValue);
  const onSelect = useOnSelect(setInputValue);

  useAutoFill(setInputValue);

  return (
    <AutoComplete
      id={inputId}
      className='item item-hide item-hide-first'
      notFoundContent={loading ? <Spin /> : !data.length && emptyLabel}
      dropdownStyle={{ zIndex }}
      options={!loading && renderMenu(data, inputValue)}
      onSelect={onSelect}
      value={inputValue}
    >
      <Input
        className={`pv--8 ${errorClassName}`}
        ref={ref}
        onChange={onInputChange}
        placeholder={placeholder}
        allowClear
        prefix={<Icon color={Themes.I90} type='magnifying-glass' size={15} />}
      />
    </AutoComplete>
  );
});

export default Procedure;
