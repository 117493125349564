import React from 'react';
import { Typography } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import propTypes from 'prop-types';

const { Text } = Typography;

const ButtonForm = ({ onClick, options, title }) => (
  <>
    <div className={options.lineClassName}>
      <div className='login__separator flex-display flex-align-items-center flex-justify-space-between'>
        <Text color='grey' fontFamily='book'>
          or
        </Text>
      </div>
    </div>
    <Button
      size='large'
      block
      className={options.buttonClassName}
      type='tertiary'
      onClick={onClick}
    >
      {title}
    </Button>
  </>
);

ButtonForm.propTypes = {
  onClick: propTypes.func.isRequired,
  options: propTypes.object.isRequired,
  title: propTypes.string.isRequired,
};

export default ButtonForm;
