import React from 'react';
import { Typography } from 'md-styled-components';
import { Link, Button } from 'md-styled-components-v2';
import Item from './Item';

const { Paragraph } = Typography;

const AllProcedures = () => (
  <div className='flex-display mv--24 mh--16'>
    <div className='flex-display flex-direction-column'>
      <Paragraph fontFamily='heavy' className='mb--16 mh--16'>
        Procedures
      </Paragraph>
      <Item>
        <Link to='/t/wellness/colon-health' size='large' className='mt--4'>
          Colonoscopy
        </Link>
      </Item>
      <Item>
        <Link to='/t/imaging-radiology/ct-scan' size='large'>
          CT Scan
        </Link>
      </Item>
      <Item>
        <Link to='/t/general-surgery/bariatric-surgery' size='large'>
          Gastric Sleeve
        </Link>
      </Item>
      <Item>
        <Link to='/t/imaging-radiology/breast-cancer-imaging' size='large'>
          Mammogram
        </Link>
      </Item>
      <Item>
        <Link to='/t/imaging-radiology/how-much-does-mri-cost' size='large'>
          MRI
        </Link>
      </Item>
      <Item>
        <Link to='/t/imaging-radiology/ultrasound' size='large'>
          Ultrasound
        </Link>
      </Item>
      <Item>
        <Link to='/t/imaging-radiology/x-ray' size='large'>
          X-ray
        </Link>
      </Item>
      <Item>
        <Link to='/procedures' size='large'>
          View All
        </Link>
      </Item>
    </div>
    <div className='flex-display flex-direction-column ml--16 pl--16 menu__divider'>
      <Paragraph fontFamily='heavy' className='mb--16 mh--16'>
        Featured Specialties
      </Paragraph>
      <Item>
        <Link
          to='/t/general-surgery/bariatric-surgery'
          size='large'
          className='mt--4'
        >
          Bariatrics
        </Link>
      </Item>
      <Item>
        <Link to='/t/general-surgery' size='large'>
          General Surgery
        </Link>
      </Item>
      <Item>
        <Link to='/t/imaging-radiology' size='large'>
          Imaging & Radiology
        </Link>
      </Item>
      <Item>
        <Link to='/t/labs' size='large'>
          Labs
        </Link>
      </Item>
      <Item>
        <Link to='/t/wellness/office-visits' size='large'>
          Office Visits
        </Link>
      </Item>
      <Item>
        <Link to='/t/pregnancy' size='large'>
          Pregnancy
        </Link>
      </Item>
      <Item>
        <Link to='/t/wellness' size='large'>
          Wellness
        </Link>
      </Item>
      <Item>
        <Button type='link' href='/united-states' size='large'>
          View All
        </Button>
      </Item>
    </div>
    <div className='flex-display flex-direction-column ml--16 pl--16 menu__divider'>
      <Paragraph fontFamily='heavy' className='mb--16 mh--16'>
        Locations
      </Paragraph>
      <Item>
        <Button type='link' href='/arizona' className='mt--4'>
          Arizona
        </Button>
      </Item>
      <Item>
        <Button type='link' href='/florida'>
          Florida
        </Button>
      </Item>
      <Item>
        <Button type='link' href='/indiana'>
          Indiana
        </Button>
      </Item>
      <Item>
        <Button type='link' href='/nebraska'>
          Nebraska
        </Button>
      </Item>
      <Item>
        <Button type='link' href='/oklahoma'>
          Oklahoma
        </Button>
      </Item>
      <Item>
        <Button type='link' href='/tennessee'>
          Tennessee
        </Button>
      </Item>
      <Item>
        <Button type='link' href='/texas'>
          Texas
        </Button>
      </Item>
      <Item>
        <Button type='link' href='/united-states' size='large'>
          View All
        </Button>
      </Item>
    </div>
  </div>
);

export default AllProcedures;
