import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Input, Form } from 'md-styled-components-v2';
import ReactInputMask from 'react-input-mask';
import propTypes from 'prop-types';
import { phoneValidation } from 'utils/phoneHelper';

const MAX_LENGTH = 10;

export const MaskedInput = React.forwardRef(
  ({ name, initialValue, onChange, id, value, ...props }, ref) => (
    <ReactInputMask
      {...props}
      mask='(999) 999-9999'
      maskChar='_'
      value={value}
      id={id}
      onChange={(e) => {
        const digitsOnly = e.target.value.replace(/\D/g, '');
        if (digitsOnly.length <= MAX_LENGTH) {
          onChange(e);
        }
      }}
    >
      {(inputProps) => (
        <Input
          placeholder='(___) ___-____'
          type='tel'
          ref={ref}
          name={name}
          classNames={{ input: 'phi ml--0' }}
          initialValue={initialValue}
          {...inputProps}
        />
      )}
    </ReactInputMask>
  )
);

MaskedInput.propTypes = {
  name: propTypes.string.isRequired,
  initialValue: propTypes.oneOfType([propTypes.string, propTypes.number]),
};

/**
 * @typedef {object} Props
 * @prop {string} name
 * @prop {string|element} label
 * @prop {boolean} [isRequired=false]
 * @prop {boolean} [breakpoint=true]
 * @prop {string|number} [initialValue]
 *
 * @param {Props}  p1
 */
const Phone = ({ isRequired, breakpoint, name, initialValue, ...props }) => {
  const optionalText = '(Optional)';
  const inputEl = useRef(null);
  const [label, setLabel] = useState(props.label);

  const handlePhoneValidation = useCallback((rule, value, callback) => {
    if (phoneValidation(inputEl)) {
      callback('Please enter a valid phone number.');
    }
    callback();
  }, []);

  const decorator = {
    initialValue: initialValue || '',
    validateTrigger: 'onBlur',
    validateFirst: true,
    rules: [
      { validator: handlePhoneValidation },
      isRequired
        ? { required: true, message: `${props.label} cannot be blank.` }
        : {},
    ],
  };

  useEffect(() => {
    if (!isRequired && !label.includes(optionalText)) {
      setLabel(`${label} ${optionalText}`);
    }
  }, [isRequired, label]);

  return (
    <Form.Item
      {...props}
      label={breakpoint && label}
      name={name}
      {...decorator}
    >
      <MaskedInput ref={inputEl} name={name} initialValue={initialValue} />
    </Form.Item>
  );
};

Phone.defaultProps = {
  isRequired: false,
  breakpoint: true,
};

Phone.propTypes = {
  name: propTypes.string.isRequired,
  label: propTypes.oneOfType([propTypes.string, propTypes.element]).isRequired,
  isRequired: propTypes.bool,
  breakpoint: propTypes.bool,
  initialValue: propTypes.oneOfType([propTypes.string, propTypes.number]),
};

export default Phone;
