import React from 'react';
import styled, { css } from 'styled-components';
import { Divider as Component } from 'antd';
import propTypes from 'prop-types';
import Themes from '../Themes';

const DividerWrapper = styled(({ width, height, color, margin, ...props }) => (
  <Component {...props} />
))`
  min-height: 1px;
  position: relative;
  border: ${(props) => props.type === 'horizontal' && 'none'};
  top: ${(props) => (props.type === 'horizontal' ? '-1px' : '-0.06em')};

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `};

  ${(props) =>
    props.color &&
    css`
      background-color: ${props.color};
    `};

  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `};

  &.ant-divider-vertical {
    top: 0;
  }
`;

const Divider = (props) => <DividerWrapper {...props} />;

Divider.propTypes = {
  margin: propTypes.string,
  /** className of container */
  className: propTypes.string,
  /** whether line is dashed */
  dashed: propTypes.bool,
  /** position of title inside divider */
  orientation: propTypes.oneOf(['left', 'right', 'center']),
  /** style object of container */
  style: propTypes.object,
  /** direction type of divider */
  type: propTypes.oneOf(['horizontal', 'vertical']),
  color: propTypes.string,
};

Divider.defaultProps = {
  color: Themes.I20,
  type: 'horizontal',
};

/** @component */
export default Divider;
