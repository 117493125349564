import React from 'react';
import DefaultModal from 'components/Modals/DefaultModal';

export default ({
  onSuccess,
  visible,
  onCancel,
  title,
  text,
  cancelButtonText,
  successButtonText,
  buttonClassName = 'error',
  closable = false,
}) => (
  <DefaultModal
    closable={closable}
    visible={visible}
    onSuccess={onSuccess}
    onCancel={onCancel}
    cancelButtonText={cancelButtonText || 'Cancel'}
    successButtonText={successButtonText || 'Delete'}
    text={text}
    title={title}
    buttonClassName={buttonClassName}
  />
);
