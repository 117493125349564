import {
  CHANGE_INITIAL_VALUE,
  CHANGE_IS_SSO_DOMAIN,
  CHANGE_LOGIN_TYPE,
  CHANGE_SSO_RADIO_TYPE,
} from '../_constants/actionTypes';
import { SIGN_IN_LINK, SSO_LINK } from '../_constants/endpoints';
import { getUrl } from '../_helpers';
import { LOGIN_SECTION } from '../_constants/loginTypes';
import { LOGIN_FORM, SSO_FORM } from '../_constants/formTypes';

export const initialState = {
  loginType: LOGIN_SECTION,
  isSsoDomain: false,
  ssoRadioType: 'sso',
  loginFormType: LOGIN_FORM,
  formAction: getUrl(SIGN_IN_LINK),
  emailInitialValue: '',
};

export const init = () => initialState;

export function loginReducer(state, action) {
  switch (action.type) {
    case CHANGE_LOGIN_TYPE:
      return { ...state, loginType: action.data };
    case CHANGE_IS_SSO_DOMAIN: {
      const { isSso } = action.data;
      return {
        ...state,
        isSsoDomain: isSso,
        formAction:
          !isSso || state.ssoRadioType === 'password'
            ? getUrl(SIGN_IN_LINK)
            : getUrl(`${SSO_LINK}${action.data.ssoName}`),
        loginFormType: isSso ? SSO_FORM : LOGIN_FORM,
      };
    }
    case CHANGE_SSO_RADIO_TYPE:
      return {
        ...state,
        ssoRadioType: action.data,
        formAction: action.data === 'password' && getUrl(SIGN_IN_LINK),
      };
    case CHANGE_INITIAL_VALUE:
      return {
        ...state,
        emailInitialValue: action.data,
      };
    default:
      return state;
  }
}
