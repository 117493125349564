import React, { lazy, Suspense, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form } from 'md-styled-components';
import isEmpty from 'lodash/isEmpty';
import { getPrivateHospitalData } from 'selectors/privateHospitalData';
import { getSuggestedProceduresHeader } from 'selectors/procedures';
import { isPrivateHospital } from 'utils/privateSite';
import { formatQuery } from 'utils/url';
import { clearSesStorageItem } from 'utils/storage';
import { sesStorage } from '../../constants';
import ProceduresFormField from './_partials/Procedures';
import { TrackTransparencySearch } from '../Analytics/Track';

const HeroSearchFormWrapper = lazy(() => import('./HeroSearchFormWrapper'));
const SearchFormWrapper = lazy(() => import('./SearchFormWrapper'));

const rules = (validate) => [
  {
    validator: (_, value) =>
      !validate
        ? Promise.resolve()
        : Promise.reject(new Error('Select an option from the dropdown.')),
  },
  { required: true },
];

export const getFormItemProps = (name, validate, isHeroSection = false) => ({
  name,
  rules: rules(validate),
  validateTrigger: 'onBlur',
  validateFirst: true,
  className: validate
    ? `error-message--visible ${isHeroSection ? 'hero' : 'header'}`
    : '',
});

export const setOnOptionSelected = (
  fieldName,
  selected,
  setOnlyName,
  setOptionSelected
) => {
  let fieldValue = false;
  if (typeof selected === 'object' && !isEmpty(selected)) {
    fieldValue = setOnlyName
      ? selected.name
      : {
          uri: selected.uri,
          cpt: selected.foundCptCode,
          id: selected.id,
          name: selected.name,
          keyword: selected.keyword,
        };
  }

  setOptionSelected(fieldValue);
};

export const resetFormField = (
  fieldName,
  setFieldsValue,
  onOptionSelected,
  setSearchData,
  searchDataName,
  clearLocation = false
) => {
  if (clearLocation) {
    clearSesStorageItem(sesStorage.locationKey);
  }
  setFieldsValue({ [fieldName]: '' });
  onOptionSelected(fieldName, {});
  setSearchData((data) => ({ ...data, [searchDataName]: '' }));
};

const focusInput = (getFieldError, proceduresFieldName) => {
  let inputId;
  const procedureHasError = getFieldError(proceduresFieldName).length;
  switch (true) {
    case !!procedureHasError:
      inputId = proceduresFieldName;
      break;
    default:
      return;
  }
  document.querySelector(`#${inputId}`).focus();
};

const getProcedureItem = (proceduresData, searchData) =>
  proceduresData?.find(
    (procedure) => procedure.name.toLowerCase() === searchData.searchText
  );

const ProceduresSearch = ({ searchFields, suffixBtn, heroSearch, Select }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { privateHospitalSlug, connectionId } = useSelector(
    getPrivateHospitalData
  );
  const { getFieldError, setFieldsValue } = form;
  const proceduresFieldName = `${searchFields.proceduresField}`;

  const [forceUpdate, setForceUpdate] = useState(false);
  const [optionsSelected, setOptionsSelected] = useState({
    [proceduresFieldName]: false,
  });
  const [searchData, setSearchData] = useState({});
  const proceduresData = useSelector(getSuggestedProceduresHeader);

  const procedureError = getFieldError(proceduresFieldName).length;

  const existingProcedure = useCallback(
    (searchText) => {
      if (proceduresData) {
        return proceduresData.find(
          (obj) => obj.name.toLowerCase() === searchText.toLowerCase()
        );
      }
      return false;
    },
    [proceduresData]
  );

  const redirectWhiteLabel = useCallback(() => {
    if (!searchData.searchText) {
      return;
    }
    const getSearchUri = (searchSubDirectory, encodedQuery, selectedItemId) =>
      selectedItemId
        ? `${searchSubDirectory}?q=${encodedQuery}&p=${selectedItemId}`.replace(
            '/',
            ''
          )
        : `${searchSubDirectory}?q=${encodedQuery}`;
    const searchSubDirectory = `/${privateHospitalSlug}-price-list`;
    const encodedQuery = formatQuery(searchData.searchText);
    const trackSearch = { white_label_hospital_id: connectionId };
    let url = optionsSelected
      ? getSearchUri(
          searchSubDirectory,
          encodedQuery,
          optionsSelected[proceduresFieldName].id
        )
      : getSearchUri(searchSubDirectory, encodedQuery);
    trackSearch.search_text = searchData.searchText;
    trackSearch.procedure_id = +optionsSelected[proceduresFieldName].id;
    if (!optionsSelected[proceduresFieldName]) {
      const procedure = existingProcedure(searchData.searchText);
      if (procedure) {
        url = getSearchUri(searchSubDirectory, encodedQuery, procedure.id);
      }
    }
    isPrivateHospital() && TrackTransparencySearch(url, location, trackSearch);
    navigate(url);
  }, [
    searchData,
    navigate,
    existingProcedure,
    optionsSelected,
    proceduresFieldName,
    privateHospitalSlug,
    connectionId,
  ]);

  const onFinishFailed = (values) => {
    setForceUpdate(!forceUpdate);
    focusInput(getFieldError, proceduresFieldName);
  };

  const onOptionSelected = useCallback((fieldName, selected) => {
    setOnOptionSelected(fieldName, selected, false, (fieldValue) =>
      setOptionsSelected((options) => ({
        ...options,
        [fieldName]: fieldValue,
      }))
    );
  }, []);

  const validateSelection = (data, name) =>
    !isEmpty(data) && !optionsSelected[name];
  const resetFieldsData = useCallback(
    (fieldName, searchDataName, clearLocation) =>
      resetFormField(
        fieldName,
        setFieldsValue,
        onOptionSelected,
        setSearchData,
        searchDataName,
        clearLocation
      ),
    [onOptionSelected, setFieldsValue]
  );

  const FormItem = (
    <ProceduresFormField
      proceduresFieldName={proceduresFieldName}
      suffixBtn={suffixBtn}
      heroSearch={heroSearch}
      onOptionSelected={onOptionSelected}
      procedureError={procedureError}
      searchData={searchData}
      setSearchData={setSearchData}
      setFieldsValue={setFieldsValue}
      resetFieldsData={resetFieldsData}
    />
  );

  return (
    <Form
      form={form}
      onFinish={() => redirectWhiteLabel()}
      onFinishFailed={onFinishFailed}
      onFieldsChange={() => setForceUpdate(!forceUpdate)}
    >
      <Suspense fallback={<></>}>
        {heroSearch ? (
          <HeroSearchFormWrapper
            buttonId='search-procedure-btn'
            formItemProps={getFormItemProps(
              proceduresFieldName,
              getProcedureItem(
                proceduresData,
                searchData
              )?.name.toLowerCase() !== searchData.searchText &&
                validateSelection(proceduresData, proceduresFieldName),
              true
            )}
            FormItem={FormItem}
          />
        ) : (
          <SearchFormWrapper
            button={false}
            Select={Select}
            formItemProps={getFormItemProps(
              proceduresFieldName,
              getProcedureItem(
                proceduresData,
                searchData
              )?.name.toLowerCase() !== searchData.searchText &&
                validateSelection(proceduresData, proceduresFieldName)
            )}
            FormItem={FormItem}
          />
        )}
      </Suspense>
    </Form>
  );
};

export default React.memo(ProceduresSearch);
