import { createSelector } from 'reselect';
import { requestSuggestedProviders } from 'actions/providers';
import createLoadingSelector from './create-loading-selector';

// Suggested Providers

export const suggestedProviders = (state) => state.suggestedProviders;

export const getSuggestedProviders = createSelector(
  suggestedProviders,
  (provider) => provider.suggestedProviders
);

export const createSuggestedProvidersLoading = createLoadingSelector(
  requestSuggestedProviders.actionName
)();
