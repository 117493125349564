import React from 'react';
import { Col, Typography } from 'md-styled-components';
import { Link, Button } from 'md-styled-components-v2';
import propTypes from 'prop-types';

const { Title } = Typography;

const Content = ({ inputType, name, colOptions, otherOptions }) =>
  inputType ? (
    <Col {...colOptions}>
      {(() => {
        switch (inputType) {
          case 'title':
            return (
              <Title level={3} className='pb--8'>
                {name}
              </Title>
            );
          case 'link':
            return <Link {...otherOptions}>{name}</Link>;
          case 'button':
            return (
              <Button type='primary' {...otherOptions}>
                {name}
              </Button>
            );
          case 'other':
            return otherOptions.component;
          default:
            return <></>;
        }
      })()}
    </Col>
  ) : (
    <></>
  );

Content.propTypes = {
  inputType: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  colOptions: propTypes.object,
  otherOptions: propTypes.object,
};

export default Content;
