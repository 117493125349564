import React from 'react';
import { Typography } from 'md-styled-components';
import { Link } from 'md-styled-components-v2';
import { useSelector } from 'react-redux';
import { getChatLink } from 'selectors/liveAgent';
import SupportModalLink from 'components/Support/SupportModalLink';
import { CONTACT_US_TEXT } from './_constants';
import PhoneNumber from './PhoneNumber';
import './_styles/contact-us.less';

const { Paragraph } = Typography;

const List = () => {
  const onlineChat = useSelector(getChatLink);

  return (
    <>
      {onlineChat && (
        <div className='contact-us__item flex-display flex-align-items-center'>
          <SupportModalLink children={<Paragraph>Live Chat</Paragraph>} />
        </div>
      )}
      <div className='contact-us__item flex-display flex-align-items-center'>
        <Link to='/contactus'>
          <Paragraph>{CONTACT_US_TEXT}</Paragraph>
        </Link>
      </div>
      <div className='pv--8'>
        <PhoneNumber />
      </div>
    </>
  );
};

export default List;
