import { getCookie } from '../../utils/cookieHelper';

const SegmentAnalytics = (analyticData, callback) => {
  const { analytics, gtag } = window;

  const source = getCookie('utm_source') ?? '';

  if (analyticData?.identifyProperties?.customerId) {
    const { customerId } = analyticData.identifyProperties;
    delete analyticData.identifyProperties.customerId;
    analytics.identify(customerId, analyticData.identifyProperties);
    gtag('set', 'user_properties', {
      employer: analyticData.identifyProperties?.employer,
      plan: analyticData.identifyProperties?.plan,
      company: analyticData.identifyProperties?.company,
      affiliation: analyticData.identifyProperties?.affiliation,
      source,
    });
  } else {
    analytics.identify({
      source,
    });
    gtag('set', 'user_properties', {
      source,
    });
  }

  callback();
  return null;
};

export default SegmentAnalytics;
