export const dateTypes = [
  {
    name: 'Week',
    value: 'week',
    key: 0,
  },
  {
    name: 'Month',
    value: 'month',
    key: 1,
  },
  {
    name: 'All Time',
    value: '',
    key: 2,
  },
];

export function getShortenedName(fullName) {
  const splitPoint = fullName.lastIndexOf(' ');
  if (splitPoint === -1) {
    return fullName;
  }
  return `${fullName.substring(0, splitPoint)} ${fullName[splitPoint + 1]}.`;
}
