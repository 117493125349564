import React from 'react';
import propTypes from 'prop-types';
import { Form, Checkbox, Typography } from 'md-styled-components';
import { isNoEmail } from 'utils/emailHelper';
import Email from '../../../Form/Email';

const { Text } = Typography;

const ShareByEmail = ({ emailAddress }) => (
  <>
    <Email
      hasFeedback
      isRequired
      className='mb--8'
      label='Email'
      name='emailAddress'
      initialValue={
        emailAddress && !isNoEmail(emailAddress) ? emailAddress : ''
      }
      validateTrigger='onSubmit'
      validateFirst
      isPhi
      rules={[
        {
          required: true,
          message: 'Email cannot be blank.',
        },
        {
          type: 'email',
          message: 'Please enter a valid email.',
        },
      ]}
    />
    <Form.Item
      name='emailPermission'
      className='mb--0'
      validateTrigger='onSubmit'
      valuePropName='checked'
      rules={[
        {
          required: true,
          transform: (value) => value || undefined,
          type: 'boolean',
          message: 'Please confirm if the patient has given permission.',
        },
      ]}
    >
      <Checkbox className='flex-display-inline'>
        <Text level={2} color='grey' fontFamily='book'>
          Patient has given permission to communicate via email
        </Text>
      </Checkbox>
    </Form.Item>
  </>
);

ShareByEmail.propTypes = {
  emailAddress: propTypes.string,
};

export default ShareByEmail;
