import React from 'react';
import { Input, Select } from 'md-styled-components-v2';

const { Option } = Select;

export const formFields = [
  {
    label: 'First Name',
    name: 'first_name',
    className: 'phi',
    rules: [
      {
        required: true,
        message: 'First Name cannot be blank.',
      },
    ],
  },
  {
    label: 'Last Name',
    name: 'last_name',
    className: 'phi',
    rules: [
      {
        required: true,
        message: 'Last Name cannot be blank.',
      },
    ],
  },
];

export const renderFormItem = (formItem, disabled = false) => {
  switch (formItem.type) {
    case 'select':
      return (
        <Select
          placeholder={formItem.placeholder}
          className={formItem.className}
        >
          {formItem.options.map((option, index) => (
            <Option key={option.value + index} value={option.value}>
              {option.name}
            </Option>
          ))}
        </Select>
      );
    default:
      return <Input className={formItem.className} disabled={disabled} />;
  }
};
