import { useMobileNavbar } from 'components/NavbarSearch/_context/NavbarMobileProvider';

export const useContainerClass = (navFixed, isCmsPage) => {
  const { searchVisible } = useMobileNavbar();
  const className = `direct-navigation 
      ${
        navFixed
          ? `direct-navigation--fixed pr--16 ${isCmsPage ? '' : 'mt--40'}`
          : ''
      } 
      ${searchVisible ? '' : 'direct-navigation--shadow'} 
    `;

  return { className };
};
