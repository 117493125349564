import React from 'react';
import { Form } from 'md-styled-components';
import propTypes from 'prop-types';

const FormWrapper = ({ children, action, onFinish, options, form }) => (
  <Form
    hideRequiredMark
    form={form}
    id={options.form.id}
    {...(options.form.vertical && { layout: 'vertical' })}
    {...(action && { action })}
    method='post'
    onFinish={onFinish}
  >
    {children}
  </Form>
);

FormWrapper.propTypes = {
  action: propTypes.string,
  onFinish: propTypes.func.isRequired,
  options: propTypes.object.isRequired,
  form: propTypes.object.isRequired,
};

export default FormWrapper;
