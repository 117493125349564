import client from 'services/client';
import { responseTypes, pageRoutes } from 'constants/index';

const useAxiosInterceptors = () => {
  client.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status, data } = error?.response || { status: null, data: {} };
      if (!data.alertData) {
        switch (status) {
          case responseTypes.NOT_FOUND_STATUS:
            window.location.pathname =
              data.redirectUrl || pageRoutes.NOT_FOUND_URL;
            break;
          case responseTypes.NOT_ALLOWED_STATUS:
            window.location.pathname =
              data.redirectUrl || pageRoutes.SIGN_IN_URL;
            break;
          case responseTypes.HTTP_STATUS_409:
            if (data.reloadRequired) {
              window.location.reload();
            }
            break;
          default:
            break;
        }
      }
      return Promise.reject(error);
    }
  );
};

export default useAxiosInterceptors;
