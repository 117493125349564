import { useCallback } from 'react';
import { useIsMobile } from 'hooks';

const useZIndex = () => {
  const isMobile = useIsMobile();

  const getZIndex = useCallback(
    (suffix) => {
      if (suffix === '-navbar-tab') {
        return 1050;
      }
      if (isMobile || suffix === '-navbar-select') {
        return 10;
      }
      return 3;
    },
    [isMobile]
  );

  return getZIndex;
};

export default useZIndex;
