import { possibleCptCodes } from '../helpers';
import { ProceduresOption } from './DefaultOptions';

export default {
  name: 'Procedures',
  data: [
    {
      ...ProceduresOption,
      rounded: 'right',
      border: true,
      rules: [{ required: true }],
      mainData: [],
      setSearchData: () => {},
      takeFirstOnEnter: true,
    },
  ],
  redirectOnChange: true,
  redirect: (items, callback) => {
    const {
      optionsSelected,
      search,
      mainData,
      setSearchData,
      setSearchText,
      setIsCptSearch,
      clearPrevSearchResult,
      dataSource,
      changePage,
    } = items[0];
    if (!search.text || !mainData) {
      return;
    }
    const possibleCpts = possibleCptCodes(search.text);
    setSearchText(search.text);
    clearPrevSearchResult();

    if (!optionsSelected) {
      setSearchData(
        mainData.filter((item) =>
          dataSource.find((element) => element.id === item.id)
        )
      );
      if (!possibleCpts.length) {
        setIsCptSearch(false);
      } else {
        setIsCptSearch(true);
      }
    } else {
      setIsCptSearch(false);
      if (optionsSelected.foundCptCode) {
        setSearchData([mainData.find((el) => el.id === optionsSelected.id)]);
      } else {
        setSearchData([mainData.find((el) => el.id === optionsSelected.id)]);
      }
    }
    callback();
    changePage();
  },
};
