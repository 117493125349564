import React from 'react';
import { Header } from 'md-styled-components';

const { AutoComplete: HeaderAutoComplete } = Header;
const { Menu, MenuItem } = HeaderAutoComplete;

export default ({
  results,
  menuProps,
  itemClass,
  className,
  renderContent,
  inputValue,
}) => (
  <Menu {...menuProps} className={className}>
    {results.map((item, index) => (
      <MenuItem
        key={item.id}
        option={item}
        position={index}
        className={itemClass}
      >
        <div className='info-wrapper'>{renderContent(item, inputValue)}</div>
      </MenuItem>
    ))}
  </Menu>
);
