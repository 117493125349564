import React, { useEffect } from 'react';
import { Typography } from 'md-styled-components';
import { Link } from 'md-styled-components-v2';
import { useSelector } from 'react-redux';
import { showNumber } from 'hooks/PhoneNumber';
import { getChatLink } from 'selectors/liveAgent';
import SupportPhoneLink from 'components/Support/SupportPhoneLink';
import { common } from 'constants/index';
import SupportModalLink from 'components/Support/SupportModalLink';
import { CONTACT_US_TEXT, SPANISH_SPIKING_TEXT } from '../_constants';

const { workHours } = common;
const { Paragraph } = Typography;

const Overlay = () => {
  const onlineChat = useSelector(getChatLink);

  useEffect(() => {
    showNumber();
  }, []);

  return (
    <div className='contact-us p--16'>
      {onlineChat && (
        <div className='contact-us__item--lg flex-display flex-align-items-center'>
          <SupportModalLink
            className='contact-us__link ml--16'
            children='Live Chat'
          />
        </div>
      )}

      <div className='contact-us__item--lg flex-display flex-align-items-center'>
        <Link to='/contactus' size='large' className='contact-us__link ml--16'>
          {CONTACT_US_TEXT}
        </Link>
      </div>
      <Paragraph className='mt--8 ml--16'>
        Call <SupportPhoneLink loadNumber />
      </Paragraph>
      <Paragraph className='mt--4 mb--8 ml--16' level={4} color='grey'>
        {workHours}
      </Paragraph>
      <Paragraph
        level={4}
        fontFamily='bookOblique'
        color='grey'
        className='ml--16 mb--8'
      >
        {SPANISH_SPIKING_TEXT}
      </Paragraph>
    </div>
  );
};

export default React.memo(Overlay);
