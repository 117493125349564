import React from 'react';
import { Tabs } from 'md-styled-components';
import SearchWrapper from '../SearchWrapper';

const Default = ({
  options,
  showButton,
  border,
  showInputButton,
  inputButtonType,
}) =>
  options.length > 1 ? (
    <Tabs
      shape='rounded'
      tabType='figured'
      className='search-tabs'
      items={options.map((option, key) => ({
        label: option.name,
        key,
        children: (
          <SearchWrapper
            items={option.data}
            showButton={showButton}
            showInputButton={showInputButton}
            inputButtonType={inputButtonType}
            border={border}
            redirectOnChange={option.redirectOnChange}
            redirect={option.redirect}
          />
        ),
      }))}
    />
  ) : (
    options.map((option, key) => (
      <SearchWrapper
        key={key}
        items={option.data}
        showButton={showButton}
        showInputButton={showInputButton}
        inputButtonType={inputButtonType}
        border={border}
        redirectOnChange={option.redirectOnChange}
        redirect={option.redirect}
        searchInitialValue={option.inputValue}
        restoreDefaultSearch={option.restoreDefaultSearch}
      />
    ))
  );

export default Default;
