/**
 * Constants used throughout the checkout process and related actions.
 *
 * This file exports various constants that are utilized in the checkout functionality,
 * including action types for Redux, button labels, and configuration values.
 */

export const CHECKOUT_CANONICAL_URL = 'https://www.mdsave.com/checkout';

/**
 * Action types for CheckoutProvider
 */
export const SET_SELECTED_PATIENT = 'SET_SELECTED_PATIENT';
export const SET_CREDIT_CARD_INFO_LOADING = 'SET_CREDIT_CARD_INFO_LOADING';
export const SET_CREDIT_CARD_INFO = 'SET_CREDIT_CARD_INFO';
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';
export const SET_DISCOUNT_DATA = 'SET_DISCOUNT_DATA';
export const SET_INITIAL_DATA = 'SET_INITIAL_DATA';
export const SET_EDIT_OR_FOR_SOMEONE = 'SET_EDIT_OR_FOR_SOMEONE';
export const SET_DUPLICATE_MODAL = 'SET_DUPLICATE_MODAL';
export const CREATE_NEW_PATIENT = 'CREATE_NEW_PATIENT';
export const RESET_PATIENT = 'RESET_PATIENT';
export const SET_PAGE_LOADING = 'SET_PAGE_LOADING';
export const SET_PATIENT_BALANCE = 'SET_PATIENT_BALANCE';
export const SET_COMPANY_BALANCE = 'SET_COMPANY_BALANCE';
export const RESET_SELECTED_COMPANY = 'RESET_SELECTED_COMPANY';

/**
 * Labels for purchase buttons
 */
export const PURCHASE_BUTTON_CONTINUE = 'Continue';
export const PURCHASE_BUTTON_PAY_PAL = 'PayPal';
export const PURCHASE_BUTTON_PURCHASE = 'Purchase';

/**
 * Constants for mailing and patient actions
 */
export const MAILING_COUNTRY_DOMESTIC = 'US';
export const EDIT_KEY = 1;
export const SOMEONE_KEY = 2;
