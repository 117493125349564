import React from 'react';
import { NavigationMenu, Button } from 'md-styled-components-v2';
import { useSelector } from 'react-redux';
import { getTabsByUserType, isPortalEmployer } from 'selectors/users';
import MenuTitle from './MenuTitle';

const { SubMenu, Item } = NavigationMenu;

const UserInfoSm = () => {
  const tabsByUserType = useSelector(getTabsByUserType);
  const isEmployer = useSelector(isPortalEmployer);

  return (
    <NavigationMenu mode='inline'>
      {isEmployer ? (
        <Item>
          <MenuTitle />
        </Item>
      ) : (
        <SubMenu title={<MenuTitle hasSub />}>
          {tabsByUserType &&
            tabsByUserType.map(
              (item, index) =>
                item.mobile !== false && (
                  <Item key={index}>
                    <Button type='link' href={item.href} className='ml--32'>
                      {item.name}
                    </Button>
                  </Item>
                )
            )}
        </SubMenu>
      )}
    </NavigationMenu>
  );
};

export default UserInfoSm;
