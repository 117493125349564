import React, { useState, lazy, Suspense } from 'react';
import { Typography } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';

const { Text } = Typography;

const GiveFeedbackModal = lazy(() => import('./GiveFeedbackModal'));

const GiveFeedback = () => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <>
      <Button
        type='link'
        className='flex-display'
        onClick={() => setIsVisible(true)}
      >
        <Text>Give Feedback</Text>
      </Button>
      <Suspense>
        <GiveFeedbackModal visible={isVisible} setIsVisible={setIsVisible} />
      </Suspense>
    </>
  );
};

export default React.memo(GiveFeedback);
