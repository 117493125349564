import React, { useEffect, useState } from 'react';
import { NavigationMenu, Link, Button } from 'md-styled-components-v2';
import { Typography } from 'md-styled-components';
import { useLocation } from 'react-router-dom';
import { groupUserNavTabsApi } from 'services/groups';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'selectors/users';
import SubMenuTitle from './SubMenuTitle';
import SubItem from './SubItem';
import '../_styles/menu.less';
import { MARKETING_HOW_TO_ORDER_URL } from '../_constants';

const { SubMenu, Item } = NavigationMenu;
const { Text } = Typography;

const Menu = ({ groupId: propGroupId }) => {
  const [allTabs, setAllTabs] = useState(null);
  const currentUser = useSelector(getCurrentUser);
  const groupId = propGroupId || currentUser.groupInfo?.GroupId;
  const { pathname } = useLocation();
  const setActiveClass = (url) =>
    pathname === url ? 'direct-menu__underline--selected' : '';

  useEffect(() => {
    let mounted = true;
    groupUserNavTabsApi({ groupId }).then((response) => {
      if (mounted) {
        setAllTabs(response.data?.data);
      }
    });
    return () => {
      mounted = false;
    };
  }, [groupId]);

  const groupIdUrl = groupId ?? 1;
  return allTabs ? (
    <NavigationMenu
      mode='horizontal'
      overflowedIndicator={<SubMenuTitle title='More' />}
      getPopupContainer={(trigger) => trigger.parentNode.parentNode.parentNode}
      className='direct-menu'
    >
      {allTabs.map((tab) => {
        switch (tab.id) {
          case 'TrainingHub':
            return (
              <SubMenu
                key={tab.id}
                className='pr--24'
                title={
                  <SubMenuTitle
                    className={setActiveClass(tab.url[0])}
                    title={tab.label}
                  />
                }
              >
                <div className='flex-display flex-direction-column m--16 pv--4'>
                  <SubItem>
                    <Link to={`/training?GroupId=${groupIdUrl}`} size='large'>
                      Training Videos
                    </Link>
                  </SubItem>
                  <SubItem>
                    <Link
                      to={`/training?GroupId=${groupIdUrl}#videoSection`}
                      size='large'
                    >
                      How-to Guides
                    </Link>
                  </SubItem>
                  <SubItem>
                    <Link
                      to={`/training?GroupId=${groupIdUrl}#videoSection`}
                      size='large'
                    >
                      Reference Videos
                    </Link>
                  </SubItem>
                </div>
              </SubMenu>
            );

          case 'MarketingMaterials':
            return (
              <SubMenu
                key={tab.id}
                className='pr--24'
                title={
                  <SubMenuTitle
                    className={setActiveClass(tab.url[0])}
                    title={
                      <Button
                        type='link'
                        className='direct-menu__title--active'
                        target='_blank'
                        href={`${tab.url[0]}?GroupId=${groupIdUrl}`}
                      >
                        {tab.label}
                      </Button>
                    }
                  />
                }
              >
                <div className='flex-display flex-direction-column m--16 pv--4'>
                  <SubItem>
                    <Button
                      type='link'
                      href={MARKETING_HOW_TO_ORDER_URL}
                      target='_blank'
                    >
                      How to Order
                    </Button>
                  </SubItem>
                  <SubItem>
                    <Button type='link' href={tab.url[0]} target='_blank'>
                      Order Materials
                    </Button>
                  </SubItem>
                </div>
              </SubMenu>
            );

          default:
            return (
              <Item
                key={tab.id}
                className={`pr--24 ${setActiveClass(
                  tab.url[0]
                )} direct-menu__underline relative`}
              >
                <Button
                  type='link'
                  href={`${tab.url[0]}?GroupId=${groupIdUrl}`}
                  className='direct-menu__title--active'
                >
                  {tab.label}
                </Button>
                {!!tab.components.dataBadge && (
                  <div className='direct-menu__data-badge flex-display flex-justify-center flex-align-items-center'>
                    <Text
                      className='direct-menu__data-badge-text'
                      fontFamily='black'
                      color='white'
                    >
                      {tab.components.dataBadgeValue}
                    </Text>
                  </div>
                )}
              </Item>
            );
        }
      })}
    </NavigationMenu>
  ) : null;
};

export default Menu;
