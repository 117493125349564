import React from 'react';
import { Icon, Themes, Typography } from 'md-styled-components';

const { Text } = Typography;

const ShareTabPanel = ({ activeTab, item, shareType }) => {
  const isActive = activeTab.tab === item.tab;

  return (
    <div className='pb--16'>
      <div className='flex-display flex-justify-center pb--8'>
        <div
          className={`flex-display flex-align-items-center flex-justify-center share-cart__tab-icon 
              ${isActive ? 'share-cart__tab-icon--active' : ''}`}
        >
          <Icon
            size={item.iconSize}
            color={isActive ? Themes.white : Themes.B70}
            type={item.icon}
            className={`social-tabs__item ${item.className} ${
              isActive && 'social-tabs__item--active'
            }`}
          />
        </div>
      </div>
      <Text className='ph--24' level={3} fontFamily='black' color='linkColor'>
        {item.title[shareType]}
      </Text>
    </div>
  );
};

export default ShareTabPanel;
