import React, { useState } from 'react';
import { NavigationMenu, Button, Link } from 'md-styled-components-v2';
import { EMPLOYER_CONNECT } from 'constants/tendoLinks';
import SubMenuTitle from './SubMenuTitle';
import '../../../_styles/menu-inline.less';

const { SubMenu, Item } = NavigationMenu;

const Menu = () => {
  const [openKeys, setOpenKeys] = useState(['']);

  const openSubMenu = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  return (
    <NavigationMenu
      mode='inline'
      openKeys={openKeys}
      onOpenChange={openSubMenu}
    >
      <SubMenu
        key='for_providers'
        title={<SubMenuTitle title='For Providers' />}
      >
        <Item key='providers'>
          <Link to='/providers' size='large' className='ml--32'>
            Provider Partners
          </Link>
        </Item>
        <Item key='referring_providers'>
          <Link to='/referring-providers' size='large' className='ml--32'>
            Referring Providers
          </Link>
        </Item>
        <Item key='referring_providers'>
          <Button
            type='link'
            href={EMPLOYER_CONNECT}
            className='ml--32'
            target='_blank'
          >
            Care Connect
          </Button>
        </Item>
      </SubMenu>
      <Item key='employers'>
        <Link to='/employers' size='large' className='ph--24 menu-inline__link'>
          For Employers
        </Link>
      </Item>
      <SubMenu key='for_patients' title={<SubMenuTitle title='For Patients' />}>
        <Item key='patients'>
          <Button type='link' href='/patients' className='ml--32'>
            How it Works
          </Button>
        </Item>
        <Item key='faq'>
          <Link to='/faq' size='large' className='ml--32'>
            FAQ
          </Link>
        </Item>
        <Item key='reviews'>
          <Button type='link' href='/reviews' className='ml--32'>
            Testimonials
          </Button>
        </Item>
        <Item key='deductible'>
          <Link
            to='/whats-my-health-insurance-deductible'
            size='large'
            className='ml--32'
          >
            Deductible Checker
          </Link>
        </Item>
        <Item key='healthcare_prices'>
          <Button
            type='link'
            href='/healthcare-prices-explained'
            className='ml--32'
          >
            Healthcare Prices
          </Button>
        </Item>
        {/* <Item key='rx'>
          <Button type='link' href='/rx' className='ml--32'>
            MDsave Rx
          </Button>
        </Item> */}
      </SubMenu>
      <SubMenu
        key='all_procedures'
        title={<SubMenuTitle title='All Procedures' />}
      >
        <Item key='colonoscopy'>
          <Link to='/t/wellness/colon-health' size='large' className='ml--32'>
            Colonoscopy
          </Link>
        </Item>
        <Item key='ct_scan'>
          <Link
            to='/t/imaging-radiology/ct-scan'
            size='large'
            className='ml--32'
          >
            CT Scan
          </Link>
        </Item>
        <Item key='gastric_sleeve'>
          <Link
            to='/t/general-surgery/bariatric-surgery'
            size='large'
            className='ml--32'
          >
            Gastric Sleeve
          </Link>
        </Item>
        <Item key='mammogram'>
          <Link
            to='/t/imaging-radiology/breast-cancer-imaging'
            size='large'
            className='ml--32'
          >
            Mammogram
          </Link>
        </Item>
        <Item key='mri'>
          <Link
            to='/t/imaging-radiology/how-much-does-mri-cost'
            size='large'
            className='ml--32'
          >
            MRI
          </Link>
        </Item>
        <Item key='ultrasound'>
          <Link
            to='/t/imaging-radiology/ultrasound'
            size='large'
            className='ml--32'
          >
            Ultrasound
          </Link>
        </Item>
        <Item key='x-ray'>
          <Link to='/t/imaging-radiology/x-ray' size='large' className='ml--32'>
            X-ray
          </Link>
        </Item>
        <Item key='procedures'>
          <Link to='/procedures' size='large' className='ml--32'>
            View All
          </Link>
        </Item>
      </SubMenu>
      <SubMenu
        key='featured_specialties'
        title={<SubMenuTitle title='Featured Specialties' />}
      >
        <Item key='bariatrics'>
          <Link
            to='/t/general-surgery/bariatric-surgery'
            size='large'
            className='ml--32'
          >
            Bariatrics
          </Link>
        </Item>
        <Item key='general_surgery'>
          <Link to='/t/general-surgery' size='large' className='ml--32'>
            General Surgery
          </Link>
        </Item>
        <Item key='imaging_radiology'>
          <Link to='/t/imaging-radiology' size='large' className='ml--32'>
            Imaging & Radiology
          </Link>
        </Item>
        <Item key='labs'>
          <Link to='/t/labs' size='large' className='ml--32'>
            Labs
          </Link>
        </Item>
        <Item key='office_visits'>
          <Link to='/t/wellness/office-visits' size='large' className='ml--32'>
            Office Visits
          </Link>
        </Item>
        <Item key='pregnancy'>
          <Link to='/t/pregnancy' size='large' className='ml--32'>
            Pregnancy
          </Link>
        </Item>
        <Item key='wellness'>
          <Link to='/t/wellness' size='large' className='ml--32'>
            Wellness
          </Link>
        </Item>
        <Item key='united_states'>
          <Button type='link' href='/united-states' className='ml--32'>
            View All
          </Button>
        </Item>
      </SubMenu>
      <SubMenu key='locations' title={<SubMenuTitle title='Locations' />}>
        <Item key='arizona'>
          <Button type='link' href='/arizona' className='ml--32'>
            Arizona
          </Button>
        </Item>
        <Item key='florida'>
          <Button type='link' href='/florida' className='ml--32'>
            Florida
          </Button>
        </Item>
        <Item key='indiana'>
          <Button type='link' href='/indiana' className='ml--32'>
            Indiana
          </Button>
        </Item>
        <Item key='nebraska'>
          <Button type='link' href='/nebraska' className='ml--32'>
            Nebraska
          </Button>
        </Item>
        <Item key='oklahoma'>
          <Button type='link' href='/oklahoma' className='ml--32'>
            Oklahoma
          </Button>
        </Item>
        <Item key='tennessee'>
          <Button type='link' href='/tennessee' className='ml--32'>
            Tennessee
          </Button>
        </Item>
        <Item key='texas'>
          <Button type='link' href='/texas' className='ml--32'>
            Texas
          </Button>
        </Item>
        <Item key='united-states_2'>
          <Button type='link' href='/united-states' className='ml--32'>
            View All
          </Button>
        </Item>
      </SubMenu>
      <SubMenu key='resources' title={<SubMenuTitle title='Resources' />}>
        <Item key='about_us'>
          <Button type='link' href='/about-us' className='ml--32'>
            About Us
          </Button>
        </Item>
        <Item key='press_release'>
          <Link
            to='/media/category/press-release'
            size='large'
            className='ml--32'
          >
            Press Releases
          </Link>
        </Item>
        <Item key='whitepapers'>
          <Link
            to='/media/category/whitepapers'
            size='large'
            className='ml--32'
          >
            Whitepapers
          </Link>
        </Item>
        <Item key='webinars'>
          <Link
            to='/media/category/infographics'
            size='large'
            className='ml--32'
          >
            Infographics
          </Link>
        </Item>
        <Item key='case-studies'>
          <Link
            to='/media/category/case-studies'
            size='large'
            className='ml--32'
          >
            Case Studies
          </Link>
        </Item>
        <Item key='blogs'>
          <Link
            to='/media/category/blog-entries'
            size='large'
            className='ml--32'
          >
            Blog Entries
          </Link>
        </Item>
        <Item key='videos'>
          <Link to='/media/category/videos' size='large' className='ml--32'>
            Videos
          </Link>
        </Item>
      </SubMenu>
      <Item key='financing'>
        <Link to='/financing' size='large' className='ph--24 menu-inline__link'>
          Financing
        </Link>
      </Item>
    </NavigationMenu>
  );
};

export default Menu;
