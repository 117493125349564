import { createSelector } from 'reselect';
import {
  requestSuggestedProcedures,
  requestSuggestedProceduresHeader,
  requestProcedure,
  requestMDSaveAverage,
  requestProcedureSurveys,
} from 'actions/procedures';
import createLoadingSelector from './create-loading-selector';

const procedures = (state) => state.procedures;

export const getProcedureMDSaveAverage = createSelector(
  procedures,
  (procedure) => procedure.averageOfMDSave
);

export const getProcedure = createSelector(
  procedures,
  (procedure) => procedure.procedure
);

export const getSelectedProcedure = createSelector(
  procedures,
  (procedure) => procedure.selectedProcedure
);

export const getRelatedProcedures = createSelector(
  procedures,
  (procedure) => procedure.relatedProcedures
);

export const getAverageRating = createSelector(
  procedures,
  (procedure) => procedure.averageRating
);

export const getCustomerLocation = createSelector(
  procedures,
  (procedure) => procedure.customerLocation
);

export const getProvidersList = createSelector(
  procedures,
  (procedure) => procedure.providersList
);

export const getStructuredData = createSelector(
  procedures,
  (procedure) => procedure.structuredData
);

export const suggestedProcedures = (state) => state.suggestedProcedures;

export const getSuggestedProcedures = createSelector(
  suggestedProcedures,
  (procedure) => procedure.suggestedProcedures
);

export const getSuggestedProceduresHeader = createSelector(
  suggestedProcedures,
  (procedure) => procedure.suggestedProceduresHeader
);

export const getProcedureSurveys = createSelector(
  procedures,
  (procedure) => procedure.procedureSurveys
);

export const getShowMoreCount = createSelector(
  procedures,
  (procedure) => procedure.showMoreClickCount
);

export const createSuggestedProceduresLoading = createLoadingSelector(
  requestSuggestedProcedures.actionName
)();

export const createSuggestedProceduresHeaderLoading = createLoadingSelector(
  requestSuggestedProceduresHeader.actionName
)();

export const createGetProcedureLoading = createLoadingSelector(
  requestProcedure.actionName
)();

export const createGetProcedureMDSaveAverageLoading = createLoadingSelector(
  requestMDSaveAverage.actionName
)();

export const createGetProcedureSurveysLoading = createLoadingSelector(
  requestProcedureSurveys.actionName
)();
