import React from 'react';
import { Typography } from 'md-styled-components';
import { Link } from 'md-styled-components-v2';
import SupportPhoneLink from 'components/Support/SupportPhoneLink';
import { getIsPortalUser } from 'selectors/users';
import { common } from 'constants';
import { useSelector } from 'react-redux';

const { Text, Paragraph } = Typography;
const { supportEmail, PAYMENT_TEAM_EMAIL } = common;

const Info = () => {
  const isPortalUser = useSelector(getIsPortalUser);
  return (
    <>
      {isPortalUser && (
        <>
          <Paragraph
            className='pb--8'
            level={4}
            letterSpacing={3}
            color='paleSky'
          >
            NEED HELP?
          </Paragraph>
          <Paragraph className='pr-sm--16 pr-md--16 mds-text--normal contact-us__info'>
            Complete our&nbsp;
            <Link
              className='contact-us-info__contact-link'
              size='large'
              to='/contactus'
              underline
            >
              Contact Us Form
            </Link>
          </Paragraph>
        </>
      )}
      <Paragraph
        className='pt-sm--16 pt-md--16 pt--32 mb--8'
        level={4}
        letterSpacing={3}
        color='paleSky'
      >
        GENERAL SUPPORT
      </Paragraph>
      <SupportPhoneLink
        className='text-left pv--8'
        renderCustomText={(children) => (
          <Text color='linkColor' children={children} />
        )}
        loadNumber
      />
      <Paragraph>{supportEmail}</Paragraph>
      <Paragraph
        level={4}
        letterSpacing={3}
        color='paleSky'
        className='pt--32 pb--8'
      >
        BILLING SUPPORT
      </Paragraph>
      <Paragraph>{PAYMENT_TEAM_EMAIL}</Paragraph>
    </>
  );
};

export default Info;
