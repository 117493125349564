import { useRef } from 'react';
import { Form } from 'md-styled-components-v2';
import Provider from '../../Autocompletes/Provider';
import useOnFinish from './useOnFinish';

const ProviderForm = ({ SubmitButton, suffix = '' }) => {
  const [form] = Form.useForm();
  const onFinish = useOnFinish(form);
  const providerRef = useRef(null);

  return (
    <Form form={form} onFinish={onFinish} layout='horizontal'>
      <div className='form-wrapper with-button'>
        <Form.Item name='provider'>
          <Provider suffix={suffix} ref={providerRef} />
        </Form.Item>
        <div className='button-container flex-display flex-align-self-top flex-align-items-center'>
          <SubmitButton id='search-provider-btn' />
        </div>
      </div>
    </Form>
  );
};

export default ProviderForm;
