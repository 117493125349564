import React from 'react';
import { NavigationMenu, Button } from 'md-styled-components-v2';
import { useTillTabletLg } from 'hooks';
import { common } from 'constants';
import { useSelector } from 'react-redux';
import { getTabsByUserType } from 'selectors/users';
import SubMenuTitle from '../../_partials/SubMenuTitle';
import '../../_styles/menu.less';

const { Item } = NavigationMenu;

const Menu = () => {
  const isTillTabletLg = useTillTabletLg();
  const tabsByUserType = useSelector(getTabsByUserType);

  return (
    <NavigationMenu
      mode='horizontal'
      overflowedIndicator={<SubMenuTitle title='More' />}
      getPopupContainer={(trigger) => trigger.parentNode.parentNode.parentNode}
      triggerSubMenuAction={isTillTabletLg ? 'click' : 'hover'}
    >
      {tabsByUserType
        ?.filter(
          (tab) =>
            tab.mobile !== true &&
            (tab.href || tab.to).indexOf(common.signOutUrl) === -1
        )
        .map((item) => (
          <Item key={item.href} className='pr--24 menu__underline'>
            <Button
              type='link'
              href={item.href}
              className='menu__title--active'
            >
              {item.name}
            </Button>
          </Item>
        ))}
    </NavigationMenu>
  );
};

export default Menu;
