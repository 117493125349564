import React from 'react';
import { useSelector } from 'react-redux';
import {
  getIsPortalUser,
  getHomePageUrl,
  getCurrentCompanyInfo,
} from 'selectors/users';
import { Themes, Typography } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { requestClearCart, requestGetItems } from 'actions/shopping-cart';
import { requestSaveCart } from 'actions/patients';
import { createGetSaveCartLoading } from 'selectors/patients';
import { useBreakpoint } from '../../hooks';

const { Title, Paragraph } = Typography;

export const closeCart = (dispatch, groupId, onClose) => {
  const requestData = {
    data: {
      closeCart: true,
    },
    groupId: groupId || '',
    cb: () => {
      dispatch(requestClearCart.request({ localClear: true }));
      dispatch(requestGetItems.request());
      onClose();
    },
  };
  dispatch(requestSaveCart.request(requestData));
};

export const SuccessFooterData = ({ dispatch, onClose }) => {
  const isPortalUser = useSelector(getIsPortalUser);
  return isPortalUser ? (
    <PortalUserFooter dispatch={dispatch} onClose={onClose} />
  ) : (
    <DefaultFooter dispatch={dispatch} onClose={onClose} />
  );
};

const PortalUserFooter = ({ dispatch, onClose }) => {
  const { currentCompany } = useSelector(getCurrentCompanyInfo);
  const homePageUrl = useSelector(getHomePageUrl);
  const navigate = useNavigate();
  return (
    <div className='flex-display flex-align-items-center flex-direction-column'>
      <Button
        className='mt--24 mb--24'
        size='large'
        onClick={(e) => {
          e.preventDefault();
          closeCart(dispatch, null, () => {
            onClose();
            navigate(homePageUrl.href ?? '/');
          });
        }}
      >
        Ok, Return to Homepage
      </Button>
      <Button
        type='link'
        onClick={(e) => {
          e.preventDefault();
          closeCart(dispatch, null, () => {
            onClose();
            navigate(`/company/savedCarts?portalId=${currentCompany.id}`);
          });
        }}
      >
        <Paragraph color='grey'>View Shared Cart</Paragraph>
      </Button>
    </div>
  );
};

const DefaultFooter = ({ dispatch, onClose }) => {
  const saveCartLoading = useSelector(createGetSaveCartLoading);
  const [searchParams] = useSearchParams();
  return (
    <div className='flex-display flex-align-items-center flex-direction-column'>
      <Button
        className='mt--24 mb--24'
        size='large'
        loading={saveCartLoading}
        onClick={() =>
          closeCart(dispatch, searchParams.get('groupId'), onClose)
        }
      >
        Close Cart
      </Button>
      <Button
        type='link'
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}
      >
        <Paragraph color='grey'>Keep Cart Open</Paragraph>
      </Button>
    </div>
  );
};

export const WarningTitle = () => {
  const isMobile = useBreakpoint(Themes.breakpoints.xs, Themes.breakpoints.md);
  return (
    <Title level={3} className='text-center'>
      This patient has opted out of {!isMobile && <br />} receiving shared
      shopping cart emails
    </Title>
  );
};

export const WarningBodyData = React.memo(() => (
  <div className='pt--24 text-left'>
    <Paragraph color='grey' className='mb--24'>
      Please either
    </Paragraph>
    <div className='flex-display mb--24'>
      <Paragraph color='grey'>{'1. '}</Paragraph>
      <Paragraph color='grey' className='pl--4'>
        Ask if the patient would like to receive the cart by text message, or
      </Paragraph>
    </div>
    <div className='flex-display'>
      <Paragraph color='grey'>{'2. '}</Paragraph>
      <Paragraph color='grey' className='pl--4'>
        Have the patient log in to MDsave.com and change their email preferences
        under Account Settings.
      </Paragraph>
    </div>
  </div>
));

export const WarningFooterData = React.memo(({ onClose }) => (
  <div className='pt--16'>
    <Button size='large' onClick={onClose}>
      Close
    </Button>
  </div>
));
