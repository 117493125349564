import React from 'react';
import { Checkbox, DatePicker, Typography } from 'md-styled-components';
import { Select, Input } from 'md-styled-components-v2';
import { MaskedInput } from 'components/Form/Phone';
import { disabledDateBefore } from 'utils/form/datepicker';
import { phoneValidation } from 'utils/phoneHelper';
import errors from 'utils/messages/errors';

const { Option } = Select;
const { Text } = Typography;

export const getFormFields = ({
  emailPreferences,
  setEmailPreferences,
  noEmailChecked,
  inputEl,
  setFieldsValue,
  validateUserByPersonalInformation,
  showInputs = true,
}) => [
  showInputs && {
    label: 'First Name',
    name: 'first_name',
    onBlur: validateUserByPersonalInformation,
    rules: [
      {
        required: true,
        message: 'First Name cannot be blank.',
      },
    ],
  },
  showInputs && {
    label: 'Last Name',
    name: 'last_name',
    onBlur: validateUserByPersonalInformation,
    rules: [
      {
        required: true,
        message: 'Last Name cannot be blank.',
      },
    ],
  },
  showInputs && {
    type: 'birthday',
  },
  showInputs && {
    label: 'Patient Email',
    name: 'email_address',
    className: 'phi',
    disabled: noEmailChecked,
    onChange: (e) => {
      setEmailPreferences(e.target.value.length > 0);
    },
    rules: [
      {
        required: !noEmailChecked,
        message: 'Email address cannot be blank.',
      },
      {
        type: 'email',
        message: 'Please enter a valid email address.',
      },
    ],
  },
  showInputs && {
    name: 'noEmail',
    type: 'checkbox',
    checkboxText: 'Patient does not have an email',
    textType: 3,
    rules: [],
    formItemClassName: 'save-cart__no-email',
    onChange: (e) => {
      if (e.target.checked) {
        setFieldsValue({
          email_address: '',
        });
        setEmailPreferences(false);
      }
    },
  },
  emailPreferences && {
    label: 'Email Preferences',
    name: 'subscribe',
    type: 'checkbox',
    fullWidth: true,
    offsetTop: true,
    checkboxText: 'Receive information via email from MDsave.',
    rules: [],
    labelBottomOffset: 2,
  },
  emailPreferences && {
    name: 'email',
    type: 'checkbox',
    fullWidth: true,
    offsetTop: true,
    checkboxText:
      'Email a copy of this cart to the patient. The patient has given permission to communicate via email.',
    rules: [],
    labelBottomOffset: 2,
  },
  showInputs && {
    label: 'Phone',
    name: 'mobile_phone',
    type: 'phone',
    className: 'phi',
    rules: [
      {
        required: true,
        message: 'Phone cannot be blank.',
      },
      {
        validator() {
          return phoneValidation(inputEl)
            ? Promise.reject(errors.phoneNotValid)
            : Promise.resolve();
        },
      },
    ],
  },
  {
    label: 'Texting Preferences',
    name: 'text',
    type: 'checkbox',
    fullWidth: true,
    offsetTop: true,
    checkboxText:
      'Text a copy of this cart to the patient.The patient has given permission to communicate via text message.',
    rules: [],
    labelBottomOffset: 2,
    formItemClassName: 'mb--0 pb--0',
  },
];

export const procedureOptionsFields = (isShareCart = false) => [
  {
    label: 'Service Date (Optional)',
    name: 'service_date',
    type: 'datepicker',
    ownProps: {
      allowClear: true,
      format: 'MM/DD/YYYY',
      inputReadOnly: true,
      mode: 'date',
    },
  },
  ...(isShareCart
    ? [
        {
          label: 'Cart Expiration Date (Optional)',
          name: 'cart_expires',
          type: 'datepicker',
          ownProps: {
            allowClear: true,
            format: 'MM/DD/YYYY',
            inputReadOnly: true,
            mode: 'date',
            disabledDate: disabledDateBefore,
          },
        },
      ]
    : []),
  {
    label: 'ICD-9/10 Codes (Optional)',
    name: 'icd_code',
    fullWidth: isShareCart,
  },
];

export const renderFormItem = (formItem, inputEl) => {
  switch (formItem.type) {
    case 'checkbox':
      return (
        <Checkbox
          id={formItem.name}
          className={`flex-display-inline ${
            formItem.offsetTop ? 'save-cart__checkbox-wrapper--offset-top' : ''
          }`}
          onChange={formItem.onChange}
        >
          <Text level={formItem.textType} color='paleSky'>
            {formItem.checkboxText}
          </Text>
        </Checkbox>
      );
    case 'select':
      return (
        <Select
          placeholder={formItem.placeholder}
          className={formItem.className}
        >
          {formItem.options.map((option, index) => (
            <Option value={option.value} key={option.value + index}>
              {option.name}
            </Option>
          ))}
        </Select>
      );
    case 'phone':
      return (
        <MaskedInput
          ref={inputEl}
          className={formItem.className}
          name='mobile_phone'
        />
      );
    case 'datepicker':
      return <DatePicker {...formItem.ownProps} />;
    default:
      return (
        <Input
          classNames={{ input: `${formItem.className} phi` }}
          onChange={formItem.onChange}
          onBlur={formItem.onBlur}
          disabled={formItem.disabled}
        />
      );
  }
};
