import React, { Component } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import LiveAgent from 'components/LiveAgent';
import { isBot } from 'utils/common';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { requestMessages } from 'actions/messages';
import { requestGetItems } from 'actions/shopping-cart';
import { requestPrivateHospitalData } from 'actions/privateHospitalData';
import { checkBrowser } from 'utils/compatibilityHelper';
import { isPrivateHospital } from 'utils/privateSite';
import { BrowserRouter } from 'react-router-dom';
import GetUserData from 'components/PageComponents/GetUserData';
import ReCaptchaProvider from 'components/ReCaptchaProvider';
// import StatsigGetFlags from 'components/PageComponents/StatsigGetFlags';
import { isCarePackagePage, isCheckoutPage } from 'utils/pages';
import client from './services/client';
import { ViewportProvider } from './contexts/ViewportProvider';
import Routes from './routes/Routes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

// eslint-disable-next-line camelcase
__webpack_public_path__ = window.env.REACT_APP_CLOUD_FRONT_URL || '/';

if (
  window.env.REACT_APP_DATADOG_ENABLED === '1' &&
  window.env.REACT_APP_MOCK_SERVER !== 'true' &&
  !isBot()
) {
  datadogRum.init({
    applicationId: window.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: window.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: window.env.REACT_APP_DATADOG_SERVICE,
    env: window.env.REACT_APP_DATADOG_ENV,
    version: window.env.REACT_APP_DATADOG_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls: [
      (url) =>
        url.startsWith('https://mdsave.com') ||
        url.startsWith('https://staging.mdsave.com') ||
        url.startsWith('https://redox.mdsave.com') ||
        url.startsWith('https://demo.mdsave.com') ||
        url.startsWith('https://app-75985.on-aptible.com'),
    ],
    defaultPrivacyLevel: 'mask-user-input',
  });
}

// !important App should be a class component in order for hot reloading to work correctly
class App extends Component {
  constructor(props) {
    super(props);
    client.get('/api/publicSite/changeCsrf').then((response) => {
      this.props.requestMessages();
      if (!(isCheckoutPage() || isCarePackagePage())) {
        this.props.requestGetItems();
      }
      if (isPrivateHospital()) {
        this.props.reqPrivateHospitalData();
      }
    });
    checkBrowser();
  }

  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <Provider store={this.props.store}>
          <LiveAgent />
          <ViewportProvider>
            <BrowserRouter>
              <GetUserData />
              {/* <StatsigGetFlags /> */}
              <ReCaptchaProvider>
                <Routes />
              </ReCaptchaProvider>
            </BrowserRouter>
          </ViewportProvider>
        </Provider>
      </QueryClientProvider>
    );
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default connect(null, {
  requestGetItems: requestGetItems.request,
  requestMessages: requestMessages.request,
  reqPrivateHospitalData: requestPrivateHospitalData.request,
})(App);
