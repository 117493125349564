import { Typography, Highlighter } from 'md-styled-components';

const { Paragraph } = Typography;

const renderMenu = (results, inputValue) =>
  results?.map((item, index) => ({
    value: item.name_clear,
    label: (
      <>
        <div
          key={`${item.id}${index}`}
          className='info-wrapper phi header-autocomplete'
        >
          <div className='flex-display flex-justify-space-between'>
            <div>
              <Paragraph className='provider-name' level={3} color='indigo'>
                <Highlighter
                  searchWords={[inputValue]}
                  textToHighlight={item.name_clear}
                />
              </Paragraph>
              {!item.dontHaveAnEmail && (
                <Paragraph color='paleSky' level={4}>
                  <Highlighter
                    searchWords={[inputValue]}
                    textToHighlight={item.email_address}
                  />
                </Paragraph>
              )}
            </div>
            {item.is_ehr && (
              <Paragraph
                level={4}
                className='save-cart__for-patient-menu-hospital'
                color='paleSky'
              >
                {item.hospital_name}
              </Paragraph>
            )}
          </div>
          <div className='save-cart__for-patient-menu-details flex-display flex-justify-space-between'>
            <Paragraph level={4} color='paleSky'>
              {item.mobile_phone && (
                <>
                  {item.mobile_phone}{' '}
                  <span className='save-cart__for-patient-menu-dot'> • </span>
                </>
              )}{' '}
              {item.dob}
            </Paragraph>
            {item.is_ehr && (
              <Paragraph level={4} color='paleSky'>
                EHR Acct #
                <Highlighter
                  searchWords={[inputValue]}
                  textToHighlight={item.ehr_number}
                />
              </Paragraph>
            )}
          </div>
        </div>
      </>
    ),
    data: item,
  }));

export default renderMenu;
