import React, { useState } from 'react';
import { Button } from 'md-styled-components-v2';
import { isPrivateHospital } from 'utils/privateSite';
import propTypes from 'prop-types';
import useAxios from 'axios-hooks';
import FormContainer from './Forms/FormContainer';
import ButtonForm from './Forms/ButtonForm';
import FormWrapper from './Forms/FormWrapper';
import {
  BUTTON_TEXT_LOGIN,
  BUTTON_TEXT_LOGIN_LINK,
} from '../_constants/formTexts';
import { LOGIN_LINK_SECTION } from '../_constants/loginTypes';
import { changeLoginTypeAction } from '../_actions';
import { USER_ONE_TIME_LOGIN_LINK } from '../_constants/endpoints';
import { useAlert } from '../../../contexts/AlertProvider';

const LoginSection = ({ form, dispatch, onSubmitLogin, state, options }) => {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [, submitUserOneTimeLoginLink] = useAxios(
    {
      url: USER_ONE_TIME_LOGIN_LINK,
      method: 'POST',
    },
    { manual: true }
  );
  const { setFields, getFieldValue } = form;

  const showLoginLinkSection = () => {
    const email = getFieldValue('username');
    setFields([{ name: 'username', errors: [] }]);
    if (!email) {
      dispatch(changeLoginTypeAction(LOGIN_LINK_SECTION));
    } else {
      submitUserOneTimeLoginLink({ data: { email } }).then((res) => {
        if (res.data.success) {
          alert.success({ text: 'Great! We sent you an email.' });
        } else {
          dispatch(changeLoginTypeAction(LOGIN_LINK_SECTION));
        }
      });
    }
  };

  const onFinish = () => {
    const email = getFieldValue('username');
    const password =
      state.ssoRadioType === 'sso' && state.isSsoDomain
        ? ''
        : getFieldValue('password');
    setLoading(true);
    onSubmitLogin(email, password, setLoading);
  };

  return (
    <FormWrapper
      form={form}
      options={options}
      action={state.formAction}
      onFinish={onFinish}
    >
      <FormContainer
        form={form}
        dispatch={dispatch}
        options={options}
        state={state}
      />
      <Button
        id='sign-in'
        size='large'
        form={options.form.id}
        htmlType='submit'
        block
        type='primary'
        loading={loading}
      >
        {BUTTON_TEXT_LOGIN}
      </Button>
      {!isPrivateHospital() && (
        <ButtonForm
          onClick={showLoginLinkSection}
          options={options}
          title={BUTTON_TEXT_LOGIN_LINK}
        />
      )}
    </FormWrapper>
  );
};

LoginSection.propTypes = {
  form: propTypes.object.isRequired,
  dispatch: propTypes.func.isRequired,
  state: propTypes.object.isRequired,
  onSubmitLogin: propTypes.func.isRequired,
  options: propTypes.object.isRequired,
};

export default LoginSection;
