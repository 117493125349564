import React from 'react';
import PropTypes from 'prop-types';
import Default from './_partials/Default';
import './styles/index.less';

export function Search(props) {
  return <Default {...props} />;
}

Search.defaultProps = {
  showButton: true,
  showInputButton: true,
  inputButtonType: 'primary',
};
Search.propTypes = {
  options: PropTypes.array.isRequired,
  showButton: PropTypes.bool,
  showInputButton: PropTypes.bool,
  inputButtonType: PropTypes.string,
};
