import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTillTablet } from 'hooks';
import { getPrivateHospitalData } from 'selectors/privateHospitalData';
import { getIsPortalUser } from 'selectors/users';
import ProviderAuthorization from 'components/Navigation/UserSection/AuthorizationItem/ProviderAuthorization';
import PhoneNumberInfo from 'components/Navigation/TopNavigation/PoweredByBlock/PhoneNumberInfo';
import PoweredBy from 'components/Navigation/TopNavigation/PoweredByBlock/PoweredBy';
import '../_styles/powered-by-block.less';

const PoweredByBlock = () => {
  const isTillTablet = useTillTablet();
  const { showSignIn } = useSelector(getPrivateHospitalData);
  const privateHospitalData = useSelector(getPrivateHospitalData);
  const isPortalUser = useSelector(getIsPortalUser);
  const flexPosition = useMemo(
    () => (showSignIn && !isPortalUser ? 'space-between' : 'end'),
    [showSignIn, isPortalUser]
  );

  return (
    <div
      className={`hospital-powered-by-banner ph--32 ph-sm--0 pv--16 
    flex-display flex-justify-${
      isTillTablet ? 'center' : flexPosition
    } flex-align-items-center`}
    >
      {showSignIn && !isTillTablet && (
        <ProviderAuthorization isPortalUser={isPortalUser} />
      )}
      <div className='flex-display flex-align-items-center'>
        {!privateHospitalData.showSingleTab && <PhoneNumberInfo />}
        <PoweredBy />
      </div>
    </div>
  );
};

export default PoweredByBlock;
