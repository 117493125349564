const isInvalidPhoneNumber = (val) =>
  val?.length && !/^\(\d{3}\) \d{3}-\d{4}$/.test(val);

export const phoneValidation = (inputEl) => {
  const inputValue = inputEl.current.input.value;
  return isInvalidPhoneNumber(inputValue);
};

export const phoneValidator = (value) =>
  new Promise((resolve, reject) => {
    if (value && isInvalidPhoneNumber(value)) {
      reject(new Error('Please enter a valid phone number.'));
    } else {
      resolve();
    }
  });

export const formatPhoneNumber = (phoneNumber) => {
  const numberString = phoneNumber.toString();
  if (numberString.length < 10) {
    return phoneNumber;
  }
  const cleaned = numberString.replace(/\D/g, '');
  const match = cleaned.match(/(\d{3})(\d{3})(\d{4})/);
  const mainNumber = `(${match[1]}) ${match[2]}-${match[3]}`;
  if (numberString.length > 10) {
    return `${mainNumber} ext. ${numberString.slice(10)}`;
  }
  return mainNumber;
};

export const numberForTel = (phoneNumber) => {
  const numberString = phoneNumber.toString();
  if (numberString.length > 10) {
    return `${numberString.substr(0, 10)},${numberString.substr(10)}`;
  }
  return phoneNumber;
};

export const MASKED_PHONE_NUMBER = '(XXX) XXX-XXXX';
