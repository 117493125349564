import { Radio as Component } from 'antd';
import propTypes from 'prop-types';
import styled, { css } from 'styled-components';
import themes from '../Themes';
import RadioGroup from './RadioGroup';

/** @type {Component} */
const Radio = styled(({ topAlign, ...props }) => <Component {...props} />)`
  &.ant-radio-wrapper {
    color: ${themes.I90} !important;
  }

  .ant-radio {
    height: 20px;
    width: 20px;

    ${(props) =>
      props.topAlign &&
      css`
        align-self: auto;
        top: 5px;
      `}

    .ant-radio-inner,
    &:hover .ant-radio-inner,
    &-wrapper:hover .ant-radio,
    &-input:focus + .ant-radio-inner {
      border-color: ${(props) =>
        !props.disabled ? themes.I50 : themes.seafoam};
    }

    &-input:focus + .ant-radio-inner {
      box-shadow: none;
      border-color: ${(props) =>
        !props.disabled ? themes['primary-cta'] : themes.I50};
      border-width: 2px;

      &:after {
        top: 2px;
        left: 2px;
      }
    }

    .ant-radio-inner {
      width: 20px;
      height: 20px;
      transition: none;
      background: transparent;

      &:after {
        top: 3px;
        left: 3px;
        width: 12px;
        height: 12px;
        background: ${(props) =>
          !props.disabled ? themes['primary-cta'] : themes.I50};
        transition: none;
        transform: scale(1);
        margin-block-start: unset;
        margin-inline-start: unset;
      }
    }

    &-disabled {
      + span {
        color: ${themes.I50} !important;
      }

      .ant-radio-inner {
        background-color: ${themes.white};
        border-color: ${themes.I50} !important;

        &:after {
          background: ${themes.I50};
        }
      }
    }
  }
`;

Radio.propTypes = {
  /** Align radio top when topAlign is true. */
  topAlign: propTypes.bool,
  /** get focus when component mounted */
  autoFocus: propTypes.bool,
  /** Specifies whether the radio is selected. */
  checked: propTypes.bool,
  /** Specifies the initial state: whether or not the radio is selected. */
  defaultChecked: propTypes.bool,
  /** Disable radio */
  disabled: propTypes.bool,
  /** According to value for comparison, to determine whether the selected */
  value: propTypes.node,
};

/** @component */
Radio.Group = RadioGroup;

export { RadioGroup };

export default Radio;
