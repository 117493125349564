import React from 'react';
import styled, { css } from 'styled-components';
import { Badge as Component } from 'antd';
import propTypes from 'prop-types';
import theme from '../Themes';

const Badge = styled(({ bgColor, textColor, fontWeight, ...props }) => (
  <Component {...props} />
))`
  & {
    line-height: 0;

    .ant-badge-count {
      ${({ bgColor, textColor, fontWeight }) => css`
        background: ${bgColor || theme.red};
        font-family: ${theme['font-sans-bold']};
        font-weight: ${fontWeight || theme['font-weight-normal']};
        font-size: ${theme['text-0-size']};
        color: ${textColor};
      `};
      height: 16px;
      padding: 3px 6px;
      line-height: 10px;
      border-radius: 9px;
      box-shadow: none;
    }
  }
`;

Badge.propTypes = {
  bgColor: propTypes.string,
  fontWeight: propTypes.string,
  /** Number to show in badge */
  count: propTypes.node,
  showZero: propTypes.bool,
  /** Max count to show */
  overflowCount: propTypes.number,
  /** whether to show red dot without number */
  dot: propTypes.bool,
  status: propTypes.oneOf([
    'success',
    'processing',
    'default',
    'error',
    'warning',
  ]),
  textColor: propTypes.string,
  text: propTypes.string,
  offset: propTypes.arrayOf(propTypes.number),
  title: propTypes.string,
};

Badge.defaultProps = {
  textColor: theme.white,
};

/** @component */
export default Badge;
