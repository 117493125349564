import { debounce } from 'lodash';
import { Input } from 'md-styled-components-v2';
import React, { useState } from 'react';
import propTypes from 'prop-types';
import { getGroupFilteredData } from '../_helpers';
import HospitalsOverlay from './HospitalsOverlay';

const GroupsOverlay = ({
  groupInfo,
  hideDropdown,
  setGroupInfo,
  groupId,
  hospitals,
}) => {
  const [term, setTerm] = useState('');
  const handleChange = debounce((value) => setTerm(value), 500, {
    leading: false,
    trailing: true,
  });
  const filteredData = getGroupFilteredData(hospitals, term);

  return (
    <div className='hospital-info__overlay-groups p--16 custom-scrollbar'>
      <Input onChange={(e) => handleChange(e.target.value)} />
      <HospitalsOverlay
        groupInfo={groupInfo}
        hideDropdown={hideDropdown}
        setGroupInfo={setGroupInfo}
        groupId={groupId}
        hospitals={filteredData}
      />
    </div>
  );
};

HospitalsOverlay.propTypes = {
  groupInfo: propTypes.object,
  setGroupInfo: propTypes.func,
  hideDropdown: propTypes.func,
  groupId: propTypes.string,
  hospitals: propTypes.object,
};

export default GroupsOverlay;
