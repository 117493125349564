import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Themes, Form, Modal } from 'md-styled-components';
import { requestGetPatientList } from 'actions/patients';
import { checkUserByPersonalInformation } from 'selectors/users';
import SaveForPatient from './SaveForPatient';
import CreatePatientProfile from './CreatePatientProfile';
import { useBreakpoint } from '../../../hooks';
import '../../styles/index.less';
import '../styles/index.less';
import './styles/index.less';
import { useSaveCart } from './hooks';

const mdScreenSize = Themes.breakpoints.md;
const xsScreenSize = Themes.breakpoints.xs;

const SaveCart = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const checkpersonalInformation = useSelector(checkUserByPersonalInformation);
  const [resetAutocomplete, setResetAutocomplete] = useState(false);
  const [, setSendData] = useState(null);
  const [newPatient, setNewPatient] = useState(false);
  const existingPatient = useRef();
  const screenIsMdMax = useBreakpoint(xsScreenSize, mdScreenSize);
  const { resetFields } = form;
  const { isModalVisible, setIsModalVisible } = props;

  const resetModalFields = () => {
    resetFields();
    setIsModalVisible(false);
    setResetAutocomplete(true);
    setNewPatient(false);
  };

  const { setSuccessModalMessage } = useSaveCart({
    cb: resetModalFields,
  });

  const togglePatientPopups = (e, item) => {
    e?.preventDefault();
    existingPatient.current = null;
    dispatch(
      requestGetPatientList.actions.success(
        {
          params: {},
        },
        null
      )
    );
    if (item) {
      existingPatient.current = item;
    }
    setNewPatient(!newPatient);
  };

  return (
    <>
      <Modal
        open={isModalVisible}
        okText='Create'
        onCancel={resetModalFields}
        footer={null}
        width={600}
        bodyStyle={{ padding: screenIsMdMax ? '16px' : '32px' }}
        centered
        wrapClassName={`save-cart save-cart__modal ${
          !newPatient
            ? 'save-cart__for-patient'
            : `save-cart__create-patient-profile${
                checkpersonalInformation ? '--has-message' : ''
              }`
        }`}
      >
        {!newPatient ? (
          <SaveForPatient
            createNewPatient={togglePatientPopups}
            setSendData={setSendData}
            resetAutocomplete={resetAutocomplete}
            setResetAutocomplete={setResetAutocomplete}
          />
        ) : (
          <CreatePatientProfile
            form={form}
            existingPatient={existingPatient}
            setSendData={setSendData}
            searchPatientClick={togglePatientPopups}
            setSuccessModalMessage={setSuccessModalMessage}
          />
        )}
      </Modal>
    </>
  );
};

export default SaveCart;
