export const emailValidation = (email) => {
  // eslint-disable-next-line max-len
  const emailRegexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((?:(?:(?:[a-zA-Z0-9][.\-_]?){0,62})[a-zA-Z0-9])+)\.([a-zA-Z]{2,6})$/;
  return emailRegexp.test(email) ? email : false;
};

/**
 * checks if email include noemail
 * @param email
 * @returns {boolean}
 */
export const isNoEmail = (email) => email.indexOf('noemail') !== -1;

export const emailMask = (email) => {
  const domainDot = email.lastIndexOf('.') + 1;
  const maskedEmail = email
    .slice(0, domainDot)
    .replace(/([^@.])/g, '*')
    .split('');
  [maskedEmail[0]] = email;
  return maskedEmail.concat(email.slice(domainDot, email.length)).join('');
};

export const emailValidator = (
  errorMessage = '${label} is not a valid email address.'
) => ({
  validator(rule, value) {
    return !value || emailValidation(value)
      ? Promise.resolve()
      : Promise.reject(new Error(errorMessage));
  },
});
