import React from 'react';
import propTypes from 'prop-types';
import TagGroup from 'components/TagGroup';

const ProcedureTags = ({
  procedureOffer,
  className,
  breakPoints = { lg: 3, md: 3, sm: 3 },
  rowCount = 1,
}) => {
  if (!procedureOffer?.length) {
    return null;
  }
  if (typeof procedureOffer === 'string') {
    procedureOffer = [procedureOffer];
  }
  return (
    <div className={className}>
      <TagGroup
        data={procedureOffer}
        breakPoints={breakPoints}
        rowCount={rowCount}
      />
    </div>
  );
};
ProcedureTags.propTypes = {
  procedureOffer: propTypes.oneOfType([propTypes.string, propTypes.array]),
  className: propTypes.string,
  breakPoints: propTypes.object,
  rowCount: propTypes.number,
};

export default ProcedureTags;
