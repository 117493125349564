import React from 'react';
import { Button } from 'md-styled-components-v2';

const GoToMDSaveLink = () => (
  <div className='direct-menu__underline relative flex-display flex-align-items-center'>
    <Button type='link' href='/' className='direct-menu__title--active'>
      Go to MDsave.com
    </Button>
  </div>
);

export default GoToMDSaveLink;
