import React from 'react';
import { Typography, Themes, Icon } from 'md-styled-components';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'selectors/users';

const { Text } = Typography;

const MenuTitle = ({ hasSub = false }) => {
  const currentUser = useSelector(getCurrentUser);

  return (
    <div className='flex-display flex-justify-space-between flex-align-items-center ph--24'>
      <div className='flex-display flex-align-items-center'>
        <div className='user-info__avatar--sm flex-display-inline flex-justify-center flex-align-items-center mr--12'>
          <Text level={4}>{currentUser.initials}</Text>
        </div>
        <Text className='user-info__username--sm'>
          {currentUser.portalName || 'My Account'}
        </Text>
      </div>
      {hasSub && <Icon size={12} color={Themes.indigo} type='chevron-down' />}
    </div>
  );
};

export default MenuTitle;
