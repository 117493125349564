import React from 'react';
import { Row, Col } from 'md-styled-components';
import SearchIcon from 'components/Navigation/SearchIcon';
import ShoppingCartModal from 'components/Navigation/ShoppingCart/ShoppingCartModal';
import ShoppingCartButton from 'components/Navigation/ShoppingCart/ShoppingCartButton';
import ConsumerLogo from '../../_partials/Logo';
import DrawerComponent from './DrawerComponent';
import Item from '../../_partials/Item';

const NavbarSm = () => (
  <>
    <Row
      justify='space-between'
      align='center'
      className='consumer-navigation--sm pv--8 ph--32 ph-sm--16'
    >
      <Col xs={2} sm={8} className='flex-display flex-align-items-center'>
        <DrawerComponent />
      </Col>
      <Col
        xs={14}
        sm={8}
        className='flex-display flex-justify-center--md flex-align-items-center'
      >
        <ConsumerLogo iconSize={44} blockClass='ml-sm--8' />
      </Col>
      <Col
        span={8}
        className='flex-display flex-align-items-center flex-justify-end'
      >
        <Item>
          <SearchIcon />
        </Item>
        <Item>
          <ShoppingCartButton />
        </Item>
      </Col>
    </Row>
    <ShoppingCartModal />
  </>
);

export default NavbarSm;
