import React from 'react';
import { NavigationMenu, Link } from 'md-styled-components-v2';
import { useTillTabletLg } from 'hooks';
import SubMenuTitle from '../../../_partials/SubMenuTitle';
import AllProcedures from './_overlays/AllProcedures';
import Providers from './_overlays/Providers';
import Patients from './_overlays/Patients';
import Resources from './_overlays/Resources';
import '../../../_styles/menu.less';

const { SubMenu, Item } = NavigationMenu;

const Menu = () => {
  const isTillTabletLg = useTillTabletLg();

  return (
    <NavigationMenu
      mode='horizontal'
      overflowedIndicator={<SubMenuTitle title='More' />}
      getPopupContainer={(trigger) => trigger.parentNode.parentNode.parentNode}
      triggerSubMenuAction={isTillTabletLg ? 'click' : 'hover'}
    >
      <SubMenu
        key='for_providers'
        className='pr--24'
        title={<SubMenuTitle title='For Providers' />}
      >
        <Providers />
      </SubMenu>
      <Item key='employers' className='pr--24 menu__underline'>
        <Link to='/employers' className='menu__title--active'>
          For Employers
        </Link>
      </Item>
      <SubMenu
        key='for_patients'
        className='pr--24'
        title={<SubMenuTitle title='For Patients' />}
      >
        <Patients />
      </SubMenu>
      <SubMenu
        key='all_procedures'
        className='pr--24'
        title={<SubMenuTitle title='All Procedures' />}
        popupClassName='all-procedures-popup'
      >
        <AllProcedures />
      </SubMenu>
      <SubMenu
        key='resources'
        className='pr--24'
        title={<SubMenuTitle title='Resources' />}
      >
        <Resources />
      </SubMenu>
      <Item key='financing' className='pr--24 menu__underline'>
        <Link to='/financing' className='menu__title--active'>
          Financing
        </Link>
      </Item>
    </NavigationMenu>
  );
};

export default Menu;
