import React from 'react';
import { NavigationMenu } from 'md-styled-components-v2';
import SignInOverlay from './SignInOverlay';
import SubMenuTitle from '../../Consumer/_partials/SubMenuTitle';

const { SubMenu, Item } = NavigationMenu;

const AuthorizationLg = () => (
  <NavigationMenu
    mode='horizontal'
    getPopupContainer={(trigger) => trigger.parentNode.parentNode.parentNode}
    triggerSubMenuAction='click'
  >
    <SubMenu
      key='sign_in_sign_up'
      title={<SubMenuTitle title='Log In / Sign Up' />}
    >
      <Item disabled>
        <SignInOverlay />
      </Item>
    </SubMenu>
  </NavigationMenu>
);

export default AuthorizationLg;
