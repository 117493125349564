import React from 'react';
import { Cascader as Component } from 'antd';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import './_styles/index.less';
import Themes from '../Themes';
import Icon from '../Icon';

const Wrapper = styled.div`
  &.md-cascader {
    .ant-cascader-menus {
      .ant-cascader-menu {
        ${(props) =>
          props.title
            ? props.title.map(
                (item, index) => css`
                  &:nth-child(${index + 1}) {
                    &::before {
                      content: '${item}';
                      display: block;
                      font-size: ${Themes['text-2-size']};
                      line-height: 18px;
                      color: ${Themes.I70} !important;
                      font-family: ${Themes['font-sans-medium']};
                      height: 50px;
                      padding: 16px 32px;
                      margin-bottom: 16px;
                      border-bottom: 1px solid ${Themes.I20};
                    }
                  }
                `
              )
            : css`
                padding-top: 16px;
              `}
      }
    }
  }
`;

const Cascader = ({ children, title, ...props }) => (
  <Wrapper className='md-cascader' title={title}>
    <Component getPopupContainer={(trigger) => trigger} {...props}>
      {children}
    </Component>
  </Wrapper>
);

Cascader.propTypes = {
  title: propTypes.array,
};

Cascader.defaultProps = {
  expandIcon: (
    <Icon type='chevron-prev' size={12} color={Themes.I90} className='p--4' />
  ),
};

export default Cascader;
