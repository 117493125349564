const sortOptions = {
  specialities: 'Specialities',
  procedures: 'Procedures',
  providers: 'Providers',
};
const dataOnEachPage = 20;

export default {
  sortOptions,
  dataOnEachPage,
};
