import React from 'react';
import propTypes from 'prop-types';
import Icon from '../Icon';
import Theme from '../Themes';

const IconHeader = ({
  headerIcon = <Icon color={Theme.I90} size={20} type='clipboard' />,
  headerText,
}) => (
  <div className='flex-display flex-align-items-center'>
    <span className='icon-wrapper'>{headerIcon}</span>
    <div className='ml--16'>{headerText}</div>
  </div>
);

IconHeader.propTypes = {
  headerIcon: propTypes.element,
  headerText: propTypes.element,
};

export default IconHeader;
