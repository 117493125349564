import React from 'react';
import styled, { css } from 'styled-components';
import { Layout as Component } from 'antd';
import propTypes from 'prop-types';

const Layout = styled(({ ...props }) => <Component {...props} />)`
  ${(props) => css`
    background: none !important;
    padding: 0;
  `};
`;

Layout.propTypes = {
  /** whether contain Sider in children, don't have to assign it normally. Useful in ssr avoid style flickering */
  hasSider: propTypes.bool,
  /** container className */
  className: propTypes.string,
  /** to customize the styles */
  style: propTypes.object,
};

// Layout.defaultProps = {
//   style: {},
// };

/** @component */
export default Layout;
