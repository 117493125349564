import React from 'react';
import { useTillTablet } from 'hooks';
import propTypes from 'prop-types';
import { Icon } from 'md-styled-components';
import './index.less';

const TendoLogo = ({ logo, size, className = '' }) => {
  const isTillTablet = useTillTablet();

  return (
    <Icon
      type={logo}
      size={size || (isTillTablet ? 110 : 120)}
      iconClassName={className}
    />
  );
};

TendoLogo.propTypes = {
  logo: propTypes.string.isRequired,
  className: propTypes.string,
  size: propTypes.number,
};
export default TendoLogo;
