import React, { useState, useRef } from 'react';
import { Col, Row, Spin, Select } from 'md-styled-components';
import { Form, Button, Input, AutoComplete } from 'md-styled-components-v2';
import Field from 'components/Form/Field';
import propTypes from 'prop-types';
import useAxios from 'axios-hooks';
import { useNavigate } from 'react-router-dom';
import { renderMenu } from './renderMenu';
import { useInputHandler } from './_hook';
import { useAlert } from '../../../../contexts/AlertProvider';

import {
  MANAGE_URL,
  VALIDATE_UNIQUE_EMAIL_DOMAIN,
} from '../_partials/constants';

const SaveForm = ({ item }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { error } = useAlert();

  const getInitialEmails = (emails) => {
    if (!emails) return undefined;

    return emails.split(',').reduce((acc, curr) => {
      acc.push({ label: curr.trim(), value: curr.trim() });
      return acc;
    }, []);
  };

  const apiHash = useRef({});

  const {
    handleChange,
    systemAndGroup,
    systemAndGroupLoading,
    isTyped,
    inputValue,
  } = useInputHandler();

  const dataSource =
    isTyped && systemAndGroup && systemAndGroup.length > 0
      ? systemAndGroup
      : [];
  const emptyText =
    isTyped && systemAndGroup?.length === 0 ? 'No matches found' : '';

  const [selectedHospitalId, setSelectedHospitalId] = useState();

  const useEmailDomainValidation = () => {
    const domainRegex =
      /^(?!\-)(?!.*\-$)(?:[A-Za-z0-9-]{1,63}\.)+[A-Za-z0-9-]{2,6}$/;

    const [{ data }, executeValidation] = useAxios(
      { url: VALIDATE_UNIQUE_EMAIL_DOMAIN, method: 'POST' },
      { manual: true }
    );

    const validateEmailDomain = async (domains) => {
      const results = [];

      for await (const domain of domains) {
          if (!domainRegex.test(domain)) {
            results.push({
              domain,
              valid: false,
              message: 'Invalid domain format.',
            });
          } else {
            try {
              const response = apiHash.current[domain] ?? await executeValidation({
                data: { email_domain: domain },
              });

              if (!apiHash.current[domain]) {
                apiHash.current[domain] = response
              }

              if (response?.data?.error) {
                results.push({
                  domain,
                  valid: false,
                  message: response?.data?.data?.message,
                });
              }
            } catch (e) {
              console.error('Domain validation failed: ', e);
            }
          }
        }
      return results;
    };

    return { validateEmailDomain, data };
  };

  const { validateEmailDomain } = useEmailDomainValidation();

  const validateEmailDomains = async (_, value) => {
    if (!value || value.length === 0) {
      return Promise.resolve();
    }

    const currentEmailDomains =
      item?.email_domains?.split(',')?.map((domain) => domain.trim()) || [];
    const newEmailDomains = value.filter(
      (email) => !currentEmailDomains.includes(email.trim())
    );

    if (newEmailDomains.length === 0) {
      return Promise.resolve();
    }

    try {
      const invalidEmails = await validateEmailDomain(newEmailDomains);
      if (invalidEmails.length > 0) {
        return Promise.reject(
          new Error(invalidEmails.map((err) => err.message).join(', '))
        );
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(
        new Error('An error occurred while validating email domains.')
      );
    }
  };

  const onHandleChange = (e) => {
    const params = {
      data: '{}',
      searchText: e.target.value,
      itemsLimit: 25,
    };

    handleChange({
      params,
    });
  };

  const onSelect = (_, selected) => {
    setSelectedHospitalId(selected.data.value);
  };

  const [{ loading }, saveSsoConfigurationMapping] = useAxios(
    {
      url: MANAGE_URL,
      method: 'POST',
    },
    { manual: true }
  );

  const handleSave = async (values, hospitalId) => {
    try {
      const { data: response } = await saveSsoConfigurationMapping({
        data: { ...values, hospital_id: hospitalId, id: +item.id },
      });
      if (response && response?.success) {
        navigate('/sso-configuration');
      }
      if (response && response?.error) {
        form.setFields(
          response.data?.errorList?.map((err) => ({
            name: err.field,
            errors: err.error,
          }))
        );
      }
    } catch (err) {
      error({
        text: 'Something went wrong!',
        footerText: 'Ok',
        timer: 3000,
      });
    }
  };

  return (
    <Form
      form={form}
      hideRequiredMark
      colon={false}
      onFinish={(values) => handleSave(values, selectedHospitalId)}
      onFinishFailed={({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
      }}
    >
      <Row justify='space-between'>
        <Col span={24}>
          <Field
            className='mb--24'
            name='name'
            label='Name'
            placeholder='Name'
            isRequired
            hasFeedback
            initialValue={item?.name}
          />
        </Col>
        <Col span={24}>
          <Field
            className='mb--24'
            name='entity_id'
            label='Entity ID'
            placeholder='Entity ID'
            isRequired
            hasFeedback
            initialValue={item?.entity_id}
          />
        </Col>
        <Col span={24}>
          <Field
            className='mb--24'
            name='sso_url'
            label='SSO URL'
            placeholder='SSO URL'
            hasFeedback
            initialValue={item?.sso_url}
          />
        </Col>
        <Col span={24}>
          <Field
            className='mb--24'
            name='sso_binding'
            label='SSO Binding'
            placeholder='SSO Binding'
            hasFeedback
            initialValue={item?.sso_binding}
          />
        </Col>
        <Col span={24}>
          <Field
            className='mb--24'
            name='slo_url'
            label='SLO URL'
            placeholder='SLO URL'
            hasFeedback
            initialValue={item?.slo_url}
          />
        </Col>
        <Col span={24}>
          <Field
            className='mb--24'
            name='slo_binding'
            label='SLO Binding'
            placeholder='SLO Binding'
            hasFeedback
            initialValue={item?.slo_binding}
          />
        </Col>
        <Col span={24}>
          <Form.Item
            label='x509 Certificate'
            hasFeedback
            name='x509_cert'
            initialValue={item?.x509_cert}
          >
            <Input.TextArea
              autoSize={{ minRows: 5, maxRows: 12 }}
              name='x509_cert'
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Field
            className='mb--24'
            name='cert_fingerprint'
            label='Certificate Fingerprint'
            placeholder='Certificate Fingerprint'
            hasFeedback
            initialValue={item?.cert_fingerprint}
          />
        </Col>
        <Col span={24}>
          <Field
            className='mb--24'
            name='cert_fingerprint_algo'
            label='Certificate Fingerprint Algorithm'
            placeholder='Certificate Fingerprint Algorithm'
            hasFeedback
            initialValue={item?.cert_fingerprint_algo}
          />
        </Col>
        <Col span={24}>
          <Form.Item
            label='Email Domains'
            name='email_domains'
            rules={[
              {
                validator: validateEmailDomains,
              },
            ]}
          >
            <Select
              mode='tags'
              options={[]}
              open={false}
              suffixIcon={null}
              defaultValue={getInitialEmails(item?.email_domains)}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label='Hospital'
            className='mb--24'
            hasFeedback
            name='hospital'
            initialValue={item?.hospital}
          >
            <AutoComplete
              id={selectedHospitalId}
              notFoundContent={systemAndGroupLoading ? <Spin /> : emptyText}
              options={renderMenu(dataSource, inputValue)}
              onSelect={onSelect}
              dropdownStyle={{ zIndex: 1050 }}
            >
              <Input onChange={onHandleChange} allowClear />
            </AutoComplete>
          </Form.Item>
        </Col>
      </Row>
      <Row justify='center'>
        <Col sm={8} md={6}>
          <Form.Item>
            <Button
              size='large'
              className='mt--24'
              type='primary'
              htmlType='submit'
              loading={loading}
            >
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

SaveForm.propTypes = {
  item: propTypes.object,
};

export default SaveForm;
