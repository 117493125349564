import client from './client';

export const getPatientListApi = ({ params }) =>
  client.post('/proxy/getPatientList', params, {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
  });

export const saveForUserApi = ({ params }) =>
  client.post(`/shareCarts/saveForUser`, params.data, {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
  });

export const editPatientInfoApi = ({ params }) =>
  client.post('/customer/editPatientInfo', params, {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
  });
