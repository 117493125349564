import React, { useState } from 'react';
import { Col } from 'md-styled-components';
import { Select, Form } from 'md-styled-components-v2';
import { useIsMobile } from 'hooks';
import BaseSelect from './BaseSelect';

const { Option } = Select;

const FormItem = ({
  className,
  isDay,
  fieldName,
  label,
  isReset,
  setIsReset,
  placeholder,
  onSelect,
  options,
  getOptionValue,
  filterOption,
  getFieldError,
  allowEmpty,
  validateTrigger,
  onBlur,
  onChange,
  initialValue,
  setFieldsValue,
  span = { xs: 24, md: 6, lg: 6 },
  validator,
}) => {
  const [value, setValue] = useState('');
  const isMobile = useIsMobile();
  const hideLabel =
    isMobile && (fieldName === 'b_day' || fieldName === 'b_year');

  return (
    <Col xs={span.xs} lg={span.lg} md={span.md} className='gutter-row'>
      <Form.Item
        className={className}
        hasFeedback
        label={!hideLabel && label}
        name={fieldName}
        initialValue={initialValue}
        validateTrigger={
          (getFieldError(fieldName).length && 'onBlur') ||
          validateTrigger ||
          (isReset ? 'onSubmit' : 'onBlur')
        }
        validateFirst
        rules={[
          validator || {
            required: true,
            message: `${placeholder} cannot be blank.`,
          },
        ]}
      >
        {isMobile ? (
          <BaseSelect
            setIsReset={setIsReset}
            placeholder={placeholder}
            onSelect={onSelect}
            options={options}
            getOptionValue={getOptionValue}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            setValue={setValue}
          />
        ) : (
          <Select
            className='phi'
            placeholder={placeholder}
            showSearch
            optionFilterProp='children'
            size='middle'
            filterOption={(input, option) =>
              filterOption
                ? filterOption(input, option)
                : option.props.children
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
            }
            onSelect={(selected, option) => {
              setValue('');
              if (onSelect) {
                onSelect(selected, option);
              }
            }}
            onBlur={() => {
              if (value) {
                setFieldsValue({ [fieldName]: value });
                onSelect(getOptionValue ? getOptionValue(value) : value);
              }
              if (onBlur) {
                setIsReset(false);
                onBlur();
              }
            }}
            onSearch={(optionValue) => {
              if (
                optionValue &&
                ((!isDay && optionValue.length >= 2) || isDay)
              ) {
                const optionsLowerCase = options.map((opt) =>
                  opt.toString().toLowerCase()
                );
                const valueLowerCase = optionValue.toLowerCase();
                const selectedIndex = optionsLowerCase.findIndex((option) =>
                  option.includes(valueLowerCase)
                );
                if (onChange) {
                  setIsReset(false);
                  onChange(options[selectedIndex]);
                }
                return selectedIndex !== -1 && setValue(options[selectedIndex]);
              }
              return undefined;
            }}
          >
            {allowEmpty && <Option value=''>{placeholder}</Option>}
            {options.map((option, index) => (
              <Option
                value={getOptionValue ? getOptionValue(option) : option}
                key={index}
              >
                {option}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
    </Col>
  );
};

export default FormItem;
