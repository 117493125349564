import React, { useEffect, useMemo } from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useIsDesktop, useTillTablet } from 'hooks';
import {
  getCurrentUser,
  getIsGroupUser,
  getIsCmsUser,
  getIsGuestUser,
  notCompleteProfile,
  getIsPortalUser,
  getIsPatientUser,
} from 'selectors/users';
import { useMobileNavbar } from 'components/NavbarSearch/_context/NavbarMobileProvider';
import { scrollTo } from 'utils/common';
import { isHomePage } from 'utils/pages';
import Consumer from 'components/Navigation/Consumer';
import PrivateHospital from 'components/Navigation/PrivateHospital';
import NavbarSearch from 'components/NavbarSearch';
import { user as constUser } from '../../constants';
import 'components/styles/mds-navbar.less';
import { useGetNavbarClasses } from './hooks';

const topSpaceClasses = {
  [constUser.CMS_USER]: 'mds-navbar--top-space-with-direct-nav',
  [constUser.GROUP_USER]: 'mds-navbar--top-space-with-direct-nav',
  [constUser.PORTAL_USER]: 'mds-navbar--top-space-portal-user',
  [constUser.PATIENT]: 'mds-navbar--top-space-customer',
  [constUser.REPORT_USER]: 'mds-navbar--top-space-report-user',
};

const PageNavbar = React.memo(
  ({
    navbarType,
    navbarFixed,
    isHospital,
    showDirectTabs,
    hideNavbarSearch,
    searchType,
    searchSticky = true,
  }) => {
    const currentUser = useSelector(getCurrentUser);
    const isGroupUser = useSelector(getIsGroupUser);
    const isCmsUser = useSelector(getIsCmsUser);
    const isPortalUser = useSelector(getIsPortalUser);
    const isPatient = useSelector(getIsPatientUser);
    const showCompleteProfile = useSelector(notCompleteProfile);
    const isDesktop = useIsDesktop();
    const isTillTablet = useTillTablet();
    const isGuest = useSelector(getIsGuestUser);
    const { searchVisible, setSearchVisible, nonStickyClass, setSearchSticky } =
      useMobileNavbar();
    const navbarClasses = useGetNavbarClasses(nonStickyClass);
    const isVisibleSearchIcon = isTillTablet || !isHomePage();
    const searchAction = () =>
      scrollTo(document.getElementById('searchTabs'), false);
    const portalSearchVisible = useMemo(() => {
      switch (true) {
        case isPortalUser:
          return 'with-banner';
        case isCmsUser || isGroupUser:
          return 'page-top-space--group';
        default:
          return '';
      }
    }, [isPortalUser, isCmsUser, isGroupUser]);

    const pageTopSpace = !isGuest
      ? topSpaceClasses[currentUser.userType]
      : 'mds-navbar--top-space-customer';
    const searchboxClasses = searchVisible
      ? `page-top-space ${portalSearchVisible}`
      : '';

    useEffect(() => {
      if (!searchSticky) {
        setSearchSticky(searchSticky);
      }
      if (
        isDesktop &&
        !isHospital &&
        (currentUser?.guest || isPatient) &&
        !hideNavbarSearch
      ) {
        setSearchVisible(true);
      }
    }, [
      currentUser,
      isDesktop,
      hideNavbarSearch,
      isHospital,
      isPatient,
      isGuest,
      setSearchVisible,
      searchSticky,
      setSearchSticky,
    ]);

    return (
      <>
        {!showCompleteProfile && !navbarType && !showDirectTabs && (
          <>
            {isHospital ? (
              <PrivateHospital />
            ) : (
              <Consumer
                navFixed={navbarFixed}
                isVisibleSearchIcon={isVisibleSearchIcon}
                searchAction={isHomePage() && searchAction}
              />
            )}
            {(isHospital || !isHomePage()) && (
              <NavbarSearch searchType={searchType} {...navbarClasses} />
            )}
          </>
        )}
        {!isHospital &&
          navbarFixed &&
          navbarClasses &&
          !showCompleteProfile && (
            <div className={`${searchboxClasses} ${pageTopSpace}`}> </div>
          )}
        {!!isHospital && (
          <div className={searchVisible ? 'page-top-space--hospital' : ''}>
            {' '}
          </div>
        )}
      </>
    );
  }
);

PageNavbar.propTypes = {
  navbarType: propTypes.string,
  openDemoModal: propTypes.func,
  navbarFixed: propTypes.bool,
  isHospital: propTypes.bool,
  hideNavbarSearch: propTypes.bool,
  searchSticky: propTypes.bool,
};

export default PageNavbar;
