import React from 'react';
import HeroSection from 'pages/AdminTools/_partials/HeroSection';
import 'pages/AdminTools/styles/index.less';
import { Typography } from 'md-styled-components';
import SaveForm from '../SaveForm';

const { Title } = Typography;
export const heroSectionOptions = {
  breadcrumbs: [
    { title: 'SSO Configuration', path: 'sso-configuration' },
    { title: 'Create' },
  ],
};

const Content = (item = {}) => (
  <div className='container-direct pt--24 pb--96'>
    <HeroSection hero={heroSectionOptions.breadcrumbs} />
    <Title level={2} className='text-center mt--24 mb--16'>
      Create SSO Configuration
    </Title>
    <SaveForm item={item} />
  </div>
  );

export default Content;
