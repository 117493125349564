import React from 'react';
import ShareByEmail from '../_partials/ShareByEmail';
import ShareByPhone from '../_partials/ShareByPhone';
import PrintQuote from '../_partials/PrintQuote';

export const buttonTitles = {
  phone: {
    main: 'Send Text',
    gfe: 'Text Good Faith Estimate',
  },
  email: {
    main: 'Send Email',
    gfe: 'Email Good Faith Estimate',
  },
  print: {
    main: 'Print Price Quote',
    gfe: 'Print Good Faith Estimate',
  },
};

export const tabList = [
  {
    tab: 'phone',
    icon: 'message-fill',
    iconSize: 16,
    className: '',
    title: {
      main: 'Text Cart',
      gfe: 'Text',
    },
    renderContent: ({ selectedPatientCart, ...props }) => {
      const mobilePhone = selectedPatientCart?.mobile_phone;
      return <ShareByPhone {...{ ...props, mobilePhone }} />;
    },
  },
  {
    tab: 'email',
    icon: 'icon-email-logo',
    iconSize: 18,
    className: 'pb--2',
    title: {
      main: 'Email Cart',
      gfe: 'Email',
    },
    renderContent: ({ selectedPatientCart, inputEl, ...props }) => {
      const emailAddress = selectedPatientCart?.email_address;
      return <ShareByEmail {...{ ...props, emailAddress }} />;
    },
  },
  {
    tab: 'print',
    icon: 'print-fill',
    iconSize: 20,
    className: 'pb--2',
    title: {
      main: 'Print Quote',
      gfe: 'Print',
    },
    renderContent: ({ selectedPatientCart, inputEl, ...props }) => (
      <PrintQuote {...props} />
    ),
  },
];

export const shareTabKeys = {
  text: 0,
  email: 1,
  pdf: 2,
};

export const sharedVia = {
  [shareTabKeys.text]: 'via text to',
  [shareTabKeys.email]: 'via email to',
  [shareTabKeys.pdf]: 'via PDF',
};
