import React, { useEffect } from 'react';
import 'pages/AdminTools/styles/index.less';
import useAxios from 'axios-hooks';
import { useGetParams } from 'utils/url';
import { Typography, Skeleton } from 'md-styled-components';
import HeroSection from '../../_partials/HeroSection';
import SaveForm from '../SaveForm';

export const heroSectionOptions = (systemName) => ({
  breadcrumbs: [
    { title: 'SSO Configuration', path: 'sso-configuration' },
    { title: `Update ${systemName}` },
  ],
});

const { Title } = Typography;

const Content = () => {

  const queryParams = useGetParams();
  const [{ data: response, loading }, executeGetData] = useAxios(
    {
      url: `/ssoConfiguration/get`,
      params: { id: queryParams.id },
    },
    { manual: true }
  );

  useEffect(() => {
    executeGetData();
  }, [executeGetData]);

  if (loading || !response) {
    return <Skeleton paragraph={{ rows: 10 }} />;
  }

  const item = response.data;

  return (
    <div className='container-direct pt--24 pb--96'>
      <HeroSection hero={heroSectionOptions(item.name).breadcrumbs} />
      <Title level={2} className='text-center mt--24 mb--16'>
        {`Update SSO Configuration ${item.name}`}
      </Title>
      <SaveForm item={item} />
    </div>
  );
};

export default Content;
