import { Input as Component } from 'antd';
import styled from 'styled-components';
import { defaultStyles } from './Input';

const TextArea = styled(Component.TextArea)`
  ${defaultStyles};
`;

TextArea.defaultProps = {
  rows: 5,
};

export default TextArea;
