import { useSelector } from 'react-redux';
import { getCurrentCompanyInfo } from 'selectors/users';
import { useLocation } from 'react-router-dom';
import useAxios from 'axios-hooks';
import { clearLocalStorageItem } from 'utils/storage';

export const useCompanyPricingGroupSwitch = () => {
  const { pathname } = useLocation();
  const { currentPricingGroup, currentCompany } = useSelector(
    getCurrentCompanyInfo
  );
  const [, executeSwitchCompany] = useAxios({}, { manual: true });

  const request = async ([companyId, pricingGroupsIds, isNarrowNetwork]) => {
    if (
      companyId === currentCompany.id &&
      pricingGroupsIds === currentPricingGroup.id
    ) {
      return;
    }

    const { data: response } = await executeSwitchCompany({
      url: `/portal/switchCompany?portalId=${companyId}`,
      method: 'POST',
      data: {
        pathname,
        pricingGroupsIds: pricingGroupsIds.split(', ').map((n) => +n),
        isNarrowNetwork,
      },
    });
    clearLocalStorageItem('userIdentityKey');

    if (response.redirect) {
      window.location.href = response.redirect;
    } else {
      window.location.reload(false);
    }
  };

  return ([companyId, pricingGroupId, isNarrowNetwork]) =>
    request([companyId, pricingGroupId, isNarrowNetwork]);
};
