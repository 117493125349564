import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { Layout, Divider } from 'md-styled-components';
import { isPrivateHospital } from 'utils/privateSite';
import { getIsPortalUser } from 'selectors/users';
import PageNavbar from './PageComponents/PageNavbar';
import common from '../constants/common';
import './styles/colors.less';

const PageContent = lazy(() => import('./PageComponents/PageContent'));
const PageMessage = lazy(() => import('./PageComponents/PageMessage'));
const WhiteLabelHospitalFooter = lazy(() =>
  import('./Footer/_partials/WhiteLabelHospitalFooter')
);
const Footer = lazy(() => import('./Footer'));
const OfflineChatModal = lazy(() => import('./OfflineChatModal'));
const ShoppingCardModals = lazy(() => import('./Modals/ShoppingCardModals'));
const GlobalModals = lazy(() => import('./Modals/GlobalModals'));

const PageContainerLayout = React.memo(
  ({
    navbarFixed,
    navbarType,
    openDemoModal,
    showDirectTabs,
    children,
    containerClassName,
    compactFooter,
    pageType,
    hideNavbarSearch = false,
    searchSticky = true,
    searchType = null,
  }) => {
    const isHospital = isPrivateHospital();
    const isPortalUser = useSelector(getIsPortalUser);
    const isCmsPage = pageType === common.cmsPage;
    return (
      <>
        <Layout>
          <Suspense fallback={<></>}>
            <PageMessage />
            <ShoppingCardModals />
            <GlobalModals />
          </Suspense>
          {!isCmsPage && (
            <PageNavbar
              navbarType={navbarType}
              searchSticky={searchSticky}
              navbarFixed={navbarFixed}
              openDemoModal={() => openDemoModal()}
              isHospital={isHospital}
              showDirectTabs={showDirectTabs}
              hideNavbarSearch={hideNavbarSearch}
              searchType={searchType}
            />
          )}
          <PageContent containerClassName={containerClassName}>
            {children}
          </PageContent>
          <Suspense fallback={<></>}>
            <OfflineChatModal />
            {!isHospital && !compactFooter && !isPortalUser ? (
              <Footer />
            ) : (
              (!compactFooter || isPortalUser) && (
                <>
                  <Divider margin='0' />
                  <WhiteLabelHospitalFooter pageType={pageType} />
                </>
              )
            )}
          </Suspense>
        </Layout>
        <Suspense fallback={<></>}>
          {compactFooter && (
            <div className='slat-bg ph-md--32'>
              <WhiteLabelHospitalFooter compactFooter pageType={pageType} />
            </div>
          )}
        </Suspense>
      </>
    );
  }
);

PageContainerLayout.propTypes = {
  navbarType: propTypes.string,
  openDemoModal: propTypes.func,
  navbarFixed: propTypes.bool,
  containerClassName: propTypes.string,
  compactFooter: propTypes.bool,
  pageType: propTypes.string,
  hideNavbarSearch: propTypes.bool,
  searchSticky: propTypes.bool,
};

export default PageContainerLayout;
