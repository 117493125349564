import React, { useContext } from 'react';
import propTypes from 'prop-types';

const NavigationContext = React.createContext({});

export const useNavigationContext = () => {
  const { isVisibleSearchIcon, searchAction } = useContext(NavigationContext);

  return { isVisibleSearchIcon, searchAction };
};

const NavigationProvider = ({
  children,
  isVisibleSearchIcon = true,
  searchAction = false,
}) => (
  <NavigationContext.Provider value={{ isVisibleSearchIcon, searchAction }}>
    {children}
  </NavigationContext.Provider>
);

NavigationProvider.prototype = {
  isVisibleSearchIcon: propTypes.bool,
};

export default NavigationProvider;
