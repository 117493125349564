import React, { memo } from 'react';
import { useTillTablet } from 'hooks';
import NavigationProvider from 'components/Navigation/_context/NavigationProvider';
import { useContainerClass } from './_hooks';
import NavbarLg from './NavbarLg';
import NavbarSm from './NavbarSm';
import PoweredByBlock from './_partials/PoweredByBlock';
import './_styles/navigation.less';

const PrivateHospital = () => {
  const isTillTablet = useTillTablet();
  const { className } = useContainerClass();

  return (
    <div className='hospital-navigation--top-space'>
      <div className={className}>
        <PoweredByBlock />
        <NavigationProvider>
          {isTillTablet ? <NavbarSm /> : <NavbarLg />}
        </NavigationProvider>
      </div>
    </div>
  );
};

export default memo(PrivateHospital);
