import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const LeaderBoardContext = createContext({});

const LeaderBoardContextProvider = ({ children, dateType, setDateType }) => (
  <LeaderBoardContext.Provider value={{ dateType, setDateType }}>
    {children}
  </LeaderBoardContext.Provider>
);

LeaderBoardContextProvider.propTypes = {
  dateType: PropTypes.number.isRequired,
  setDateType: PropTypes.func.isRequired,
};

export default LeaderBoardContextProvider;

export const useContextLeaderBoard = () => {
  const { dateType, setDateType } = useContext(LeaderBoardContext);
  return { dateType, setDateType };
};
