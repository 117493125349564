import client from './client';

export const getRequestDemoApi = (data) =>
  client.post('/api/publicSite/requestDemo', data);

export const getContactUsApi = (params) =>
  client.post('/api/publicSite/sendUsMessage', params);

export const getMailChimpMemberEmailApi = (params) =>
  client.get('/api/publicSite/getMailChimpMemberEmail', { params });

export const getPageInstanceApi = ({ params }) =>
  client.post('/public/parseUrl', {
    isParseUrl: true,
    slug: params.page,
  });
