import styled from 'styled-components';
import { Menu } from 'antd';
import Themes from '../../Themes';
import './_styles/index.less';

const NavigationMenu = styled(Menu)`
  &&.ant-menu {
    &-horizontal {
      line-height: normal;
      border-bottom: none;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex: 100%;
      height: 100%;

      .ant-menu {
        &-submenu {
          border-bottom: none;
          margin: 0;
          padding: 0;
          height: 100%;
          display: flex;
          align-items: center;

          &:after {
            border: none;
          }

          &-title {
            position: unset;
          }

          &-open {
            svg {
              transform: rotate(180deg);
            }
          }
        }

        &-title-content {
          line-height: 16px;
        }

        &-item {
          border-bottom: none;
          margin: 0;
          padding: 0;
          height: 100%;
          display: flex;
          align-items: center;

          &:after {
            border: none;
            inset-inline: unset;
          }
        }

        &-overflow-item-rest {
          padding-right: 24px;
        }
      }
    }

    &-inline {
      .ant-menu {
        &-sub {
          a {
            color: ${Themes.astral} !important;
          }
        }

        &-item {
          height: 44px;
          margin: 0;
          padding: 0 !important;
          display: flex;
          align-items: center;
          width: unset;

          &:after {
            border-right: none;
          }

          &:active,
          &-selected,
          &:hover {
            background: transparent;
          }
        }

        &-submenu {
          &-open {
            svg {
              transform: rotate(180deg);
            }
          }

          &-title {
            height: 44px;
            margin: 0;
            padding: 0 !important;

            &:hover,
            &:active {
              background: transparent;
            }

            .ant-menu-submenu-arrow {
              display: none;
            }
          }

          .ant-menu-inline {
            background: ${Themes.I05};

            .ant-menu-item {
              margin: 0;
              padding: 0 !important;
              height: 44px;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
`;

NavigationMenu.ItemGroup = Menu.ItemGroup;
NavigationMenu.SubMenu = Menu.SubMenu;
NavigationMenu.Item = Menu.Item;

export default NavigationMenu;
