import { useSelector, useDispatch } from 'react-redux';
import {
  getOpenCart,
  cartNotEmpty,
  getQuantity,
  getSelectedPatientCart,
} from 'selectors/shopping-cart';
import { requestSetOpenCart } from 'actions/shopping-cart';

export const useOptionsShoppingCart = () => {
  const dispatch = useDispatch();
  const openCartState = useSelector(getOpenCart);
  const shoppingCartNotEmpty = useSelector(cartNotEmpty);
  const shoppingCartQuantity = useSelector(getQuantity);
  const selectedPatientCart = useSelector(getSelectedPatientCart);

  const toggleCart = (toggle) => {
    dispatch(requestSetOpenCart.request({ visibility: toggle }));
  };

  return {
    shoppingCartNotEmpty,
    shoppingCartQuantity,
    selectedPatientCart,
    openCartState,
    toggleCart,
  };
};
