import React from 'react';
import { Logo, Icon } from 'md-styled-components';

const PoweredBy = () => (
  <>
    <Logo className='powered-by-banner__logo-box flex-display flex-align-items-center pl--16'>
      <Icon size={186} type='mdsave-care-connect' />
    </Logo>
  </>
);

export default PoweredBy;
