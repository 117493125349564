import client from './client';

export const getFeaturedSpecialties = ({ params }) =>
  client.post('/api/groupSystem/getFeaturedSpecialties', params);

export const getSystemLocations = ({ params: { url, ...options } }) =>
  client.post(`/groupSystemLanding/system`, options);

export const getMapDataApi = ({ params }) =>
  client.post('/location/getMapData', params);

export const getImageFullPathApi = ({ params }) =>
  client.post(
    `/api/publicSite/getAwsItem?path=${params.src}&echo=true`,
    params
  );

export const getAllSpecialtiesApi = ({ params }) =>
  client.post('/api/groupSystem/getAllSpecialties', params);

export const shareCartApi = ({ params }) =>
  client.post(`/shareCarts/shareCart`, params);

export const groupUserHospitalListApi = () =>
  client.get('/api/publicSearch/groupUserHospitalList');

export const groupLogoApi = ({ params }) =>
  client.get(`/api/groupSystem/getLogo?groupId=${params.groupId}`);

export const groupUserAllHospitalsApi = (params) =>
  client.get('/api/GroupsApi/getCurrentUserHospitalsList', { ...params });

export const groupUserAccountManagerInfoApi = () =>
  client.get('/api/GroupsApi/getAccountManagerInfo');

export const groupUserNavTabsApi = (params) =>
  client.get(`/groups/GetNavbarTabs?groupId=${params.groupId}`);

export const setActiveGroupApi = (params) =>
  client.post('/api/GroupsApi/setActiveGroupApi', params);

export const getGroupLeaderBoardApi = (params) =>
  client.get(
    `/groups/getGroupLeaderBoard?groupId=${params.groupId}&start=${params.startDate}&end=${params.endDate}`
  );

export const getGiveFeedbackApi = (params) =>
  client.post('/groups/giveFeedback', params);

export const getPatientsApi = ({ urlPrefix, entityID, id, ...params }) =>
  client.post(`${urlPrefix}/getPatients?${entityID}=${id}`, params);
