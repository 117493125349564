import propTypes from 'prop-types';
import { Form, Typography } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import SaveAutocomplete from './_partials/SaveAutoComplete';

const { Title } = Typography;

const SaveForPatient = ({
  createNewPatient,
  resetAutocomplete,
  setResetAutocomplete,
  setSendData,
}) => (
  <>
    <div className='save-cart__headline flex-display flex-justify-space-between flex-align-items-bottom mb--16'>
      <Title className='save-cart__title' color='indigo' level={6}>
        Save Cart
      </Title>
      <Button
        type='link'
        onClick={createNewPatient}
        className='save-cart__for-patient-create-button mb--2'
      >
        Create a New Patient Profile
      </Button>
    </div>
    <Form hideRequiredMark className='pt--4'>
      <Form.Item
        className='save-cart__form-item save-cart__form-item--autocomplete'
        name='saveCart-patient'
      >
        <SaveAutocomplete
          createNewPatient={createNewPatient}
          resetField={resetAutocomplete}
          setResetField={setResetAutocomplete}
          setSendData={setSendData}
        />
      </Form.Item>
    </Form>
  </>
);

SaveForPatient.propTypes = {
  createNewPatient: propTypes.func.isRequired,
  resetAutocomplete: propTypes.bool.isRequired,
  setResetAutocomplete: propTypes.func.isRequired,
  setSendData: propTypes.func.isRequired,
};

export default SaveForPatient;
