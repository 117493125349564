import React, { useMemo } from 'react';
import { Dropdown, Icon, Themes, Typography } from 'md-styled-components';
import { user } from 'constants/index';
import { useSelector } from 'react-redux';
import { getCurrentUser, getTabsByUserType } from 'selectors/users';
import { common } from 'constants';
import UserOverlay from './UserOverlay';
import UserInfoOverlay from './UserInfoOverlay';

const { Paragraph } = Typography;

const UserInfoLg = () => {
  const currentUser = useSelector(getCurrentUser);
  const tabsByUserType = useSelector(getTabsByUserType);
  const userType = currentUser && currentUser.userType;

  const setOverlay = useMemo(() => {
    switch (true) {
      case userType === user.PORTAL_USER:
        return (
          <UserInfoOverlay
            data={tabsByUserType?.filter(
              (tab) => (tab.href || tab.to).indexOf(common.signOutUrl) !== -1
            )}
          />
        );
      case userType === user.PATIENT || userType === user.REPORT_USER:
        return (
          <UserInfoOverlay
            data={tabsByUserType?.filter((tab) => tab.mobile !== true)}
          />
        );
      case userType === user.CMS_USER || userType === user.GROUP_USER:
        return (
          <div className='user-info__container relative'>
            <UserOverlay
              tabsByUserType={tabsByUserType}
              currentUser={currentUser}
            />
          </div>
        );
      default:
        return <></>;
    }
  }, [userType, tabsByUserType, currentUser]);

  return tabsByUserType ? (
    <Dropdown
      className='user-info__dropdown pointer'
      overlayClassName='user-info__dropdown-overlay'
      placement='bottomRight'
      trigger={['click']}
      dropdownRender={() => setOverlay}
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      <div className='flex-display flex-align-items-center'>
        <div className='user-info__circle flex-display flex-align-items-center flex-justify-center'>
          <Paragraph level={3} className='user-info__dropdown-title--active'>
            {currentUser?.initials}
          </Paragraph>
        </div>
        <Icon
          size={12}
          color={Themes.indigo}
          type='chevron-down'
          className='ml--8 mb--4'
        />
      </div>
    </Dropdown>
  ) : null;
};

export default UserInfoLg;
