import React, { useEffect, useState } from 'react';
import { Header, Form } from 'md-styled-components';
import { Button as StyledButton } from 'md-styled-components-v2';
import { useSelector } from 'react-redux';
import Selectors from 'selectors';

const { RoundedBlock, RoundedBlockItem } = Header;
const FormItem = Form.Item;

const FormItemComponent = ({ formItem }) => {
  const {
    props: { className, optionSelected, searchText, setNewRules, ...props },
  } = formItem;
  const [formItemProps, setFormItemProps] = useState(props);
  const dataSource = useSelector(Selectors[props.name].data);
  useEffect(() => {
    setNewRules?.({
      className,
      dataSource,
      rules: props.rules,
      optionSelected,
      searchText,
      setFormItemProps,
    });
  }, [
    className,
    dataSource,
    optionSelected,
    props.rules,
    searchText,
    setNewRules,
  ]);

  return <FormItem {...formItemProps}>{formItem.component()}</FormItem>;
};

export default (props) => {
  const Button = ({
    id,
    className = 'hero-search-button search-btn',
    block = false,
  }) => (
    <StyledButton
      type={props.inputButtonType}
      size='large'
      htmlType='submit'
      className={className}
      block={block}
      id={id}
    >
      Search
    </StyledButton>
  );

  return (
    <RoundedBlock tabIndex={0}>
      <div className='search-form-wrapper__main text-left'>
        {props.formItems.map((formItem, key) => (
          <div key={key} className='search-form-wrapper__field'>
            <RoundedBlockItem
              noMargin
              className={
                props.formItems.length - 1 === key &&
                props.showButton &&
                props.showInputButton
                  ? 'with-button'
                  : 'without-button'
              }
            >
              <FormItemComponent formItem={formItem} />
              {props.formItems.length - 1 === key &&
                props.showButton &&
                props.showInputButton && <Button id={props.buttonId} />}
            </RoundedBlockItem>
            {!props.showInputButton && <div className='pb--8 pb-sm--0' />}
          </div>
        ))}
        {props.showButton && (
          <div className='search-form-wrapper__submit'>
            <div
              className={`${
                props.showInputButton ? 'mt--0' : 'mt--16 mt-md--8'
              } mt-sm--8 flex-display flex-justify-space-around flex-align-items-center`}
            />
            <Button
              className={`${
                props.showInputButton ? 'visible-sm' : ''
              } search-btn`}
              block
            />
          </div>
        )}
      </div>
    </RoundedBlock>
  );
};
