import React from 'react';
import { Link, Button } from 'md-styled-components-v2';
import Item from './Item';

const Resources = () => (
  <div className='flex-display flex-direction-column m--16 pv--4'>
    <Item>
      <Button type='link' href='/about-us'>
        About Us
      </Button>
    </Item>
    <Item>
      <Link to='/media/category/press-release' size='large'>
        Press Releases
      </Link>
    </Item>
    <Item>
      <Link to='/media/category/whitepapers' size='large'>
        Whitepapers
      </Link>
    </Item>
    <Item>
      <Link to='/media/category/infographics' size='large'>
        Infographics
      </Link>
    </Item>
    <Item>
      <Link to='/media/category/case-studies' size='large'>
        Case Studies
      </Link>
    </Item>
    <Item>
      <Link to='/media/category/blog-entries' size='large'>
        Blog Entries
      </Link>
    </Item>
    <Item>
      <Link to='/media/category/videos' size='large'>
        Videos
      </Link>
    </Item>
  </div>
);

export default Resources;
