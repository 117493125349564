import client from './client';

export const getForgotPasswordApi = ({ params }) =>
  client.post('/public/forgotPassword', params);

export const getResetPasswordApi = ({ params }) =>
  client.post('/api/publicSite/resetPassword', params, {
    headers: { 'X-Requested-With': null },
  });
export const getCheckCodeApi = ({ params }) =>
  client.get('/api/publicSite/checkCode', { params });

export const getClearCodeApi = ({ params }) =>
  client.get('/api/publicSite/clearCode', { params });

export const getValidatePasswordApi = (params) =>
  client.post('/api/publicSite/validatePassword', params);

export const getUserEmailApi = ({ params }) =>
  client.post('/api/publicSite/validateActivationCode', params);

export const getPasswordRecoveryApi = ({ params }) =>
  client.get('/api/publicSite/validateRecoveryCode', { params });
