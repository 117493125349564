import Theme from 'md-styled-components/Themes';

export default `
&&&{
  background-color: ${Theme['primary-cta']};
  color: ${Theme.white};
    
  &:hover {
    background-color: ${Theme['mdsave-blue']};
    color: ${Theme.white};
  }
    
  &:focus {
     background-color: ${Theme['mdsave-blue']};
     color: ${Theme.white};
     box-shadow: ${Theme['btn-shadow-inset']} ${Theme['btn-outer-border-size']} ${Theme['mdsave-blue']},
       ${Theme['btn-shadow-inset']} ${Theme['btn-inner-border-size']} ${Theme.white};
  }
    
  &:active {
    background-color: ${Theme['mdsave-blue']};
    color: ${Theme.white};
  }
    
  &:disabled, &:disabled:hover {
    background-color: ${Theme.I10} !important;
    color: ${Theme.I50} !important;
    path {
      fill: ${Theme.I50} !important;
    }
  }
}
`;
