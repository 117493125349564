import dayjs from 'dayjs';

/**
 * disables dates before date current date
 * @param current
 * @returns {boolean}
 */
export const disabledDateBefore = (current) =>
  current && current < dayjs().endOf('day');

/**
 * Format date to send backend
 * @param date
 * @param format
 * @returns {*}
 */
export const formatDate = (date, format = 'MM/DD/YYYY') =>
  date ? date.format(format) : null;
