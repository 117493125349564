export const autofillOnResults = (
  search,
  procedureUri,
  form,
  setInputValue
) => {
  const searchParams = new URLSearchParams(search);
  const { q, name, keyword } = Object.fromEntries(searchParams.entries());
  const selectedProcedure = {
    uri: `/f/procedure/${procedureUri}`,
    name,
    keyword,
  };
  if (/^\d+$/.test(q)) {
    selectedProcedure.cpt = q;
  }
  form.setFields([{ name: 'procedure', value: selectedProcedure, errors: [] }]);
  setInputValue(selectedProcedure.name);
};

export const autofillOnProcedure = (
  name,
  procedureUri,
  form,
  setInputValue
) => {
  const selectedProcedure = {
    uri: `/f/procedure/${procedureUri}`,
    name,
  };
  form.setFields([{ name: 'procedure', value: selectedProcedure, errors: [] }]);
  setInputValue(selectedProcedure.name);
};

export const autofillOnPriceList = (search, form, setInputValue) => {
  const searchParams = new URLSearchParams(search);
  const { q, uri } = Object.fromEntries(searchParams.entries());
  if (q && uri) {
    const selectedProcedure = {
      uri: `/f/procedure/${uri}`,
      name: q,
    };
    form.setFields([
      { name: 'procedure', value: selectedProcedure, errors: [] },
    ]);
    setInputValue(selectedProcedure.name);
  }
};
