export const trimWhiteSpace = (str) => str.replace(/\s/g, '');

export const matchEmptySpaces = (value) => !value.match(/^$|.*\S.*/g);

export const replaceAll = (str, replaceObj) => {
  if (typeof replaceObj !== 'object' || replaceObj === null) {
    return str;
  }
  const re = new RegExp(Object.keys(replaceObj).join('|'), 'gi');

  return str.replace(re, (matched) => replaceObj[matched.toLowerCase()]);
};

export const processTextWithLineBreaks = (text) =>
  text
    .replace(/\u2028/g, '') // Remove line separator characters
    .split('\n') // Split the text into an array of lines
    .flatMap((textVal, i) => [
      i > 0 && <br key={i} />, // Add <br /> element for each line except the first
      textVal,
    ]);

export const sliceText = (text, maxLength) =>
  text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

/**
 * Capitalizes the first letter of each word in a given string.
 *
 * This function takes a string as input and returns a new string
 * with the first letter of each word converted to uppercase.
 *
 * @param {string} str - The input string to be transformed.
 * @returns {string} - The transformed string with capitalized first letters.
 */
export const capitalizeFirstLetters = (str) =>
  str.replace(/\b\w/g, (char) => char.toUpperCase());
