import React from 'react';
import { Tooltip, Typography } from 'md-styled-components';
import { Link } from 'md-styled-components-v2';
import SupportPhoneLink from '../../components/Support/SupportPhoneLink';
import common from '../../constants/common';

const { Text } = Typography;

export default {
  NOT_MATCH: (
    <span>
      Looks like your password and email do not match. Please try again or{' '}
      <Link
        className='link-router--underline link-router--black'
        to='/forgotPassword'
        size='large'
      >
        reset your password
      </Link>
      .{' '}
    </span>
  ),
  NOT_ACTIVE: (
    <span>
      Your account is not activated. Please check your inbox for an activation
      email from MDsave and click the 'Confirm Email' button to get started.
    </span>
  ),
  NOT_ACTIVE_DIRECT: (
    <span>
      Your account is not activated. Please check your inbox for an activation
      email from MDsave and click the ‘Activate My Account’ button to get
      started.
    </span>
  ),
  ACTIVATION_NEEDED: (
    <span>
      Your account is not activated. The MDsave team is reviewing your request
      and will be in touch soon!
    </span>
  ),
  STATUS_BAN_WITH_EMAIL: (email) => (
    <span>
      We have locked your account temporarily as a security precaution. Please{' '}
      <Link
        to='/forgotPassword'
        className='link-router--underline link-router--black'
        size='large'
      >
        click here
      </Link>{' '}
      to reset your password via email. If you need help, please call us at{' '}
      <SupportPhoneLink
        renderCustomText={(content) => <Text underline>{content}</Text>}
        loadNumber
      />
      .
    </span>
  ),
  STATUS_BAN: (
    <span>
      {' '}
      We have blocked your account temporarily as a security precaution. Please
      call MDsave support at{' '}
      <SupportPhoneLink
        renderCustomText={(content) => <Text underline>{content}</Text>}
        loadNumber
      />{' '}
      to unblock your account.{' '}
    </span>
  ),
  STATUS_INACTIVITY: (
    <span>
      {' '}
      To protect your account, we have locked it due to inactivity. Our team
      will review your access and email you a password reset link once your
      account is restored.{' '}
    </span>
  ),
  PASSWORD_EXPIRE_WITH_EMAIL: (emailValue) => (
    <span>
      Your password has expired due to inactivity. We have sent you an email at{' '}
      {emailValue} to reset your password. Please follow the instructions in
      that email, or call us at{' '}
      <SupportPhoneLink
        renderCustomText={(content) => <Text underline>{content}</Text>}
        loadNumber
      />{' '}
      if you have any questions.{' '}
    </span>
  ),
  PASSWORD_EXPIRE: (
    <span>
      Your password has expired due to inactivity. To reset your password please
      call us at{' '}
      <SupportPhoneLink
        renderCustomText={(content) => <Text underline>{content}</Text>}
        loadNumber
      />
      .
    </span>
  ),
  ATTEMPTS_COUNT: (attemptsCount) => (
    <span>
      <div className='mb--16'>
        Looks like that password does not match your account. Please try again
        or{' '}
        <Link
          className='link-router--underline link-router--black'
          to='/forgotPassword'
          size='large'
        >
          reset your password
        </Link>
        .
      </div>
      <div className='mb--16'>
        For security reasons, after <b>{attemptsCount}</b> more login attempts,
        you’ll have to wait <b>30 minutes</b> before trying again.
      </div>
      If you need help, please call us at{' '}
      <SupportPhoneLink
        renderCustomText={(content) => <Text underline>{content}</Text>}
        loadNumber
      />
      .
    </span>
  ),
  DUPLICATE_ACCOUNT: (
    <span>
      An account associated with this information already exists. Please contact
      us at{' '}
      <SupportPhoneLink
        renderCustomText={(content) => <Text underline>{content}</Text>}
        loadNumber
      />
      .
    </span>
  ),
  ACTIVATE_PATIENT: (
    <span>
      This email has already been used to purchase procedures, so we need to
      validate your email address to protect your privacy. Please check your
      inbox for an activation email from MDsave and click Confirm Email to
      verify your email address.
      <br />
      <br />
      If you need further assistance, please call us at{' '}
      <SupportPhoneLink
        renderCustomText={(content) => <Text underline>{content}</Text>}
        loadNumber
      />{' '}
      or{' '}
      <Link
        className='link-router--underline link-router--black'
        to='/contactus'
        reloadDocument
        size='large'
      >
        send us a message
      </Link>
      .
    </span>
  ),
  passwordsDontMatch: 'Please make sure your passwords match.',
  zipCodeNotValid: 'Please enter a valid ZIP Code.',
  phoneNumberNotValid: 'Please enter a valid phone number.',
  phoneNotValid: 'Please enter a valid phone.',
  COMPROMISED_PASSWORD: (
    <span>
      The password entered was detected on a public list of compromised
      passwords. Please enter a different password.
      <Tooltip
        title='These public lists are compiled by HIBP. For security reasons, we recommend you update your
    password on all other sites using this password.'
        placement='bottom'
      >
        <Link underline size='large' className='ml--4'>
          Learn more
        </Link>
      </Tooltip>
    </span>
  ),
  NOT_DELETE: 'Could not be deleted at this time',
  NOT_DELETE_PATIENT_CART: 'Unable to Delete Patient Cart',
  PUT_ITEM_ERRORS: [
    'You have services from different facilities in your shopping cart. ' +
      'Procedures from different facilities must be purchased in separate orders.',
    <>
      If you cannot find all the procedures you need at one facility, please
      call the MDsave {common.supportTeam} at{' '}
      <SupportPhoneLink
        renderCustomText={(content) => <Text color='grey'>{content}</Text>}
        loadNumber
      />{' '}
      for assistance.
    </>,
  ],
  PUT_ITEM_SPLIT_SHARED_ERRORS:
    'The services in your shopping cart are part of a shared shopping cart that cannot be edited. ' +
    'If you need a different set of procedures, please contact that care navigator that shared the cart with you.',
};
