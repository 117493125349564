import React from 'react';
import propTypes from 'prop-types';
import { Form, Typography } from 'md-styled-components';
import { Input } from 'md-styled-components-v2';
import { useSelector } from 'react-redux';
import { getContactInformation } from 'selectors/shopping-cart';
import Phone from '../../../Form/Phone';

const { Paragraph } = Typography;

const PrintQuote = ({ getFieldValue }) => {
  const initialInfo = useSelector(getContactInformation);

  return (
    <>
      <Paragraph level={2} color='grey' fontFamily='book' className='mb--16'>
        Please enter your contact information so the patient can reach you to
        complete their purchase. This information will appear on the PDF.
      </Paragraph>
      <Form.Item
        hasFeedback
        className='mb--24'
        label='Name of Contact Person (Optional)'
        name='hospital_contact_person'
        initialValue={initialInfo.hospitalContactPerson}
        validateTrigger='onBlur'
        validateFirst
        rules={[
          {
            required: Boolean(
              getFieldValue('hospital_phone_number')?.replace(/\D/g, '')
            ),
            message: 'Name of Contact Person cannot be blank.',
          },
        ]}
      >
        <Input classNames={{ input: 'phi' }} />
      </Form.Item>
      <Phone
        name='hospital_phone_number'
        label='Phone'
        initialValue={initialInfo.hospitalPhoneNumber}
        className='mb--0'
        hasFeedback
        validateFirst
        isRequired={Boolean(getFieldValue('hospital_contact_person'))}
      />
    </>
  );
};

PrintQuote.propTypes = {
  getFieldValue: propTypes.func.isRequired,
};

export default PrintQuote;
