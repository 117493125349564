import React, { useState } from 'react';
import { Button } from 'md-styled-components-v2';
import { logout } from 'utils/user';
import DefaultModal from 'components/Modals/DefaultModal';
import { common } from 'constants';
import propTypes from 'prop-types';

const UserInfoOverlay = ({ data }) => {
  const [visibleSignOut, setVisibleSignOut] = useState(false);

  return (
    <div className='flex-display flex-direction-column p--16'>
      {data.map((item) => (
        <div
          key={item.name}
          className='user-info__item-sub flex-display flex-align-items-center'
        >
          {(item.href || item.to).indexOf(common.signOutUrl) !== -1 ? (
            <Button
              type='link'
              onClick={() => setVisibleSignOut(true)}
              className='mh--16'
            >
              {item.name}
            </Button>
          ) : (
            <Button type='link' href={item.href} className='mh--16'>
              {item.name}
            </Button>
          )}
        </div>
      ))}
      {visibleSignOut && (
        <DefaultModal
          visible={visibleSignOut}
          onSuccess={() => logout()}
          onCancel={() => setVisibleSignOut(false)}
          cancelButtonText='Cancel'
          successButtonText='Sign Out'
          text='Are you sure you want to sign out?'
          title='Sign Out'
          buttonType='primary'
        />
      )}
    </div>
  );
};

UserInfoOverlay.propTypes = {
  data: propTypes.array,
};

export default UserInfoOverlay;
