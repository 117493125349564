import React from 'react';
import { Link } from 'md-styled-components-v2';
import { useSelector } from 'react-redux';
import { getChatLink } from 'selectors/liveAgent';
import { useContactUsModal, useLiveAgent } from '../LiveAgent/_hooks';

const SupportModalLink = (props) => {
  const onlineChat = useSelector(getChatLink);

  const onClickOnline = useLiveAgent();
  const onClickOffline = useContactUsModal();

  const toggleChatModal = () =>
    onlineChat ? onClickOnline() : onClickOffline();

  return (
    <Link
      size='large'
      onClick={toggleChatModal}
      {...(onlineChat && { to: 'javascript://Chat', reloadDocument: true })}
      {...props}
    >
      {props.children || 'send us a message'}
    </Link>
  );
};

export default SupportModalLink;
