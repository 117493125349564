import React from 'react';
import { Header, Typography } from 'md-styled-components';

const { AutoComplete: HeaderAutoComplete } = Header;
const { Menu, MenuItem } = HeaderAutoComplete;
const { Paragraph, Text } = Typography;

export default ({ results, menuProps, isSmall, itemClass, className }) => {
  const menuItems = results.map((item, index) =>
    item.id !== 'allResults' ? (
      <MenuItem
        key={`${item.name}${item.id}`}
        option={item}
        position={index}
        className={itemClass}
      >
        <div
          className={`flex-display flex-justify-space-between ${
            isSmall ? 'flex-direction-column' : ''
          }`}
        >
          <div className='info-wrapper'>
            <Paragraph className='wrap-text provider-name'>
              {item.name}
            </Paragraph>
            <Paragraph level={4} color='paleSky' className='wrap-text'>
              {item.specialty}
              {item.practice && (
                <>
                  <span className='dot'> • </span>
                  {item.practice}
                </>
              )}
            </Paragraph>
          </div>
          <div className='info-wrapper'>
            <Text level={4} color='paleSky'>
              {item.address}
              {item.countLocation > 1 &&
                `(+ ${item.countLocation - 1} Location${
                  item.countLocation > 2 ? 's' : ''
                })`}
            </Text>
          </div>
        </div>
      </MenuItem>
    ) : (
      <MenuItem
        key={item.id}
        option={item}
        position={index}
        className={itemClass}
      >
        {item.name}
      </MenuItem>
    )
  );
  return (
    <Menu {...menuProps} className={className}>
      {menuItems}
    </Menu>
  );
};
