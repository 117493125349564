import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { render } from 'react-dom';
import propTypes from 'prop-types';
import { AutoComplete, Input } from 'md-styled-components-v2';
import { Themes, Spin, Icon } from 'md-styled-components';
import {
  createGetPatientListLoading,
  getPatientList,
} from 'selectors/patients';
import { common } from 'constants/index';
import renderMenu from './renderMenu';
import EmptyLabel from './EmptyLabel';
import { useInputChange, useOnSelect } from './hooks';
import CreateNewPatientLink from './CreateNewPatientLink';

const { searchMinLength } = common;

const SaveAutoComplete = ({
  createNewPatient,
  setSendData,
  resetField,
  setResetField,
}) => {
  const [hasFixedLabel, setHasFixedLabel] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const patientListData = useSelector(getPatientList);
  const patientListLoading = useSelector(createGetPatientListLoading);

  const { patientSearchText, searchByText } = useInputChange();
  const selectPatient = useOnSelect();

  const labelKey = 'name_clear';
  const dataSource = patientListData || [];
  const notFound = !patientListData?.length &&
    inputValue.length >= searchMinLength && (
      <EmptyLabel patientSearchText={patientSearchText} />
    );

  useEffect(() => {
    if (!hasFixedLabel) {
      const reactContainer = document.createElement('div');
      const elem = document.querySelector('.ant-select-dropdown > div');
      if (elem) {
        elem.after(reactContainer);
        render(
          <CreateNewPatientLink createNewPatient={createNewPatient} />,
          reactContainer
        );
        setHasFixedLabel(true);
      }
    }
  }, [dataSource, hasFixedLabel]);

  useEffect(() => {
    if (resetField) {
      setInputValue('');
      setResetField(false);
    }
  }, [resetField, setResetField]);

  return (
    <AutoComplete
      onSelect={(_, selectedOption) =>
        selectPatient({
          selectedOption,
          setInputValue,
          createNewPatient,
          setSendData,
          labelKey,
        })
      }
      notFoundContent={patientListLoading ? <Spin /> : notFound}
      options={renderMenu(dataSource, inputValue, createNewPatient)}
      dropdownStyle={{ zIndex: 1050 }}
    >
      <Input
        value={inputValue}
        onChange={(e) => searchByText({ e, setInputValue })}
        placeholder="Enter a patient's name, email, or account number"
        prefix={<Icon color={Themes.I90} type='magnifying-glass' size='15px' />}
      />
    </AutoComplete>
  );
};

SaveAutoComplete.propTypes = {
  createNewPatient: propTypes.func.isRequired,
  setSendData: propTypes.func.isRequired,
  resetField: propTypes.bool.isRequired,
  setResetField: propTypes.func.isRequired,
};

export default SaveAutoComplete;
