import {
  EMAIL_TEXT,
  PASSWORD_TEXT,
} from 'components/Login/_constants/formTexts';

export const options = {
  formEmail: {
    className: 'mb--8',
    placeholder: EMAIL_TEXT,
    id: 'username',
  },
  formPassword: {
    className: 'mb--8',
    placeholder: PASSWORD_TEXT,
  },
  form: {
    vertical: false,
    id: 'login-overlay',
  },
  buttonClassName: 'login__btn--focus login__btn',
  lineClassName: 'pv--8',
  rememberEmailClassName: 'mb--0 mt--8',
  ssoContainerClassName: 'mt--16',
};
