import React, { useState, useEffect, useCallback } from 'react';
import { Header, Themes, Icon, Spin } from 'md-styled-components';
import { Input } from 'md-styled-components-v2';
import { useSelector } from 'react-redux';
import { getPageGroup } from 'selectors/support';
import { searchOnBlur } from 'utils/common';
import { matchEmptySpaces } from 'utils/stringHelper';
import { getSesStorageItem, getSesStorageItemKey } from 'utils/storage';
import { common } from '../../constants';
import { useBreakpoint as breakpoint } from '../../hooks';

const { AutoComplete } = Header;

const xsScreenSize = Themes.breakpoints.xs;
const mdScreenSize = Themes.breakpoints.md;

export default React.forwardRef(
  (
    {
      id,
      onChange,
      optionSelected,
      dataSource,
      notFound,
      loading,
      iconType,
      placeholder,
      emptyLabel,
      fieldName,
      rounded = '',
      labelKey,
      renderMenu,
      suffix,
      size,
      border,
      takeFirstOnEnter,
      deftValue = '',
      setAutocompleteClass,
      autocompleteClass,
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(deftValue);
    const isSmall = breakpoint(xsScreenSize, mdScreenSize);
    const { searchMinLength } = common;
    const [isSelected, setIsSelected] = useState(false);
    const pageGroup = useSelector(getPageGroup);
    const iconSize = isSmall ? 'text-1-size' : 'text-2-size';

    useEffect(() => {
      switch (true) {
        case !!pageGroup: {
          const sesStorageKey = getSesStorageItemKey(fieldName, pageGroup);
          const sesStorageValue = getSesStorageItem(sesStorageKey);
          const initialValue = sesStorageValue || deftValue;
          if (initialValue) {
            setInputValue(initialValue);
            onChange(initialValue, fieldName);
          }
          break;
        }
        case !deftValue:
          setInputValue('');
          break;
        default:
          setInputValue(deftValue);
          onChange(deftValue, fieldName);
          break;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldName, pageGroup, deftValue]);

    const handleKeyDown = (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        const firstItem = document.querySelector(`#${id}-autocomplete-item-0`);
        if (
          firstItem &&
          !document.querySelector(`#${id}-autocomplete > .active`)
        ) {
          firstItem.click();
        }
      }
    };

    const setClass = useCallback(() => {
      setAutocompleteClass &&
        !autocompleteClass &&
        setAutocompleteClass('has-clear-button');
    }, [setAutocompleteClass, autocompleteClass]);

    const renderInput = ({ inputRef, referenceElementRef, ...inputProps }) => {
      const input = document.querySelector(`input#${fieldName}`);

      return (
        <>
          <Input
            {...inputProps}
            className='prefix-input'
            value={inputValue}
            onChange={(e) => {
              const { value } = e.target;
              if (matchEmptySpaces(value)) {
                return;
              }
              input.click();

              if (isSelected) {
                setIsSelected(false);
              }
              setInputValue(value);
              onChange(value, fieldName, dataSource?.length);
              setClass();
            }}
            onFocus={() => {
              document.body.click();
              inputValue.length >= searchMinLength && setClass();
            }}
            onBlur={(e) => {
              searchOnBlur(
                e.relatedTarget,
                autocompleteClass,
                setAutocompleteClass
              );
            }}
            onMouseDown={() => {
              if (!isSmall) {
                document.addEventListener(
                  'mouseup',
                  (e) => {
                    if (e.target !== input) {
                      setTimeout(() => {
                        input.click();
                      });
                    }
                  },
                  { once: true }
                );
              }
            }}
            placeholder={placeholder}
            autoComplete='off'
            size={size}
            rounded={rounded}
            id={fieldName}
            border={border || ''}
            prefix={
              iconType && (
                <Icon color={Themes.I90} type={iconType} size='15px' />
              )
            }
            allowClear={{
              clearIcon: (
                <Icon
                  color={Themes.I90}
                  type='icon-cancel2'
                  size={Themes[iconSize]}
                />
              ),
            }}
          />
          {suffix}
        </>
      );
    };

    return (
      <AutoComplete
        ref={ref}
        id={`${id}-autocomplete`}
        className={autocompleteClass}
        options={dataSource}
        onKeyDown={takeFirstOnEnter && handleKeyDown}
        labelKey={labelKey}
        emptyLabel={loading ? <Spin /> : notFound && emptyLabel}
        filterBy={() => true}
        onInputChange={(value) =>
          onChange(value, fieldName, dataSource && dataSource.length)
        }
        onChange={(selected) => {
          if (selected[0]) {
            setInputValue(selected[0][labelKey]);
            onChange(
              selected[0][labelKey],
              fieldName,
              dataSource && dataSource.length,
              selected[0].keyword,
              true
            );
            optionSelected(fieldName, selected[0]);
            setIsSelected(true);
          }
        }}
        renderInput={renderInput}
        renderMenu={(results, menuProps) =>
          renderMenu(results, menuProps, isSmall, inputValue)
        }
      />
    );
  }
);
