import React from 'react';
import { Typography } from 'md-styled-components';
import {
  getCompanyUserCompanies,
  getCurrentCompanyInfo,
} from 'selectors/users';
import { useSelector } from 'react-redux';
import { useIsMobile } from 'hooks';
import CompanyCascader from './CompanyCascader';
import CompanyModal from './CompanyModal';

const { Paragraph } = Typography;

const CompanyName = () => {
  const isMobile = useIsMobile();
  const companies = useSelector(getCompanyUserCompanies);
  const { currentCompany, currentPricingGroup } = useSelector(
    getCurrentCompanyInfo
  );

  const isMultiCompanyUser =
    companies?.companiesList.length > 1 ||
    companies?.companiesList[0].pricingGroups.length > 1;

  return (
    <div className='flex-display flex-justify-center--sm mds-full--width-sm-max'>
      {currentCompany?.name && (
        <div className='flex-display flex-justify-center--sm mds-full--width-sm-max'>
          <Paragraph level={3} className='portal-user-banner__company-name'>
            {currentCompany.name}
          </Paragraph>
          <Paragraph level={3} className='ph--8'>
            /
          </Paragraph>
          <Paragraph level={3} className='portal-user-banner__company-name'>
            {currentPricingGroup.name}
          </Paragraph>
          {isMultiCompanyUser &&
            (isMobile ? <CompanyModal /> : <CompanyCascader />)}
        </div>
      )}
    </div>
  );
};

export default CompanyName;
