import Theme from 'md-styled-components/Themes';

export default `
  background-color: transparent;
  padding: 0 !important;
  font-family: ${Theme['font-sans-book']};
  color: ${Theme.I90};
  path {
    fill: ${Theme.I90} !important;
  }
 
  &:hover {
    background-color: transparent !important;
    color: ${Theme.I90};
  }
  
  &:focus {
    background-color: transparent;
    color: ${Theme.I90};
  }
  
  &:active {
    background-color: transparent !important;
    color: ${Theme.I90};
  }
 
  &:disabled, &:disabled:hover {
    background-color: transparent;
    color: ${Theme['mdsave-blue']};
    path {
      fill: ${Theme['mdsave-blue']} !important;
    }
  }
`;
