import React, { useEffect } from 'react';
import { Form, Input } from 'md-styled-components-v2';
import propTypes from 'prop-types';

const LoginForm = ({
  initialValue,
  onChangeEmail,
  onEffectFunction = () => {},
  options,
  className,
}) => {
  useEffect(() => {
    onEffectFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rememberedEmail = localStorage.getItem('remembered_email');

  return (
    <Form.Item
      name='username'
      {...(options.formEmail.label && { label: options.formEmail.label })}
      rules={[{ required: true, message: 'Email cannot be blank.' }]}
      className={className}
      hasFeedback
      shouldUpdate
      initialValue={initialValue || rememberedEmail}
    >
      <Input
        {...(options.formEmail.placeholder && {
          placeholder: options.formEmail.placeholder,
        })}
        classNames={{ input: 'phi mb--0' }}
        name='RegisterPartForm[email_address]'
        id={options.formEmail.id}
        onChange={onChangeEmail}
      />
    </Form.Item>
  );
};

LoginForm.propTypes = {
  initialValue: propTypes.string.isRequired,
  onChangeEmail: propTypes.func.isRequired,
  onEffectFunction: propTypes.func,
  options: propTypes.object.isRequired,
  className: propTypes.string.isRequired,
};

export default LoginForm;
