import React, { memo } from 'react';
import { useTillTablet } from 'hooks';
import NavbarSm from './NavbarSm/index';
import NavbarLg from './NavbarLg';

const Default = () => {
  const isTillTablet = useTillTablet();

  return isTillTablet ? <NavbarSm /> : <NavbarLg />;
};

export default memo(Default);
