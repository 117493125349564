import React, { useState } from 'react';
import { Divider, Drawer, Icon } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import ShoppingCartButton from 'components/Navigation/ShoppingCart/ShoppingCartButton';
import { useSelector } from 'react-redux';
import { getPrivateHospitalData } from 'selectors/privateHospitalData';
import ProviderAuthorization from 'components/Navigation/UserSection/AuthorizationItem/ProviderAuthorization';
import Logo from '../_partials/Logo';
import Item from '../_partials/Item';
import Menu from '../_partials/Menu';
import ContactUs from '../../ContactUs';

const DrawerComponent = () => {
  const [visible, setVisible] = useState(false);
  const { showSignIn, hideMDsavePrices, showSingleTab } = useSelector(
    getPrivateHospitalData
  );

  return (
    <>
      <Button type='text' onClick={() => setVisible(true)}>
        <Icon size={20} type='menu' />
      </Button>
      <Drawer
        placement='left'
        onClose={() => setVisible(false)}
        open={visible}
        width='256px'
        closable={false}
        bodyStyle={{ padding: 0, width: '100%' }}
      >
        <Logo iconSize={44} logoClassName='ml--24 mv--24' />
        <Menu />
        {!hideMDsavePrices && <Divider className='mv--8' />}
        {showSignIn && (
          <Item>
            <ProviderAuthorization />
          </Item>
        )}
        <Item>
          <ShoppingCartButton inDrawer />
        </Item>
        <Divider className='mv--8' />
        {!showSingleTab && (
          <Item>
            <ContactUs type='phoneNumber' />
          </Item>
        )}
      </Drawer>
    </>
  );
};

export default DrawerComponent;
