import {
  createSuggestedProceduresLoading,
  getSuggestedProcedures,
} from './procedures';
import {
  createSuggestedProvidersLoading,
  getSuggestedProviders,
} from './providers';
import {
  createInsuranceProvidersLoading,
  getSuggestedInsuranceProviders,
} from './transparency';

export default {
  Procedures: {
    data: getSuggestedProcedures,
    loading: createSuggestedProceduresLoading,
  },
  Providers: {
    data: getSuggestedProviders,
    loading: createSuggestedProvidersLoading,
  },
  InsuranceProvider: {
    data: getSuggestedInsuranceProviders,
    loading: createInsuranceProvidersLoading,
  },
};
