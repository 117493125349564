import {
  getIsGroupUser,
  getIsCmsUser,
  getIsPortalUser,
  getIsReportUser,
  getIsDirectUser,
} from 'selectors/users';
import { isPrivateHospital } from 'utils/privateSite';
import { isHomePage } from 'utils/pages';
import { useSelector } from 'react-redux';

export const useGetNavbarClasses = (nonStickyClass = '') => {
  const isGroupUser = useSelector(getIsGroupUser);
  const isCmsUser = useSelector(getIsCmsUser);
  const isPortalUser = useSelector(getIsPortalUser);
  const isReportUser = useSelector(getIsReportUser);
  const isDirectUser = useSelector(getIsDirectUser);

  const directNavPaddingClass =
    (isDirectUser || isReportUser) && !isPortalUser && !isPrivateHospital()
      ? 'mt--40'
      : '';

  const headerPaddingClass = `ph-lg--0 ph-md--0 ${
    nonStickyClass ? 'ph--48 ' : 'pl--48 pr--64'
  }`;
  return (
    (!isHomePage() || isPrivateHospital()) && {
      headerSearchClass: `header-search--fixed ${headerPaddingClass} ${
        isPrivateHospital()
          ? `header-search--fixed-top-${isPortalUser ? 'md' : 'xs'}`
          : 'header-search--fixed-top'
      } ${directNavPaddingClass ? 'header-search--top-space' : ''}
  ${
    isCmsUser || isGroupUser
      ? 'header-search--none-position header-search--top-space-new '
      : ''
  }
  ${nonStickyClass}
  `,
    }
  );
};
