import React from 'react';
import 'pages/AdminTools/styles/index.less';
import PageContainer from 'components/PageContainer';
import common from 'constants/common';
import HelmetContent from 'components/HelmetContent';
import { home } from 'constants/helmet';
import Content from './Content';

const { helmetTitle, helmetDescription } = home;

const SsoConfigurationUpdate = () => (
  <PageContainer
    containerClassName='admin-page'
    compactFooter
    pageType={common.cmsPage}
  >
    <HelmetContent title={helmetTitle} description={helmetDescription} />
    <Content />
  </PageContainer>
);

export default SsoConfigurationUpdate;
