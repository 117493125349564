import { Tooltip as tooltip } from 'antd';
import propTypes from 'prop-types';
import styled from 'styled-components';
import './index.less';

const TooltipWrapper = styled(tooltip)``;

/** @type {Component} */
const Tooltip = (props) => <TooltipWrapper {...props} />;

Tooltip.propTypes = {
  /** The text shown in the tooltip */
  title: propTypes.oneOfType([
    propTypes.string,
    propTypes.element,
    propTypes.func,
  ]),
  /** Whether the arrow is pointed at the center of target, supported after antd@1.11+ */
  arrow: propTypes.bool,
  /** Whether to adjust popup placement automatically when popup is off screen */
  autoAdjustOverflow: propTypes.bool,
  /** Whether the floating tooltip card is visible by default */
  defaultVisible: propTypes.bool,
  /** The DOM container of the tip, the default behavior is to create a div element in body */
  getPopupContainer: propTypes.func,
  /** Delay in seconds, before tooltip is shown on mouse enter */
  mouseEnterDelay: propTypes.number,
  /** Delay in seconds, before tooltip is hidden on mouse leave */
  mouseLeaveDelay: propTypes.number,
  /** Class name of the tooltip card */
  overlayClassName: propTypes.string,
  /** Style of the tooltip card */
  overlayStyle: propTypes.object,
  /** The position of the tooltip relative to the target, which can be one of top left right bottom
   * topLeft topRight bottomLeft bottomRight leftTop leftBottom rightTop rightBottom */
  placement: propTypes.oneOf([
    'top',
    'left',
    'right',
    'bottom',
    'topLeft',
    'topRight',
    'bottomLeft',
    'bottomRight',
    'leftTop',
    'leftBottom',
    'rightTop',
    'rightBottom',
  ]),
  /** Tooltip trigger mode */
  trigger: propTypes.oneOf(['hover', 'focus', 'click', 'contextMenu']),
  /** Whether the floating tooltip card is visible or not */
  open: propTypes.bool,
  /** Callback executed when visibility of the tooltip card is changed */
  onOpenChange: propTypes.func,
  /** this value will be merged into placement's config */
  align: propTypes.object,
};

/** @component */
export default Tooltip;
