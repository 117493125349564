import React from 'react';
import { Link, Button } from 'md-styled-components-v2';
import { EMPLOYER_CONNECT } from 'constants/tendoLinks';
import Item from './Item';

const Providers = () => (
  <div className='flex-display flex-direction-column m--16 pv--4'>
    <Item>
      <Link to='/providers' size='large'>
        Provider Partners
      </Link>
    </Item>
    <Item>
      <Link to='/referring-providers' size='large'>
        Referring Providers
      </Link>
    </Item>
    <Item>
      <Button type='link' href={EMPLOYER_CONNECT} target='_blank'>
        Care Connect
      </Button>
    </Item>
  </div>
);

export default Providers;
