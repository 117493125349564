import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { requestCurrentUser } from 'actions/users';
import { getUserData } from 'selectors/users';
import { useUpdateFavicons } from 'hooks/useUpdateFavicons';

const GetUserData = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userData = useSelector(getUserData);

  useUpdateFavicons();

  useEffect(
    () => {
      if (
        userData?.userIdentityKey &&
        userData.userIdentityKey === localStorage.getItem('userIdentityKey') &&
        ((!localStorage.getItem('currentUserId') &&
          userData.userIdentityKey === 'user-identifier') ||
          (localStorage.getItem('currentUserId') &&
            userData.userIdentityKey !== 'user-identifier'))
      ) {
        return;
      }
      dispatch(requestCurrentUser.request());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, location]
  );

  useEffect(() => {
    if (userData) {
      localStorage.setItem('userIdentityKey', userData.userIdentityKey);
      localStorage.setItem(
        'currentUserId',
        userData.guest ? '' : String(userData.id)
      );
    }
  }, [userData]);

  return null;
};

export default GetUserData;
