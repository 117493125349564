import React, { memo } from 'react';
import { useIsDesktop } from 'hooks';
import BackButton from './BackButton';
import PhoneNumber from './PhoneNumber';

const Default = () => {
  const isDesktop = useIsDesktop();

  return (
    <>
      <BackButton />
      {isDesktop && <PhoneNumber />}
    </>
  );
};

export default memo(Default);
