import React from 'react';
import { Typography, Icon, Themes } from 'md-styled-components';
import propTypes from 'prop-types';

const { Text } = Typography;

const SubMenuTitle = ({ title }) => (
  <div className='flex-display menu__underline'>
    <Text level={3} className='menu__title--active'>
      {title}
    </Text>
    <Icon
      size={12}
      color={Themes.indigo}
      type='chevron-down'
      className='menu__underline-icon ml--8 mb--2'
    />
  </div>
);

SubMenuTitle.propTypes = {
  title: propTypes.string.isRequired,
};

export default SubMenuTitle;
