import React, { Suspense, lazy, useCallback, useState } from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form } from 'md-styled-components';
import { requestSetSelectedProcedure } from 'actions/procedures';
import { getSuggestedProceduresHeader } from 'selectors/procedures';
import { getPrivateHospitalData } from 'selectors/privateHospitalData';
import { isPrivateHospital } from 'utils/privateSite';
import { formatQuery } from 'utils/url';
import { TrackTransparencySearch } from '../Analytics/Track';
import { resetFormField } from './ProceduresSearch';

const HeroSearchFormWrapper = lazy(() => import('./HeroSearchFormWrapper'));
const SearchFormWrapper = lazy(() => import('./SearchFormWrapper'));
const ProceduresFormField = lazy(() => import('./_partials/Procedures'));
const InsuranceProvidersFormField = lazy(() =>
  import('./_partials/InsuranceProviders')
);

const ProceduresInsuranceSearch = ({
  searchFields,
  suffixBtn,
  heroSearch,
  toggleSearchVisible,
}) => {
  const dispatch = useDispatch();
  const proceduresFieldName = searchFields.proceduresField;
  const insuranceProviderFieldName = searchFields.insuranceProviderField;

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { getFieldError, setFieldsValue } = form;

  const procedureError = getFieldError(proceduresFieldName).length;
  const insuranceProviderError = getFieldError(
    insuranceProviderFieldName
  ).length;

  const [forceUpdate, setForceUpdate] = useState(false);
  const [searchData, setSearchData] = useState({});
  const [optionsSelected, setOptionsSelected] = useState({
    [proceduresFieldName]: false,
    [insuranceProviderFieldName]: false,
  });

  const { privateHospitalSlug, connectionId } = useSelector(
    getPrivateHospitalData
  );
  const proceduresData = useSelector(getSuggestedProceduresHeader);

  const focusInput = useCallback(() => {
    let inputId;
    const procedureHasError = getFieldError(proceduresFieldName).length;
    const insuranceProviderHasError = getFieldError(
      insuranceProviderFieldName
    ).length;
    switch (true) {
      case !!procedureHasError:
        inputId = proceduresFieldName;
        break;
      case !!insuranceProviderHasError:
        inputId = insuranceProviderFieldName;
        break;
      default:
        return;
    }
    document.querySelector(`#${inputId}`).focus();
  }, [getFieldError, insuranceProviderFieldName, proceduresFieldName]);

  const existingProcedure = useCallback(
    (searchText) => {
      if (proceduresData) {
        return proceduresData.find(
          (obj) => obj.name.toLowerCase() === searchText.toLowerCase()
        );
      }
      return false;
    },
    [proceduresData]
  );

  const redirect = useCallback(() => {
    if (!searchData.searchText) {
      return;
    }
    let selectedProcedure = optionsSelected[proceduresFieldName];
    const selectedInsurance = optionsSelected[insuranceProviderFieldName];
    const exactCPTMatch =
      proceduresData.length === 1 &&
      (proceduresData[0].foundCptCode === searchData.searchText ||
        proceduresData[0].name.toLowerCase() ===
          searchData.searchText.toLowerCase());
    const currentUrl = `${location.pathname}${location.search}`;
    if (
      (!selectedProcedure ||
        selectedProcedure.name !== proceduresData[0].name) &&
      exactCPTMatch
    ) {
      [selectedProcedure] = proceduresData;
      searchData.searchText = selectedProcedure.name;
    }
    const trackSearch = { white_label_hospital_id: connectionId };
    const getSearchUri = (searchSubDirectory, encodedQuery, selectedItemId) =>
      selectedItemId
        ? `${searchSubDirectory}?q=${encodedQuery}&p=${selectedItemId}`
        : `${searchSubDirectory}?q=${encodedQuery}`;
    const searchSubDirectory = `/${privateHospitalSlug}-price-list`;
    const encodedQuery = formatQuery(searchData.searchText);

    let url = selectedProcedure
      ? getSearchUri(searchSubDirectory, encodedQuery, selectedProcedure.id)
      : getSearchUri(searchSubDirectory, encodedQuery);
    trackSearch.search_text = searchData.searchText;
    if (!selectedProcedure) {
      const procedure = existingProcedure(searchData.searchText);
      if (procedure) {
        url = getSearchUri(searchSubDirectory, encodedQuery, procedure.id);
        trackSearch.procedure_id = +procedure.id;
      }
    } else {
      trackSearch.procedure_id = +selectedProcedure.id;
    }
    if (selectedInsurance.id) {
      trackSearch.insurance_provider_id = selectedInsurance.id;
      url = `${url}&i=${selectedInsurance.id}&iq=${formatQuery(
        selectedInsurance.name
      )}`;
    }
    if (toggleSearchVisible && currentUrl !== url) {
      toggleSearchVisible();
    }
    (selectedProcedure || exactCPTMatch) &&
      dispatch(
        requestSetSelectedProcedure.request(
          selectedProcedure.foundCptCode ? selectedProcedure : {}
        )
      );
    isPrivateHospital() && TrackTransparencySearch(url, location, trackSearch);
    navigate(url);
  }, [
    dispatch,
    searchData,
    navigate,
    location,
    optionsSelected,
    proceduresFieldName,
    insuranceProviderFieldName,
    privateHospitalSlug,
    existingProcedure,
    toggleSearchVisible,
    connectionId,
  ]);

  const onFinishFailed = (values) => {
    const [error] = values.errorFields;
    setForceUpdate(!forceUpdate);
    focusInput(error.name[0]);
  };

  const onOptionSelected = useCallback((fieldName, selected) => {
    setOptionsSelected((prevOptions) => ({
      ...prevOptions,
      [fieldName]: selected,
    }));
  }, []);

  const resetFieldsData = useCallback(
    (fieldName, searchDataName, clearLocation) =>
      resetFormField(
        fieldName,
        setFieldsValue,
        onOptionSelected,
        setSearchData,
        searchDataName,
        clearLocation
      ),
    [onOptionSelected, setFieldsValue]
  );

  const getFormItemProps = (name) => ({
    name,
    validateTrigger: 'onBlur',
    validateFirst: true,
    rules: [{ required: true }],
  });

  const FormItem = (
    <ProceduresFormField
      proceduresFieldName={proceduresFieldName}
      heroSearch={heroSearch}
      onOptionSelected={onOptionSelected}
      procedureError={procedureError}
      searchData={searchData}
      setSearchData={setSearchData}
      setFieldsValue={setFieldsValue}
      resetFieldsData={resetFieldsData}
    />
  );
  const SecondFormItem = (
    <InsuranceProvidersFormField
      insFieldName={insuranceProviderFieldName}
      suffixBtn={suffixBtn}
      heroSearch={heroSearch}
      insError={insuranceProviderError}
      setFieldsValue={setFieldsValue}
      onOptionSelected={onOptionSelected}
    />
  );

  return (
    <Suspense fallback={<></>}>
      <Form
        form={form}
        onFinish={redirect}
        onFinishFailed={onFinishFailed}
        onFieldsChange={() => setForceUpdate(!forceUpdate)}
        className='procedures-insurance'
      >
        {heroSearch ? (
          <HeroSearchFormWrapper
            buttonId='search-procedure-btn'
            formItemProps={getFormItemProps(proceduresFieldName)}
            FormItem={FormItem}
            secondFormItemProps={{
              name: insuranceProviderFieldName,
            }}
            SecondFormItem={SecondFormItem}
          />
        ) : (
          <SearchFormWrapper
            button={false}
            formItemProps={getFormItemProps(proceduresFieldName)}
            FormItem={FormItem}
            secondFormItemProps={{
              name: insuranceProviderFieldName,
            }}
            SecondFormItem={SecondFormItem}
          />
        )}
      </Form>
    </Suspense>
  );
};

ProceduresInsuranceSearch.propTypes = {
  heroSearch: propTypes.bool,
  suffixBtn: propTypes.bool,
  searchFields: propTypes.object.isRequired,
  toggleSearchVisible: propTypes.func.isRequired,
};

export default ProceduresInsuranceSearch;
