import { formatQuery } from 'utils/url';
import { existingData } from '../helpers';
import { ProceduresOption, InsuranceProviderOption } from './DefaultOptions';

const getProcedureUrl = (procedureItems) => {
  const { dataSource, optionsSelected, search, slug } = procedureItems;

  const getSearchUri = (searchSubDirectory, encodedQuery, selectedItemId) =>
    selectedItemId
      ? `${searchSubDirectory}?q=${encodedQuery}&p=${selectedItemId}`.replace(
          '/',
          ''
        )
      : `${searchSubDirectory}?q=${encodedQuery}`;
  const searchSubDirectory = `${slug}-price-list`;
  const encodedQuery = formatQuery(search.text);
  let url = optionsSelected
    ? getSearchUri(searchSubDirectory, encodedQuery, optionsSelected.id)
    : getSearchUri(searchSubDirectory, encodedQuery);
  const procedure = existingData(dataSource, 'name', search.text);
  if (procedure) {
    url = getSearchUri(searchSubDirectory, encodedQuery, procedure.id);
  }
  return url;
};

export const redirectNoInsurance = (items, callback) => {
  const [item] = items;
  item.search?.text && callback(`/${getProcedureUrl(items[0])}`);
};

export default Object.freeze({
  name: 'Procedures',
  data: [
    { ...ProceduresOption },
    { ...InsuranceProviderOption, rounded: 'right' },
  ],
  redirectOnChange: false,
  redirect: (items, callback, privateHospitalSlug) => {
    const { dataSource: dataSourceInsurance, search: searchInsurance } =
      items[1];
    if (!searchInsurance.text && dataSourceInsurance?.length) {
      searchInsurance.text = dataSourceInsurance[0].name;
    }
    let url = getProcedureUrl(items[0]);
    const insurance = existingData(
      dataSourceInsurance,
      'name',
      searchInsurance.text
    );
    if (insurance) {
      url = `${url}&i=${insurance.id}&iq=${formatQuery(insurance.name)}`;
    }
    callback(`/${url}`);
  },
});
