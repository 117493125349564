import React from 'react';
import { Typography } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import propTypes from 'prop-types';
import { ordinalSuffixOf } from 'utils/grammaticalParticles';
import { useRankData } from './_hooks';

const { Text } = Typography;

const LeaderBoard = ({ dateType, onClickLeaderBoard }) => {
  const rank = useRankData(dateType);

  return (
    <Button
      type='link'
      className='flex-display flex-align-items-center text-left'
      display='block'
      onClick={onClickLeaderBoard}
    >
      <Text>Leaderboard</Text>
      {rank && (
        <div className='direct-navbar__leader-board-rank ml--8'>
          <Text color='white' level={3}>
            {`${ordinalSuffixOf(rank)} Place`}
          </Text>
        </div>
      )}
    </Button>
  );
};

LeaderBoard.propTypes = {
  onClickLeaderBoard: propTypes.func.isRequired,
  dateType: propTypes.number.isRequired,
};

export default React.memo(LeaderBoard);
