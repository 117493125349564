import React from 'react';
import { Typography } from 'md-styled-components';
import { isNoEmail } from 'utils/emailHelper';

const { Text } = Typography;

const PatientDetails = ({ existingPatient }) => (
  <div className='flex-display flex-direction-column p--16 save-cart__patient-details flex-1'>
    <div>
      <Text level={2} fontFamily='heavy'>
        {`${existingPatient.first_name} ${existingPatient.last_name}`}
      </Text>
    </div>
    <div className='flex-display flex-direction-row flex-justify-content-start mt--4'>
      <div className='flex-display flex-direction-column mr--16'>
        <div className='mt--4'>DOB</div>
        <div className='mt--4'>Email</div>
        <div className='mt--4'>Phone</div>
      </div>
      <div className='flex-display flex-direction-column'>
        <div className='mt--4'>{`${existingPatient.b_day}/${existingPatient.b_month}/${existingPatient.b_year}`}</div>
        <div className='mt--4'>
          {isNoEmail(existingPatient.email_address)
            ? 'Patient does not have an email'
            : existingPatient.email_address}
        </div>
        <div className='mt--4'>{existingPatient.mobile_phone}</div>
      </div>
    </div>
  </div>
);

export default PatientDetails;
