import { Fragment } from 'react';
import { Typography, Highlighter } from 'md-styled-components';

const { Paragraph } = Typography;

const renderMenu = (results, inputValue) =>
  results.map((item) => ({
    value: item.name,
    label: (
      <Fragment key={item.id}>
        <Paragraph className='wrap-text'>
          <Highlighter
            searchWords={[inputValue]}
            textToHighlight={item.name}
            autoEscape
          />
        </Paragraph>
        {item.drgCodes && (
          <Paragraph level={4} color='paleSky'>
            DRG Code: {item.drgCodes.join(', ')}
          </Paragraph>
        )}
        {item.keyword && (
          <Paragraph level={4} color='paleSky' className='wrap-text pt--8'>
            Also Known As:{' '}
            <Highlighter
              searchWords={[inputValue]}
              textToHighlight={item.keyword}
            />
          </Paragraph>
        )}
        {item.cpt && (
          <Paragraph level={4} color='paleSky' className='pt--8'>
            CPT Code:
            {item.cpt.map((cpt, index) => (
              <Highlighter
                key={`${cpt}-${index}`}
                searchWords={[inputValue]}
                textToHighlight={` ${cpt.mainCPT}${
                  item.cpt[index + 1] ? ' + ' : ''
                }`}
              />
            ))}
          </Paragraph>
        )}
      </Fragment>
    ),
    data: item,
  }));

export default renderMenu;
