import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Spin as spin } from 'antd';
import propTypes from 'prop-types';
import Icon from '../Icon';
import Themes from '../Themes';

const size = {
  small: 14,
  default: 24,
  large: 36,
};

const colors = {
  white: Themes.white,
  default: Themes['primary-color'],
};

const loadingAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const SpinWrapper = styled(spin)`
  .ant-spin-dot {
    width: auto;
    height: auto;
    animation: ${loadingAnimation} 1s linear infinite;
  }
`;
const Spin = (props) => (
  <SpinWrapper
    indicator={
      <Icon
        type='loading'
        color={colors[props.color || 'default']}
        size={size[props.size]}
      />
    }
    {...props}
  />
);
Spin.propTypes = {
  /** specifies a delay in milliseconds for loading state (prevent flush) */
  delay: propTypes.number,
  /** React node of the spinning indicator */
  indicator: propTypes.element,
  /** size of Spin, options: small, default and large */
  size: propTypes.oneOf(['small', 'default', 'large']),
  /** whether Spin is spinning */
  spinning: propTypes.bool,
  /** customize description content when Spin has children */
  tip: propTypes.string,
  /** className of wrapper when Spin has children */
  wrapperClassName: propTypes.string,
  color: propTypes.oneOf(Object.keys(colors)),
};

Spin.defaultProps = {
  size: 'default',
};

/** @component */
export default Spin;
