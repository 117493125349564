import React from 'react';
import { Icon, Themes } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';

const SearchButton = () => (
  <Button
    className='search-btn'
    type='text'
    htmlType='submit'
    aria-label='submit'
    size='middle'
  >
    <Icon type='icon-search2' size={16} color={Themes.I90} />
  </Button>
);

export default SearchButton;
