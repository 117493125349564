import React from 'react';
import { Icon, Themes } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import { useMobileNavbar } from 'components/NavbarSearch/_context/NavbarMobileProvider';
import { useNavigationContext } from 'components/Navigation/_context/NavigationProvider';
import './_styles/index.less';

const SearchIcon = () => {
  const { toggleSearchVisible } = useMobileNavbar();
  const { searchAction } = useNavigationContext();

  return (
    <Button
      type='text'
      className='search-icon'
      onClick={searchAction || toggleSearchVisible}
      aria-label='search'
    >
      <Icon
        iconClassName='m--0'
        type='magnifying-glass'
        color={Themes.indigo}
        size={20}
      />
    </Button>
  );
};

export default SearchIcon;
