import React from 'react';
import styled, { css } from 'styled-components';
import { Alert as alert } from 'antd';
import PropTypes from 'prop-types';
import icon from '../Icon';
import themes from '../Themes';

const Icon = styled(icon)`
  &&& {
    line-height: 16px;
  }
`;

const BannerWrapper = styled(alert)`
  & {
    margin: 0 auto;
    align-items: center;

    &.ant-alert {
      padding: ${themes['padding-md']};
      line-height: 22px;
    }

    .ant-alert-message {
      font-size: ${themes['font-size-base']};
      font-family: ${themes['font-sans-roman']};
      color: ${themes.indigo};
    }

    .ant-alert-icon {
      align-self: start;
      line-height: 19px;
      margin-right: ${themes['padding-md']};
    }

    &.ant-alert.ant-alert-closable {
      padding-right: 48px;
    }

    .ant-alert-close-icon {
      align-self: start;
      top: ${themes['padding-md']};
      font-size: ${themes['text-4-size']};
      line-height: ${themes['text-4-size']};
    }

    a.ant-btn {
      height: inherit;
      line-height: inherit !important;

      span {
        text-decoration: underline;
      }
    }
  }

  ${(props) =>
    props &&
    props.type === 'success' &&
    css`
      & {
        &.ant-alert-success {
          background: ${themes['light-green']};
        }

        &.ant-alert-success .ant-alert-icon {
          color: ${themes['accent-green']};
        }
      }
    `};

  ${(props) =>
    props &&
    props.type === 'error' &&
    css`
      background: ${themes['light-red']};
      &.ant-alert-success .ant-alert-icon {
        color: ${themes['accent-green']};
      }
    `};

  ${(props) =>
    props &&
    props.type === 'gold' &&
    css`
      background: ${themes.gold};
      color: ${themes.indigo};
      &.ant-alert-success .ant-alert-icon {
        color: ${themes.indigo};
      }
    `};

  ${(props) =>
    props &&
    props.type === 'gold-medium' &&
    css`
      background: ${themes['gold-medium']};
      color: ${themes.indigo};
      &.ant-alert-success .ant-alert-icon {
        color: ${themes.indigo};
      }
    `};

  ${(props) =>
    props &&
    props.type === 'magnolia' &&
    css`
      background: ${themes.magnolia};
    `};

  ${(props) =>
    props &&
    props.type === 'disclaimer' &&
    css`
      background: ${themes.porcelain};
    `};

  ${(props) =>
    props &&
    props.type === 'info' &&
    css`
      background: ${themes.B10};
    `};
`;

const setColor = (type) => {
  switch (type) {
    case 'warning':
      return themes.gold;
    case 'error':
      return themes.red;
    case 'gold':
      return themes.indigo;
    case 'gold-medium':
      return themes.indigo;
    case 'info':
      return themes.B100;
    case 'magnolia':
      return themes['@I90'];
    default:
      return themes['accent-green'];
  }
};

const setIcon = (type) => {
  switch (type) {
    case 'success':
      return 'success-circle';
    case 'info':
      return 'info-fill';
    case 'magnolia':
      return 'camcorder';
    default:
      return 'icon-warning';
  }
};

const Banner = (props) => (
  <BannerWrapper
    banner
    icon={
      <Icon
        type={setIcon(props.type)}
        theme='filled'
        size='1.25rem'
        color={setColor(props.type)}
      />
    }
    {...props}
  />
);

Banner.propTypes = {
  type: PropTypes.oneOf([
    'info',
    'success',
    'warning',
    'error',
    'gold',
    'gold-medium',
  ]),
  closable: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Banner.defaultProps = {
  showIcon: true,
};
export default Banner;
