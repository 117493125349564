import client from './client';

export const getSuggestedProceduresApi = ({ params }) =>
  client.post('/api/publicSearch/getProcedureByText', params);

export const getMDSaveAverageApi = ({ params }) =>
  client.post('/api/procedurePage/getMDSaveAverage', params);

export const getProcedureApi = ({ params }) =>
  client.post('/api/procedurePage/getProcedure', params);

export const getRelatedProceduresApi = ({ params }) =>
  client.get('/api/procedurePage/getRelatedProcedures', {
    params: { ...params },
  });

export const getGetAverageRatingApi = ({ params }) =>
  client.post('/api/procedurePage/getAverageRating', params);

export const getProcedureSurveysApi = ({ params }) => {
  const { showMoreClickCount, procedureId } = params;
  return client.post('/api/procedurePage/getProcedureSurveys', {
    procedureId,
    showMoreClickCount,
  });
};

export const getProvidersListApi = ({ params }) =>
  client.post('/api/procedurePage/getProvidersList', params);

export const getStructuredDataApi = ({ params }) =>
  client.get(
    `/api/procedurePage/structuredData?procedureId=${params.procedureId}`
  );
