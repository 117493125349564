import React, { useReducer } from 'react';
import { Form } from 'md-styled-components';
import propTypes from 'prop-types';
import LoginSection from './_partials/LoginSection';
import LoginLinkSection from './_partials/LoginLinkSection';
import { loginReducer, initialState, init } from './_reducer';
import './styles/index.less';

const Login = ({ data }) => {
  const [form] = Form.useForm();
  const [state, dispatch] = useReducer(loginReducer, initialState, init);
  const { options, onSubmitLogin } = data;

  const loginTypes = {
    LOGIN_SECTION: (
      <LoginSection
        {...{
          form,
          dispatch,
          onSubmitLogin,
          state,
          options,
        }}
      />
    ),
    LOGIN_LINK_SECTION: <LoginLinkSection {...{ form, dispatch, options }} />,
  };

  return loginTypes[state.loginType];
};

Login.propTypes = {
  data: propTypes.object.isRequired,
};

export default Login;
