import React from 'react';
import styled from 'styled-components';
import { Modal as modal } from 'antd';
import propTypes from 'prop-types';
import Themes from '../Themes';
import Icon from '../Icon';

const Modal = styled(modal)`
  .ant-modal {
    &-title {
      padding: ${(props) => props.title && '18px 0 0 24px'};
    }

    &-header {
      border-bottom-color: ${Themes.I20};
    }

    &-footer {
      border-top-color: ${Themes.I20};
    }

    &-content {
      border-radius: 0;
      padding: 0;
      box-shadow: ${Themes['box-shadow-light']};
      font-size: ${Themes['text-4-size']};
      color: ${Themes.I90};
      line-height: 1.5;
      font-family: ${Themes['font-sans-roman']};
    }

    &-body {
      padding: 24px;
    }

    &-close {
      width: 56px;
      height: 56px;
      top: 0;
      right: 0;
      background: transparent !important;
      font-weight: ${Themes['font-weight-xbold']};

      &-x {
        display: block;
        width: inherit;
        height: inherit;
        font-size: ${Themes['text-5-size']};
        line-height: 56px;
      }
    }
  }
`;

Modal.propTypes = {
  /** Specify a function that will be called when modal is closed completely */
  afterClose: propTypes.func,
  /** Body style for modal body element. Such as height, padding etc. */
  bodyStyle: propTypes.object,
  /** Text of the Cancel button */
  cancelText: propTypes.oneOfType([propTypes.node, propTypes.string]),
  /** Centered Modal */
  centered: propTypes.bool,
  /** Whether a close (x) button is visible on top right of the modal dialog or not */
  closable: propTypes.bool,
  /** Whether to apply loading visual effect for OK button or not */
  confirmLoading: propTypes.bool,
  /** Whether to unmount child components on onClose */
  destroyOnClose: propTypes.bool,
  /** Footer content, set as footer={null} when you don't need default buttons */
  footer: propTypes.oneOfType([propTypes.node, propTypes.string]),
  /** Force render Modal */
  forceRender: propTypes.bool,
  /** Return the mount node for Modal */
  getContainer: propTypes.instanceOf(HTMLElement),
  /** Whether show mask or not */
  mask: propTypes.bool,
  /** Whether to close the modal dialog when the mask (area outside the modal) is clicked */
  maskClosable: propTypes.bool,
  /** Style for modal's mask element */
  maskStyle: propTypes.object,
  /** Text of the OK button */
  okText: propTypes.oneOfType([propTypes.node, propTypes.string]),
  /** Button type of the OK button */
  okType: propTypes.string,
  /** The ok button props */
  okButtonProps: propTypes.object,
  /** The cancel button props */
  cancelButtonProps: propTypes.object,
  /** Style of floating layer, typically used at least for adjusting the position */
  style: propTypes.object,
  /** The modal dialog's title */
  title: propTypes.oneOfType([propTypes.node, propTypes.string]),
  /** Whether the modal dialog is visible or not */
  open: propTypes.bool,
  /** Width of the modal dialog */
  width: propTypes.oneOfType([propTypes.number, propTypes.string]),
  /** The class name of the container of the modal dialog */
  wrapClassName: propTypes.string,
  /** The z-index of the Modal */
  zIndex: propTypes.number,
  /** Specify a function that will be called when a user clicks mask, close button on top right or Cancel button */
  onCancel: propTypes.func,
  /** Specify a function that will be called when a user clicks the OK button */
  onOk: propTypes.func,
};

Modal.defaultProps = {
  maskStyle: { backgroundColor: Themes.overlay },
  closeIcon: (
    <Icon color={Themes.I90} type='cancel' size={Themes['text-2-size']} />
  ),
};

/** @component */
export default Modal;
