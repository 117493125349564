import { useTrackEvent } from 'components/Analytics/useAnalytics';
import { getProcedureAnalytics } from 'utils/ecommerce';
import { getAllItems, getProcedures } from 'selectors/shopping-cart';
import { Track } from 'components/Analytics/Track';
import { useSelector } from 'react-redux';

export const useChangeProductQuantityTrack = () => {
  const totalData = useSelector(getAllItems);
  const procedures = useSelector(getProcedures);
  useTrackEvent(() => {
    if (totalData.status === 1) {
      let analyticsData = null;
      procedures.forEach((procedure, index) => {
        const productId =
          procedure.productId || procedure.productid || procedure.encoded_id;
        if (!analyticsData && procedure.id === totalData.changedProcedureId) {
          analyticsData = getProcedureAnalytics({
            productId,
            category: procedure.category,
            name: procedure.procedure,
            brand: procedure.brand,
            variant: procedure.medicalProvider,
            price: procedure.total_price,
            position: index + 1,
            mpprPrice: procedure.mppr_price,
            promotionalDiscount: procedure.promotionalDiscount,
            quantity: procedure.quantity,
            affiliation: procedure.affiliation,
          });
          Track('Change Product Quantity', analyticsData);
        }
      });
    }
  }, [procedures]);
};
