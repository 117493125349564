import React from 'react';
import { InputNumber as Component } from 'antd';
import styled from 'styled-components';
import themes from 'md-styled-components/Themes';
import useDisableScrollOnWheel from './useDisableScrollOnWheel';
import { defaultStyles } from './Input';

const StyledInputNumber = styled(Component)`
  height: ${themes['input-height-base']} !important;
  ${defaultStyles}
`;

const Number = React.forwardRef(({ allowClear, ...props }, ref) => {
  useDisableScrollOnWheel();

  return <StyledInputNumber {...props} ref={ref} />;
});

export default Number;
