import React from 'react';
import { Typography, Cascader } from 'md-styled-components';
import { useCompanyPricingGroupSwitch } from 'hooks/Company';
import { useViewCompaniesForCascader } from './_hooks';

const { Paragraph } = Typography;

const CompanyCascader = () => {
  const { title, option, fieldNames, defaultValue, changeValue } =
    useViewCompaniesForCascader();
  const onSwitch = useCompanyPricingGroupSwitch();

  return (
    <Cascader
      options={option}
      popupClassName='portal-user-banner__dropdown-overlay'
      getPopupContainer={(trigger) => trigger.parentNode}
      fieldNames={fieldNames}
      defaultValue={defaultValue}
      title={title}
      onChange={(item) => onSwitch(changeValue(item))}
    >
      <Paragraph
        level={3}
        color='linkColor'
        className='ml--16 relative pointer'
      >
        Change
      </Paragraph>
    </Cascader>
  );
};

export default CompanyCascader;
