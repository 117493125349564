import React from 'react';
import { NavigationMenu } from 'md-styled-components-v2';
import Title from './Title';
import Info from '../Info';
import '../_styles/contact-us-info.less';

const { SubMenu, Item: MenuItem } = NavigationMenu;

const ContactUsSubMenu = () => (
  <NavigationMenu mode='inline'>
    <SubMenu title={<Title />} className='contact-us-info__submenu'>
      <MenuItem key='1' className='contact-us-info__submenu-item mv--16'>
        <Info />
      </MenuItem>
    </SubMenu>
  </NavigationMenu>
);

export default ContactUsSubMenu;
