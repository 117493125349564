import React from 'react';
import {
  Themes as theme,
  Tooltip,
  Icon,
  Typography,
} from 'md-styled-components';
import propTypes from 'prop-types';
import { shoppingCart } from '../../constants';

const { Text } = Typography;

const SameSessionDiscount = ({
  containerClassName = '',
  txtWrapperClassName,
}) => (
  <div className={containerClassName}>
    <Tooltip
      title={shoppingCart.SAME_SESSION_DISCOUNT}
      placement='bottom'
      overlayClassName='shopping-cart__tooltip'
    >
      <div className={txtWrapperClassName}>
        <Text level={2} color='danger' className='mr--4'>
          Same session discount applied
        </Text>
        <Icon size={14} type='info' color={theme.red} />
      </div>
    </Tooltip>
  </div>
);

SameSessionDiscount.propTypes = {
  containerClassName: propTypes.string,
  txtWrapperClassName: propTypes.string,
};

export default React.memo(SameSessionDiscount);
