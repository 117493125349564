import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Themes, Icon } from 'md-styled-components';
import { Link, Button } from 'md-styled-components-v2';
import { getIsGuestUser } from 'selectors/users';
import SignOutConfirm from 'components/Modals/SignOutConfirm';
import './_styles/provider-authorization.less';

const ProviderAuthorization = ({ isPortalUser = false }) => {
  const [visibleSignOut, setVisibleSignOut] = useState(false);
  const isGuest = useSelector(getIsGuestUser);

  return !isPortalUser ? (
    <>
      {isGuest ? (
        <div className='flex-display flex-align-items-center provider-authorization__item'>
          <Icon type='icon-profile2' size={20} color={Themes.I90} />
          <Link
            to='/signin'
            size='large'
            className='provider-authorization__link ml--8 ml-sm--24 ml-md--24'
          >
            Provider Log In
          </Link>
        </div>
      ) : (
        <Button
          type='text'
          className='provider-authorization__button pointer'
          onClick={() => setVisibleSignOut(true)}
        >
          <Icon
            type='icon-profile2'
            className='mr-sm--16 mr-md--16'
            size={20}
            color={Themes.I90}
          />
          Sign Out
        </Button>
      )}
      <SignOutConfirm
        visibleSignOut={visibleSignOut}
        setVisibleSignOut={setVisibleSignOut}
      />
    </>
  ) : null;
};

export default ProviderAuthorization;
