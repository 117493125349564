import React, { useState } from 'react';
import { Divider, Drawer, Icon } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import { useSelector } from 'react-redux';
import { getIsGuestUser } from 'selectors/users';
import UserSection from 'components/Navigation/UserSection';
import ContactUs from 'components/Navigation/ContactUs';
import SignOut from 'components/Navigation/UserSection/UserInfo/SignOut';
import ShoppingCartButton from 'components/Navigation/ShoppingCart/ShoppingCartButton';
import ConsumerLogo from '../../_partials/Logo';
import Item from '../../_partials/Item';
import Menu from './Menu';

const DrawerComponent = () => {
  const [visible, setVisible] = useState(false);
  const isGuest = useSelector(getIsGuestUser);

  return (
    <>
      <Button
        type='text'
        aria-label='side menu'
        onClick={() => setVisible(true)}
      >
        <Icon size={20} type='menu' />
      </Button>
      <Drawer
        placement='left'
        onClose={() => setVisible(false)}
        open={visible}
        width='256px'
        closable={false}
        bodyStyle={{ padding: '24px 0', width: '100%' }}
      >
        <ConsumerLogo blockClass='ml--24 mb--24' iconSize={44} />
        <Menu />
        <Divider className='mv--8' />
        <UserSection />
        <Item>
          <ShoppingCartButton inDrawer />
        </Item>
        <Divider className='mv--8' />
        <Item>
          <ContactUs type='list' />
        </Item>
        {!isGuest && (
          <Item>
            <SignOut withIcon />
          </Item>
        )}
      </Drawer>
    </>
  );
};

export default DrawerComponent;
