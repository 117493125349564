import propTypes from 'prop-types';
import HospitalInfo from './HospitalInfo';
import ContactUs from './ContactUs';

const Hospitals = ({ groupId }) => (
  <>
    <HospitalInfo groupId={groupId} />
    <div className='flex-display flex-align-items-center'>
      <ContactUs />
    </div>
  </>
);

Hospitals.propTypes = {
  groupId: propTypes.string,
};

export default Hospitals;
