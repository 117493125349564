import React from 'react';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';

/** @type {HTMLImageElement} */
const ImgWrapper = styled.img`
  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth} !important;
    `};
  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight} !important;
    `};
  position: ${(props) => props.position};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  top: ${(props) => props.top};
  ${(props) =>
    props.responsive &&
    `
    display: block;
    max-width: 100%;
    height: auto;
  `};
`;

/** @type {function(Partial<HTMLImageElement>): ReactNode} */
const Img = (props) => <ImgWrapper {...props} />;

Img.propTypes = {
  maxWidth: propTypes.string,
  position: propTypes.string,
  retina: propTypes.bool,
  left: propTypes.any,
  right: propTypes.any,
  bottom: propTypes.any,
  top: propTypes.any,
  responsive: propTypes.bool,
};

Img.defaultProps = {
  position: 'static',
  retina: false,
  responsive: false,
};

export default Img;
