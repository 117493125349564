import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import SearchAutocomplete from 'components/HeroSearch/SearchAutocomplete';
import {
  getGroupsGroup,
  getPageGroup,
  getPageGroupSystem,
  getSystem,
} from 'selectors/support';
import { getPrivateHospitalData } from 'selectors/privateHospitalData';
import { isSystem } from 'utils/groupSystemHelpers';
import { getSesStorageItem, getSesStorageItemKey } from 'utils/storage';

const getPageInstanceGroup = (group, groupSystem, pageGroup, system) =>
  (!isSystem(group) && group) || groupSystem || pageGroup || system;

export default () => {
  const [inputValue, setInputValue] = useState('');
  const [autocompleteClass, setAutocompleteClass] = useState('');
  const group = useSelector(getGroupsGroup);
  const system = useSelector(getSystem);
  const pageGroup = useSelector(getPageGroup);
  const groupSystem = useSelector(getPageGroupSystem);
  const { privateHospitalSlug } = useSelector(getPrivateHospitalData);

  const callback = useCallback(
    (props) => {
      const key = privateHospitalSlug
        ? `${privateHospitalSlug}-${getSesStorageItemKey(props.name)}`
        : getSesStorageItemKey(
            props.name,
            getPageInstanceGroup(group, groupSystem, pageGroup),
            system
          );
      const sesStorageItem = getSesStorageItem(key);

      return (
        <SearchAutocomplete
          inputValue={inputValue}
          setInputValue={setInputValue}
          autocompleteClass={autocompleteClass}
          setAutocompleteClass={setAutocompleteClass}
          querySearchValue={sesStorageItem}
          {...props}
        />
      );
    },
    [
      group,
      pageGroup,
      system,
      groupSystem,
      privateHospitalSlug,
      inputValue,
      setInputValue,
      autocompleteClass,
      setAutocompleteClass,
    ]
  );

  return {
    resetSearch: () => setInputValue(''),
    renderAutoComplete: callback,
    inputValue,
    autocompleteClass,
    setAutocompleteClass,
  };
};
