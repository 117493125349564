export const getProcedureAnalytics = ({
  productId,
  category,
  name,
  brand,
  variant,
  price,
  position,
  affiliation,
  quantity,
  mpprPrice,
  promotionalDiscount = 0,
}) => {
  if (!quantity) quantity = 1;
  const data = {
    product_id: productId,
    category,
    name,
    brand,
    variant,
    price: price * quantity,
    position: position || 0,
    affiliation,
    quantity,
    category2: mpprPrice ? 'MPPR' : 'Regular',
    discount: mpprPrice
      ? (price - mpprPrice) * quantity
      : promotionalDiscount * quantity,
  };
  return { products: [{ ...data }] };
};

export const getAnalyticsList = (procedures, chunkPosition = 0) => {
  const data = procedures.map(
    (procedure, index) =>
      getProcedureAnalytics({
        ...procedure,
        productId:
          procedure.productId || procedure.productid || procedure.encoded_id,
        position: index + 1 + chunkPosition,
      }).products[0]
  );
  return { products: data };
};
