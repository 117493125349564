import React from 'react';
import { Form, Input } from 'md-styled-components-v2';
import propTypes from 'prop-types';
import ResetPasswordForm from './ResetPasswordForm';
import HiddenInput from './HiddenInput';

const { Password } = Input;

const LoginForm = ({ options }) => (
  <>
    <Form.Item
      className={options.formPassword.className}
      {...(options.formPassword.label && { label: options.formPassword.label })}
      name='password'
      rules={[{ required: true, message: 'Password cannot be blank.' }]}
      hasFeedback
    >
      <Password
        type='password'
        name='RegisterPartForm[password]'
        {...(options.formPassword.placeholder && {
          placeholder: options.formPassword.placeholder,
        })}
      />
    </Form.Item>
    <Form.Item className='mb--16'>
      <ResetPasswordForm />
    </Form.Item>
    <HiddenInput />
  </>
);

LoginForm.propTypes = {
  options: propTypes.object.isRequired,
};

export default LoginForm;
