import React from 'react';
import { Button as AntButton } from 'antd';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import Theme from 'md-styled-components/Themes';
import Icon from 'md-styled-components/Icon';
import primaryStyles from './_types/primary';
import tertiaryStyles from './_types/tertiary';
import secondaryStyles from './_types/secondary';
import dangerousStyles from './_types/dangerous';
import dangerousTertiaryStyles from './_types/dangerousTertiary';
import ghostStyles from './_types/ghost';
import textStyles from './_types/text';

const sizes = {
  small: 7,
  middle: 9,
  large: 14,
};

// iconSize is added for managing problematic icons (e.g. icons with undesired paddings)
const Button = styled(({ iconType, iconSize, ...props }) => (
  <AntButton
    {...props}
    icon={
      iconType ? (
        <Icon type={iconType} color={Theme.white} size={iconSize} />
      ) : null
    }
  />
))`
  &&.ant-btn {
    padding: ${(props) => sizes[props.size]}px 16px;
    -webkit-transition: none;
    transition: none;

    &-link {
      color: ${Theme.astral};

      &:hover {
        color: ${Theme['blue-picton']};
      }
    }

    svg {
      margin-right: 6px;
    }
  }

  font-family: ${Theme['font-sans-black']};
  font-size: ${Theme['font-size-base']} !important;
  line-height: 22px;
  border-radius: 4px !important;
  border: none;
  height: auto !important;

  ${(props) =>
    props.type === 'primary' &&
    css`
      ${primaryStyles}
    `}

  ${(props) =>
    props.type === 'secondary' &&
    css`
      ${secondaryStyles}
    `}

  ${(props) =>
    props.type === 'text' &&
    css`
      ${textStyles}
    `}

  ${(props) =>
    props.type === 'tertiary' &&
    css`
      ${tertiaryStyles}
    `}

  ${(props) =>
    props.type === 'dangerous' &&
    css`
      ${dangerousStyles}
    `}

  ${(props) =>
    props.type === 'dangerous-tertiary' &&
    css`
      ${dangerousTertiaryStyles}
    `}

  ${(props) =>
    props.type === 'ghost' &&
    css`
      ${ghostStyles}
    `}
`;

Button.propTypes = {
  // can be set to primary, secondary, tertiary, dangerous, dangerous-tertiary, ghost, text, link
  type: propTypes.string,
  // can be set to small, middle, large
  size: propTypes.string,
  // iconType is passed to Icon component as it's type
  iconType: propTypes.string,
  loading: propTypes.bool,
  onClick: propTypes.func,
  iconSize: propTypes.number,
};

Button.defaultProps = {
  size: 'large',
  type: 'primary',
  iconSize: 16,
};

/** @component */
export default Button;
