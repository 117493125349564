import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { matchEmptySpaces } from 'utils/stringHelper';
import { common } from 'constants/index';
import { getGoodFaithEnabled } from 'selectors/shopping-cart';
import { requestGetPatientList, requestSaveCart } from 'actions/patients';
import { isGroupPage } from 'utils/pages';

const { searchMinLength } = common;

export const useInputChange = () => {
  const dispatch = useDispatch();
  const [patientSearchText, setPatientSearchText] = useState('');

  const searchByText = ({ e, setInputValue }) => {
    const { value } = e.target;
    if (matchEmptySpaces(value)) {
      return;
    }
    setInputValue(value);

    if (!value || value.length < searchMinLength) {
      dispatch(
        requestGetPatientList.actions.success(
          {
            params: {},
          },
          false
        )
      );
      dispatch(requestGetPatientList.cancel());
      return;
    }
    const data = {
      data: value,
      getPatient: 'yes',
    };
    setPatientSearchText(value);
    if (value.length >= searchMinLength) {
      dispatch(requestGetPatientList.request(data));
    }
  };

  return { patientSearchText, searchByText };
};

export const useOnSelect = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const isGoodFaithEnabled = useSelector(getGoodFaithEnabled);

  const selectPatient = useCallback(
    ({
      selectedOption,
      setInputValue,
      createNewPatient,
      setSendData,
      labelKey,
    }) => {
      const { data } = selectedOption;
      if (data) {
        setInputValue(data[labelKey]);

        if (isGoodFaithEnabled) {
          createNewPatient(null, data);
          return;
        }
        const requestData = {
          data: {
            ehr_number: data.ehr_number || null,
            isChange: false,
            isPublicPage: !isGroupPage(location),
            token: data.token || null,
            user: data,
            user_id: data.id,
          },
          groupId: searchParams.get('groupId') || '',
        };
        setSendData(requestData);
        dispatch(requestSaveCart.request(requestData));
      }
    },
    [isGoodFaithEnabled]
  );

  return selectPatient;
};
