import React from 'react';
import { Dropdown, Typography, Icon, Themes } from 'md-styled-components';
import Overlay from './Overlay';
import '../_styles/contact-us.less';

const { Text } = Typography;

const ContactUsDropdown = () => (
  <Dropdown
    className='contact-us__dropdown pointer'
    overlayClassName='contact-us__dropdown-overlay'
    placement='bottomRight'
    trigger={['hover']}
    dropdownRender={() => <Overlay />}
    getPopupContainer={(trigger) => trigger.parentNode}
  >
    <div className='contact-us__dropdown-title flex-display flex-align-items-center'>
      <Text level={3} className='contact-us__dropdown-title--active'>
        Contact Us
      </Text>
      <Icon
        size={12}
        color={Themes.indigo}
        type='chevron-down'
        className='contact-us__dropdown-icon ml--8 mb--2'
      />
    </div>
  </Dropdown>
);

export default ContactUsDropdown;
