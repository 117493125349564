import { Button as AntButton } from 'antd';
import Icon from 'md-styled-components/Icon';
import Theme from 'md-styled-components/Themes';
import propTypes from 'prop-types';
import styled, { css } from 'styled-components';
import buttonCommonStyles from './_types/common';
import dangerousStyles from './_types/dangerous';
import dangerousTertiaryStyles from './_types/dangerousTertiary';
import ghostStyles from './_types/ghost';
import linkStyles from './_types/link';
import primaryStyles from './_types/primary';
import secondaryStyles from './_types/secondary';
import simpleStyles from './_types/simple';
import tertiaryStyles from './_types/tertiary';
import textStyles from './_types/text';

const sizes = {
  small: 7,
  middle: 9,
  large: 14,
};

// icon is taken for preventing it's usage, instead use iconType prop
// iconSize is added for managing problematic icons (e.g. icons with undesired paddings)
const Button = styled(({ icon, iconType, iconSize, underline, ...props }) =>
  props.type === 'simple' ? (
    <button {...props} />
  ) : (
    <AntButton
      icon={
        iconType ? (
          <Icon type={iconType} color={Theme.white} size={iconSize} />
        ) : null
      }
      {...props}
    />
  )
)`
  ${(props) =>
    props.size &&
    props.type !== 'simple' &&
    css`
      ${buttonCommonStyles(sizes[props.size])}
    `}

  ${(props) =>
    props.type === 'simple' &&
    css`
      ${simpleStyles}
    `}

  ${(props) =>
    props.breakSpaces &&
    css`
      white-space: normal !important;
      text-align: left;
    `}

  ${(props) =>
    props.type === 'primary' &&
    css`
      ${primaryStyles}
    `}

  ${(props) =>
    props.type === 'secondary' &&
    css`
      ${secondaryStyles}
    `}

  ${(props) =>
    props.type === 'tertiary' &&
    css`
      ${tertiaryStyles}
    `}

  ${(props) =>
    props.type === 'dangerous' &&
    css`
      ${dangerousStyles}
    `}

  ${(props) =>
    props.type === 'dangerous-tertiary' &&
    css`
      ${dangerousTertiaryStyles}
    `}

  ${(props) =>
    props.type === 'ghost' &&
    css`
      ${ghostStyles}
    `}

  ${(props) =>
    props.type === 'text' &&
    css`
      ${textStyles}
    `}

  ${(props) =>
    props.type === 'link' &&
    css`
      ${linkStyles}
      ${props.underline &&
      css`
        && span {
          text-decoration: underline;
        }
      `}
    `}

  ${(props) =>
    props.loading &&
    css`
      .ant-btn-loading-icon {
        width: 16px !important;
        display: inline-block;
        margin-left: -16px;

        + span {
          transform: translate(8px, 0);
        }

        .anticon {
          padding-right: 0;
        }
      }
    `}
  ${(props) =>
    !props.loading &&
    css`
      .ant-btn-loading-icon {
        display: none;
      }
    `}
`;

Button.propTypes = {
  // can be set to primary, secondary, tertiary, dangerous, dangerous-tertiary, ghost, text, link
  type: propTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'dangerous',
    'dangerous-tertiary',
    'ghost',
    'text',
    'link',
    'simple',
  ]),
  // can be set to small, middle, large
  size: propTypes.string,
  // iconType is passed to Icon component as it's type
  iconType: propTypes.string,
  icon: propTypes.node,
  // 'start' or 'end'
  iconPosition: propTypes.string,
  loading: propTypes.bool,
  onClick: propTypes.func,
  iconSize: propTypes.number,
  underline: propTypes.bool,
};

Button.defaultProps = {
  size: 'middle',
  type: 'primary',
  iconSize: 16,
  underline: false,
  shape: 'round',
};

/** @component */
export default Button;
