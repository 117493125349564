import React, { useState, Suspense, lazy } from 'react';
import {
  DynamicList as List,
  Divider,
  Typography,
  Themes,
} from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getIsCmsUser } from 'selectors/users';
import SignOutConfirm from 'components/Modals/SignOutConfirm';
import { useIsDesktop } from 'hooks';
import LeaderBoard from './LeaderBoard/LeaderBoard';
import LeaderBoardContext from './LeaderBoard/_context';

const LeaderBoardModal = lazy(() => import('./LeaderBoard/LeaderBoardModal'));

const { Paragraph, Text } = Typography;

const UserOverlay = ({ tabsByUserType, currentUser }) => {
  const [visibleSignOut, setVisibleSignOut] = useState(false);
  const [isLeaderBoardModalVisible, setIsLeaderBoardModalVisible] =
    useState(false);
  const [dateType, setDateType] = useState(1);
  const isDesktop = useIsDesktop();
  const isCmsUser = useSelector(getIsCmsUser);

  const list = (item, date, showSignOut = false) => {
    switch (true) {
      case item.name === 'Leaderboard':
        return (
          <LeaderBoard
            dateType={date}
            onClickLeaderBoard={() => {
              setIsLeaderBoardModalVisible(true);
            }}
          />
        );
      case item.name === 'Sign Out':
        return !isDesktop || showSignOut || isCmsUser ? (
          <Button type='link' onClick={() => setVisibleSignOut(true)}>
            <Text color={Themes.I90}>Sign Out</Text>
          </Button>
        ) : null;
      default:
        return (
          <Button type='link' href={item.href}>
            <Text color={Themes.I90}>{item.name}</Text>
          </Button>
        );
    }
  };

  return (
    <>
      <div className='pv--16 ph--24'>
        <Paragraph level={1} className='mb--4'>
          {currentUser.fullName}
        </Paragraph>
        <Paragraph level={3} color='paleSky'>
          {currentUser.email}
        </Paragraph>
      </div>
      <Divider margin='0' />
      <div className='p--24'>
        <List
          noPadding
          className='direct-navbar__hospitals-overlay-list'
          dataSource={Object.values(tabsByUserType)}
          renderItem={(item, index) => {
            const listItem = list(item, dateType);
            return (
              listItem && (
                <div className={index !== 0 && 'pt--16'}>{listItem}</div>
              )
            );
          }}
        />
      </div>
      <SignOutConfirm
        visibleSignOut={visibleSignOut}
        setVisibleSignOut={setVisibleSignOut}
      />
      <LeaderBoardContext dateType={dateType} setDateType={setDateType}>
        <Suspense fallback={<></>}>
          {' '}
          {isLeaderBoardModalVisible && (
            <LeaderBoardModal
              visibleLeaderBoardModal={isLeaderBoardModalVisible}
              cancelLeaderBoardModal={setIsLeaderBoardModalVisible}
            />
          )}
        </Suspense>
      </LeaderBoardContext>
    </>
  );
};

UserOverlay.propTypes = {
  currentUser: propTypes.object.isRequired,
  tabsByUserType: propTypes.array.isRequired,
};

export default React.memo(UserOverlay);
