import React from 'react';
import { Modal, Themes, Typography } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import { useBreakpoint } from '../../hooks';
import './index.less';

const { Title, Paragraph } = Typography;

export default ({
  visible,
  onCancel,
  onSuccess,
  buttonType,
  cancelButtonText,
  successButtonText,
  text,
  title,
  buttonClassName,
  horizontalPadding = 11,
  closable = false,
  buttonLoading = false,
  ...props
}) => {
  const isMobile = useBreakpoint(Themes.breakpoints.xs, Themes.breakpoints.sm);

  return (
    <Modal
      open={visible}
      centered
      width={isMobile ? '100%' : '480px'}
      onCancel={onCancel}
      footer={null}
      closable={closable}
      className='default-modal'
    >
      {title && (
        <Title level={5} className='text-center pt--8 mb--16'>
          {title}
        </Title>
      )}
      <Paragraph className='text-center' color='grey'>
        {text}
      </Paragraph>
      <div className='flex-display flex-justify-center flex-align-items-center pt--24'>
        <Button onClick={onCancel} type='link'>
          <Paragraph className='mr--32' color='grey'>
            {cancelButtonText}
          </Paragraph>
        </Button>
        <Button
          {...props}
          type={buttonType}
          shape='round'
          size='small'
          loading={buttonLoading}
          className={`default-modal__${buttonClassName}`}
          onClick={onSuccess}
        >
          {successButtonText}
        </Button>
      </div>
    </Modal>
  );
};
