import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMobile as isMobileDevice } from 'react-device-detect';
import { Themes, Icon } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import {
  setSearchOptions,
  useSearchOptions,
} from 'pages/System/_partials/Search';
import { useGetParamByKey } from 'hooks/paginationUrl';
import {
  clearSuggestedProcedures,
  requestSuggestedProcedures,
} from 'actions/procedures';
import { suggestedProcedures } from 'selectors/procedures';
import {
  getProcedures,
  getPageGroup,
  getPriceListGroupIds,
  getGroup,
  getSystemProperties,
  getPageGroupSystem,
} from 'selectors/support';
import { getProviderName } from 'selectors/transparency';
import { useBreakpoint as breakpoint, usePrevious } from 'hooks';
import { useSearchProcedure } from 'contexts/SearchProcedureProvider';
import { allProcedures } from 'constants/procedures';
import { useGetParams, recoverQuery } from 'utils/url';
import { isPrivateHospital } from 'utils/privateSite';
import { isSystem } from 'utils/groupSystemHelpers';
import { getSesStorageItem, getSesStorageItemKey } from 'utils/storage';
import { Search as SearchTabs } from 'components/SearchTabs/Search';
import { useMobileNavbar } from 'components/NavbarSearch/_context/NavbarMobileProvider';
import PriceListProcedureOptions from 'components/SearchTabs/_options/PriceListProcedureOptions';
import ProcedureInsuranceOptions, {
  redirectNoInsurance,
} from 'components/SearchTabs/_options/ProcedureInsuranceOptions';
import { usePagination } from '../context/PaginationProvider';

export const checkQueryName = (queryname) =>
  queryname && queryname !== allProcedures;

export const ClearButton = ({
  setInputValue,
  children,
  resetFields,
  underline = false,
  clearSuggested,
  setAutocompleteClass,
}) => {
  const navigate = useNavigate();
  const { changePage } = usePagination();
  const iconSize = breakpoint(Themes.breakpoints.xs, Themes.breakpoints.sm)
    ? 'text-1-size'
    : 'text-2-size';
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { restoreDefault } = useSearchProcedure();

  const doClear = (e) => {
    e.preventDefault();
    restoreDefault();
    setInputValue('');
    setAutocompleteClass('');
    dispatch(clearSuggested.request());
    changePage(1);
    resetFields && resetFields();
    navigate(pathname, { replace: true, state: { disableScroll: true } });
  };
  return (
    <Button
      type='link'
      onClick={doClear}
      underline={underline}
      className='clear-search'
    >
      {children || (
        <Icon color={Themes.I90} type='icon-cancel2' size={Themes[iconSize]} />
      )}
    </Button>
  );
};

const Search = ({
  procedureSearch,
  insuranceSearch,
  showTransparencyPrice,
  insuranceExists,
}) => {
  const { renderAutoComplete, inputValue, resetSearch, setAutocompleteClass } =
    procedureSearch;
  const {
    renderAutoComplete: insRenderAutoComplete,
    resetSearch: insResetSearch,
    setAutocompleteClass: insuranceSetAutocompleteClass,
  } = insuranceSearch;
  const { search, pathname } = useLocation();
  const { changePage } = usePagination();
  const { redirect } = PriceListProcedureOptions;
  const priceListOptions = useSearchOptions(
    insuranceExists,
    ProcedureInsuranceOptions,
    PriceListProcedureOptions
  );
  const prevLocPath = usePrevious(pathname);
  const dispatch = useDispatch();
  const mainData = useSelector(getProcedures);
  const priceListGroup = useSelector(getGroup);
  const systemProperties = useSelector(getSystemProperties);
  const insuranceSelectName = useSelector(getProviderName);
  const suggestedProcedure = useSelector(suggestedProcedures);
  const group = useSelector(getPageGroup);
  const isGroup = !isSystem(group);
  const priceListGroupIds = useSelector(getPriceListGroupIds);
  const groupSystem = useSelector(getPageGroupSystem);
  const isHospital = isPrivateHospital();
  const insSesStorageKey =
    priceListOptions.data[1] &&
    getSesStorageItemKey(
      priceListOptions.data[1].name,
      (isGroup && group) || groupSystem
    );
  const insSesStorageValue = getSesStorageItem(insSesStorageKey);

  const { searchVisible, toggleSearchVisible } = useMobileNavbar();
  const {
    setSearchData,
    setSearchText,
    setIsCptSearch,
    setItems,
    restoreDefault,
    setGroupData,
  } = useSearchProcedure();
  const isMobile = breakpoint(Themes.breakpoints.xs, Themes.breakpoints.sm);
  const isTablet = breakpoint(Themes.breakpoints.sm, Themes.breakpoints.md);

  const {
    p: procedureId,
    q: queryNameEncoded,
    specialty,
    iq: insuranceName,
  } = useGetParams();
  const queryName = queryNameEncoded && recoverQuery(queryNameEncoded);
  const prevQueryName = usePrevious(search);
  const prevInsuranceName = usePrevious(insuranceName);
  const [priceListData] = priceListOptions.data;
  const pageNumber = useGetParamByKey({ key: 'page' });

  const clearResult = useCallback(
    () => dispatch(clearSuggestedProcedures.request()),
    [dispatch]
  );
  const makeSearchData = useCallback(
    (dataList) => {
      if (queryName && !procedureId && queryName !== allProcedures) {
        redirect([
          {
            optionsSelected: false,
            search: { text: queryName },
            mainData,
            setSearchData,
            setSearchText,
            setIsCptSearch,
            clearPrevSearchResult: () => setItems(null),
            dataSource: dataList,
          },
        ]);
      }
    },
    [
      queryName,
      procedureId,
      mainData,
      setSearchData,
      setSearchText,
      setIsCptSearch,
      setItems,
      redirect,
    ]
  );

  useEffect(() => {
    if (suggestedProcedure.clear) {
      restoreDefault();
      setAutocompleteClass('');
      changePage(1);
    }
  }, [
    suggestedProcedure.clear,
    restoreDefault,
    changePage,
    setAutocompleteClass,
  ]);

  useEffect(() => {
    if (
      isHospital &&
      showTransparencyPrice &&
      !!search !== searchVisible &&
      !isMobile &&
      !isTablet
    ) {
      toggleSearchVisible();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTransparencyPrice, search, isMobile, isTablet]);

  useEffect(() => {
    if (prevQueryName && !search) {
      resetSearch();
      insuranceExists && !insSesStorageValue && insResetSearch();
      restoreDefault();
      clearResult();
      changePage(1);
    }
  }, [
    insSesStorageValue,
    prevQueryName,
    search,
    resetSearch,
    clearResult,
    restoreDefault,
    changePage,
    insuranceExists,
    insResetSearch,
  ]);

  useEffect(() => {
    if (
      !prevLocPath &&
      (specialty || queryName === allProcedures || !queryName)
    ) {
      resetSearch();
      insuranceExists && !insSesStorageValue && insResetSearch();
      changePage(pageNumber);
    }
  }, [
    insSesStorageValue,
    prevLocPath,
    specialty,
    queryName,
    resetSearch,
    changePage,
    insuranceExists,
    insResetSearch,
    pageNumber,
  ]);

  useEffect(() => {
    priceListData.customSearch = (searchData) => {
      if (!mainData) {
        return clearResult();
      }
      return requestSuggestedProcedures.request({
        group_id: priceListGroupIds,
        ...searchData,
      });
    };

    priceListData.mainData = mainData;
    priceListData.setSearchData = setSearchData;
    priceListData.setSearchText = setSearchText;
    priceListData.setIsCptSearch = setIsCptSearch;
    priceListData.changePage = () => changePage(1);
    priceListData.clearPrevSearchResult = () => setItems(null);
    if (isMobileDevice) {
      priceListData.onSelect = (id) => document.getElementById(id).blur();
    }

    return () => dispatch(clearResult());
  }, [
    priceListData,
    dispatch,
    mainData,
    group.id,
    setSearchData,
    setSearchText,
    setIsCptSearch,
    setItems,
    priceListGroupIds,
    clearResult,
    changePage,
  ]);

  useEffect(() => {
    if (queryName && queryName !== allProcedures) {
      dispatch(
        requestSuggestedProcedures.request({
          group_id: priceListGroupIds,
          data: queryName,
          count: 30,
          cbRedirect: makeSearchData,
        })
      );
    }
  }, [dispatch, procedureId, priceListGroupIds, queryName, makeSearchData]);

  useEffect(() => {
    if (queryName && procedureId) {
      const getItem =
        Array.isArray(mainData) &&
        mainData.find((el) => el.id === +procedureId);
      let searchName = queryName;
      setItems(null);
      if (getItem) {
        searchName = getItem.name;
        setSearchData([getItem]);
      }
      setSearchText(searchName);
      priceListData.querySearchValue = searchName;
      priceListData.search = { text: searchName };
    }
  }, [
    queryName,
    procedureId,
    mainData,
    priceListData,
    setSearchText,
    setSearchData,
    setItems,
  ]);

  useEffect(() => {
    setGroupData(priceListGroup);
  }, [priceListGroup, setGroupData]);

  useEffect(() => {
    if (queryName && !procedureId && queryName !== allProcedures) {
      priceListData.querySearchValue = queryName;
      priceListData.search = { text: queryName };
      priceListData.isQueryName = true;
    }
  }, [queryName, procedureId, priceListData]);

  useEffect(() => {
    if (insuranceName && insuranceExists && !insSesStorageValue) {
      priceListOptions.data[1].querySearchValue =
        insuranceSelectName || insuranceName;
      priceListOptions.data[1].search = {
        text: insuranceSelectName || insuranceName,
      };
    }
  }, [
    insuranceName,
    procedureId,
    priceListOptions.data,
    insuranceExists,
    insSesStorageValue,
    insuranceSelectName,
  ]);

  useEffect(() => {
    if (prevInsuranceName && !insuranceName) {
      insResetSearch();
    }
  }, [prevInsuranceName, insuranceName, insResetSearch]);

  setSearchOptions(priceListOptions.data[0], {
    inputValue,
    border: !isMobile,
    renderAutoComplete,
    setAutocompleteClass,
    slug: showTransparencyPrice
      ? (systemProperties && systemProperties.slug) ||
        (priceListGroup && priceListGroup.slug)
      : undefined,
  });

  if (showTransparencyPrice) {
    if (insuranceExists) {
      setSearchOptions(priceListOptions.data[1], {
        rounded: 'right',
        border: !isMobile,
        renderAutoComplete: insRenderAutoComplete,
        params: {
          groupId: isSystem(priceListGroupIds)
            ? priceListGroupIds
            : [priceListGroupIds],
        },
        setAutocompleteClass: insuranceSetAutocompleteClass,
      });
    } else {
      priceListOptions.redirect = redirectNoInsurance;
    }
  }

  return (
    <SearchTabs
      options={[priceListOptions]}
      showButton={breakpoint(Themes.breakpoints.sm) || showTransparencyPrice}
    />
  );
};

export default Search;
