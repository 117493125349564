import { BaseOptionType, DefaultOptionType } from 'antd/es/select';
import { Spin } from 'md-styled-components';
import { AutoComplete, Form, Input } from 'md-styled-components-v2';
import { useState } from 'react';
import { TODO } from 'types';
import { useInputChange } from './hooks';
import { renderMenu } from './renderMenu';

export interface InsuranceProviderProps {
  handleSelectProvider?: (value: string, item: TODO) => void;
  selectedProvider?: string;
  initialInputValue: string;
  hasValidate?: boolean;
  className?: string;
}

export function InsuranceProvider({
  handleSelectProvider,
  selectedProvider,
  hasValidate = false,
  initialInputValue = '',
  className = 'mb--0',
}: InsuranceProviderProps) {
  const [inputValue, setInputValue] = useState(initialInputValue);
  const { onInputChange, providersList, loading, isTyped } = useInputChange(
    setInputValue,
    handleSelectProvider
  );

  const emptyLabel =
    'Sorry, we could not find a match for that insurance provider';
  const noData = providersList !== null && isTyped && !providersList.length;
  const dataSource = (isTyped && providersList) || [];

  const onSelect = (
    value?: string,
    item?: BaseOptionType | DefaultOptionType
  ) => {
    if (value) {
      setInputValue(value);
      handleSelectProvider?.(value, item);
    }
  };

  return (
    <Form.Item
      label='Insurance Provider'
      className={className}
      name='insuranceProvider'
      validateTrigger='onBlur'
      validateFirst
      rules={
        hasValidate
          ? [
              {
                required: true,
                message: 'Insurance Provider cannot be blank.',
              },
              {
                validator(_, value) {
                  if (value && !selectedProvider) {
                    return Promise.reject(
                      new Error('Select an option from the dropdown.')
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]
          : []
      }
    >
      <AutoComplete
        id='provider_name'
        onSelect={onSelect}
        notFoundContent={loading ? <Spin /> : noData && emptyLabel}
        options={renderMenu(dataSource, inputValue)}
      >
        <Input
          onChange={onInputChange}
          data-testid='provider_name'
          className='phi'
        />
      </AutoComplete>
    </Form.Item>
  );
}
