import styled from 'styled-components';
import propTypes from 'prop-types';
import { List as list } from 'antd';

const DynamicList = styled(list)`
  .ant-list-item {
    ${(props) => !props.noPadding && 'padding: 16px 0;'}

    &-meta-avatar {
      margin-inline-end: 0 !important;
    }
  }

  .ant-list-empty-text {
    ${(props) => props.locale?.emptyText && 'padding: 0;'}
  }
`;

DynamicList.propTypes = {
  noPadding: propTypes.bool,
};

DynamicList.defaultProps = {
  noPadding: false,
};

/** @component */
export default DynamicList;
