import React, { memo, useEffect } from 'react';
import { useIsMobile } from 'hooks';
import { useDispatch } from 'react-redux';
import { requestCompanyUserCompanies } from 'actions/users';
import ContactUs from '../../../ContactUs';
import CompanyName from './CompanyName';
import './_styles/index.less';

const PortalUserBanner = () => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestCompanyUserCompanies.request());
  }, []);

  return (
    <div className='portal-user-banner flex-display flex-align-items-center flex-justify-space-between flex-justify-center--sm ph--32 relative'>
      <CompanyName />
      {!isMobile && (
        <div className='flex-display flex-align-items-center'>
          <ContactUs type='topDropdown' />
        </div>
      )}
    </div>
  );
};

export default memo(PortalUserBanner);
