import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header, Themes, Icon, Spin } from 'md-styled-components';
import { useDispatch } from 'react-redux';
import { useBreakpoint as breakpoint } from 'hooks';
import { common } from 'constants/index';
import { searchOnBlur } from 'utils/common';
import { matchEmptySpaces } from 'utils/stringHelper';

const { RoundedInput, AutoComplete } = Header;

const xsScreenSize = Themes.breakpoints.xs;
const mdScreenSize = Themes.breakpoints.md;

export default React.memo(
  React.forwardRef(
    (
      {
        id,
        onChange,
        optionSelected,
        dataSource = [],
        notFound,
        loading,
        iconType,
        placeholder,
        emptyLabel,
        fieldName,
        border = '',
        rounded = '',
        labelKey,
        renderMenu,
        takeFirstOnEnter,
        querySearchValue,
        inputValue,
        setInputValue,
        onSelect,
        resetForm,
        inputClassName,
        clearSuggested,
        autocompleteClass,
        setAutocompleteClass,
        isQueryName,
      },
      ref
    ) => {
      const navigate = useNavigate();
      const isSmall = breakpoint(xsScreenSize, mdScreenSize);
      const { searchMinLength } = common;
      const [isSelected, setIsSelected] = useState(false);
      const dispatch = useDispatch();
      const iconSize = isSmall ? 'text-1-size' : 'text-2-size';

      useEffect(() => {
        if (
          querySearchValue &&
          (!inputValue || inputValue !== querySearchValue)
        ) {
          setInputValue(querySearchValue);
          !isQueryName && onChange(querySearchValue, fieldName);

          if (fieldName === common.heroSearchFields.locationsField) {
            optionSelected(fieldName, { name: querySearchValue });
          }
        } else if (!querySearchValue) {
          setInputValue('');
          dataSource?.length && dispatch(clearSuggested.request());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [querySearchValue, fieldName]);

      const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          const firstItem = document.querySelector(
            `#${id}-autocomplete-item-0`
          );
          if (
            firstItem &&
            !document.querySelector(`#${id}-autocomplete > .active`)
          ) {
            firstItem.click();
          }
        }
      };

      const renderInput = ({
        inputRef,
        referenceElementRef,
        ...inputProps
      }) => {
        const inputClass = 'has-clear-button';

        return (
          <>
            <RoundedInput
              {...inputProps}
              value={inputValue}
              onChange={(e) => {
                if (e.type === 'click') {
                  navigate({
                    pathname: window.location.pathname,
                  });
                }
                const { value } = e.target;
                if (matchEmptySpaces(value)) {
                  return;
                }
                if (isSelected) {
                  setIsSelected(false);
                  document.querySelector(`input#${fieldName}`).click();
                }
                setInputValue(value);
                setAutocompleteClass(inputClass);
                onChange(
                  value,
                  fieldName,
                  dataSource && dataSource.length,
                  '',
                  null,
                  optionSelected
                );
              }}
              onFocus={() => {
                inputValue.length >= searchMinLength &&
                  setAutocompleteClass(inputClass);
                document.body.click();
                document.querySelector(`input#${fieldName}`).click();
              }}
              onBlur={(e) => {
                searchOnBlur(
                  e.relatedTarget,
                  autocompleteClass,
                  setAutocompleteClass
                );
              }}
              placeholder={placeholder}
              autoComplete='off'
              size='large'
              rounded={rounded}
              id={fieldName}
              border={border}
              prefix={<Icon color={Themes.I90} type={iconType} size='15px' />}
              allowClear={{
                clearIcon: (
                  <Icon
                    color={Themes.I90}
                    type='icon-cancel2'
                    size={Themes[iconSize]}
                  />
                ),
              }}
              className={inputClassName}
            />
          </>
        );
      };

      return (
        <AutoComplete
          ref={ref}
          id={`${id}-autocomplete`}
          options={dataSource}
          onKeyDown={takeFirstOnEnter && handleKeyDown}
          labelKey={labelKey}
          emptyLabel={loading ? <Spin /> : notFound && emptyLabel}
          filterBy={() => true}
          className={autocompleteClass}
          onInputChange={(value) =>
            onChange(
              value,
              fieldName,
              dataSource && dataSource.length,
              '',
              null,
              optionSelected
            )
          }
          onChange={(selected) => {
            onSelect && onSelect(fieldName);
            if (selected[0]) {
              setInputValue(selected[0][labelKey]);
              onChange(
                selected[0][labelKey],
                fieldName,
                dataSource && dataSource.length,
                selected[0].keyword,
                true
              );
              optionSelected(fieldName, selected[0]);
              setIsSelected(true);
            }
          }}
          renderInput={renderInput}
          renderMenu={(results, menuProps) =>
            renderMenu(results, menuProps, isSmall, inputValue)
          }
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
      );
    }
  )
);
