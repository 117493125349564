import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'md-styled-components';
import { Link } from 'md-styled-components-v2';
import { getPrivateHospitalData } from 'selectors/privateHospitalData';
import { isPrivateHospital } from 'utils/privateSite';
import { isSystem } from 'utils/groupSystemHelpers';

const useBreadcrumb = (isPriceListPage = false, pageInstance = {}) => {
  const priceListGroup = pageInstance?.data?.groupData;
  const systemGroup = pageInstance?.groups?.group;
  const systemProperties = pageInstance?.group?.system_properties;
  const privateHospitalData = useSelector(getPrivateHospitalData);
  const [brdCrumbData, setBrdCrumbData] = useState({});
  const system = pageInstance?.groupSystem;
  const groupSystem = pageInstance?.groups?.group?.groupSystem;
  const currentSystem = groupSystem || system;

  useEffect(() => {
    if (
      systemGroup &&
      !(isPrivateHospital() && currentSystem && currentSystem.slug)
    ) {
      setBrdCrumbData({
        id: systemGroup.id,
        slug: systemGroup.slug,
        groupName: systemGroup.name_of_practice,
      });
    } else if (priceListGroup) {
      if (priceListGroup.id === 0) {
        setBrdCrumbData({
          slug: currentSystem.slug,
          groupName: currentSystem.name,
        });
      } else {
        setBrdCrumbData({
          id: priceListGroup.id,
          slug: priceListGroup.slug,
          groupName:
            currentSystem &&
            window.location.pathname === `/${currentSystem.slug}-price-list`
              ? ''
              : priceListGroup.group_name,
        });
      }
    }
  }, [currentSystem, priceListGroup, systemGroup]);

  let data = [];
  const procedureBrcmb = { title: 'Procedures' };
  if (currentSystem && currentSystem.slug) {
    if (
      !(
        isPriceListPage &&
        isPrivateHospital() &&
        !isSystem(privateHospitalData.privateHospitalIds)
      )
    ) {
      data = [
        {
          title: currentSystem.name,
          path: currentSystem.slug,
        },
      ];
    }
    if (brdCrumbData.slug) {
      if (!systemProperties) {
        data = [
          ...data,
          {
            title: brdCrumbData.groupName,
            path: brdCrumbData.slug,
          },
        ];
      }
    }
    const groupIsMissing =
      data.findIndex((item) => item.title === brdCrumbData.groupName) === -1;
    if (brdCrumbData.groupName && groupIsMissing) {
      data = [
        ...data,
        {
          title: brdCrumbData.groupName,
          path: isPriceListPage && brdCrumbData.slug,
        },
      ];
    }
    if (isPriceListPage) {
      data = [...data, procedureBrcmb];
    }
  } else if (isPriceListPage) {
    data = [
      {
        title: brdCrumbData.groupName,
        path: brdCrumbData.slug,
      },
      procedureBrcmb,
    ];
  }

  if (data.length) {
    const lastIndex = data.length - 1;
    data[lastIndex] = { title: data[lastIndex].title };
  }

  return data;
};

export function itemRender(route, params, routes) {
  const last = routes.indexOf(route) === routes.length - 1;
  const pathAttr = route.href
    ? { to: `/${route.href}`, reloadDocument: true }
    : { to: `/${route.path}` };
  return last ? route.title : <Link {...pathAttr}>{route.title}</Link>;
}

const BreadCrumb = ({ pageInstance, isPriceListPage }) => {
  const data = useBreadcrumb(isPriceListPage, pageInstance);

  return <Breadcrumb seperator='/' itemRender={itemRender} items={data} />;
};

BreadCrumb.defaultProps = {
  isPriceListPage: false,
};

export default BreadCrumb;
