import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'selectors/users';
import { getAnalyticData } from 'selectors/analyticData';
import SegmentAnalytics from 'components/Analytics/SegmentAnalytics';

export const useTrackPage = (callback, dependencies) => {
  const currentUser = useSelector(getCurrentUser);

  dependencies.push(currentUser?.isSet);
  useEffect(() => {
    window.analytics.ready(() => {
      if (currentUser?.isSet) {
        SegmentAnalytics(currentUser.analyticData, callback);
      }
    });
  }, dependencies);
};

export const useTrackEvent = (callback, dependencies) => {
  const currentUser = useSelector(getCurrentUser);
  const analyticData = useSelector(getAnalyticData);

  dependencies.push(currentUser?.isSet, analyticData?.pageDataSent);
  useEffect(() => {
    window.analytics.ready(() => {
      if (currentUser?.isSet && analyticData?.pageDataSent) {
        callback();
      }
    });
  }, dependencies);
};

export const useTrackLink = (callback, dependencies) => {
  const currentUser = useSelector(getCurrentUser);
  const analyticData = useSelector(getAnalyticData);
  dependencies.push(currentUser?.isSet, analyticData?.pageDataSent);
  useEffect(() => {
    window.analytics.ready(() => {
      if (currentUser?.isSet && analyticData?.pageDataSent) {
        callback();
      }
    });
  }, dependencies);
};
