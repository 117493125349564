import React from 'react';
import SearchIcon from 'components/Navigation/SearchIcon';
import ShoppingCartDropdown from 'components/Navigation/ShoppingCart/ShoppingCartDropdown';
import { useSelector } from 'react-redux';
import { privateCompany } from 'selectors/privateHospitalData';
import Logo from './_partials/Logo';
import Item from './_partials/Item';
import Menu from './_partials/Menu';

const NavbarLg = () => {
  const isPrivateCompany = useSelector(privateCompany);

  return (
    <div className={`${isPrivateCompany ? 'mh--32' : 'container'} relative`}>
      <div className='flex-display flex-justify-space-between pv--16'>
        <div className='flex-display flex-align-items-center hospital-navigation__item hospital-navigation__item--fixed-height'>
          <Logo />
        </div>
        <div className='flex-display flex-align-items-center hospital-navigation__item-center'>
          <Menu />
        </div>
        <div className='flex-display flex-align-items-center flex-justify-end hospital-navigation__item'>
          <Item>
            <SearchIcon />
          </Item>
          <Item>
            <ShoppingCartDropdown />
          </Item>
        </div>
      </div>
    </div>
  );
};

export default NavbarLg;
