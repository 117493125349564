import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Banner, Typography, Divider } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import { requestCheckUserByPersonalInformation } from 'actions/users';
import { requestShareCart } from 'actions/groups';
import { requestPatientInfo } from 'actions/patients';
import {
  createGetPatientInfoLoading,
  createGetSaveCartLoading,
} from 'selectors/patients';
import { createGetShareCartLoading } from 'selectors/groups';
import { checkUserByPersonalInformation } from 'selectors/users';
import { scrollTo } from 'utils/common';
import { isNoEmail } from 'utils/emailHelper';
import BirthdayPicker from '../../Form/DateMultiSelect';
import { getFormFields, procedureOptionsFields } from './_partials/Form';
import SupportPhoneLink from '../../Support/SupportPhoneLink';
import PatientFormItem from './_partials/PatientFormItem';
import PatientDetails from './_partials/PatientDetails';
import { useSaveCartRequest } from './hooks';

const { Text, Title } = Typography;

export default (props) => {
  const [form] = Form.useForm();
  const [emailPreferences, setEmailPreferences] = useState(false);
  const [forceRender, setForceRender] = useState(false);
  const inputEl = useRef(null);
  const dispatch = useDispatch();
  const patientInfoLoading = useSelector(createGetPatientInfoLoading);
  const saveCartLoading = useSelector(createGetSaveCartLoading);
  const shareCartLoading = useSelector(createGetShareCartLoading);
  const checkpersonalInformation = useSelector(checkUserByPersonalInformation);
  const noEmailChecked = form.getFieldsValue(['noEmail']).noEmail;
  const saveCartRequest = useSaveCartRequest();
  const {
    getFieldError,
    getFieldsValue,
    getFieldValue,
    validateFields,
    setFieldsValue,
    resetFields,
    setFields,
  } = form;
  useEffect(() => {
    if (props.existingPatient.current) {
      setFieldsValue(props.existingPatient.current);
      if (
        props.existingPatient.current.email_address &&
        !isNoEmail(props.existingPatient.current.email_address)
      ) {
        setEmailPreferences(true);
      }
    }
  }, [props.existingPatient, setFieldsValue]);
  useEffect(() => {
    checkpersonalInformation &&
      resetFields(['first_name', 'last_name', 'b_month', 'b_day', 'b_year']);
  }, [checkpersonalInformation, resetFields]);

  const validateUserByPersonalInformation = (cb, values) => {
    const validateData = {
      personalInfo: {
        ...getFieldsValue(),
        ...values,
        isCustomer: true,
      },
      cb: () => cb && cb(),
    };
    dispatch(requestCheckUserByPersonalInformation.request(validateData));
  };

  const formFields = getFormFields({
    emailPreferences,
    setEmailPreferences,
    noEmailChecked,
    inputEl,
    setFieldsValue,
    validateUserByPersonalInformation,
    showInputs: !props.existingPatient.current,
  });

  useEffect(
    () => () => {
      resetFields();
      dispatch(
        requestCheckUserByPersonalInformation.actions.success(
          {
            params: {},
          },
          { checkUserByPersonalInformation: null }
        )
      );
    },
    [resetFields, dispatch]
  );

  const handleSaveCart = () => {
    validateFields().then((value) => {
      const { email, subscribe, text } = value;
      const serviceDate = value.service_date
        ? value.service_date.format('MM/DD/YYYY')
        : null;
      delete value.email;
      delete value.subscribe;
      delete value.text;
      const submitData = {
        create: true,
        data: {
          ...props.existingPatient.current,
          ...value,
          isCustomer: true,
        },
        cb: (response) => {
          if (response.error) {
            const emailFieldId = 'email_address';
            setFields([
              {
                name: 'email_address',
                value: getFieldValue(emailFieldId),
                errors: [response.errorList && response.errorList[0].error[0]],
              },
            ]);
            scrollTo(
              document.getElementById(emailFieldId),
              false,
              true,
              'auto'
            );
            return;
          }
          const { data } = response;
          const requestSaveCart = () => {
            const requestData = saveCartRequest({
              data,
              serviceDate,
              icdCode: value.icd_code,
            });
            props.setSendData(requestData);
          };

          if ((email && subscribe) || text) {
            const phoneInput = {
              checked: text || false,
              phone:
                value?.mobile_phone?.replace(/[^0-9]/g, '') ||
                props.existingPatient.current?.mobile_phone,
              send_by: text || false,
            };
            const emailInput = {
              checkedEmail: subscribe || false,
              email:
                value.email_address ||
                props.existingPatient.current?.email_address,
              send_by: email || false,
            };
            const pdfInput = {
              send_by: false,
            };
            delete value.mobile_phone;
            delete value.email_address;
            const shareCartData = {
              create: true,
              validate: true,
              shareCart: {
                ...props.existingPatient.current,
                ...value,
                id: data.id,
                statusSaved: true,
                phoneInput,
                emailInput,
                pdfInput,
              },
              cb: requestSaveCart,
            };
            props.setSuccessModalMessage({
              title: 'Cart Shared',
              text: 'Your cart has successfully been shared and saved.',
            });
            dispatch(requestShareCart.request(shareCartData));
          } else {
            requestSaveCart();
          }
        },
      };
      if (props.existingPatient.current) {
        submitData.cb({ data: props.existingPatient.current });
      } else {
        dispatch(requestPatientInfo.request(submitData));
      }
    });
  };
  const handleFormSubmit = () => {
    validateUserByPersonalInformation(handleSaveCart);
  };

  return (
    <>
      <div className='pb--4 mb--16 mt--2  flex-display flex-justify-space-between flex-align-items-bottom'>
        <Title level={5} className='save-cart__title'>
          Save Cart
        </Title>
        <Button
          onClick={props.searchPatientClick}
          type='link'
          className='save-cart__for-patient-create-button mb--2'
        >
          Search Existing Patients
        </Button>
      </div>
      {checkpersonalInformation && (
        <>
          <Banner
            className='pb--4 pb--16'
            message={
              <Text level={2}>
                {checkpersonalInformation.message}
                <SupportPhoneLink
                  renderCustomText={(content) => (
                    <Text underline>{content}</Text>
                  )}
                  loadNumber
                />
                .
              </Text>
            }
            type='error'
          />
        </>
      )}
      <Form
        form={form}
        onFinish={handleFormSubmit}
        layout='vertical'
        hideRequiredMark
        onValuesChange={() => setForceRender(!forceRender)}
      >
        <Text level={1} fontFamily='heavy'>
          Patient Details
        </Text>
        <div className='flex-display flex-justify-space-between flex-wrap pb--12'>
          {props.existingPatient.current && (
            <PatientDetails existingPatient={props.existingPatient.current} />
          )}
          {formFields.map((formItem) =>
            formItem.type === 'birthday' ? (
              <div className='save-cart__form-item--full'>
                <BirthdayPicker
                  key={`form-item-${formItem.type}`}
                  validateTrigger='onSubmit'
                  onChange={validateUserByPersonalInformation}
                />
              </div>
            ) : (
              formItem && (
                <PatientFormItem
                  formItem={formItem}
                  getFieldError={getFieldError}
                  inputEl={inputEl}
                />
              )
            )
          )}
        </div>
        <Divider />
        <Text level={1} fontFamily='heavy'>
          Service Details
        </Text>
        <div className='flex-display flex-justify-space-between flex-wrap'>
          {procedureOptionsFields().map((item) => (
            <PatientFormItem
              formItem={item}
              getFieldError={getFieldError}
              inputEl={inputEl}
            />
          ))}
        </div>
        <div className='text-center'>
          <Button
            type='primary'
            htmlType='submit'
            shape='round'
            size='middle'
            loading={patientInfoLoading || saveCartLoading || shareCartLoading}
          >
            Save Cart
          </Button>
        </div>
      </Form>
    </>
  );
};
