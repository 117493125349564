import React from 'react';
import styled, { css } from 'styled-components';
import { Dropdown as Component } from 'antd';
import propTypes from 'prop-types';
import './index.less';
import themes from '../Themes';

const sortType = css`
  font-family: ${themes['font-family']};
  color: ${themes['primary-active-color']};
  font-size: ${themes['font-size-base']};
  font-weight: ${themes['font-weight-normal']};

  :hover {
    color: ${themes['primary-active-color']};
  }
`;

const DropdownWrapper = styled(Component)`
  ${(props) => props.sort && sortType}
`;

const Dropdown = (props) => <DropdownWrapper {...props} />;

Dropdown.propTypes = {
  /** whether the dropdown menu is disabled */
  disabled: propTypes.bool,
  /** to set the container of the dropdown menu.
   *  The default is to create a div element in body,
   *  you can reset it to the scrolling area and make a relative reposition */
  getPopupContainer: propTypes.func,
  /** function returns the dropdown menu */
  menu: propTypes.func,
  /** Class name of the dropdown root element */
  overlayClassName: propTypes.string,
  /** Style of the dropdown root element */
  overlayStyle: propTypes.object,
  /** placement of pop menu: bottomLeft, bottomCenter, bottomRight, topLeft, topCenter, topRight */
  placement: propTypes.oneOf([
    'bottom',
    'bottomCenter',
    'bottomLeft',
    'bottomRight',
    'top',
    'topLeft',
    'topCenter',
    'topRight',
  ]),
  /** the trigger mode which executes the drop-down action, hover doesn't work on mobile device */
  trigger: propTypes.array,
  /** whether the dropdown menu is visible */
  open: propTypes.bool,
  /** a callback function takes an argument: visible, is executed when the visible state is changed */
  onOpenChange: propTypes.func,
  /** describes that dropdown content is for sort */
  sort: propTypes.bool,
};

Dropdown.defaultProps = {
  sort: false,
};

/** @component */
Dropdown.Button = Component.Button;
export default Dropdown;
