import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import { Collapse as Component } from 'antd';
import Theme from '../Themes';
import ExpandIcon from './ExpandIcon';
import IconHeader from './IconHeader';

const Collapse = styled(Component)`
  .icon-wrapper {
    background: ${Theme.salt};
    height: 56px;
    width: 56px;
    border-radius: 50%;
    text-align: center;
    line-height: 56px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .arrow-rotate {
    transition: transform 0.24s;

    &-90 {
      transform: rotate(90deg) !important;
    }

    &-270 {
      transform: rotate(270deg) !important;
    }
  }

  &&.ant-collapse {
    background: ${Theme.B10};
    border: none;
    line-height: ${Theme['line-height-base']};
    font-size: ${Theme['font-size-base']};
    border-radius: ${Theme['border-radius-none']};
    font-family: ${Theme['font-sans-roman']};

    .ant-collapse-item {
      border: none;

      .ant-collapse-content {
        background: ${Theme.B10};
        border: none;

        .ant-collapse-content-box {
          padding-top: 0;

          .ant-btn {
            white-space: normal;
          }
        }
      }
    }

    &.ant-collapse-icon-position-end .ant-collapse-item .ant-collapse-header {
      padding-top: 16px;
      padding-bottom: 16px;
      padding-left: 16px;
      line-height: ${Theme['line-height-base']};
      align-items: baseline;
    }

    ${(props) =>
      props.bordered &&
      css`
        background: transparent;

        &.ant-collapse-icon-position-end .ant-collapse-item {
          .ant-collapse-header {
            padding-left: 0;
            padding-right: 32px;

            .ant-collapse-expand-icon {
              height: auto;
            }

            .ant-collapse-arrow {
              padding-top: 12px;
              position: absolute;
              right: 4px;
              top: 6px;
            }
          }
        }

        .ant-collapse-item {
          border-bottom: 1px solid ${Theme.I20};
          border-radius: 0;

          .ant-collapse-content {
            background: transparent;

            .ant-collapse-content-box {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
      `}

    ${(props) =>
      props.ghost &&
      css`
        background: transparent;

        .ant-collapse-content {
          background: transparent !important;

          .ant-collapse-content-box {
            padding-left: 0;
            padding-right: 0;
          }
        }

        &.ant-collapse-icon-position-end .ant-collapse-item {
          .ant-collapse-header {
            display: flex;
            padding-left: 0;
            padding-right: 0;
            align-items: baseline;
            position: static;

            &-text {
              flex: unset;
              margin-inline-end: unset;
              line-height: 24px;
            }

            & .ant-collapse-expand-icon {
              padding-left: 0;
            }

            & .ant-collapse-arrow {
              order: 2;
              margin: 0 8px;
              padding-top: 12px;
              position: static;
            }
          }
        }
      `};

    ${(props) =>
      props &&
      props.leftSpace &&
      css`
        .ant-collapse-content .ant-collapse-content-box {
          padding-left: 52px;
        }
      `};
  }
`;

Collapse.propTypes = {
  bordered: propTypes.bool,
  ghost: propTypes.bool,
  leftSpace: propTypes.bool,
};

Collapse.defaultProps = {
  expandIconPosition: 'end',
};

Collapse.IconHeader = IconHeader;
Collapse.ExpandIcon = ExpandIcon;

export default Collapse;
