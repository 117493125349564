import { memo } from 'react';
import propTypes from 'prop-types';
import { Button } from 'md-styled-components-v2';

const CreateNewPatientLink = ({ createNewPatient }) => (
  <div className='save-cart__new-patient pv--8 ph--16'>
    <Button
      type='link'
      className='save-cart__new-patient-create-button mb--2'
      onClick={createNewPatient}
    >
      Create a New Patient Profile
    </Button>
  </div>
);

CreateNewPatientLink.propTypes = {
  createNewPatient: propTypes.func.isRequired,
};

export default memo(CreateNewPatientLink);
