import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form } from 'md-styled-components';
import { requestSetSelectedProcedure } from 'actions/procedures';
import Selectors from 'selectors';
import { getPrivateHospitalData } from 'selectors/privateHospitalData';
import { useExcludePageFromUrl } from 'hooks/paginationUrl';
import { isPrivateHospital } from 'utils/privateSite';
import FormWrapper from './_partials/SearchFormWrapper';
import { TrackTransparencySearch } from '../Analytics/Track';

const SearchWrapper = ({
  items,
  showButton,
  redirectOnChange,
  redirect,
  searchInitialValue,
  restoreDefaultSearch,
  showInputButton,
  inputButtonType,
}) => {
  const [form] = Form.useForm();
  const { setFieldsValue, getFieldError } = form;
  const navigate = useNavigate();
  const location = useLocation();
  const [searchItems, setSearchItems] = useState(items);
  const [searchData, setSearchData] = useState({});
  const [forceUpdate, setForceUpdate] = useState(false);
  const dispatch = useDispatch();
  const excludedPageFromUrl = useExcludePageFromUrl();
  const { privateHospitalSlug, connectionId } = useSelector(
    getPrivateHospitalData
  );
  const Procedures = useSelector(Selectors.Procedures.data);
  const InsuranceProvider = useSelector(Selectors.InsuranceProvider.data);
  const loading = useSelector(Selectors.Procedures.loading);
  const insuranceLoading = useSelector(Selectors.InsuranceProvider.loading);

  const restoreSearch = useCallback(
    (key) => {
      searchItems[key].optionsSelected = false;
      searchItems[key].search = {};
      searchItems[key].dataSource = [];
    },
    [searchItems]
  );

  const getDataSourceAndLoading = (item) => {
    const isShowNotFound = item.search?.text?.length > 1;
    if (item.name === 'InsuranceProvider') {
      return {
        dataSource: InsuranceProvider,
        dataLoading: insuranceLoading,
        notFound: isShowNotFound && InsuranceProvider?.length === 0,
      };
    }
    return {
      dataSource: Procedures,
      dataLoading: loading,
      notFound: isShowNotFound && Procedures?.length === 0,
    };
  };

  searchItems.forEach((item, key) => {
    const { dataSource, dataLoading, notFound } = getDataSourceAndLoading(item);
    searchItems[key].notFound = notFound;
    searchItems[key].dataSource = dataSource || [];
    searchItems[key].loading = dataLoading;
    searchItems[key].errors = getFieldError(item.name).length;
  });

  useEffect(() => {
    searchItems.forEach((item) => {
      setFieldsValue({ [item.name]: item.search?.text });
    });
  }, [searchItems]);

  useEffect(() => {
    const newItems = searchItems;
    newItems.forEach((_, key) => {
      newItems[key].optionsSelected = false;
      newItems[key].search = {};
    });
    setSearchItems([...newItems]);
  }, []);

  const cb = useCallback(
    (url) => {
      if (isPrivateHospital()) {
        const [procedure, insurance] = searchItems;
        const trackSearch = { white_label_hospital_id: connectionId };
        trackSearch.search_text = procedure.search.text;
        trackSearch.procedure_id = procedure.optionsSelected?.id;
        trackSearch.insurance_provider_id =
          insurance && insurance.optionsSelected?.id;
        TrackTransparencySearch(url, location, trackSearch);
      }
      url && navigate(url);
    },
    [connectionId, navigate, location, searchItems]
  );

  const onOptionSelected = useCallback(
    (key, _, selected) => {
      searchItems[key].optionsSelected = selected;
      setSearchItems([...searchItems]);
      if (redirectOnChange) {
        redirect([...searchItems], cb);
      }
      navigate(excludedPageFromUrl);
    },
    [searchItems, redirectOnChange, navigate, excludedPageFromUrl, redirect, cb]
  );

  const onFinishFailed = useCallback(
    (values) => {
      const [error] = values.errorFields;
      const inputItem = items.find((item) => item.name !== error.name[0]);
      inputItem && inputItem.setAutocompleteClass('');
      error.name[0] &&
        !values.values[error.name[0]] &&
        document.querySelector(`input#${error.name[0]}`).focus();
    },
    [items]
  );

  const resetForm = useCallback(
    (key, name) => {
      restoreSearch(key);
      setFieldsValue({ [name]: '' });
      onOptionSelected(key, name, null);
    },
    [onOptionSelected, restoreSearch, setFieldsValue]
  );

  const searchByText = useCallback(
    (item, key, value, selected) => {
      if (!value || (value.length < item.minLength && item.dataSource.length)) {
        if (restoreDefaultSearch && (!value || value.length === 0)) {
          restoreDefaultSearch();
          resetForm(key, item.name);
        }
        restoreSearch(key);
        dispatch(item.clearSuggested.request());
        if (item.switchClearSuggested) {
          dispatch(item.switchClearSuggested.request());
        }
        return;
      }
      const data = {
        count: 30,
        data: value,
        ...item.params,
      };
      if (value.length >= item.minLength && !selected) {
        restoreSearch(key);
        if (item.customSearch) {
          dispatch(item.customSearch(data));
        } else {
          dispatch(item.requestSuggested.request(data));
        }
      }
      const search = {
        ...searchData,
        text: value,
      };
      searchItems[key].search = search;
      setSearchData(search);
      setSearchItems([...searchItems]);
    },
    [
      searchData,
      searchItems,
      restoreDefaultSearch,
      restoreSearch,
      dispatch,
      resetForm,
    ]
  );

  const onFinish = useCallback(() => {
    const [firstSearchItem] = searchItems;
    const searchTxt = firstSearchItem.search.text;
    const [firstResult] = firstSearchItem.dataSource;
    const exactCPTMatch =
      firstSearchItem.dataSource.length === 1 &&
      (firstResult.foundCptCode === searchTxt ||
        firstResult.name.toLowerCase() === searchTxt.toLowerCase());

    if (
      privateHospitalSlug &&
      !firstSearchItem.optionsSelected &&
      exactCPTMatch
    ) {
      firstSearchItem.search.text = firstResult.name;
    }

    (firstSearchItem.optionsSelected || exactCPTMatch) &&
      dispatch(
        requestSetSelectedProcedure.request(
          firstSearchItem.optionsSelected.foundCptCode
            ? firstSearchItem.optionsSelected
            : firstResult || {}
        )
      );
    redirect(searchItems, cb, privateHospitalSlug);
  }, [cb, dispatch, privateHospitalSlug, redirect, searchItems]);

  const formItems = useMemo(
    () =>
      items.map((item, key) => ({
        props: {
          name: item.name,
          initialValue: searchInitialValue,
          validateTrigger: 'onBlur',
          validateFirst: true,
          rules: item.rules,
          searchText: item.search?.text || item.querySearchValue,
          optionSelected: searchItems[key].optionsSelected,
          setNewRules: item?.setNewRules,
        },
        component: () =>
          item.renderAutoComplete({
            key,
            ...item,
            renderMenu: (results, menuProps, isSmall, inputValue) =>
              item.renderMenu({
                results,
                menuProps,
                isSmall,
                itemClass: item.renderMenuItemClass,
                className: item.className,
                inputValue,
              }),
            onChange: (value, fieldName, dataSourceLen, keyWord, selected) =>
              searchByText(item, key, value, selected),
            fieldName: item.name,
            optionSelected: (fieldName, selected) =>
              onOptionSelected(key, fieldName, selected),
            placeholder: item.errors
              ? item.placeholderError
              : item.placeholderDefault,
            resetForm: () => resetForm(key, item.name),
          }),
      })),
    [
      items,
      onOptionSelected,
      resetForm,
      searchByText,
      searchInitialValue,
      searchItems,
    ]
  );

  return (
    <div className='search-form-wrapper'>
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={() => setForceUpdate(!forceUpdate)}
      >
        <FormWrapper
          showButton={showButton}
          showInputButton={showInputButton}
          inputButtonType={inputButtonType}
          buttonId='search-procedure-btn'
          formItems={formItems}
        />
      </Form>
    </div>
  );
};

export default SearchWrapper;
