import React from 'react';
import styled, { css } from 'styled-components';
import { Form as Component } from 'antd';
import propTypes from 'prop-types';
import themes from '../Themes';
import errorIcon from './error-icon.svg';

const formItemSplitStyles = css`
  font-size: ${themes['text-4-size']};
  font-family: ${themes['font-sans-roman']};
`;

const formItemExtraStyles = css`
  margin-top: 2px;
`;

const Form = styled(Component)`
  &.ant-form {
    &-vertical .ant-form-item {
      padding-bottom: 8px;

      &-label {
        line-height: 18px !important;
        flex: none;
      }

      &-control {
        width: 100%;
        flex: none;
      }

      &-explain {
        margin-bottom: -5px;
      }
    }

    &-horizontal {
      .ant-form-item {
        display: flex;

        &-row {
          width: 100%;
        }

        &-label {
          padding-bottom: 0;
          line-height: 39px;
        }

        &-control {
          flex: 100%;
        }

        &-explain {
          min-height: 24px;
        }

        &:not(.ant-form-item-has-error) {
          .ant-input-suffix {
            .ant-input-password-icon {
              margin-inline-end: 0;
            }
          }
        }
      }

      .ant-input-password-icon {
        color: ${themes.I70};
      }
    }
  }

  .ant-form {
    &-split {
      ${formItemSplitStyles};
    }

    &-extra {
      ${formItemExtraStyles};
    }

    &-item {
      margin-bottom: 10px;

      &-explain {
        min-height: 25px;
        ${formItemSplitStyles};
        ${formItemExtraStyles};
      }

      &-control {
        line-height: 15px;
      }

      #username {
        margin-bottom: 5px;
      }

      &-has-error {
        #username {
          margin-bottom: 0;
        }
      }
    }
  }

  .login-tooltip-divider {
    border-bottom: 1px solid ${themes.I20};
    clear: both;
    height: 15px;
    text-align: center;

    span {
      background-color: #fff;
      padding: 0 10px;
    }
  }
`;

const defaultFormItemStyles = css`
  ${(props) =>
    props &&
    props.validateStatus === 'error' &&
    css`
      color: ${themes['error-color']};
    `};

  ${(props) =>
    props &&
    props.validateStatus === 'success' &&
    css`
      color: ${themes.green};
    `};

  ${(props) =>
    props &&
    props.validateStatus === 'warning' &&
    css`
      color: ${themes.gold};
    `};

  ${(props) =>
    props.validateColor
      ? css`
          .ant-form-item-explain-error {
            color: ${props.validateColor};
          }
        `
      : css`
          &.ant-form-item.ant-form-item-has-error {
            .ant-form-item-explain-error {
              color: ${themes['error-color']};
            }
          }
        `};

  &.ant-form label,
  .ant-form-item-label label {
    font-family: ${themes['font-sans-book']};

    ${(props) =>
      props &&
      props.labelSize === 'default' &&
      css`
        font-size: ${themes['text-4-size']};
        color: ${themes.I70};
      `};

    ${(props) =>
      props &&
      props.labelSize === 'large' &&
      css`
        font-family: ${themes['font-sans-medium']};
        font-size: ${themes['text-5-size']};
        color: ${themes.indigo};
      `};
  }

  &.ant-form-item {
    &-has {
      &-error {
        .ant-form-item {
          &-feedback-icon-error {
            width: auto;
            height: auto;
            position: relative;

            .anticon-close-circle {
              display: none;
            }

            &:before {
              content: url('${errorIcon}');
              display: inline-block;
              transform: scale(0.26);
              position: absolute;
              top: -31px;
              right: ${(props) => props.iconRight};
            }
          }

          &-control {
            .ant-input {
              padding-right: 40px;

              &-affix-wrapper {
                .ant-input {
                  padding-right: 60px;

                  &-suffix {
                    right: 32px;
                  }
                }
              }
            }
          }
        }

        .ant-select {
          &-selection {
            &-search {
              margin-right: 54px;
            }

            &-selected-value {
              padding-right: 0 !important;
            }
          }

          &-arrow {
            right: 19px !important;
          }
        }

        textarea {
          + .ant-form-item-children-icon {
            top: -97px;
          }
        }
      }

      &-success {
        .ant-input-suffix {
          .ant-form-item-feedback-icon {
            display: none !important;
          }
        }
      }

      &-feedback:not(.ant-form-item-has-error) {
        .ant-input-affix-wrapper {
          .ant-input-suffix {
            padding-right: 0;
          }
        }
      }
    }
  }
`;

const FormItem = styled(
  ({
    labelSize,
    validateColor,
    iconRight,
    inputName,
    checkboxText,
    fieldName,
    ...props
  }) => <Component.Item {...props} />
)`
  ${defaultFormItemStyles}
`;

const FormItemSelect = styled(({ labelSize, validateColor, ...props }) => (
  <Component.Item {...props} />
))`
  ${defaultFormItemStyles}

  &.ant-form-item-has-feedback {
    .ant-form-item-children-icon {
      margin-top: -11px;
      right: 18px !important;
    }
  }
`;

const FormItemTextarea = styled(({ labelSize, validateColor, ...props }) => (
  <Component.Item {...props} />
))`
  ${defaultFormItemStyles}

  &.ant-form-item-has-feedback {
    .ant-form-item-children-icon {
      top: -1px !important;
    }

    .ant-form-item-children-icon:before {
      top: 0 !important;
      right: -3px;
    }
  }
`;

Form.propTypes = {
  /** Hide required mark of all form items */
  hideRequiredMark: propTypes.bool,
  /** Label text align */
  labelAlign: propTypes.oneOf(['right', 'left']),
  /** The layout of label. You can set span offset to something like {span: 3, offset: 12} or sm: {span: 3, offset: 12} same as with <Col> */
  labelCol: propTypes.object,
  /** Define form layout */
  layout: propTypes.oneOf(['horizontal', 'vertical', 'inline']),
  /** Defines a function will be called if form data validation is successful */
  onSubmit: propTypes.func,
  /** The layout for input controls, same as labelCol */
  wrapperCol: propTypes.object,
  /** change default props colon value of Form.Item */
  colon: propTypes.bool,
};

FormItem.propTypes = {
  labelSize: propTypes.oneOf(['default', 'large']),
  validateColor: propTypes.string,
  iconRight: propTypes.string,
};

FormItem.defaultProps = {
  labelSize: 'default',
  iconRight: '-37px',
};

FormItemSelect.propTypes = {
  labelSize: propTypes.oneOf(['default', 'large']),
  validateColor: propTypes.string,
};

FormItemSelect.defaultProps = {
  labelSize: 'default',
};

FormItemTextarea.propTypes = {
  labelSize: propTypes.oneOf(['default', 'large']),
  validateColor: propTypes.string,
};

FormItemTextarea.defaultProps = {
  labelSize: 'default',
};

Form.Item = FormItem;
Form.ItemSelect = FormItemSelect;
Form.ItemTextarea = FormItemTextarea;

/** @component */
export default Form;
