import React from 'react';
import { Typography } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import { useContactUsModal } from 'components/LiveAgent/_hooks';

const { Text } = Typography;

const SendUsMessage = () => {
  const onClickOffline = useContactUsModal();
  return (
    <Button
      type='link'
      className='flex-display mb--16'
      onClick={onClickOffline}
    >
      <Text>Send a Message</Text>
    </Button>
  );
};

export default React.memo(SendUsMessage);
