export const existingData = (data, key, text) =>
  data && text
    ? data.find((obj) => obj[key].toLowerCase() === text.toLowerCase())
    : false;

export const possibleCptCodes = (text) => {
  if (!text) {
    return [];
  }
  const cptsArray = text
    .replace(/\s+/g, ' ')
    .replace(/[^A-Za-z0-9 ]/g, ' ')
    .split(' ');
  return cptsArray.filter(
    (v) => v && v.length === 5 && !v.match(/[a-zA-Z]{2,}/g)
  );
};
