import React from 'react';
import propTypes from 'prop-types';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Tabs as Component } from 'antd';
import Themes from '../Themes';
import tab1 from './tab_1.svg';
import tab2 from './tab_2.svg';
import activeTab1 from './active_tab_1.svg';
import activeTab2 from './active_tab_2.svg';
import './index.less';

const Tab = styled(({ tabType, tabPanePadding, newFigured, ...props }) => (
  <Component {...props} />
))`
  &,
  .ant-tabs-nav-container,
  .ant-tabs-nav-scroll {
    overflow: visible !important;
  }

  .ant-form-item {
    margin: 0 !important;
  }

  .has-error .ant-select-selection {
    background-color: white !important;
  }

  .ant-form-item-explain {
    display: none !important;
  }

  .ant-form-inline .ant-form-item-with-help {
    margin-bottom: 0 !important;
  }

  .has-error .ant-select.ant-select-auto-complete .ant-input:focus {
    border: none !important;
  }

  .ant-select-selection {
    box-shadow: none !important;
  }

  .ant-tabs-nav {
    margin-bottom: 0;

    &:before {
      border: none;
    }
  }

  ${(props) =>
    props.tabType === 'figured' &&
    css`
      .ant-tabs {
        &-nav-list {
          border-bottom: none;
          padding-left: 0;
        }

        &-tab {
          width: 90px;
          height: 40px;
          border: none !important;
          border-top: 1px solid
            ${props.newFigured ? Themes.I20 : Themes.seafoam} !important;
          border-radius: 0 !important;
          padding: 0 !important;
          margin: 0 31px !important;
          transition-property: none !important;
          justify-content: center;
          background-color: ${Themes.I10} !important;
          font-size: 16px;
          font-variant: tabular-nums;
          font-feature-settings: normal;
          line-height: 24px;
          text-size-adjust: 100%;

          &:before,
          &:after {
            content: '';
            background-image: url(${props.newFigured ? tab2 : tab1});
            width: 32px;
            height: 41px;
            border-top: none !important;
            position: absolute;
            top: -1px;
            transition: none;
          }

          &:before {
            left: -29px;
            z-index: -1;
          }

          &:after {
            transform: scaleX(-1);
            right: -29px;
            z-index: -1;
          }

          &-btn {
            margin-top: -1px;
            font-family: ${Themes['font-sans-medium']};
            color: ${Themes.I90} !important;
          }

          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              text-shadow: none;
            }
          }
        }

        &-tab-active {
          transition-property: none !important;
          background-color: ${Themes.white} !important;

          &:before {
            background: url(${props.newFigured ? activeTab2 : activeTab1});
          }

          &:before,
          &:after {
            background: url(${props.newFigured ? activeTab2 : activeTab1});
          }
        }

        &-nav-wrap {
          ${breakpoint('xs', 'md')`
          overflow: visible !important;
          justify-content: center;
        `};
        }

        &-tabpane {
          height: auto;
          padding: ${props.tabPanePadding || '14px 16px 48px'};
          text-align: center;
          background: ${Themes.white};

          &-inactive {
            display: none;
          }
        }
      }
    `};

  ${breakpoint('xs', 'md')`
    .ant-tabs {
      ${(props) =>
        !props.newFigured &&
        css`
          &-nav {
            position: relative;
            z-index: 20;
          }
        `};
      
      &-tab {
        height: 41px;
        margin-bottom: -1px !important;
        
        &-btn {
          margin-top: -2px;
        }
      }

      &-content {
        ${(props) =>
          !props.newFigured &&
          css`
            border-top: 1px solid ${Themes.seafoam};
          `};
        position: relative;
        z-index: 10;
      }
    }
  `};

  ${breakpoint('md')`
    .ant-tabs {
      &-nav-list {
        margin: 0;
        padding-left: 25px;
      }

      &-tabpane {
        height : 60px;
        padding: 0 3px 0 0;
        background: transparent;
      }
    }
  `};
`;

const Tabs = (props) => (
  <Tab type='card' popupClassName='tabs-dropdown' {...props}>
    {props.children}
  </Tab>
);

Tabs.propTypes = {
  /** Current TabPane's key */
  activeKey: propTypes.string,
  /** Whether to change tabs with animation. Only works while tabPosition="top"\|"bottom" */
  animated: propTypes.oneOfType([
    propTypes.bool,
    propTypes.shape({
      inkBar: propTypes.bool,
      tabPane: propTypes.bool,
    }),
  ]),
  /** replace the TabBar */
  renderTabBar: propTypes.func,
  /** Initial active TabPane's key, if activeKey is not set. */
  defaultActiveKey: propTypes.string,
  /** Hide plus icon or not. Only works while type="editable-card" */
  hideAdd: propTypes.bool,
  /** preset tab bar size */
  size: propTypes.oneOf(['large', 'default', 'small']),
  /** Extra content in tab bar */
  tabBarExtraContent: propTypes.node,
  /** The gap between tabs */
  tabBarGutter: propTypes.number,
  /** Tab bar style object */
  tabBarStyle: propTypes.object,
  /** Position of tabs */
  tabPosition: propTypes.oneOf(['top', 'right', 'bottom', 'left']),
  /** Basic style of tabs */
  type: propTypes.oneOf(['line', 'card', 'editable-card']),
  /** Callback executed when active tab is changed */
  onChange: propTypes.func,
  /** Callback executed when tab is added or removed. Only works while type="editable-card" */
  onEdit: propTypes.func,
  /** Callback executed when next button is clicked */
  onNextClick: propTypes.func,
  /** Callback executed when prev button is clicked */
  onPrevClick: propTypes.func,
  /** Callback executed when tab is clicked */
  onTabClick: propTypes.func,
  tabPanePadding: propTypes.string,
};

export default Tabs;
