import React, { useState } from 'react';
import { Modal, Icon } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import { disableFields, enableFields } from 'utils/form';
import SignInOverlay from './SignInOverlay';

const AuthorizationSm = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <div className='authorization__item flex-display flex-align-items-center ml--24'>
        <Button
          type='text'
          className='authorization__button'
          onClick={() => {
            disableFields();
            setIsModalVisible(true);
          }}
        >
          <Icon
            type='profile'
            iconClassName='m--0'
            className='mr--24'
            size={20}
          />
          Sign In
        </Button>
      </div>
      <Modal
        mask={false}
        footer={null}
        open={isModalVisible}
        onCancel={() => {
          enableFields();
          setIsModalVisible(false);
        }}
        width='100vw'
        className='p--0 m--0 authorization__sign-in-modal'
      >
        <SignInOverlay />
      </Modal>
    </>
  );
};

export default AuthorizationSm;
