import React from 'react';
import { Typography, Icon, Themes } from 'md-styled-components';
import propTypes from 'prop-types';

const { Text } = Typography;

const SubMenuTitle = ({ className = '', title }) => (
  <div className={`${className} direct-menu__underline flex-display`}>
    <Text level={3} className='direct-menu__title--active'>
      {title}
    </Text>
    <Icon
      size={12}
      color={Themes.indigo}
      type='chevron-down'
      className='direct-menu__underline-icon ml--8 mb--2'
    />
  </div>
);

SubMenuTitle.propTypes = {
  title: propTypes.oneOfType([propTypes.string, propTypes.node]).isRequired,
  className: propTypes.string,
};

export default SubMenuTitle;
