import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxios from 'axios-hooks';
import { matchEmptySpaces } from 'utils/stringHelper';
import { Form } from 'md-styled-components-v2';
import { common } from 'constants';

const { searchMinLength } = common;

export const useInputChange = (setInputValue, inputId) => {
  const form = Form.useFormInstance();
  const [{ data = { data: [] }, loading }, makeRequest] = useAxios(
    {
      url: '/public/getProvidersByText',
      method: 'POST',
    },
    { manual: true, autoCancel: false }
  );
  const onInputChange = useCallback(
    async (event) => {
      const { value: searchText } = event.target;
      if (matchEmptySpaces(searchText)) {
        return;
      }
      setInputValue(searchText);
      if (searchText.length < searchMinLength) {
        form.setFields([{ name: 'provider', value: undefined, errors: [] }]);
        if (data.data.length) {
          data.data = [];
        }
        return;
      }
      const response = await makeRequest({
        data: { count: 30, data: searchText },
      });
      form.setFields([
        {
          name: 'provider',
          value: { searchText, searchData: response.data.data },
          errors: [],
        },
      ]);
    },
    [data, form, makeRequest, setInputValue]
  );

  return { onInputChange, data: data.data, loading };
};

export const useOnSelect = (setInputValue) => {
  const form = Form.useFormInstance();
  const navigate = useNavigate();

  return useCallback(
    (_, selectedOption) => {
      const { data } = selectedOption;
      let url = data.uri;
      if (data.provider_uri) {
        form.setFields([{ name: 'provider', value: data, errors: [] }]);
        setInputValue(data.name);
        url = `/p/${data.provider_uri}`;
      }
      navigate(url);
    },
    [form, navigate, setInputValue]
  );
};

export const useAutocompleteProps = () => {
  const form = Form.useFormInstance();
  const fieldValue = form.getFieldValue('provider');

  return {
    placeholder: fieldValue?.placeholder || 'Dr. John Smith, Imaging Center...',
    emptyLabel:
      fieldValue?.searchText && !fieldValue?.name
        ? 'Sorry, we could not find a match for that provider'
        : '',
    errorClassName: fieldValue?.placeholder ? 'input-error' : '',
  };
};
