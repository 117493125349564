import ProceduresMenu from 'components/Input/AutoComplete/_menu/ProceduresMenu';
import ProvidersMenu from 'components/Input/AutoComplete/_menu/ProvidersMenu';
import InsuranceProviderMenu from 'components/Input/AutoComplete/_menu/InsuranceProvidersMenu';
import {
  clearSuggestedProcedures,
  requestSuggestedProcedures,
  switchClearSuggestedProcedures,
} from 'actions/procedures';
import {
  clearSuggestedProviders,
  requestSuggestedProviders,
} from 'actions/providers';
import { requestInsuranceProviders } from 'actions/transparency';
import { common } from '../../../constants';

const { proceduresField, providersField, insuranceProviderField } =
  common.heroSearchFields;

export const ProceduresOption = Object.freeze({
  name: proceduresField,
  id: 'procedure-input',
  iconType: 'magnifying-glass',
  rounded: '',
  labelKey: 'name',
  emptyLabel: 'Sorry, we could not find a match for that procedure',
  placeholderError: 'Please enter a procedure',
  placeholderDefault: 'Office Visit, Procedure, or CPT Code',
  rules: [{ required: true }],
  minLength: 2,
  params: {},
  clearSuggested: clearSuggestedProcedures,
  switchClearSuggested: switchClearSuggestedProcedures,
  requestSuggested: requestSuggestedProcedures,
  renderMenuItemClass: 'procedure-item',
  className: 'procedure-autocomplete',
  renderMenu: ProceduresMenu,
});

export const ProvidersOption = {
  name: providersField,
  id: 'provider-input',
  iconType: 'magnifying-glass',
  rounded: '',
  labelKey: 'name',
  emptyLabel: 'Sorry, we could not find a match for that provider',
  placeholderError: 'Please enter a provider',
  placeholderDefault: 'Dr. John Smith, Imaging Center...',
  rules: [{ required: true }],
  minLength: 3,
  params: {},
  clearSuggested: clearSuggestedProviders,
  requestSuggested: requestSuggestedProviders,
  renderMenuItemClass: 'provider-item',
  className: 'provider-autocomplete',
  renderMenu: ProvidersMenu,
};

export const InsuranceProviderOption = Object.freeze({
  name: insuranceProviderField,
  id: 'insurance-provider-input',
  iconType: 'shield-checked',
  rounded: '',
  labelKey: 'name',
  emptyLabel: 'Sorry, we could not find a match for that insurance provider',
  placeholderDefault: 'Insurance Provider (Optional)',
  rules: [{ required: false }],
  minLength: 3,
  params: {},
  clearSuggested: {
    request: () =>
      requestInsuranceProviders.actions.success(
        {
          params: {},
        },
        { success: 1, data: null }
      ),
  },
  requestSuggested: requestInsuranceProviders,
  renderMenuItemClass: 'insurance-provider-item',
  className: 'insurance-provider-autocomplete',
  renderMenu: InsuranceProviderMenu,
});
