import React from 'react';
import { Button } from 'md-styled-components-v2';
import { Icon, Themes } from 'md-styled-components';
import { useSelector } from 'react-redux';
import {
  getCurrentUser,
  getIsGroupUser,
  getIsReportUser,
} from 'selectors/users';
import { useGetDirectUrlByUserType } from '../../hooks/index';
import './_styles/back-button.less';

const Default = () => {
  const currentUser = useSelector(getCurrentUser);
  const isReportUser = useSelector(getIsReportUser);
  const isGroupUser = useSelector(getIsGroupUser);
  const getDirectUrlByUserType = useGetDirectUrlByUserType(
    currentUser.groupInfo?.GroupId,
    false
  );

  return (
    <Button
      type='text'
      href={getDirectUrlByUserType}
      className='back-button flex-display flex-align-items-center'
    >
      <Icon
        type='chevron-next'
        iconClassName='mr--4'
        size={12}
        color={Themes.indigo}
        className='back-button-icon'
      />
      {isGroupUser || isReportUser
        ? 'Back to Tendo Marketplace'
        : 'Back to MDsave Internal'}
    </Button>
  );
};

export default Default;
