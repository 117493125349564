import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  getCurrentUser,
  getIsPortalUser,
  getShowDirectNavbar,
  notCompleteProfile,
} from 'selectors/users';
import Direct from 'components/Navigation/Direct';
import { useLocation } from 'react-router-dom';
import TopNavigation from 'components/Navigation/TopNavigation';
import Hospitals from 'components/Navigation/TopNavigation/Hospitals';
import Default from 'components/Navigation/TopNavigation/Default';
import NavbarSearch from 'components/NavbarSearch';
import common from 'constants/common';
import { useMobileNavbar } from 'components/NavbarSearch/_context/NavbarMobileProvider';
import { downloadFile } from 'utils/downloadFile';
import { getDecodeCookie } from 'utils/cookieHelper';
import { hideDirectNavbar } from '../helpers/index';
import 'components/styles/direct-navbar.less';
import './_styles/navbar-search.less';
import './_styles/top-spacing.less';

const PageDirectNavbar = ({ navbarFixed, pageType }) => {
  const currentUser = useSelector(getCurrentUser);
  const isPortalUser = useSelector(getIsPortalUser);
  const location = useLocation();
  const showDirectNavbar = useSelector(getShowDirectNavbar);
  const isNotCompleteProfile = useSelector(notCompleteProfile);
  const { searchVisible } = useMobileNavbar();
  const getGroupId = new URLSearchParams(location.search).get('GroupId');
  const [groupId, setGroupId] = useState(getGroupId);
  const isGroupPage = pageType === common.groupPage;
  const isCmsPage = pageType === common.cmsPage;

  useEffect(() => {
    setGroupId(getGroupId);
  }, [getGroupId, groupId]);

  useEffect(() => {
    if (currentUser?.id) {
      downloadFile(
        '/api/publicSite/downloadFile',
        getDecodeCookie('hasDownloadFile')
      );
    }
  }, [currentUser]);

  return (
    !hideDirectNavbar(isPortalUser) &&
    showDirectNavbar && (
      <>
        {!isCmsPage && (
          <TopNavigation navFixed={navbarFixed}>
            {isGroupPage ? <Hospitals groupId={groupId} /> : <Default />}
          </TopNavigation>
        )}
        {(isGroupPage || isCmsPage) && !isNotCompleteProfile && (
          <Direct
            groupId={groupId}
            isGroupPage={isGroupPage}
            isCmsPage={isCmsPage}
            navFixed={navbarFixed}
          />
        )}
        {isCmsPage && (
          <NavbarSearch
            headerSearchClass={`ph--40 ${
              navbarFixed
                ? 'navbar-search--fixed navbar-search--fixed-top pr--16'
                : ''
            }`}
            isCmsPage
          />
        )}
        {navbarFixed && isCmsPage && (
          <div
            className={
              searchVisible ? 'top-spacing--search-visible' : 'top-spacing'
            }
          />
        )}
      </>
    )
  );
};

PageDirectNavbar.propTypes = {
  navbarFixed: propTypes.bool,
  pageType: propTypes.string,
};
export default React.memo(PageDirectNavbar);
