import { InsuranceProvider } from 'components/Deductible/_partials/Form/InsuranceProvider';
import { Col, Row } from 'md-styled-components';
import { Form, Input } from 'md-styled-components-v2';
import { useSelector } from 'react-redux';
import { getCurrentCompanyInfo } from 'selectors/users';
import { useCheckout } from '../../../../pages/Checkout/_context/CheckoutProvider';

const { Item } = Form;
const InsuranceFields = () => {
  const form = Form.useFormInstance();
  const selectedInsuranceProvider = Form.useWatch('insurance_provider', form);
  const { currentCompany } = useSelector(getCurrentCompanyInfo);
  const { state: { selectedCompany } = {} } = useCheckout();

  if (!currentCompany && !selectedCompany) {
    return null;
  }
  const { enableInsuranceProvider, includeInsuranceInformation } =
    currentCompany || selectedCompany || {};

  return (
    <Row gutter={[16, 0]}>
      {enableInsuranceProvider && (
        <Col span={24}>
          <Item hidden name='insurance_provider' />
          <InsuranceProvider
            className='mb--16'
            hasValidate
            selectedProvider={selectedInsuranceProvider?.name}
            handleSelectProvider={(_, item) => {
              form.setFieldsValue({ insurance_provider: item.data });
            }}
          />
        </Col>
      )}
      {includeInsuranceInformation && (
        <>
          <Col span={12}>
            <Item
              wrapperCol={{ span: 24 }}
              label='Member ID'
              name='insurance_member_id'
              className='phi'
              rules={[{ required: true, message: '${label} cannot be blank.' }]}
            >
              <Input type='text' />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              wrapperCol={{ span: 24 }}
              label='Group ID'
              name='insurance_group_id'
              className='phi'
              rules={[{ required: true, message: '${label} cannot be blank.' }]}
            >
              <Input type='text' />
            </Item>
          </Col>
        </>
      )}
    </Row>
  );
};

export default InsuranceFields;
