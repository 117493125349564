import { useState } from 'react';
import { debounce } from 'lodash';
import useAxios from 'axios-hooks';
import { common } from 'constants/index';

const { searchMinLength } = common;

export const useInputHandler = () => {
  const [isTyped, setIsTyped] = useState(false);
  const [inputValue, setInputValue] = useState();

  const [
    { data: systemAndGroup = null, loading: systemAndGroupLoading },
    getSystemAndGroup,
  ] = useAxios('/company/getSystemAndGroups', {
    manual: true,
    autoCancel: false,
  });

  const debouncedResults = debounce((params) => {
    getSystemAndGroup({ params });
    !isTyped && setIsTyped(true);
  }, 500);

  const handleChange = ({ params }) => {
    setInputValue(params.searchText);
    if (params.searchText.length >= searchMinLength) {
      debouncedResults(params);
    } else {
      setIsTyped(false);
    }
  };
  return {
    handleChange,
    systemAndGroup,
    systemAndGroupLoading,
    isTyped,
    inputValue,
  };
};
