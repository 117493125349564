import React, { memo } from 'react';
import { Typography, Icon, Dropdown, Themes } from 'md-styled-components';
import ContactUsOverlay from './ContactUsOverlay';
import '../_styles/contact-us.less';

const { Text } = Typography;

const ContactUs = () => (
  <div className='top-contact-us'>
    <Dropdown
      className='pointer'
      overlayClassName='top-contact-us__overlay'
      placement='bottomRight'
      trigger={['hover']}
      dropdownRender={() => (
        <div className='top-contact-us__container relative'>
          <ContactUsOverlay />
        </div>
      )}
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      <div className='top-contact-us__dropdown-title'>
        <Text level={3}>Contact Us</Text>
        <Icon
          size={12}
          color={Themes.indigo}
          type='chevron-down'
          className='ml--8'
        />
      </div>
    </Dropdown>
  </div>
);

export default memo(ContactUs);
