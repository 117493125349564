/**
 * Utility functions for managing checkout loading indicators and validating form fields.
 */

import {
  B_DAY,
  B_MONTH,
  B_YEAR,
  EMAIL_ADDRESS,
  FIRST_NAME,
  LAST_NAME,
  MOBILE_PHONE,
} from '../Form/_constants/fieldNames';
import { SET_PAGE_LOADING } from '../constants';
import { checkoutRequest } from '../Form/_actions/actions';

/**
 * Toggles the checkout loading state.
 * This function dispatches an action to update the checkout loading state in the context.
 * The `pageLoading` property is set based on the provided visibility parameter.
 *
 * @param {Function} dispatch - The dispatch function from the checkout context to handle state updates.
 * @param {boolean | null} visibility - Determines the new loading state:
 *                                       - `true`: Enables the loader.
 *                                       - `false`: Disables the loader.
 *                                       - `null`: Leaves the current loading state unchanged.
 *
 * @returns {void}
 */
export const toggleCheckoutLoading = (dispatch, visibility) => {
  dispatch({
    type: SET_PAGE_LOADING,
    pageLoading: visibility,
  });
};

/**
 * Validates form fields and toggles the checkout loading indicator.
 * This function validates the provided form fields. If validation is successful,
 * it enables the checkout loading indicator. If validation fails, it scrolls to
 * the first field with an error to help the user address it.
 *
 * @param {Object} form - The form instance containing fields to validate.
 * @param {Function} form.validateFields - Method to validate all form fields.
 * @param {Function} form.scrollToField - Method to scroll to a specific field in case of validation errors.
 * @param {Function} dispatch - The dispatch function from the checkout context to toggle loading state.
 *
 * @returns {Promise<boolean>} Resolves to `true` if validation is successful, `false` otherwise.
 */
export const validateAndToggleLoading = async (form, dispatch) => {
  try {
    await form.validateFields();
    const formData = form.getFieldsValue();
    toggleCheckoutLoading(dispatch, true);
    const { data, error } = await checkoutRequest({
      PublicBuyForm: { ...formData },
      ajax: 'checkout-form',
    });
    if (error) {
      const errors = new Error();
      errors.errorFields = data;
      throw errors;
    }
  } catch (e) {
    const firstErrorField =
      e.errorFields?.[0]?.name || Object.keys(e.errorFields)[0];
    form.scrollToField(firstErrorField, { block: 'center' });
    toggleCheckoutLoading(dispatch, false);
    return false;
  }
  return true;
};

/**
 * Checks if any of the required fields in the patient object are empty.
 * This function verifies that all required fields for a patient are present and non-empty.
 * If any required field is missing or empty, it returns true.
 * @param {Object} patient - The patient object containing various field values.
 * @param {string} patient[FIRST_NAME] - The patient's first name.
 * @param {string} patient[LAST_NAME] - The patient's last name.
 * @param {string} patient[B_DAY] - The patient's birthday.
 * @param {string} patient[B_MONTH] - The patient's birth month.
 * @param {string} patient[B_YEAR] - The patient's birth year.
 * @param {string} patient[EMAIL_ADDRESS] - The patient's email address.
 * @param {string} patient[MOBILE_PHONE] - The patient's mobile phone number.
 * @returns {boolean} - Returns true if any required field is empty, false otherwise.
 */
export const someOfRequiredIsEmpty = (patient) => {
  const requiredFields = [
    FIRST_NAME,
    LAST_NAME,
    B_DAY,
    B_MONTH,
    B_YEAR,
    EMAIL_ADDRESS,
    MOBILE_PHONE,
  ];
  return Boolean(patient) && requiredFields.some((field) => !patient[field]);
};
