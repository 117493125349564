import client from './client';

export const getProviderInfoApi = ({ params }) =>
  client.get('/api/provider/getProviderInfo', { params });

export const getCurrentProcedureApi = ({ params }) =>
  client.get('/api/provider/getCurrentProcedure', { params });

export const getAdditionalProceduresApi = ({ params }) =>
  client.get('/api/provider/getAdditionalProcedures', { params });

export const getProviderReviews = ({ params }) =>
  client.post('/api/provider/getProviderReviews', { ...params });

export const getPartyData = ({ params }) =>
  client.get('/api/provider/getPartyData', { params });

export const getVisitProceduresApi = ({ params }) =>
  client.get('/api/provider/getVisitProcedures', { params });

export const getEquivalentProceduresApi = ({ params }) =>
  client.post('/api/provider/getEquivalentProcedures', { ...params });
