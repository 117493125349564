import React from 'react';
import propTypes from 'prop-types';
import DefaultModal from './DefaultModal';
import { logout } from '../../utils/user';

const SignOutConfirm = React.memo(
  ({ visibleSignOut = false, setVisibleSignOut }) => (
    <DefaultModal
      visible={visibleSignOut}
      onSuccess={() => logout()}
      onCancel={() => setVisibleSignOut(false)}
      cancelButtonText='Cancel'
      successButtonText='Sign Out'
      text='Are you sure you want to sign out?'
      title='Sign Out'
      buttonType='primary'
    />
  )
);

SignOutConfirm.propTypes = {
  setVisibleSignOut: propTypes.func.isRequired,
  visibleSignOut: propTypes.bool,
};

export default SignOutConfirm;
