/**
 * Date format.
 * @type {string}
 */
export const DATE_FORMAT = 'MM/DD/YYYY';
/**
 * Appointment status: No appointment.
 * @type {number}
 */
export const HAVE_AN_APPOINTMENT = 2;
