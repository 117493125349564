import styled, { css } from 'styled-components';
import { Radio as radio } from 'antd';
import PropTypes from 'prop-types';
import themes from '../Themes';

/** @type {Component} */
const RadioGroup = styled(radio.Group)`
  &.ant-radio-group {
    font-family: ${themes['font-sans-book']};
    color: ${themes.I90};

    .ant-radio {
      height: 20px;
      width: 20px;

      .ant-radio-inner,
      &:hover .ant-radio-inner,
      &-wrapper:hover .ant-radio,
      &-input:focus + .ant-radio-inner {
        border-color: ${(props) =>
          !props.disabled ? themes.I50 : themes.seafoam};
      }

      &-input:focus + .ant-radio-inner {
        box-shadow: none;
        border-color: ${(props) =>
          !props.disabled ? themes['primary-cta'] : themes.I50};
        border-width: 2px;

        &:after {
          top: 2px;
          left: 2px;
        }
      }

      .ant-radio-inner {
        width: 20px;
        height: 20px;
        transition: none;
        background: transparent;

        &:after {
          top: 3px;
          left: 3px;
          width: 12px;
          height: 12px;
          background: ${(props) =>
            !props.disabled ? themes['primary-cta'] : themes.I50};
          transition: none;
          transform: scale(1);
          margin-block-start: unset;
          margin-inline-start: unset;
        }
      }

      &-disabled {
        + span {
          color: ${themes.I50} !important;
        }

        .ant-radio-inner {
          background-color: ${themes.white};
          border-color: ${themes.I50} !important;

          &:after {
            background: ${themes.I50};
          }
        }
      }
    }

    /* used for Type: default and Type: secondary */

    .ant-radio-button-wrapper {
      font-size: ${themes['font-size-base']};
      font-family: ${themes['font-sans-book']};
      border-color: ${themes.I20};
      border-radius: 0;

      &:not(.ant-radio-button-wrapper-checked) {
        span {
          color: ${themes.I90};
        }
      }

      &:before {
        background-color: ${themes.I20};
      }

      &:hover {
        color: ${themes.I90};
      }

      &-checked {
        color: ${themes.astral};
        background: ${themes.B05};
        border-color: ${themes.astral} !important;

        &:before {
          background-color: ${themes.astral};
        }

        &:active,
        &:hover {
          color: ${themes.astral};
        }
      }

      .ant-radio-button-checked {
        background: ${themes.B05};
      }

      ${(props) =>
        props.size === 'middle' &&
        css`
          line-height: 30px;
          height: 32px;
        `};
    }

    ${(props) =>
      props.type &&
      props.type === 'secondary' &&
      css`
        border: 1px solid ${themes.I50};
        border-radius: 50px;
        padding: 3px;
        background-color: ${themes.white};

        .ant-radio-button-wrapper {
          border: none;
          line-height: inherit !important;
          height: 100% !important;
          border-radius: 50px;
          box-shadow: none;
          padding: 7px 11px;

          .ant-radio-button-checked {
            background: ${themes['gold-medium']};
            border-radius: 50px;
          }
        }

        .ant-radio-button-wrapper:not(:first-child)::before {
          background: none;
        }

        .ant-radio-button-wrapper:not(:last-child) {
          margin-right: -5px;
        }
      `};
  }
`;

RadioGroup.propTypes = {
  type: PropTypes.string,
  size: PropTypes.oneOf(['small', 'middle', 'large']),
};

RadioGroup.defaultProps = {
  size: 'middle',
};

export default RadioGroup;
