import React, {
  useState,
  useCallback,
  Fragment,
  useEffect,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Row,
  Col,
  FormattedPrice,
  Divider,
} from 'md-styled-components';
import { Button, Link, Select } from 'md-styled-components-v2';
import { useIsDesktop } from 'hooks';
import {
  removeItem,
  requestChangeQty,
  requestSetDiscountItem,
  requestClearCart,
} from 'actions/shopping-cart';
import { requestSaveCart } from 'actions/patients';
import { getAllItems, getProcedures } from 'selectors/shopping-cart';
import {
  getIsDirectUser,
  getIsGuestUser,
  getIsPortalUser,
  getCurrentCompanyInfo,
} from 'selectors/users';
import ProcedureTags from 'components/ProcedureTags';
import ShopCartNoteModal from 'components/Modals/ShopCartNoteModal';
import ShareCart from 'components/Cart/share';
import ShareCartText from 'components/Cart/ShareCartText';
import DirectCart from './DirectCart';
import SameSessionDiscount from './SameSessionDiscount';
import RemoveConfirmation from './RemoveConfirmation';
import './_styles/shopping-cart-modal.less';
import { useOptionsShoppingCart } from '../Navigation/ShoppingCart/_hooks';
import { useChangeProductQuantityTrack } from './_hooks/useChangeProductQuantityTrack';

const { Paragraph, Text } = Typography;

export default React.memo((props) => {
  const isDesktop = useIsDesktop();
  const dispatch = useDispatch();
  const [visibleRemoveModal, setVisibleRemoveModal] = useState(false);
  const [isQuantityClick, setIsQuantityClick] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const [removeId, setRemoveId] = useState(null);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const totalData = useSelector(getAllItems);
  const procedures = useSelector(getProcedures);
  const isDirectUser = useSelector(getIsDirectUser);
  const isPortalUser = useSelector(getIsPortalUser);
  const { currentCompany } = useSelector(getCurrentCompanyInfo);
  const isGuest = useSelector(getIsGuestUser);
  const refs = useRef([]);
  const [noteModalVisible, setNoteModalVisible] = useState(false);
  const { toggleCart } = useOptionsShoppingCart();
  if (currentCompany) {
    totalData.isGFE = false;
  }

  useChangeProductQuantityTrack();

  const removeShoppingCart = useCallback(
    () => procedures && procedures.length === 1 && !isGuest && isDirectUser,
    [procedures, isGuest, isDirectUser]
  );
  const removeCurrentItem = (id, mpprPrice) => {
    if (isGuest || !isDirectUser) {
      dispatch(
        removeItem.request({ id: mpprPrice ? `${id}${mpprPrice}` : id })
      );
    } else {
      setVisibleRemoveModal(true);
      !removeShoppingCart() &&
        setRemoveId(mpprPrice ? `${id}${mpprPrice}` : id);
    }
    procedures.length === 1 && toggleCart(false);
  };

  const dispatchChangeQty = (params) => {
    dispatch(requestChangeQty.request(params));
  };

  const assignRef = (element, index) => {
    if (refs.current.length < procedures.length) {
      refs.current = Array(procedures.length).fill(null);
    }
    refs.current[index] = element;
  };

  const changeQuantity = (id, value, mpprPrice) => {
    const itemId = mpprPrice ? `${id}${mpprPrice}` : id;
    dispatchChangeQty({ id, item: itemId, quantity: value });
    dispatch(requestSetDiscountItem.request({ quantity: value, itemId }));
  };

  const toggleQuantityClick = useCallback(
    (isMouseEnter) => setIsQuantityClick(isMouseEnter),
    [setIsQuantityClick]
  );
  const selectQuantity = document.querySelector(
    `.shoppingCart__quantity-${selectedQuantity}`
  );
  const handleScroll = useCallback(() => {
    if (selectQuantity && isQuantityClick && refs.current[selectedQuantity]) {
      selectQuantity.parentNode.classList.add('display-none');
      refs.current[selectedQuantity].blur();
    }
    toggleQuantityClick(false);
  }, [
    selectQuantity,
    isQuantityClick,
    refs,
    selectedQuantity,
    toggleQuantityClick,
  ]);

  let removeModalProp = {
    text: 'Are you sure you want to remove this procedure?',
    cancelButtonText: 'No',
    successButtonText: 'Yes',
    onSuccess: () => {
      removeShoppingCart()
        ? dispatch(
            requestClearCart.request({
              cb: () =>
                dispatch(
                  requestSaveCart.request({ data: { closeCart: true } })
                ),
            })
          )
        : dispatch(
            removeItem.request({
              id: removeId,
            })
          );
      setVisibleRemoveModal(false);
    },
  };

  if (totalData.isSplitPaymentEnabled) {
    removeModalProp = {
      text: 'Are you sure you want to remove all procedures?',
      cancelButtonText: 'Cancel',
      successButtonText: 'Yes, Proceed',
      title: 'Delete Shopping Cart',
      buttonClassName: 'default',
      onSuccess: () => {
        dispatch(
          requestClearCart.request({
            cb: () =>
              dispatch(requestSaveCart.request({ data: { closeCart: true } })),
          })
        );
        setVisibleRemoveModal(false);
      },
    };
  }

  useEffect(() => {
    isDesktop && document.addEventListener('scroll', handleScroll);
    return () => {
      isDesktop && document.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, isDesktop]);
  if (!(procedures && procedures.length > 0)) {
    return null;
  }

  const showDirectCarts = isDirectUser && !props.mobile && !isPortalUser;
  return (
    <div className='pt--24 shopping-cart-modal'>
      {showDirectCarts && (
        <DirectCart
          totalData={totalData}
          openNoteModal={() => setNoteModalVisible(true)}
        />
      )}
      <div
        onScroll={handleScroll}
        className={`shoppingCart ${
          props.mobile ? `mobile${totalData.note && '__note-visible'}` : ''
        }`}
      >
        {procedures.map((cart, i) => (
          <Fragment key={`procedure-${i}`}>
            <Link
              to={cart.mppxUri}
              reloadDocument
              className='shoppingCart__hospital-link mb--4'
              size='large'
            >
              {cart.procedure}
            </Link>
            <Paragraph color='paleSky' level={3} className='mt--4'>
              {cart.medicalProvider}
            </Paragraph>
            <ProcedureTags
              procedureOffer={cart.procedure_offer}
              className='mt--8'
              breakPoints={{ lg: 1, md: 1, sm: 1 }}
            />
            <Row justify='space-around' className='mt--16'>
              <Col span={14} className='flex-display flex-align-items-center'>
                {totalData.isSplitPaymentEnabled ? (
                  <Paragraph color='paleSky'>Qty: {cart.quantity}</Paragraph>
                ) : (
                  <>
                    <Select
                      className='quantity-select shoppingCart__quantity-select mr--2'
                      ref={(element) => assignRef(element, i)}
                      style={{ width: 72 }}
                      value={cart.quantity}
                      onBlur={() => toggleQuantityClick(false)}
                      onFocus={() => {
                        selectQuantity &&
                          selectQuantity.parentNode.classList.remove(
                            'display-none'
                          );
                        toggleQuantityClick(true);
                      }}
                      onMouseEnter={() => setSelectedQuantity(i)}
                      onChange={(value) =>
                        changeQuantity(cart.id, value, cart.mppr_price)
                      }
                      popupClassName={`shoppingCart__quantity-${i} shoppingCart__quantity-select ${
                        props.navFixed
                          ? 'shoppingCart__quantity-select--fixed'
                          : ''
                      }`}
                    >
                      {Array.from(Array(100).keys()).map((numItem) => (
                        <Select.Option
                          key={numItem + 1}
                          value={numItem + 1}
                          className='quantity-select-option'
                        >
                          {numItem + 1}
                        </Select.Option>
                      ))}
                    </Select>
                    <Button
                      size='small'
                      type='link'
                      onClick={() =>
                        removeCurrentItem(cart.id, cart.mppr_price)
                      }
                      className='ml--16'
                    >
                      <Text level={3} color='linkColor'>
                        Remove
                      </Text>
                    </Button>
                  </>
                )}
              </Col>
              <Col span={10} className='mt--8 pt--2'>
                {!totalData.isSplitPaymentEnabled && (
                  <>
                    {(cart.is_deleted && (
                      <div className='pull-right'>N/A</div>
                    )) ||
                      (cart.mppr_price && (
                        <div className='flex-display flex-direction-column flex-align-items-bottom'>
                          <FormattedPrice
                            level={3}
                            small
                            remove
                            price={cart.total_price - cart.promotionalDiscount}
                          />
                          <FormattedPrice
                            price={` ${cart.mppr_price}`}
                            small
                            color='danger'
                            level={1}
                          />
                        </div>
                      )) || (
                        <div className='flex-display flex-align-items-bottom flex-direction-column'>
                          <FormattedPrice
                            price={` ${
                              cart.total_price - cart.promotionalDiscount
                            }`}
                            level={1}
                            small
                          />
                        </div>
                      )}
                  </>
                )}
              </Col>
            </Row>
            {cart.mppr_price ? (
              <SameSessionDiscount containerClassName='flex-display flex-justify-end' />
            ) : null}
            {!totalData.isSplitPaymentEnabled &&
            cart.old_price &&
            !cart.is_deleted ? (
              <div className='pt--8 flex-display flex-align-items-center'>
                <Paragraph level={3} color='danger' className='pr--4'>
                  Price changed from
                </Paragraph>
                <FormattedPrice
                  price={cart.old_price}
                  small
                  level={3}
                  color='danger'
                />
              </div>
            ) : null}
            {cart.is_deleted ? (
              <Paragraph level={3} color='danger' className='pt--8'>
                No longer offered
              </Paragraph>
            ) : null}
            {procedures.length !== i + 1 && (
              <Divider className='shopping-cart__divider' />
            )}
          </Fragment>
        ))}
      </div>
      <div className='pv--8 ph--24 mb--8'>
        <Row justify='space-around' align='middle'>
          <Divider className='shopping-cart__divider shopping-cart__divider--last' />
          <Col span={12}>
            <Text level={3} color='paleSky'>
              {totalData.isSplitPaymentEnabled ? 'Your Total*' : 'Subtotal'}
            </Text>
          </Col>
          <Col span={12}>
            <div className='shopping-cart__total-price-wrapper'>
              <FormattedPrice
                level={5}
                price={totalData.total_price - totalData.promotionalDiscount}
                fontFamily='heavy'
              />
            </div>
          </Col>
          {totalData.isSplitPaymentEnabled && (
            <Col span={24} className='mt--16'>
              <Text level={3} color='paleSky'>
                *The remaining balance will be paid by{' '}
                {totalData.organizationName}.
              </Text>
            </Col>
          )}
        </Row>
        <Row justify='space-around' align='middle' className='mt--16'>
          <Button
            href='/checkout'
            id='checkout-cart'
            type='secondary'
            size='large'
            block
          >
            <Text color='white' fontFamily='heavy'>
              Check Out
            </Text>
          </Button>
          {totalData.isSplitPaymentEnabled && (
            <Button
              type='link'
              size='large'
              className='mt--16 mb--8'
              onClick={() => setVisibleRemoveModal(true)}
            >
              Delete Shopping Cart
            </Button>
          )}
        </Row>
        {(showDirectCarts || currentCompany?.enableSharedCarts) && (
          <>
            <Button
              className='mt--8'
              type='tertiary'
              size='large'
              block
              onClick={() => setIsShareModalVisible(true)}
              children={<ShareCartText isGoodFaithEnabled={totalData.isGFE} />}
            />
            <ShareCart
              isModalVisible={isShareModalVisible}
              setIsModalVisible={setIsShareModalVisible}
              data={totalData}
            />
          </>
        )}
      </div>
      <ShopCartNoteModal
        visible={noteModalVisible}
        onClose={() => setNoteModalVisible(false)}
        existingNote={totalData.note}
      />
      <RemoveConfirmation
        visible={visibleRemoveModal}
        onCancel={() => setVisibleRemoveModal(false)}
        {...removeModalProp}
      />
    </div>
  );
});
