import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getIsPortalUser } from 'selectors/users';

export const useUpdateFavicons = () => {
  const isPortalUser = useSelector(getIsPortalUser);

  const updateMetaTag = (metaTag) => {
    const currentContent = metaTag.getAttribute('content');
    if (currentContent.includes('favicon')) {
      const parts = currentContent.split('favicon');
      metaTag.setAttribute(
        'content',
        `${parts[0]}favicon/md-ms-icon-cc-144x144.png`
      );
    }
  };

  const updateManifest = (manifest) => {
    const currentHref = manifest.getAttribute('href');
    const parts = currentHref.split('manifest.json');
    manifest.setAttribute('href', `${parts[0]}manifest-cc.json${parts[1]}`);
  };

  const updateLinkTag = (link) => {
    const sizes = link.getAttribute('sizes');
    if (!sizes) return;

    const iconMap = {
      '57x57': '/md-apple-icon-cc-57x57.png',
      '60x60': '/md-apple-icon-cc-60x60.png',
      '72x72': '/md-apple-icon-cc-72x72.png',
      '76x76': '/md-apple-icon-cc-76x76.png',
      '114x114': '/md-apple-icon-cc-114x114.png',
      '120x120': '/md-apple-icon-cc-120x120.png',
      '144x144': '/md-apple-icon-cc-144x144.png',
      '152x152': '/md-apple-icon-cc-152x152.png',
      '180x180': '/md-apple-icon-cc-180x180.png',
      '192x192': '/md-android-icon-cc-192x192.png',
      '32x32': '/md-favicon-cc-32x32.png',
      '96x96': '/md-favicon-cc-96x96.png',
      '16x16': '/md-favicon-cc-16x16.png',
    };

    const newHref = iconMap[sizes];
    if (newHref) {
      const currentHref = link.getAttribute('href');
      if (currentHref.includes('favicon')) {
        const parts = currentHref.split('favicon');
        link.setAttribute('href', `${parts[0]}favicon${newHref}`);
      }
    }
  };

  useEffect(() => {
    if (!isPortalUser) return;

    const metaTag = document.querySelector(
      'meta[name="msapplication-TileImage"]'
    );
    if (metaTag) updateMetaTag(metaTag);

    const manifest = document.querySelector('link[rel="manifest"]');
    if (manifest) updateManifest(manifest);

    const linkTags = document.querySelectorAll(
      'link[rel="icon"], link[rel="apple-touch-icon"]'
    );
    linkTags.forEach(updateLinkTag);
  }, [isPortalUser]);
};
