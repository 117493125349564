import React, { useState, useMemo, Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { getPrivateHospitalData } from 'selectors/privateHospitalData';
import { common } from 'constants';
import HeaderSearchWrapper from './_partials/HeaderSearchWrapper';
import './styles/index.less';

const ProceduresTabItem = lazy(() => import('./ProceduresSearch'));
const ProceduresInsuranceTabItem = lazy(() =>
  import('./ProceduresInsuranceSearch')
);
const ProvidersTabItem = lazy(() => import('./ProvidersSearch'));
const PrefixSelect = lazy(() => import('./PrefixSelect'));

const { proceduresField } = common.heroSearchFields;

const HeaderSearchRC = ({
  searchType: type,
  searchFields,
  toggleSearchVisible,
}) => {
  const [searchType, setSearchType] = useState(type || proceduresField);
  const { insuranceExists } = useSelector(getPrivateHospitalData);

  const headerTabs = useMemo(() => {
    switch (true) {
      case insuranceExists:
        return (
          <ProceduresInsuranceTabItem
            suffixBtn
            searchFields={searchFields}
            toggleSearchVisible={toggleSearchVisible}
          />
        );
      case searchType === proceduresField:
        return (
          <>
            <ProceduresTabItem
              suffixBtn
              searchFields={searchFields}
              Select={
                <PrefixSelect
                  searchType={searchType}
                  setSearchType={setSearchType}
                />
              }
            />
          </>
        );
      default:
        return (
          <ProvidersTabItem
            suffixBtn
            searchFields={searchFields}
            Select={
              <PrefixSelect
                searchType={searchType}
                setSearchType={setSearchType}
              />
            }
          />
        );
    }
  }, [insuranceExists, searchFields, searchType, toggleSearchVisible]);

  return (
    <Suspense fallback={<></>}>
      <HeaderSearchWrapper>{headerTabs}</HeaderSearchWrapper>
    </Suspense>
  );
};

HeaderSearchRC.propTypes = {
  searchType: propTypes.string,
  searchFields: propTypes.object,
  toggleSearchVisible: propTypes.func,
};

export default HeaderSearchRC;
