import { forwardRef, useState } from 'react';
import { Spin, Icon, Themes } from 'md-styled-components';
import { AutoComplete, Input } from 'md-styled-components-v2';
import {
  useAutocompleteProps,
  useInit,
  useInputChange,
  useOnSelect,
} from './hooks';
import renderMenu from './renderMenu';
import useZIndex from '../hooks';

const Location = forwardRef(({ suffix = '' }, ref) => {
  const [inputValue, setInputValue] = useState('');
  const inputId = `location-input${suffix}`;
  const getZIndex = useZIndex();
  const zIndex = getZIndex(suffix);

  const { placeholder, emptyLabel, errorClassName } = useAutocompleteProps();
  const { onInputChange, data, loading } = useInputChange(setInputValue);
  const onSelect = useOnSelect(setInputValue);

  useInit(setInputValue);

  return (
    <AutoComplete
      id={inputId}
      className='item item-second'
      notFoundContent={loading ? <Spin /> : !data.length && emptyLabel}
      dropdownStyle={{ zIndex }}
      value={inputValue}
      options={renderMenu(data, inputValue)}
      onSelect={onSelect}
    >
      <Input
        className={`pv--8 ${errorClassName}`}
        placeholder={placeholder}
        ref={ref}
        allowClear
        onChange={onInputChange}
        prefix={<Icon color={Themes.I90} type='marker' size={15} />}
      />
    </AutoComplete>
  );
});

export default Location;
