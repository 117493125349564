import React, { useRef } from 'react';
import { Form } from 'md-styled-components-v2';
import useOnFinish from '../../../Forms/ProviderForm/useOnFinish';
import Provider from '../../../Autocompletes/Provider';
import SearchButton from './SearchButton';

const ProviderForm = () => {
  const [form] = Form.useForm();
  const onFinish = useOnFinish(form);
  const providerRef = useRef(null);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      className='flex-display mds-full--width'
    >
      <Form.Item name='provider'>
        <Provider form={form} suffix='-navbar-select' ref={providerRef} />
      </Form.Item>
      <SearchButton />
    </Form>
  );
};

export default ProviderForm;
