export const setPlural = (name, count) => `${name}${(+count > 1 && 's') || ''}`;

export const getHelpingVerb = (count) => (+count > 1 ? 'are' : 'is');

export const ordinalSuffixOf = (number) => {
  const j = number % 10;
  const k = number % 100;
  switch (true) {
    case j === 1 && k !== 11:
      return `${number}st`;
    case j === 2 && k !== 12:
      return `${number}nd`;
    case j === 3 && k !== 13:
      return `${number}rd`;
    default:
      return `${number}th`;
  }
};
