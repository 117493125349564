import React from 'react';
import { Typography } from 'md-styled-components';
import SupportPhoneLink from 'components/Support/SupportPhoneLink';
import './_styles/index.less';

const { Paragraph } = Typography;

const PhoneNumberInfo = () => (
  <div className='powered-by-banner__phone-number flex-display flex-align-items-center mr--16'>
    <Paragraph level={3} color='grey' className='mr--4'>
      Call
    </Paragraph>
    <SupportPhoneLink
      loadNumber
      renderCustomText={(content) => (
        <Paragraph level={3} color='grey'>
          {content}
        </Paragraph>
      )}
    />
    <Paragraph level={3} color='grey' className='hidden-sm ml--8'>
      Se habla español
    </Paragraph>
  </div>
);

export default PhoneNumberInfo;
