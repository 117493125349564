import React, { useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { DefaultTabs, Themes } from 'md-styled-components';
import { Button } from 'md-styled-components-v2-direct';
import { tabList, buttonTitles } from '../constants';
import ShareTabPanel from './ShareTabPanel';

const useShareType = (isGoodFaithEnabled) =>
  isGoodFaithEnabled ? 'gfe' : 'main';

const ShareTabs = ({
  validTabFields,
  activeTabKey,
  isGoodFaithEnabled,
  ...props
}) => {
  const [activeTab, setActiveTab] = useState(tabList[activeTabKey]);
  const shareType = useShareType(isGoodFaithEnabled);
  const isBtnDisabled = useMemo(() => {
    if (!validTabFields.splitPaymentValid) {
      return true;
    }
    switch (activeTab.tab) {
      case 'phone':
        return !(
          validTabFields.newPhoneNumber && validTabFields.phonePermission
        );
      case 'print':
        return false;
      default:
        return !(validTabFields.emailAddress && validTabFields.emailPermission);
    }
  }, [activeTab.tab, validTabFields]);

  return (
    <>
      <DefaultTabs
        className='share-cart__tab'
        color={Themes.B70}
        defaultActiveKey={tabList[activeTabKey].tab}
        centered
        onChange={(activeKey) => {
          setActiveTab(tabList.find((val) => val.tab === activeKey));
        }}
        items={tabList.map((val) => ({
          label: (
            <ShareTabPanel
              activeTab={activeTab}
              item={val}
              shareType={shareType}
            />
          ),
          key: val.tab,
          children: val.renderContent(props),
        }))}
      />
      <div className='flex-display flex-justify-center'>
        <Button
          className='mt--16'
          type='primary'
          htmlType='submit'
          size='large'
          onClick={(e) => props.handelSend(e, activeTab.tab)}
          loading={props.loading}
          disabled={isBtnDisabled}
        >
          {buttonTitles[activeTab.tab][shareType]}
        </Button>
      </div>
    </>
  );
};

ShareTabs.propTypes = {
  validTabFields: propTypes.object,
  handelSend: propTypes.func.isRequired,
  selectedPatientCart: propTypes.object,
  inputEl: propTypes.object,
  loading: propTypes.bool,
  isGoodFaithEnabled: propTypes.bool.isRequired,
  activeTabKey: propTypes.number.isRequired,
};

export default ShareTabs;
