import {
  CHANGE_INITIAL_VALUE,
  CHANGE_IS_SSO_DOMAIN,
  CHANGE_LOGIN_TYPE,
  CHANGE_SSO_RADIO_TYPE,
} from '../_constants/actionTypes';

export const changeLoginTypeAction = (data) => ({
  type: CHANGE_LOGIN_TYPE,
  data,
});
export const changeIsSsoDomainAction = (isSso, ssoName) => ({
  type: CHANGE_IS_SSO_DOMAIN,
  data: { isSso, ssoName },
});
export const changeSsoRadioTypeAction = (data) => ({
  type: CHANGE_SSO_RADIO_TYPE,
  data,
});
export const changeEmailInitialValueAction = (data) => ({
  type: CHANGE_INITIAL_VALUE,
  data,
});
