import React from 'react';
import { Icon } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import {
  getCompanyUserCompanies,
  getCurrentCompanyInfo,
} from 'selectors/users';
import { useSelector } from 'react-redux';
import { useCompanyPricingGroupSwitch } from 'hooks/Company';

const Networks = () => {
  const companies = useSelector(getCompanyUserCompanies);
  const { currentPricingGroup, currentCompany } = useSelector(
    getCurrentCompanyInfo
  );
  const onSwitch = useCompanyPricingGroupSwitch();

  return (
    <div>
      {companies.companiesList.map((item) =>
        item.pricingGroups.map((childData) => {
          const isActive = childData.id === currentPricingGroup.id;

          return (
            <div key={childData.id} className='flex-display mb--24'>
              {isActive ? (
                <Icon type='check-bordered' size={20} className='mr--16' />
              ) : (
                <div className='ml--32 pl--4' />
              )}
              <Button
                type='link'
                onClick={() =>
                  onSwitch([
                    currentCompany.id,
                    childData.id,
                    childData.narrow_network,
                  ])
                }
              >
                {childData.name}
              </Button>
            </div>
          );
        })
      )}
    </div>
  );
};

export default Networks;
