import React, { Fragment, useState } from 'react';
import { Modal } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import { useViewCompanies } from './_hooks';
import './_styles/index.less';

const CompanyModal = () => {
  const { Title, View } = useViewCompanies();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        type='link'
        underline
        className='company-modal__title ml--8'
      >
        Change
      </Button>
      <Modal
        title={<Title />}
        className='company-modal'
        onCancel={() => setOpen(false)}
        footer={null}
        open={open}
      >
        <div className='company-modal__content pv--24 ph--8 '>
          <View />
        </div>
      </Modal>
    </>
  );
};

export default CompanyModal;
