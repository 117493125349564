import React from 'react';
import { Input } from 'md-styled-components-v2';
import { useLocation } from 'react-router-dom';

const HiddenInput = () => {
  const location = useLocation();
  const getUrlReferrer = () =>
    location ? `${location.pathname}${location.search}` : '';

  return (
    <Input
      id='hidden-input'
      type='hidden'
      name='RegisterPartForm[urlReferrer]'
      value={getUrlReferrer()}
    />
  );
};

export default HiddenInput;
