import client from './client';
import { shoppingCart } from '../constants';

export const getItemsApi = () => client.get('/shoppingCart/getItems');
export const removeItemApi = ({ params }) =>
  client.post('/shoppingCart/removeItem', {
    ...params,
    type: shoppingCart.TYPE_PROCEDURE,
  });
export const changeQtyApi = ({ params }) =>
  client.post('/shoppingCart/changeQuantity', {
    ...params,
    type: shoppingCart.TYPE_PROCEDURE,
  });
export const saveGroupUserData = ({ params }) =>
  client.post(`/shareCarts/saveGroupUsersData`, {
    ...params,
  });
export const removeCart = ({ params }) =>
  params?.localClear ||
  client.post(`/shareCarts/removeShoppingCart`, { cart: true, ...params });
export const setNote = ({ params }) =>
  client.post('/groups/shoppingCartNote', { ...params });
export const putItemApi = ({ params }) =>
  client.post('/shoppingCart/putItem', {
    ...((params && params.params) || {}),
    type: shoppingCart.TYPE_PROCEDURE,
  });

export const updatePhoneNumber = (phone) =>
  client.post('/public/updatePhoneNumber', {
    data: phone.replace(/\D/g, ''),
  });
