import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { Tabs as Component } from 'antd';
import Themes from '../Themes';

const DefaultTabs = styled(({ color, activeColor, ...props }) => (
  <Component {...props} />
))`
  .ant-tabs {
    &-nav:before {
      border-color: ${Themes.mercury};
    }

    &-tab {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &-btn {
        color: ${(props) => props.color};
      }

      &-active {
        .ant-tabs-tab-btn {
          color: ${(props) => props.activeColor};
        }
      }
    }

    &-ink-bar {
      background: ${(props) => props.activeColor};
    }
  }
`;

DefaultTabs.propTypes = {
  /** Current TabPane's key */
  activeKey: propTypes.string,
  /** Whether to change tabs with animation. Only works while tabPosition="top"\|"bottom" */
  animated: propTypes.oneOfType([
    propTypes.bool,
    propTypes.shape({
      inkBar: propTypes.bool,
      tabPane: propTypes.bool,
    }),
  ]),
  /** replace the TabBar */
  renderTabBar: propTypes.func,
  /** Initial active TabPane's key, if activeKey is not set. */
  defaultActiveKey: propTypes.string,
  /** Hide plus icon or not. Only works while type="editable-card" */
  hideAdd: propTypes.bool,
  /** preset tab bar size */
  size: propTypes.oneOf(['large', 'default', 'small']),
  /** Extra content in tab bar */
  tabBarExtraContent: propTypes.node,
  /** The gap between tabs */
  tabBarGutter: propTypes.number,
  /** Tab bar style object */
  tabBarStyle: propTypes.object,
  /** Position of tabs */
  tabPosition: propTypes.oneOf(['top', 'right', 'bottom', 'left']),
  /** Basic style of tabs */
  type: propTypes.oneOf(['line', 'card', 'editable-card']),
  /** Callback executed when active tab is changed */
  onChange: propTypes.func,
  /** Callback executed when tab is added or removed. Only works while type="editable-card" */
  onEdit: propTypes.func,
  /** Callback executed when next button is clicked */
  onNextClick: propTypes.func,
  /** Callback executed when prev button is clicked */
  onPrevClick: propTypes.func,
  /** Callback executed when tab is clicked */
  onTabClick: propTypes.func,
  tabPanePadding: propTypes.string,
  color: propTypes.string,
};

DefaultTabs.defaultProps = {
  color: Themes.I70,
  activeColor: Themes['primary-cta'],
};

/** @component */
export default DefaultTabs;
