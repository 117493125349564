import React, { useState } from 'react';
import { Icon, Themes } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import SignOutConfirm from 'components/Modals/SignOutConfirm';
import './_styles/index.less';

const SignOut = ({ withIcon }) => {
  const [visibleSignOut, setVisibleSignOut] = useState(false);

  return (
    <>
      <div className='user-info__item flex-display flex-align-items-center'>
        {withIcon && (
          <Icon
            type='sign-out'
            size={20}
            className='mr--24'
            color={Themes.indigo}
          />
        )}
        <Button
          type='text'
          className='user-info__button pointer'
          onClick={() => setVisibleSignOut(true)}
        >
          Sign Out
        </Button>
      </div>
      <SignOutConfirm
        visibleSignOut={visibleSignOut}
        setVisibleSignOut={setVisibleSignOut}
      />
    </>
  );
};

export default SignOut;
