import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { Row } from 'md-styled-components';
import { Form } from 'md-styled-components-v2';
import { useState } from 'react';
import FormItem from './FormItem';
import './_styles/form.less';
import { useCheckFutureDate } from './useCheckFutureDate';

const getYears = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 1901;
  return Array.from(
    { length: currentYear - startYear + 1 },
    (_, i) => currentYear - i
  );
};

const getDays = (date) =>
  Array.from({ length: date ? date.daysInMonth() : 31 }, (_, i) => {
    const day = i + 1;
    return `${day < 10 ? 0 : ''}${day}`;
  });

const DateMultiSelect = (props) => {
  const form = Form.useFormInstance();
  const {
    onChange,
    allowEmpty,
    validateTrigger,
    onBlur,
    defaultMonth,
    monthHidden = false,
    dayHidden = false,
    yearHidden = false,
    defaultDay,
    defaultYear,
    label,
    className = '',
    gutter = {
      md: 16,
      lg: 16,
    },
  } = props;

  const { setFieldsValue, getFieldError, resetFields } = form;

  const [days, setDays] = useState(getDays());
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [yearIsReset, setYearIsReset] = useState(false);
  const [monthIsReset, setMonthIsReset] = useState(false);
  const [dayIsReset, setDayIsReset] = useState(false);
  dayjs.extend(localeData);
  const { monthValidator, futureDateValidation } = useCheckFutureDate(form);

  const formatDays = (month, year) => {
    const selectedDate = dayjs(`${month} ${year}`, ['MM YYYY', 'YYYY', 'MM']);
    const daysInSelectedMonth = selectedDate.daysInMonth();

    if (daysInSelectedMonth !== days.length) {
      setDays(getDays(selectedDate));
      if (selectedDay > daysInSelectedMonth) {
        resetFields(['b_day']);
        setSelectedDay('');
        setDayIsReset(true);
      }
    }
  };

  const onMonthSelect = (month) => {
    if (month) {
      formatDays(month, selectedYear);
      setSelectedMonth(month);
      setFieldsValue({ b_month: month });
      return;
    }
    resetFields(['b_month']);
    setSelectedMonth('');
    setMonthIsReset(true);
  };

  const onDaySelect = (day) => {
    if (day) {
      setSelectedDay(day);
      return;
    }
    resetFields(['b_day']);
    setSelectedDay('');
    setDayIsReset(true);
  };

  const onYearSelect = (year) => {
    if (year) {
      selectedMonth && formatDays(selectedMonth, year);
      setSelectedYear(year);
      return;
    }
    resetFields(['b_year']);
    setSelectedYear('');
    setYearIsReset(true);
  };

  const onChangeField = (field, value) => {
    const values = {
      b_month: selectedMonth,
      b_day: selectedDay,
      b_year: selectedYear,
    };
    values[field] = value;
    onChange && onChange(null, values);
  };

  const handelOnBlur = () => {
    validateTrigger === 'onBlur' && futureDateValidation();
    onBlur?.();
  };

  return (
    <Row
      className='date-multi-select'
      gutter={gutter}
    >
      {!monthHidden && (
        <FormItem
          className={className}
          span={{ xs: 24, md: 12, lg: 12 }}
          fieldName='b_month'
          label={label || `Date of Birth${allowEmpty ? ' (Optional)' : ''} `}
          isReset={monthIsReset}
          setIsReset={setMonthIsReset}
          placeholder='Month'
          onSelect={(value) => {
            onMonthSelect(value);
            onChangeField('b_month', value);
          }}
          options={dayjs.months()}
          getOptionValue={(option) => dayjs(option, 'MMMM').format('MM')}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          getFieldError={getFieldError}
          allowEmpty={allowEmpty}
          validateTrigger={validateTrigger}
          onBlur={onBlur && (() => onBlur())}
          onSearch={
            onChange &&
            ((value) => {
              onMonthSelect(value);
              onChangeField('b_month', value);
            })
          }
          initialValue={defaultMonth}
          setFieldsValue={setFieldsValue}
          validator={monthValidator}
        />
      )}
      {!dayHidden && (
        <FormItem
          className={`hide-label ${className}`}
          isDay
          fieldName='b_day'
          label='_'
          isReset={dayIsReset}
          setIsReset={setDayIsReset}
          placeholder='Day'
          onSelect={(value) => {
            onDaySelect(value);
            onChangeField('b_day', value);
          }}
          options={days}
          getFieldError={getFieldError}
          allowEmpty={allowEmpty}
          validateTrigger={validateTrigger}
          onBlur={handelOnBlur}
          onSearch={
            onChange &&
            ((value) => {
              onDaySelect(value);
              onChangeField('b_day', value);
            })
          }
          initialValue={defaultDay}
          setFieldsValue={setFieldsValue}
        />
      )}
      {!yearHidden && (
        <FormItem
          className={`hide-label ${className}`}
          fieldName='b_year'
          isReset={yearIsReset}
          label='_'
          setIsReset={setYearIsReset}
          placeholder='Year'
          onSelect={(value) => {
            onYearSelect(value);
            onChangeField('b_year', value);
          }}
          options={getYears()}
          getOptionValue={(option) => option.toString()}
          getFieldError={getFieldError}
          allowEmpty={allowEmpty}
          validateTrigger={validateTrigger}
          onBlur={handelOnBlur}
          onSearch={
            onChange &&
            ((value) => {
              onYearSelect(value);
              onChangeField('b_year', value);
            })
          }
          initialValue={defaultYear}
          setFieldsValue={setFieldsValue}
        />
      )}
    </Row>
  );
};

export default DateMultiSelect;
