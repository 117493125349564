import React from 'react';
import { Typography, Themes, Icon } from 'md-styled-components';

const { Text } = Typography;

const Title = () => (
  <div className='flex-display flex-justify-space-between flex-align-items-center pr--24'>
    <div className='flex-display flex-align-items-center'>
      <Text>Contact Us</Text>
    </div>
    <Icon size={12} color={Themes.indigo} type='chevron-down' />
  </div>
);

export default Title;
