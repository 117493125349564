import { getCookie, getDecodeCookie } from 'utils/cookieHelper';
import { emailValidation } from 'utils/emailHelper';
import errors from 'utils/messages/errors';
import { APP_APTIBLE_URL } from '../../../config/env-vars';

const recentEmail = getDecodeCookie('recentEmail');
const errorStatus = getCookie('errorStatus');
const attemptsCount = getCookie('attemptsCount');
const loginErrorTextRef = getDecodeCookie('loginErrorText');

export const getEmailInitialValue = () =>
  (recentEmail && errorStatus) || attemptsCount || loginErrorTextRef
    ? recentEmail
    : '';

export const getUrl = (value) =>
  APP_APTIBLE_URL ? `${APP_APTIBLE_URL}${value}` : value;

export const loginErrorsHandler = (status, email, attempts, loginErrorText) => {
  const verifiedEmail = emailValidation(email);

  switch (true) {
    case Boolean(loginErrorText):
      return loginErrorText;
    case Boolean(attempts):
      return errors.ATTEMPTS_COUNT(attempts);
    case Boolean(status):
      if (errors[`${status}_WITH_EMAIL`] && verifiedEmail) {
        return errors[`${status}_WITH_EMAIL`](email);
      }
      return errors[status];
    default:
      return '';
  }
};
