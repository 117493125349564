import Theme from 'md-styled-components/Themes';

export default `
  &&{
    color: ${Theme.white};
    path {
      fill: ${Theme.white};
    }
      
    &:hover, &:visited {
      color: ${Theme.white};
      path {
       fill: ${Theme.white};
      }
    } 
      
    &[disabled] {
      color: ${Theme.I50};
      path { 
        fill: ${Theme.I50};
      }
    }
  }
`;
