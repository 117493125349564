import React, { useMemo } from 'react';
import { Icon, Tooltip } from 'md-styled-components';
import { Tag } from 'md-styled-components-v2-consumer';
import propTypes from 'prop-types';
import { useIsTablet, useIsDesktop } from 'hooks';
import { DATA } from './constants';
import './_styles/index.less';

const makeArray = (num) => Array(num).fill(true);

const TagGroup = ({ data, breakPoints, rowCount = 1 }) => {
  const mainBreakPoints = {
    lg: 3,
    md: 3,
    sm: 3,
    ...breakPoints,
  };
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();

  const breakpointArray = useMemo(() => {
    switch (true) {
      case isDesktop:
        return makeArray(mainBreakPoints.lg);
      case isTablet:
        return makeArray(mainBreakPoints.md);
      default:
        return makeArray(mainBreakPoints.sm);
    }
  }, [
    isDesktop,
    mainBreakPoints.lg,
    mainBreakPoints.md,
    mainBreakPoints.sm,
    isTablet,
  ]);

  const classContainer = useMemo(() => {
    switch (rowCount) {
      case 1:
        return 'flex-display flex-wrap';
      case 3:
        return 'flex-display flex-direction-column flex-align-items-top';
      default:
        return '';
    }
  }, [rowCount]);

  return (
    <div className={classContainer}>
      {data.map((el, idx) => {
        const { text, iconName, type, id, tooltipText } = DATA[el];

        const isRowFirstTag = idx < rowCount;
        return (
          <>
            {isRowFirstTag && idx !== 0 && <div className='mt--8' />}
            <Tooltip
              arrow
              overlayClassName='tag-tooltip'
              title={tooltipText}
              placement='bottom'
            >
              <Tag
                className={isRowFirstTag ? '' : 'ml--8'}
                icon={<Icon type={iconName} />}
                type={type}
                key={id}
              >
                {!!breakpointArray[idx] && text}
              </Tag>
            </Tooltip>
          </>
        );
      })}
    </div>
  );
};
TagGroup.propTypes = {
  data: propTypes.array.isRequired,
  breakPoints: propTypes.object,
  rowCount: propTypes.number,
};

export default TagGroup;
