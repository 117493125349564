import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import NavigationProvider from 'components/Navigation/_context/NavigationProvider';
import propTypes from 'prop-types';
import { getIsPortalUser } from 'selectors/users';
import PortalUserBanner from './_partials/PortalUserBanner';
import Default from './Default';
import Portal from './Portal';
import { useContainerClass } from './_hooks';
import './_styles/navigation.less';

const Consumer = ({ navFixed, isVisibleSearchIcon, searchAction }) => {
  const isPortalUser = useSelector(getIsPortalUser);
  const { className } = useContainerClass(navFixed);

  return (
    <NavigationProvider
      isVisibleSearchIcon={isVisibleSearchIcon}
      searchAction={searchAction}
    >
      <div className={className}>
        {isPortalUser ? (
          <>
            <PortalUserBanner />
            <Portal />
          </>
        ) : (
          <Default />
        )}
      </div>
    </NavigationProvider>
  );
};

Consumer.propTypes = {
  navFixed: propTypes.bool,
  isVisibleSearchIcon: propTypes.bool,
  searchAction: propTypes.func,
};

export default memo(Consumer);
