import React from 'react';
import { emailValidator } from 'utils/emailHelper';
import Field from './Field';

export default ({ errorMessage = '', ...props }) => {
  const fieldName = props.label || props.placeholder;
  const rules = props.rules || [
    emailValidator(
      errorMessage || `${fieldName} is not a valid email address.`
    ),
  ];

  return <Field {...props} decorator={{ rules }} validateTrigger='onBlur' />;
};
