import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import {
  Typeahead,
  Menu,
  MenuItem,
  Highlighter,
} from 'react-bootstrap-typeahead';
import { transparentize } from 'polished';
import Input from '../V2/Input';
import theme from '../Themes';

const inputStyles = css`
  height: 58px !important;

  ${breakpoint('xs', 'md')`
    height: 50px !important;
    margin-bottom: 8px;
    border: 1px solid ${theme.seafoam} !important;

    &.ant-select-focused {
      border-color: ${theme['primary-cta']};
    }
  `};

  .prefix-input {
    padding-left: 6px;

    .ant-input {
      background: transparent !important;
    }
  }

  .ant-select-dropdown {
    background-color: ${theme.black};
  }
`;

const AutoComplete = styled(Typeahead)`
  .dropdown-menu {
    width: 100%;
    border-left: 1px solid ${theme.seafoam};
    border-right: 1px solid ${theme.seafoam};
    margin-top: -1px;
    opacity: 1 !important;
    top: 100% !important;
    z-index: 3;
    pointer-events: all !important;
    background-color: ${theme.white};
    box-shadow: ${theme['box-shadow-light']};

    ${breakpoint('xs', 'md')`
      top: calc(100% - 8px) !important;
    `};
  }

  .dropdown-item {
    width: 100%;
    border-top: 1px solid ${theme.seafoam};
    padding: 8px 16px;
    display: block;
    line-height: 22px;

    &:last-child {
      border-bottom: 1px solid ${theme.seafoam};
    }
  }
`;

const RoundedInput = styled(Input)`
  ${(props) => props.size === 'large' && inputStyles};

  border-color: ${theme.seafoam};

  .ant-input-suffix {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover,
  &:active,
  &:focus {
    border-color: ${theme.seafoam} !important;
  }

  ${(props) =>
    props.rounded === 'right' &&
    css`
      border-radius: 0 50px 50px 0 !important;
    `};

  ${(props) =>
    props.rounded === 'rounded' &&
    css`
      border-radius: 50px !important;
    `};

  ${(props) =>
    props.rounded === 'left' &&
    css`
      border-radius: 50px 0 0 50px !important;
    `};

  .ant-input-lg {
    font-size: 18px !important;
  }
`;

const RoundedBlock = styled.div`
  .ant-form-item {
    width: 100%;
  }

  .ant-form-item-control-wrapper {
    width: 100%;
  }

  ${breakpoint('md')`
    .visible-sm {
      display: none;
    }
  `};
`;

const RoundedBlockItem = styled.div`
  margin-top: ${(props) => !props.noMargin && '5px'};
  position: relative;

  &.with-button {
    .has-clear-button {
      .ant-input {
        padding-right: 166px !important;
      }
    }
  }

  .ant-form-item {
    &-control .ant-input-affix-wrapper {
      border-right-width: 1px !important;
    }

    &-control-input-content {
      height: 100%;
    }

    &.ant-form-item-has-error {
      .ant-form-item-control {
        &,
        .ant-input-affix-wrapper {
          .ant-input {
            padding-right: 16px;
            background-color: ${theme.white};

            &::placeholder {
              color: ${theme.red};
            }
          }
        }
      }
    }
  }

  ${breakpoint('xs', 'md')`
    .hero-search-button {
      display: none;
    }
    
    .ant-input-suffix {
      right: 12px;
    }
    
    &.with-button, &.without-button {
      .has-clear-button {
        .ant-input {
          padding-right: 44px !important;
        }
      }
    }
  `};

  ${breakpoint('md')`
    margin: 0;
    
    .ant-input-suffix {
      right: 16px;
    }
    
    &.right-input-item {
      margin-left: 3px;
      
      .ant-form-item-has-feedback {
        .ant-input {
          padding-right: 168px !important;
        }
        
        .ant-form-item-children-icon {
          right: 139px !important;
        }
      }
    }

    &.with-button {
      .ant-input-affix-wrapper {
        padding-right: 10px;
      }

      .ant-input {
        padding-right: 128px !important;
      }

      .rbt-menu.dropdown-menu {
        width: calc(100% - 120px) !important;
      }
    }
    
    &&.without-button {
      .has-clear-button {
        .ant-input {
          padding-right: 56px;
        }
      }
    }

    .search-btn {
      position: absolute;
      top: 4px;
      right: 4px;
      z-index: 1;
    } 
  `};
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  height: fit-content;

  ${(props) =>
    !props.bg
      ? css`
          background: no-repeat,
            linear-gradient(
              -225deg,
              ${transparentize(0.46, `${theme['light-blue-start-3']}`)} -10%,
              ${transparentize(0.46, `${theme['dark-blue-end-3']}`)} 75%
            ),
            no-repeat,
            linear-gradient(
              -225deg,
              ${theme['light-blue-start-3']} -50%,
              ${theme['dark-blue-end-3']} 150%
            );
          background-blend-mode: normal, multiply;
        `
      : `background: ${props.bg};`};
`;

const Container = styled.div`
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;

  .ant-select-selection {
    border: none !important;
    cursor: auto;

    &:hover {
      border: none !important;
      box-shadow: ${theme['box-shadow-light']} !important;
    }

    .ant-form-item-control {
      line-height: 0 !important;
    }
  }
`;

const Logo = styled.div`
  color: white;
  padding: 15px;
  width: 100px;
  height: 100px;
  background: rgb(41, 98, 133);
  margin: 0 20px;
  line-height: 7;
`;

const TitleBlock = styled.div`
  max-width: 870px;
  margin: 0 20px;
  padding-top: 60px;
  z-index: 1;
  position: relative;

  ${breakpoint('xs')`
    margin-bottom: 0;
  `};

  ${breakpoint('md')`
    padding-top: 100px !important;
  `};
`;

const TextBlock = styled.div`
  margin: 15px 20px;
`;

const TabsBlock = styled.div`
  margin: ${(props) => (props.isPrivateHospital ? '47' : '6')}px 0 0;
  min-height: 130px;

  ${breakpoint('md')`
    margin: 79px 20px 81px;
    max-width: 870px;
  `};
`;

AutoComplete.Menu = Menu;
AutoComplete.MenuItem = MenuItem;
AutoComplete.Highlighter = Highlighter;

export default {
  AutoComplete,
  RoundedInput,
  RoundedBlock,
  RoundedBlockItem,
  Wrapper,
  Container,
  Logo,
  TitleBlock,
  TextBlock,
  TabsBlock,
};
