import React from 'react';
import styled from 'styled-components';
import { DatePicker as Component } from 'antd';
import propTypes from 'prop-types';
import themes from '../Themes';
import Icon from '../Icon';
import './index.less';

const DatePickerWrapper = styled(Component)`
  &&&& {
    width: 100%;
    height: 40px;
    border: ${themes['border-width-base']} ${themes['border-style-base']}
      ${themes.I50};
    border-right-width: 1px !important;
    border-radius: 0;
    display: ${(props) => props.display || 'block'};
    box-shadow: none;
    padding-right: ${themes['font-size-base']};

    &:hover {
      border-color: ${themes.I50} !important;
    }

    &:hover,
    &:focus {
      border-width: ${themes['border-width-base']} !important;
    }

    &:focus,
    &:active {
      border-color: ${themes['primary-cta']};
      box-shadow: none;
    }

    .ant-picker-input {
      height: 100%;
      font-family: ${themes['font-sans-book']} !important;

      input {
        color: ${themes.I90} !important;
        font-size: ${themes['font-size-base']};

        &::placeholder {
          color: ${themes.I50};
        }
      }
    }

    .ant-picker-suffix {
      height: 18px;
    }
  }
`;

const DatePicker = (props) => <DatePickerWrapper {...props} />;

DatePicker.propTypes = {
  /** Whether to show clear button */
  allowClear: propTypes.bool,
  /** get focus when component mounted */
  autoFocus: propTypes.bool,
  /** picker className */
  className: propTypes.string,
  /** custom rendering function for date cells */
  dateRender: propTypes.func,
  /** determine whether the DatePicker is disabled */
  disabled: propTypes.bool,
  /** specify the date that cannot be selected, function returns boolean */
  disabledDate: propTypes.func,
  /** to customize the className of the popup calendar */
  popupClassName: propTypes.string,
  /** to set the container of the floating layer, while the default is to create a div element in body */
  getCalendarContainer: propTypes.func,
  /** localization configuration */
  locale: propTypes.object,
  /** picker panel mode */
  mode: propTypes.oneOf(['time', 'date', 'month', 'year']),
  /** open state of picker */
  open: propTypes.bool,
  /** placeholder of date input */
  placeholder: propTypes.string,
  /** to customize the style of the popup calendar */
  popupStyle: propTypes.object,
  /** determine the size of the input box, the height of large and small, are 40px and 24px respectively, while default size is 32px */
  size: propTypes.oneOf(['large', 'middle', 'small']),
  /** The custom suffix icon */
  suffixIcon: propTypes.node,
  /** to customize the style of the input box */
  style: propTypes.object,
  /** a callback function, can be executed whether the popup calendar is popped up or closed */
  onOpenChange: propTypes.func,
  /** to set default date, if start time or end time is null or undefined, the date range will be an open interval */
  defaultValue: propTypes.object,
  /** to set default picker date */
  defaultPickerValue: propTypes.object,
  /** to specify the time that cannot be selected */
  disabledTime: propTypes.func,
  /** to set the date format, refer to day.js.
   * When an array is provided, all values are used for parsing and first value is used for formatting. */
  format: propTypes.oneOfType([propTypes.string, propTypes.array]),
  /** render extra footer in panel */
  renderExtraFooter: propTypes.node,
  /** to provide an additional time selection */
  showTime: propTypes.oneOfType([propTypes.object, propTypes.bool]),
  /** whether to show "Today" button */
  showToday: propTypes.bool,
  /** to set date dayjs */
  value: propTypes.object,
  /** a callback function, can be executed when the selected time is changing */
  onChange: propTypes.func,
  /** callback when click ok button */
  onOk: propTypes.func,
  /** Callback function for panel changing */
  onPanelChange: propTypes.func,
};

DatePicker.defaultProps = {
  suffixIcon: <Icon type='calendar' color={themes.I90} size={16} />,
  allowClear: false,
};

/** @component */
DatePicker.RangePicker = Component.RangePicker;
export default DatePicker;
