import { Typography, Highlighter } from 'md-styled-components';

const { Paragraph } = Typography;

const renderMenu = (results, inputValue) =>
  results.map((item) => ({
    value: item.name,
    label: (
      <Paragraph className='wrap-text'>
        <Highlighter searchWords={[inputValue]} textToHighlight={item.name} />
      </Paragraph>
    ),
    data: item,
  }));

export default renderMenu;
