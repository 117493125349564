import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getIsPortalUser, getIsProxyUser } from 'selectors/users';
import { Header, Themes, Icon, Drawer } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import { isPrivateHospital } from 'utils/privateSite';
import { useBreakpoint as breakpoint } from 'hooks';
import {
  getIsAllHospitals,
  getPrivateHospitalData,
} from 'selectors/privateHospitalData';
import SearchTabs from 'components/SearchNew/Navbar/Tabs';
import SearchSelect from 'components/SearchNew/Navbar/Select';
import HeaderSearch from 'components/HeaderSearch';
import ProceduresTabItem from 'components/HeaderSearch/ProceduresSearch';
import ProceduresInsuranceTabItem from 'components/HeaderSearch/ProceduresInsuranceSearch';
import { useMobileNavbar } from './_context/NavbarMobileProvider';
import { common } from '../../constants';

const { Wrapper, Container, TabsBlock } = Header;
const mdMinScreenSize = Themes.breakpoints.smMax;

const NavbarSearch = ({ isCmsPage, headerSearchClass, searchType }) => {
  const isAllHospital = useSelector(getIsAllHospitals);
  const isProxyUser = useSelector(getIsProxyUser);
  const { insuranceExists } = useSelector(getPrivateHospitalData);
  const { searchVisible, toggleSearchVisible } = useMobileNavbar();
  const screenIsMdMin = breakpoint(mdMinScreenSize);
  const isPortalUser = useSelector(getIsPortalUser);
  const isHospital = useMemo(
    () => !isAllHospital && isPrivateHospital(),
    [isAllHospital]
  );

  return (
    <div
      className={`header-search 
      ${searchVisible && screenIsMdMin ? '' : 'header-search--hidden'}
      ${isCmsPage && searchVisible ? 'header-search--direct' : ''}`}
    >
      {screenIsMdMin && (
        <div
          className={`header-search-container ${headerSearchClass} ${
            isPortalUser ? 'business-page' : ''
          }`}
        >
          <div
            className={`flex-display flex-align-items-center ${
              !isHospital ? 'mb--16 mb-sm--0' : ''
            }`}
          >
            {isHospital ? (
              <div className='header-search__wrapper private-hospital ml-md--32 mr-md--56'>
                <HeaderSearch
                  searchFields={common.headerSearchFields}
                  toggleSearchVisible={toggleSearchVisible}
                  searchType={searchType}
                />
              </div>
            ) : (
              <SearchSelect />
            )}
            <Button
              className='header-search__close-btn'
              type='text'
              onClick={toggleSearchVisible}
              aria-label='Close Search'
            >
              <Icon type='icon-cancel2' color={Themes.I90} size='14px' />
            </Button>
          </div>
        </div>
      )}
      {!screenIsMdMin && (
        <Drawer
          rootClassName={
            isAllHospital || isProxyUser || isHospital
              ? 'header-search-sm nav-with-banner'
              : 'header-search-sm'
          }
          placement='top'
          closable={false}
          open={searchVisible}
          onClose={toggleSearchVisible}
        >
          <Wrapper bg={Themes.white}>
            <Container>
              <TabsBlock
                isPrivateHospital={isHospital}
                className={`${isAllHospital ? 'mt--0' : 'mt--8'}`}
              >
                {isHospital ? (
                  <div className='header-search__white-label ph--16 pb--16'>
                    {insuranceExists ? (
                      <ProceduresInsuranceTabItem
                        heroSearch
                        searchFields={common.headerSearchFields}
                        toggleSearchVisible={toggleSearchVisible}
                      />
                    ) : (
                      <ProceduresTabItem
                        heroSearch
                        searchFields={common.headerSearchFields}
                      />
                    )}
                  </div>
                ) : (
                  <SearchTabs />
                )}
              </TabsBlock>
            </Container>
          </Wrapper>
        </Drawer>
      )}
    </div>
  );
};

export default React.memo(NavbarSearch);
