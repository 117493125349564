import { useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import { isNoEmail } from 'utils/emailHelper';

export const useSetShareCartInfo = (
  setFieldsValue,
  setValidTabFields,
  data
) => {
  const defaultCartExpires = useCallback(
    () => (data.isGFE ? dayjs().add(6, 'months') : undefined),
    [data.isGFE]
  );

  useEffect(() => {
    let cartExpires = defaultCartExpires();
    if (data.cart_expires) {
      cartExpires =
        dayjs(data.cart_expires).diff(dayjs(), 'days') <= 0
          ? defaultCartExpires()
          : dayjs(data.cart_expires);
    }
    const patientIfo = {
      cart_expires: cartExpires,
      emailAddress:
        data.email_address && !isNoEmail(data.email_address)
          ? data.email_address
          : '',
      newPhoneNumber: data.phone_number,
    };

    const insuranceInfo = {
      insurance_provider: data.insurance_partner
        ? { key: data.insurance_partner, name: data.insuranceProvider }
        : null,
      insurance_member_id: data.insurance_member_id || null,
      insurance_group_id: data.insurance_group_id || null,
    };

    if (data.procedureDetails) {
      const { service_date: serviceDate, icd_code: icdCode } =
        data.procedureDetails;
      patientIfo.service_date = serviceDate ? dayjs(serviceDate) : undefined;
      patientIfo.icd_code = icdCode;
    }
    const splitPaymentValid =
      !data.isSplitPaymentEnabled || data.patient_responsibility_amount > 0;
    setFieldsValue({ ...data, ...patientIfo, ...insuranceInfo });
    setValidTabFields((v) => ({
      ...v,
      emailAddress: !!patientIfo.emailAddress,
      newPhoneNumber: !!patientIfo.newPhoneNumber,
      splitPaymentValid,
    }));
  }, [data, defaultCartExpires, setFieldsValue, setValidTabFields]);
};
