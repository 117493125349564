/* eslint-disable camelcase */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Themes,
  Modal,
  Divider,
  Typography,
  CollapseNew as Collapse,
} from 'md-styled-components';
import { Input, Form } from 'md-styled-components-v2';
import { useCheckout } from 'pages/Checkout/_context/CheckoutProvider';
import { formatDate } from 'utils/form/datepicker';
import { getShareCart, createGetShareCartLoading } from 'selectors/groups';
import { getCurrentCompanyInfo } from 'selectors/users';
import { createGetPatientInfoLoading } from 'selectors/patients';
import { requestPatientInfo } from 'actions/patients';
import { requestShareCart } from 'actions/groups';
import { requestSaveGroupUserData } from 'actions/shopping-cart';
import { getAllItems } from 'selectors/shopping-cart';
import BirthdayPicker from '../../Form/DateMultiSelect';
import Label from '../../Form/Label';
import { formFields, renderFormItem } from './_partials/Form';
import { useAlert } from '../../../contexts/AlertProvider';
import {
  SuccessFooterData,
  WarningFooterData,
  WarningBodyData,
  WarningTitle,
} from '../modal';
import '../../styles/index.less';
import '../styles/index.less';
import './styles/index.less';
import ShareTabs from './_partials/ShareTabs';
import ShareCartText from '../ShareCartText';
import ServiceDetails from './_partials/ServiceDetails';
import { useSetShareCartInfo } from './_hooks';
import InsuranceFields from './_partials/InsuranceFields';

const { Text, Paragraph, Title } = Typography;
const { Panel, ExpandIcon } = Collapse;

const patientFields = ['first_name', 'last_name', 'b_month', 'b_day', 'b_year'];
const INSURANCE_FIELDS = [
  'insurance_provider',
  'insuranceProvider',
  'insurance_member_id',
  'insurance_group_id',
];

const ShareCart = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const inputEl = useRef(null);
  const shareCart = useSelector(getShareCart);
  const shareCartLoading = useSelector(createGetShareCartLoading);
  const patientInfoLoading = useSelector(createGetPatientInfoLoading);
  const { success, close, warning, error } = useAlert();
  const [validTabFields, setValidTabFields] = useState({});
  const totalData = useSelector(getAllItems);

  const {
    getFieldValue,
    getFieldError,
    setFields,
    getFieldsValue,
    setFieldsValue,
    validateFields,
  } = form;

  const { isModalVisible, setIsModalVisible, data } = props;
  const {
    price_change: priceChange,
    isGFE: isGoodFaithEnabled,
    selectedPatientCart,
    activeTabKey,
    authKey,
    reShare,
    executeCartData,
    enableSplitPayment,
    company_invoice: companyInvoice,
    patient_responsibility_amount: patientResponsibilityAmount,
  } = data;

  const { state: { selectedCompany } = {} } = useCheckout();
  const { currentCompany } = useSelector(getCurrentCompanyInfo);
  const planOfCareIdentifier = currentCompany?.enablePlanOfCareIdentifier;
  const companyName =
    selectedCompany?.portalName ?? currentCompany?.name ?? 'company';

  const selectedCompanyHasEnabledSplitPayment =
    selectedCompany?.enableSplitPayment;
  const companyHasEnabledSplitPayment = currentCompany?.enableSplitPayment;
  const isSplitShareEnabled = reShare
    ? enableSplitPayment
    : companyHasEnabledSplitPayment || selectedCompanyHasEnabledSplitPayment;

  const totalAmount = reShare
    ? patientResponsibilityAmount + companyInvoice
    : totalData.total_price - totalData.promotionalDiscount;
  const company_invoice = priceChange ? 0 : companyInvoice ?? 0;
  const patient_responsibility_amount = totalAmount - company_invoice;

  data.totalAmount = totalAmount;
  data.patient_responsibility_amount = patient_responsibility_amount;
  data.company_invoice = company_invoice;

  useSetShareCartInfo(setFieldsValue, setValidTabFields, data);
  const resetModalFields = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  useEffect(() => {
    if (shareCart && shareCart.success) {
      resetModalFields();
      let successMessage = {
        title: (
          <Title level={3} className='mt--8 text-center'>
            {isGoodFaithEnabled ? 'Estimate Shared' : 'Cart Shared'}
          </Title>
        ),
        text: 'The cart has been shared with the patient.',
        footerTopSpace: 0,
        closable: true,
        footerData: <SuccessFooterData dispatch={dispatch} onClose={close} />,
      };
      if (reShare) {
        successMessage = {
          text: 'Your cart has successfully been shared and saved',
          footerText: 'Okay',
        };
        executeCartData();
      }
      success(successMessage);
    }
    if (shareCart?.errorMessage) {
      error({
        title: 'Error!',
        text: shareCart.errorMessage,
      });
    }

    if (shareCart && shareCart.error) {
      setFields(
        shareCart.errorList?.map((err) => ({
          name: err.field,
          errors: err.error,
        }))
      );
    }

    return () =>
      dispatch(requestShareCart.actions.success({ params: {} }, false));
  }, [
    shareCart,
    success,
    dispatch,
    resetModalFields,
    close,
    isGoodFaithEnabled,
    reShare,
    executeCartData,
  ]);

  const manageCart = () => {
    window.open(
      `/price-quote-output/?send=yes${authKey ? `&key=${authKey}` : ''}`,
      '_blank'
    );
    setIsModalVisible(false);
    if (reShare) {
      executeCartData();
    }
  };

  const printQuote = ({
    hospital_contact_person,
    hospital_phone_number,
    cb = () => {},
    ...printQuoteInfo
  }) => {
    dispatch(
      requestSaveGroupUserData.request({
        shareCart: printQuoteInfo,
        printQuote: true,
        hospital_contact_person,
        hospital_phone_number,
        cb,
      })
    );
  };

  const handelSend = useCallback(
    (e, type) => {
      e.preventDefault();
      let requiredFields = ['hospital_contact_person', 'hospital_phone_number'];
      const optionalFields = ['service_date', 'icd_code', 'cart_expires'];

      if (isGoodFaithEnabled) {
        requiredFields = [...patientFields, ...requiredFields];
      }

      let isPhone = false;
      let isEmail = false;
      let isPdf = false;
      const planOfCareIdentifierKey = getFieldValue('planOfCareIdentifierKey');
      if (type === 'email') {
        isEmail = getFieldValue('emailPermission');
        requiredFields = [...requiredFields, 'emailAddress', 'emailPermission'];
      } else if (type === 'phone') {
        isPhone = getFieldValue('phonePermission');
        requiredFields = [
          ...requiredFields,
          'newPhoneNumber',
          'phonePermission',
        ];
      } else {
        isPdf = true;
      }
      validateFields([
        ...requiredFields,
        ...optionalFields,
        ...INSURANCE_FIELDS,
      ])
        .then(() => {
          let patientFieldsKeys = [
            ...requiredFields,
            ...patientFields,
            ...optionalFields,
          ];
          patientFieldsKeys = isPdf
            ? [...patientFieldsKeys, 'emailAddress', 'newPhoneNumber']
            : patientFieldsKeys;
          const patientInfo = getFieldsValue(patientFieldsKeys);
          const phoneInput = {
            checked: isPhone,
            phone:
              patientInfo.newPhoneNumber
                ?.split('')
                .filter((i) => !/[^0-9]+$/.test(i))
                .join('') || '',
            send_by: isPhone,
          };
          const emailInput = {
            checkedEmail: isEmail,
            email: patientInfo.emailAddress || '',
            send_by: isEmail,
          };
          const pdfInput = {
            send_by: isPdf,
          };
          patientInfo.service_date = formatDate(patientInfo.service_date);
          patientInfo.cart_expires = formatDate(patientInfo.cart_expires);
          const sentData = {
            validate: true,
            data: {
              ...patientInfo,
              noValidation: true,
              isCustomer: true,
              isGoodFaithEnabled,
              id: selectedPatientCart?.id || null,
            },
            cb: (response) => {
              if (response.error) {
                setFields(
                  response.errorList?.map((err) => ({
                    name:
                      err.field === 'email_address'
                        ? 'emailAddress'
                        : err.field,
                    errors: err.error,
                  }))
                );
                return;
              }
              if (!response.data.care_package_subscribe) {
                warning({
                  title: <WarningTitle />,
                  html: <WarningBodyData />,
                  footerTopSpace: 16,
                  footerData: <WarningFooterData onClose={close} />,
                  closable: true,
                });
                return;
              }

              const splitPaymentFields = [
                'patient_responsibility_amount',
                'company_invoice',
              ];
              const splitPaymentInfo = isSplitShareEnabled && {
                ...getFieldsValue(splitPaymentFields),
                onBehalfOfCompany: Boolean(selectedCompany),
                portalId: selectedCompany?.id,
              };

              const shareCartData = {
                create: true,
                validate: true,
                authKey,
                insurance: getFieldsValue(INSURANCE_FIELDS),
                shareCart: {
                  enableSplitPayment: isSplitShareEnabled,
                  statusSaved: false,
                  newPatientSelected: false,
                  planOfCareIdentifierKey,
                  expired: { send_by: false },
                  id: response.data && response.data.id,
                  phoneInput,
                  emailInput,
                  pdfInput,
                  ...patientInfo,
                  ...splitPaymentInfo,
                },
              };
              if (isPdf) {
                shareCartData.cb = () => {
                  manageCart();
                };
              }
              dispatch(requestShareCart.request(shareCartData));
            },
          };
          const dispatchPatientInfo = () =>
            dispatch(requestPatientInfo.request(sentData));
          if (isPdf) {
            printQuote({
              ...patientInfo,
              phoneInput,
              emailInput,
              cb: dispatchPatientInfo,
            });
          } else {
            dispatchPatientInfo();
          }
        })
        .catch(() => {});
    },
    [
      isGoodFaithEnabled,
      validateFields,
      getFieldValue,
      getFieldsValue,
      dispatch,
      printQuote,
      authKey,
      setFields,
      warning,
      close,
      manageCart,
    ]
  );
  return (
    <Modal
      forceRender
      open={isModalVisible}
      title=''
      okText='Create'
      onCancel={resetModalFields}
      footer={null}
      width={600}
      bodyStyle={{ padding: 0 }}
      centered
      wrapClassName='share-cart share-cart__modal'
    >
      <div className='pt-md--16 pt--32'>
        <Form
          form={form}
          layout='vertical'
          hideRequiredMark
          onValuesChange={(changedValues) => {
            const [fieldKey, fieldValue] = Object.entries(changedValues)[0];
            const isAmountField = [
              'patient_responsibility_amount',
              'company_invoice',
            ].includes(fieldKey);

            form
              .validateFields([fieldKey])
              .then(() => {
                setValidTabFields((v) => ({ ...v, [fieldKey]: true }));

                if (isAmountField) {
                  const otherKey =
                    fieldKey === 'patient_responsibility_amount'
                      ? 'company_invoice'
                      : 'patient_responsibility_amount';
                  const updatedValues =
                    fieldValue > data.totalAmount
                      ? { [fieldKey]: data.totalAmount, [otherKey]: 0 }
                      : {
                          [otherKey]: Math.max(
                            0,
                            parseFloat(data.totalAmount - fieldValue).toFixed(2)
                          ),
                        };

                  form.setFieldsValue(updatedValues);
                  const patientResponsibility = form.getFieldValue(
                    'patient_responsibility_amount'
                  );
                  const companyInvoiceValue =
                    form.getFieldValue('company_invoice');
                  if (!patientResponsibility || patientResponsibility === '0') {
                    form.setFields([
                      {
                        name: 'patient_responsibility_amount',
                        errors: [
                          `Please select the 'Pay with Invoice' option on Checkout to invoice the total amount to ${companyName}.`,
                        ],
                      },
                    ]);
                    setValidTabFields((v) => ({
                      ...v,
                      splitPaymentValid: false,
                    }));
                  } else if (companyInvoiceValue % 1) {
                    form.setFields([
                      {
                        name: 'company_invoice',
                        errors: [
                          <span>
                            Please enter a whole dollar amount <br /> (no
                            cents).
                          </span>,
                        ],
                      },
                      {
                        name: 'patient_responsibility_amount',
                        errors: [
                          <span>
                            Please enter a whole dollar amount <br /> (no
                            cents).
                          </span>,
                        ],
                      },
                    ]);
                    setValidTabFields((v) => ({
                      ...v,
                      splitPaymentValid: false,
                    }));
                  } else {
                    form.setFields([
                      { name: 'patient_responsibility_amount', errors: [] },
                      { name: 'company_invoice', errors: [] },
                    ]);
                    setValidTabFields((v) => ({
                      ...v,
                      splitPaymentValid: true,
                    }));
                  }
                }
              })
              .catch(() => {
                setValidTabFields((v) => ({ ...v, [fieldKey]: false }));
              });
          }}
        >
          <div className='ph-md--16 ph--32 pb--16'>
            <Title level={3} className='text-center mb--24' as='h2'>
              <ShareCartText isGoodFaithEnabled={isGoodFaithEnabled} />
            </Title>
            <Paragraph level={1}>Patient Information</Paragraph>
            <Divider margin='8px 0' color={Themes.I20} />
            <div className='flex-display flex-justify-space-between flex-wrap mb--16'>
              {formFields.map((formItem) => {
                const name =
                  formItem.name === 'first_name'
                    ? selectedPatientCart?.first_name
                    : selectedPatientCart?.last_name;
                return (
                  <Form.Item
                    className={`share-cart__form-item mb--0 pb--0 ${
                      formItem.formItemClassName || ''
                    } ${
                      formItem.fullWidth ? 'share-cart__form-item--full' : ''
                    }`}
                    key={`form-item-${formItem.name}`}
                    hasFeedback
                    label={
                      <Label>{`${formItem.label} ${
                        isGoodFaithEnabled ? '' : '(Optional)'
                      }`}</Label>
                    }
                    name={formItem.name}
                    initialValue={name}
                    validateTrigger={
                      getFieldError(formItem.name).length
                        ? 'onBlur'
                        : 'onSubmit'
                    }
                    valuePropName={
                      formItem.type === 'checkbox' ? 'checked' : 'value'
                    }
                    validateFirst
                    {...(isGoodFaithEnabled && { rules: formItem.rules })}
                  >
                    {renderFormItem(formItem, name && !!reShare)}
                  </Form.Item>
                );
              })}
            </div>
            <InsuranceFields />
            {((isGoodFaithEnabled && !selectedPatientCart?.b_month) ||
              !reShare) && (
              <BirthdayPicker
                validateTrigger='onSubmit'
                allowEmpty={!isGoodFaithEnabled}
                defaultDay={selectedPatientCart?.b_day}
                defaultMonth={selectedPatientCart?.b_month}
                defaultYear={selectedPatientCart?.b_year}
              />
            )}
            {planOfCareIdentifier && (
              <Form.Item
                className='share-cart__form-item mb--0 pb--0 share-cart__form-item--full'
                key='form-item-plan-of-care-identifier-key'
                label={<Label>Plan of Care Identifier (Optional)</Label>}
                name='planOfCareIdentifierKey'
              >
                {renderFormItem({ className: 'phi' })}
              </Form.Item>
            )}
            {isSplitShareEnabled && (
              <div className='flex-display flex-justify-space-between flex-wrap mb--16'>
                <Form.Item
                  className='share-cart__form-item mb--0 pb--0'
                  key='form-item-patient_responsibility_amount'
                  label={<Label> Patient Responsibility </Label>}
                  name='patient_responsibility_amount'
                  initialValue={patient_responsibility_amount}
                >
                  <Input.Price max={data.totalAmount} />
                </Form.Item>
                <Form.Item
                  className='share-cart__form-item mb--0 pb--0'
                  key='form-item-company_invoice'
                  label={<Label> Invoiced to Company </Label>}
                  name='company_invoice'
                  initialValue={company_invoice}
                >
                  <Input.Price max={data.totalAmount} />
                </Form.Item>
              </div>
            )}
            <Collapse
              bordered
              expandIcon={ExpandIcon}
              defaultActiveKey={
                (isGoodFaithEnabled || reShare) && 'service_details'
              }
            >
              <Panel
                key='service_details'
                header={
                  <div className='mds-full--width'>
                    <div className='mds-full--width'>
                      <Text level={1}>Service Details</Text>
                    </div>
                  </div>
                }
              >
                <div className='flex-display flex-justify-space-between flex-wrap'>
                  <ServiceDetails
                    getFieldError={getFieldError}
                    inputEl={inputEl}
                  />
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className='ph--32 pt--16 pb--32 B05-bg'>
            <ShareTabs
              {...{
                activeTabKey: activeTabKey ?? 0,
                getFieldValue,
                validTabFields,
                selectedPatientCart,
                inputEl,
                handelSend,
                isGoodFaithEnabled,
                loading: shareCartLoading || patientInfoLoading,
              }}
            />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ShareCart;
