import React, { createContext, useState, useContext, useCallback } from 'react';

const SearchProcedureContext = createContext({});

export default ({ children }) => {
  const [searchData, setSearchData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isCptSearch, setIsCptSearch] = useState(false);
  const [items, setItems] = useState(null);
  const hasSearchData = searchData && searchData.length;
  const [groupData, setGroupData] = useState(null);

  return (
    <SearchProcedureContext.Provider
      value={{
        searchText,
        setSearchText,
        searchData,
        setSearchData,
        hasSearchData,
        isCptSearch,
        setIsCptSearch,
        items,
        setItems,
        groupData,
        setGroupData,
      }}
    >
      {children}
    </SearchProcedureContext.Provider>
  );
};

export const useSearchProcedure = () => {
  const {
    searchText,
    setSearchText,
    searchData,
    setSearchData,
    hasSearchData,
    isCptSearch,
    setIsCptSearch,
    items,
    setItems,
    groupData,
    setGroupData,
  } = useContext(SearchProcedureContext);

  const restoreDefault = useCallback(() => {
    setSearchData([]);
    setSearchText('');
    setIsCptSearch(false);
    setItems(null);
  }, [setSearchData, setSearchText, setIsCptSearch, setItems]);

  return {
    searchText,
    setSearchText,
    searchData,
    setSearchData,
    hasSearchData,
    isCptSearch,
    setIsCptSearch,
    items,
    setItems,
    restoreDefault,
    groupData,
    setGroupData,
  };
};
