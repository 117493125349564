import React from 'react';
import { Icon, Typography } from 'md-styled-components';
import { useSelector } from 'react-redux';
import { getSelectedPatientCart } from 'selectors/shopping-cart';

const { Paragraph } = Typography;

const ItemSelectedPatientCard = () => {
  const selectedPatientCart = useSelector(getSelectedPatientCart);

  return (
    selectedPatientCart && (
      <div className='flex-display flex-align-items-center ml--16 pl--4'>
        <div>
          <Paragraph fontFamily='medium' level={3}>
            You're shopping for
          </Paragraph>
          <Paragraph
            className='phi shopping-cart--fixed-space mds-text--nowrap'
            fontFamily='black'
            level={3}
          >
            {selectedPatientCart.name}
          </Paragraph>
        </div>
        <Icon className='pl--8' type='chevron-down' size={12} />
      </div>
    )
  );
};

export default ItemSelectedPatientCard;
