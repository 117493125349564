import styled from 'styled-components';
import Highlighter from 'react-highlight-words';

const StyledHighlighter = styled(Highlighter)`
  mark {
    color: inherit;
    background-color: transparent;
    font-weight: bold;
    padding: 0;
    -webkit-font-smoothing: antialiased;
  }
`;

export default StyledHighlighter;
