import { useSelector } from 'react-redux';
import {
  getIsGroupUser,
  getIsCmsUser,
  getIsPortalUser,
  getIsReportUser,
} from 'selectors/users';

export const useGetDirectUrlByUserType = (
  groupId,
  isGroupPage,
  homepageUrl = '/'
) => {
  const isGroupUser = useSelector(getIsGroupUser);
  const isCmsUser = useSelector(getIsCmsUser);
  const isPortalUser = useSelector(getIsPortalUser);
  const isReportUser = useSelector(getIsReportUser);
  const procedureUrl = `/groups/dashboard2?GroupId=${groupId}`;
  switch (true) {
    case isPortalUser:
      return homepageUrl;
    case isReportUser:
      return '/groups/reportPage';
    case isGroupUser:
      return procedureUrl;
    case isCmsUser && isGroupPage:
      return procedureUrl;
    default:
      return '/site';
  }
};
