import React, { useRef } from 'react';
import { Form } from 'md-styled-components-v2';
import useOnFinish from '../../../Forms/ProcedureForm/useOnFinish';
import Procedure from '../../../Autocompletes/Procedure';
import Location from '../../../Autocompletes/Location';
import SearchButton from './SearchButton';

const ProcedureForm = () => {
  const [form] = Form.useForm();
  const onFinish = useOnFinish(form);
  const procedureRef = useRef(null);
  const locationRef = useRef(null);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      className='flex-display mds-full--width'
    >
      <Form.Item name='procedure'>
        <Procedure form={form} suffix='-navbar-select' ref={procedureRef} />
      </Form.Item>
      <Form.Item name='location'>
        <Location form={form} suffix='-navbar-select' ref={locationRef} />
      </Form.Item>
      <SearchButton />
    </Form>
  );
};

export default ProcedureForm;
