import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Themes } from 'md-styled-components';
import './_styles/index.less';

/**
 * Option component for rendering an option element within a select dropdown.
 */

const Option = ({ value, disabled = false, hidden = false, ...props }) => (
  <option value={value} disabled={disabled} hidden={hidden} {...props}>
    {props.children}
  </option>
);

/**
 * OptGroup component for rendering a group of options within a select dropdown.
 */

const OptGroup = ({ title, ...props }) => (
  <optgroup label={title} {...props}>
    {props.children}
  </optgroup>
);

/**
 * BaseSelect component for rendering a custom select dropdown.
 */
/**
 * @typedef {object} Props
 * @prop {string} [className='']
 * @prop {string} [placeholder]
 * @prop {string} [value]
 * @prop {ReactNode} [children]
 *
 * @param {Props} p1
 */
const BaseSelect = ({ className = '', placeholder, value, ...props }) => (
  <div className='base-select'>
    <select
      className={`base-select--error ${className}`}
      value={value}
      {...props}
    >
      {placeholder && (
        <option value='' disabled={value} hidden>
          {placeholder}
        </option>
      )}
      {props.children}
    </select>
    <Icon
      type='chevron-down'
      color={Themes.I90}
      size={12}
      className='base-select__icon'
    />
  </div>
);

BaseSelect.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
};

Option.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
};

OptGroup.propTypes = {
  title: PropTypes.string,
};

BaseSelect.Option = Option;
BaseSelect.OptGroup = OptGroup;

export default BaseSelect;
