import React, { memo, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import { requestGroupUserAllHospitalsApi } from 'actions/groups';
import { getCurrentUser, getIsCmsUser } from 'selectors/users';
import {
  getThirdPartyName,
  groupUserAllHospitals,
  hasGroupUserMultipleHospitals,
  getGroupName,
} from 'selectors/groups';
import { isNotSingleHospital } from '../_helpers';
import SystemOverlay from './SystemOverlay';
import GroupName from './GroupName';
import BreadcrumbComponent from './Breadcrumb';
import GroupsOverlay from './GroupsOverlay';
import '../_styles/hospital-info.less';

const HospitalInfo = ({ groupId }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const isMultiHospitalUser = useSelector(hasGroupUserMultipleHospitals);
  const hospitals = useSelector(groupUserAllHospitals);
  const groupName = useSelector(getGroupName);
  const thirdPartyName = useSelector(getThirdPartyName);
  const isCmsUser = useSelector(getIsCmsUser);
  const [groupInfo, setGroupInfo] = useState({});
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (groupName) {
      setGroupInfo({
        GroupId: groupId,
        GroupName: groupName,
      });
    }

    if (!groupName && currentUser && currentUser.groupInfo) {
      setGroupInfo(currentUser.groupInfo);
    }
  }, [currentUser, groupId, groupName]);

  useEffect(() => {
    dispatch(requestGroupUserAllHospitalsApi.request({ groupId }));
  }, [groupId, dispatch]);

  return (
    <div className='flex-display flex-align-items-center'>
      {isCmsUser && <BreadcrumbComponent groupInfo={groupInfo} />}
      {!isMultiHospitalUser && !isCmsUser && groupInfo.GroupName && (
        <GroupName groupInfo={groupInfo} />
      )}
      {(isMultiHospitalUser || isCmsUser) && isNotSingleHospital(hospitals) && (
        <Dropdown
          placement='bottomLeft'
          loading
          overlayClassName='hospital-info__overlay'
          getPopupContainer={(trigger) => trigger.parentNode}
          onOpenChange={() => setIsVisible(!isVisible)}
          trigger={['click']}
          open={isVisible}
          dropdownRender={() => (
            <div className='top-contact-us__container relative'>
              {isCmsUser ? (
                <SystemOverlay
                  groupInfo={groupInfo}
                  setIsVisible={() => setIsVisible(false)}
                  setGroupInfo={setGroupInfo}
                  groupId={groupId}
                  systems={hospitals}
                />
              ) : (
                <GroupsOverlay
                  groupInfo={groupInfo}
                  hideDropdown={() => setIsVisible(false)}
                  setGroupInfo={setGroupInfo}
                  groupId={groupId}
                  hospitals={hospitals}
                />
              )}
            </div>
          )}
        >
          <div className='flex-display flex-align-items-center'>
            {!isCmsUser && <GroupName groupInfo={groupInfo} />}
            <Button
              type='link'
              className='ml--8 hospital-info__btn'
              onClick={() => setIsVisible((state) => !state)}
            >
              Change
            </Button>
          </div>
        </Dropdown>
      )}
      {thirdPartyName && <span className='pl--4'>{`(${thirdPartyName})`}</span>}
    </div>
  );
};

HospitalInfo.propTypes = {
  groupId: propTypes.string,
};

export default memo(HospitalInfo);
