import Input from './Input';
import Password from './Password';
import TextArea from './TextArea';
import Number from './Number';
import Price from './Price';

Input.Password = Password;
Input.TextArea = TextArea;
Input.Number = Number;
Input.Price = Price;

export default Input;
