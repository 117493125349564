import React from 'react';
import { Icon, Logo } from 'md-styled-components';
import { useIsMobile } from 'hooks';
import { Link } from 'md-styled-components-v2';
import propTypes from 'prop-types';
import TendoLogo from 'components/TendoLogo';
import { useSelector } from 'react-redux';
import { getHomePageUrl, getIsPortalUser } from 'selectors/users';
import { isCheckoutPage } from 'utils/pages';
import '../_styles/checkout-logo.less';

const LogoComponent = ({ blockClass = '', iconSize = 48 }) => {
  const homePageUrl = useSelector(getHomePageUrl);
  const isPortalUser = useSelector(getIsPortalUser);
  const isCheckout = isCheckoutPage();
  const isMobile = useIsMobile();

  return (
    <div className={blockClass}>
      <Link
        to={homePageUrl.to || homePageUrl.href}
        reloadDocument={!!homePageUrl.href}
        type='standardWidhLogo'
        className='logo'
        aria-label='MDsave'
        keyprop='logo'
      >
        <div
          className={`flex-display flex-align-items-center ${
            isCheckout && 'checkout-logo'
          }`}
        >
          <Logo>
            {isCheckout ? (
              <Icon size={isMobile ? 256 : 280} type='mdsave-care-connect' />
            ) : (
              <Icon
                size={iconSize}
                type={isPortalUser ? 'care-connect-logo' : 'logo-color'}
              />
            )}
          </Logo>
          {!isCheckout && (
            <TendoLogo
              logo='a-tendo-marketplace'
              className='tendo-logo ml--8'
            />
          )}
        </div>
      </Link>
    </div>
  );
};

LogoComponent.propTypes = {
  blockClass: propTypes.string,
  iconSize: propTypes.number,
};

export default LogoComponent;
