import React from 'react';
import { Header, Typography } from 'md-styled-components';
import MenuContainer from './MenuContainer';

const {
  AutoComplete: { Highlighter },
} = Header;
const { Paragraph, Text } = Typography;

const renderContent = (item, inputValue) => (
  <>
    <Paragraph className='wrap-text'>
      <Highlighter
        highlightClassName='rbt-highlight-strong'
        search={inputValue}
      >
        {item.name}
      </Highlighter>
    </Paragraph>
    {item.drgCodes && (
      <Text level={4} color='paleSky'>
        DRG Code: {item.drgCodes.join(', ')}
      </Text>
    )}
    {item.keyword && (
      <Paragraph level={4} color='paleSky'>
        Also Known As:{' '}
        <Highlighter
          highlightClassName='rbt-highlight-strong'
          search={inputValue}
        >
          {item.keyword}
        </Highlighter>
      </Paragraph>
    )}
    {item.cpt && (
      <Text level={4} color='paleSky'>
        CPT Code :{' '}
        {item.cpt.map((cpt, index) => (
          <span key={`${cpt}-${index}`}>
            {`${cpt.mainCPT}${item.cpt[index + 1] ? ' + ' : ''}`}
          </span>
        ))}
      </Text>
    )}
  </>
);

export default (props) => (
  <MenuContainer {...props} renderContent={renderContent} />
);
