import React from 'react';
import { Typography } from 'md-styled-components';
import SupportPhoneLink from 'components/Support/SupportPhoneLink';

const { Text } = Typography;

const PhoneNumber = () => (
  <SupportPhoneLink
    renderCustomText={(content) => (
      <Text level={3}>
        Call <Text level={3} children={content} />
      </Text>
    )}
  />
);

export default PhoneNumber;
