import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { getCurrentUserRankApi } from 'services/users';
import { getGroupLeaderBoardApi } from 'services/groups';
import { getCurrentUser } from 'selectors/users';
import { dateTypes } from '../_helpers';

export const useLeaderData = (dateType) => {
  const currentUser = useSelector(getCurrentUser);
  const [leaderBoardData, setLeaderBoardData] = useState({
    data: [],
    isLoading: false,
  });

  useEffect(() => {
    const handler = async () => {
      const type = dateTypes[dateType];
      const startDate =
        (type.value && dayjs().startOf(type.value).toDate().getTime() / 1000) ||
        '';
      const endDate = new Date().getTime() / 1000;
      setLeaderBoardData({ ...leaderBoardData, isLoading: true });
      const data = await getGroupLeaderBoardApi({
        groupId: currentUser.groupInfo.GroupId,
        startDate,
        endDate,
      });
      setLeaderBoardData({
        ...leaderBoardData,
        ...data.data,
        isLoading: false,
      });
    };
    handler();
  }, [dateType, currentUser.groupInfo.GroupId]);

  return leaderBoardData;
};

export const useRankData = (dateType) => {
  const [rank, setRank] = useState('');

  useEffect(() => {
    const handler = async () => {
      const data = await getCurrentUserRankApi(dateTypes[dateType]);
      setRank(data.data.data);
    };
    handler();
  }, [dateType]);

  return rank;
};
