import React from 'react';
import { NavigationMenu, Link } from 'md-styled-components-v2';
import { useSelector } from 'react-redux';
import {
  getPrivateHospitalData,
  getIsAllHospitals,
} from 'selectors/privateHospitalData';
import { useTillTablet } from 'hooks';
import '../_styles/menu.less';

const { Item } = NavigationMenu;

const Menu = () => {
  const isTillTablet = useTillTablet();
  const privateHospitalData = useSelector(getPrivateHospitalData);
  const isAllHospitals = useSelector(getIsAllHospitals);

  const menuProps = isTillTablet
    ? { mode: 'inline' }
    : { mode: 'horizontal', className: 'flex-justify-center' };

  if (privateHospitalData.hideMDsavePrices) {
    return null;
  }

  return (
    <NavigationMenu {...menuProps}>
      {!isAllHospitals ? (
        <Item
          key='price_list'
          className='mr--24 ml-sm--24 ml-md--24 hospital-menu__underline'
        >
          <Link
            className='hospital-menu__title--active'
            to={`/${privateHospitalData.privateHospitalSlug}-price-list`}
          >
            All Procedures
          </Link>
        </Item>
      ) : (
        <Item key='empty_item'>
          <Link />
        </Item>
      )}
      <Item key='faq' className='ml-sm--24 ml-md--24 hospital-menu__underline'>
        <Link className='hospital-menu__title--active' to='/faq'>
          How It Works
        </Link>
      </Item>
      <Item key='financing' className='ml--24 hospital-menu__underline'>
        <Link className='hospital-menu__title--active' to='/financing'>
          Financing
        </Link>
      </Item>
    </NavigationMenu>
  );
};

export default Menu;
