export const allProcedures = 'all-procedures';
export const consultTypeNotAn = 0;
export const consultTypeNewPatient = 1;
export const consultTypeEstablishedPatient = 2;
export const consultTypePhysicianOrder = 3;
export const SEARCH_MIN_LENGTH = 2;
export const IMAGING_RADIOLOGY = {
  link: '/t/imaging-radiology',
  name: 'Imaging & Radiology',
};
export const CT_SCAN = {
  link: '/t/imaging-radiology/ct-scan',
  name: 'CT Scan',
};

export const SERVICE_TYPE_VIRTUAL_HEALTH = 5;
export const IN_PERSON_VISIT = 'In-person Visit';
export const VIDEO_VISIT = 'Video Visit';
export const COVERED = 'Covered';
export const EXCLUSIVE_PRICE = 'Exclusive Price';
