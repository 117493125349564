import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Themes, Typography } from 'md-styled-components';
import { Input, Button } from 'md-styled-components-v2';
import { requestSetNote } from 'actions/shopping-cart';
import {
  getAllItems,
  getNote,
  createSetNoteLoading,
} from 'selectors/shopping-cart';
import { useBreakpoint } from '../../hooks';

const { Title, Paragraph } = Typography;

export default (props) => {
  const isMobile = useBreakpoint(Themes.breakpoints.xs, Themes.breakpoints.sm);
  const { visible, onClose } = props;
  const existingNote = useSelector(getNote);
  const authKey = useSelector(getAllItems).auth_key;
  const isLoading = useSelector(createSetNoteLoading);
  const [note, setNote] = useState(existingNote);
  const dispatch = useDispatch();

  return (
    <Modal
      open={visible}
      onCancel={() => {
        onClose();
        setNote(existingNote);
      }}
      centered
      closable
      footer={null}
      className='shop-cart-note'
      width={isMobile ? '100%' : '480px'}
    >
      <Title className='text-center mb--8 text-center mb--8' level={5}>
        Patient Cart Notes
      </Title>
      <Input.TextArea
        className='shop-cart-note__area'
        value={note}
        onChange={(e) => setNote(e.target.value)}
        autoSize={{ minRows: 10 }}
      />
      <div className='flex-display flex-justify-center flex-align-items-center pt--32'>
        <Button type='link' onClick={onClose}>
          <Paragraph color='grey'>Cancel</Paragraph>
        </Button>
        <div className='ph--16'>
          <Button
            type='primary'
            size='small'
            loading={isLoading}
            onClick={() => {
              dispatch(
                requestSetNote.request({
                  note,
                  savedCartPage: false,
                  auth_key: authKey,
                  cb: () => onClose(),
                })
              );
            }}
          >
            <Paragraph color='white' fontFamily='heavy'>
              Save
            </Paragraph>
          </Button>
        </div>
      </div>
    </Modal>
  );
};
