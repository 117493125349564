import { useMobileNavbar } from 'components/NavbarSearch/_context/NavbarMobileProvider';
import { useSelector } from 'react-redux';
import { getIsDirectUser, getIsReportUser } from 'selectors/users';

export const useContainerClass = (navFixed) => {
  const { searchVisible } = useMobileNavbar();
  const isReportUser = useSelector(getIsReportUser);
  const isDirectUser = useSelector(getIsDirectUser);
  const className = `consumer-navigation 
      ${navFixed ? 'consumer-navigation--fixed pr--16 pr-lg--0 pr-md--0 pr-sm--0' : ''} 
      ${searchVisible ? '' : 'consumer-navigation--shadow'}
      ${(isReportUser || isDirectUser) && navFixed ? 'mt--40' : ''}
    `;

  return { className };
};
