import { AutoComplete as Component } from 'antd';
import React from 'react';
import './styles/autocomplete-popup.less';
import './styles/autocomplete.less';

/** @type {Component} */
const AutoComplete = React.forwardRef(
  ({ popupClassName, className = '', ...props }, ref) => (
    <Component
      {...props}
      ref={ref}
      popupClassName={`autocomplete-popup-v2 ${popupClassName || ''}`}
      className={`autocomplete-v2 ${className}`}
      dropdownAlign={{
        offset: [0, 0],
        overflow: {
          adjustX: false,
          adjustY: false,
        },
      }}
    />
  )
);

export default AutoComplete;
