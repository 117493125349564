import React, { createContext, useCallback, useContext, useState } from 'react';

const NavbarMobileContext = createContext({});

const HEADER_SEARCH_HEIGHT = 56;
const NON_STICKY_SEARCH_CLASS = 'non-sticky';

export default ({ children }) => {
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchSticky, setSearchSticky] = useState(true);

  const toggleSearchVisible = useCallback(() => {
    setSearchVisible((val) => (!searchSticky && window.scrollY) || !val);
    setSearchSticky(true);
  }, [searchSticky, setSearchVisible]);

  const topOffset = searchSticky && searchVisible ? HEADER_SEARCH_HEIGHT : 0;
  const nonStickyClass = searchSticky ? '' : NON_STICKY_SEARCH_CLASS;

  return (
    <NavbarMobileContext.Provider
      value={{
        searchVisible,
        toggleSearchVisible,
        setSearchVisible,
        setSearchSticky,
        topOffset,
        nonStickyClass,
        searchSticky,
      }}
    >
      {children}
    </NavbarMobileContext.Provider>
  );
};

export const useMobileNavbar = () => {
  const {
    searchVisible,
    toggleSearchVisible,
    setSearchVisible,
    setSearchSticky,
    topOffset,
    nonStickyClass,
  } = useContext(NavbarMobileContext);
  return {
    searchVisible,
    toggleSearchVisible,
    setSearchVisible,
    setSearchSticky,
    topOffset,
    nonStickyClass,
  };
};
