import { cloneDeep } from 'lodash';

const filterData = (data, term) =>
  data.filter(
    (hospital) =>
      hospital.name_of_practice.toUpperCase().includes(term.toUpperCase()) ||
      hospital.third_party_unique_id?.toUpperCase().includes(term.toUpperCase())
  );

export const getSystemFilteredData = (allData, term) => {
  if (!term) {
    return allData;
  }
  const newData = cloneDeep(allData);
  const data = newData.map((item) => {
    const groupData = filterData(item.groupData, term);
    item.groupData = groupData;
    return item;
  });
  return data.filter((item) => !!item.groupData.length);
};

export const getGroupFilteredData = (allData, term) => {
  if (!term) {
    return allData;
  }
  const data = filterData(allData, term);
  return data;
};

export const isNotSingleHospital = (hospitals) =>
  hospitals.length > 1 || hospitals[0]?.groupData.length > 1;
