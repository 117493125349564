import React from 'react';
import { Icon, Themes } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import { useMobileNavbar } from 'components/NavbarSearch/_context/NavbarMobileProvider';
import '../_styles/search.less';

const SearchIcon = () => {
  const { toggleSearchVisible } = useMobileNavbar();

  return (
    <Button
      type='text'
      className='direct-search-icon'
      onClick={toggleSearchVisible}
    >
      <Icon
        iconClassName='m--0'
        type='magnifying-glass'
        color={Themes.indigo}
        size={20}
        className='mr--8'
      />
      Search
    </Button>
  );
};

export default SearchIcon;
