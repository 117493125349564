import React from 'react';
import { Icon } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import {
  getCompanyUserCompanies,
  getCurrentCompanyInfo,
} from 'selectors/users';
import { useCompanyPricingGroupSwitch } from 'hooks/Company';
import { useSelector } from 'react-redux';

const Accounts = () => {
  const companies = useSelector(getCompanyUserCompanies);
  const { currentCompany } = useSelector(getCurrentCompanyInfo);
  const onSwitch = useCompanyPricingGroupSwitch();

  return (
    <div>
      {companies.companiesList.map((item) => {
        const isActive = item.id === currentCompany.id;

        return (
          <div key={item.id} className='flex-display mb--24'>
            {isActive ? (
              <Icon type='check-bordered' size={20} className='mr--16' />
            ) : (
              <div className='ml--32 pl--4' />
            )}
            <Button
              type='link'
              onClick={() =>
                onSwitch([
                  item.id,
                  item.pricingGroups[0].id,
                  item.pricingGroups[0].narrow_network,
                ])
              }
            >
              {item.name}
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default Accounts;
