import React, { useState } from 'react';
import { Typography, Radio } from 'md-styled-components';
import { Form, Input } from 'md-styled-components-v2';
import propTypes from 'prop-types';
import ResetPasswordForm from './ResetPasswordForm';
import HiddenInput from './HiddenInput';
import { changeSsoRadioTypeAction } from '../../_actions';

const { Password } = Input;
const { Paragraph } = Typography;

const SsoForm = ({ options, ssoData, dispatch }) => {
  const [value, setValue] = useState('sso');

  const onChangeRadio = (e) => {
    setValue(e.target.value);
    dispatch(changeSsoRadioTypeAction(e.target.value));
  };

  return (
    <>
      <Radio.Group
        value={value}
        className='mds-full--width'
        onChange={onChangeRadio}
      >
        <div
          className={`flex-display flex-direction-column login__sso-container ${options.ssoContainerClassName}`}
        >
          <Radio value='sso' className='flex-display mb--24 mr--0'>
            <Paragraph fontFamily='book' color='grey' className='mb--8'>
              Single Sign-On
            </Paragraph>
            <Paragraph
              fontFamily='book'
              color='secondary'
              level={3}
              className='mds-text--normal'
            >
              We detected {ssoData?.ssoProviderName} single sign-on enabled for
              this email
            </Paragraph>
          </Radio>
          <Radio value='password' className='flex-display mr--0'>
            <Paragraph fontFamily='book' color='grey' className='mb--8'>
              Password
            </Paragraph>
            <Form.Item
              className={options.formPassword.className}
              name='password'
              rules={[
                {
                  required: value === 'password',
                  message: 'Password cannot be blank.',
                },
              ]}
              hasFeedback
            >
              <Password
                type='password'
                name='RegisterPartForm[password]'
                disabled={value === 'sso'}
              />
            </Form.Item>
          </Radio>
        </div>
      </Radio.Group>
      <Form.Item className='mb-md--8 mb--16'>
        <ResetPasswordForm />
      </Form.Item>
      <HiddenInput />
    </>
  );
};

SsoForm.propTypes = {
  ssoData: propTypes.object,
  form: propTypes.object.isRequired,
  options: propTypes.object.isRequired,
  dispatch: propTypes.func.isRequired,
};

export default SsoForm;
