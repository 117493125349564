import React, { Suspense, lazy, useMemo } from 'react';
import { Typography } from 'md-styled-components';
import './styles/login.less';
import propTypes from 'prop-types';
import Login from 'components/Login';
import { options } from './options';

const NewToMDsave = lazy(() => import('./NewToMDsave'));
const { Title } = Typography;

const SignInOverlay = () => {
  const onSubmit = () => {
    document.querySelector('#login-overlay').submit();
  };

  const data = useMemo(
    () => ({
      options,
      onSubmitLogin: onSubmit,
    }),
    []
  );

  return (
    <>
      <div className='login-overlay mv-sm--24 mv-md--0'>
        <div className='p-sm--0 pv-md--24 ph-md--0 p--24'>
          <Title level='3' className='text-center pb--16'>
            Welcome Back
          </Title>
          <Login data={data} />
        </div>
      </div>
      <div className='login-overlay__footer mb-md--48 mb-sm--48'>
        <Suspense fallback={<></>}>
          <NewToMDsave />
        </Suspense>
      </div>
    </>
  );
};

SignInOverlay.propTypes = {
  setLoginModalVisible: propTypes.func,
};

export default SignInOverlay;
