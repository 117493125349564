import React from 'react';
import { Typography } from 'md-styled-components';

const { Paragraph } = Typography;

export default (props) => (
  <Paragraph className='mt--8' level={2} fontFamily='book'>
    {props.children}
  </Paragraph>
);
