import { Typography, Highlighter } from 'md-styled-components';
import { setPlural } from 'utils/grammaticalParticles';

const { Paragraph } = Typography;

const renderMenu = (results, inputValue) =>
  results.map((item) =>
    item.id !== 'allResults'
      ? {
          value: `${item.id}-${item.name}`,
          label: (
            <div
              className='flex-display flex-justify-space-between flex-direction-column--sm'
              key={`${item.id}-${item.name}`}
            >
              <div>
                <Paragraph className='wrap-text provider-name pb-sm--4'>
                  <Highlighter
                    searchWords={[inputValue]}
                    textToHighlight={item.name}
                  />
                </Paragraph>
                <Paragraph
                  className='wrap-text hidden-sm pt--8'
                  color='paleSky'
                  level={4}
                >
                  {item.specialty}
                  {item.practice && (
                    <>
                      <span className='dot'> • </span>
                      {item.practice}
                    </>
                  )}
                </Paragraph>
                <Paragraph
                  className='wrap-text visible-sm pt-sm--4'
                  color='paleSky'
                  level={4}
                >
                  {item.specialty}
                </Paragraph>
                {item.practice && (
                  <Paragraph
                    className='wrap-text visible-sm pt-sm--4'
                    color='paleSky'
                    level={4}
                  >
                    {item.practice}
                  </Paragraph>
                )}
              </div>
              <Paragraph className='pt-sm--4' color='paleSky' level={4}>
                {item.address}
                {item.countLocation > 1 &&
                  ` (+${item.countLocation - 1} ${setPlural(
                    'Location',
                    item.countLocation - 1
                  )})`}
              </Paragraph>
            </div>
          ),
          data: item,
        }
      : {
          value: item.name,
          label: (
            <Paragraph level={3} color='astral' key={item.id}>
              {item.name}
            </Paragraph>
          ),
          data: item,
        }
  );

export default renderMenu;
