import React from 'react';
import { Link } from 'md-styled-components-v2';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLoginModalVisible } from 'actions/loginModal';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handelClick = (e) => {
    e.preventDefault();
    dispatch(setLoginModalVisible(false));
    navigate('/forgotPassword');
  };

  return (
    <div className='pull-right'>
      <Link className='login-form-forgot' onClick={(e) => handelClick(e)}>
        Reset password
      </Link>
    </div>
  );
};

export default ResetPassword;
