import React from 'react';
import { useSelector } from 'react-redux';
import { Icon, Logo, Themes, Img } from 'md-styled-components';
import { Link } from 'md-styled-components-v2';
import {
  getPrivateHospitalData,
  privateCompany,
} from 'selectors/privateHospitalData';
import '../_styles/logo.less';
import propTypes from 'prop-types';

const HospitalLogo = ({ iconSize = 48, logoClassName = '' }) => {
  const isPrivateCompany = useSelector(privateCompany);
  const privateHospitalData = useSelector(getPrivateHospitalData);

  if (!privateHospitalData.privateHospitalLogo) {
    return null;
  }

  return (
    <Link
      to='/'
      type='link'
      className={`${logoClassName} flex-display flex-align-items-center`}
    >
      {isPrivateCompany && (
        <>
          <Logo className='mr-sm--12 mr--16'>
            <Icon type='logo-color' size={iconSize} />
          </Logo>
          <Icon
            size={14}
            type='add'
            color={Themes.I90}
            className='mr--16 mr-sm--12'
          />
        </>
      )}
      <Img
        src={privateHospitalData.privateHospitalLogo}
        alt='groupLogo'
        className={
          isPrivateCompany ? 'hospital-logo-private-company' : 'hospital-logo'
        }
      />
    </Link>
  );
};

HospitalLogo.propTypes = {
  logoClassName: propTypes.string,
  iconSize: propTypes.number,
};

export default HospitalLogo;
