import React, { memo } from 'react';
import { Icon, Typography } from 'md-styled-components';
import propTypes from 'prop-types';

const { Text } = Typography;

const GroupName = ({ groupInfo }) => (
  <div className='flex-display flex-align-items-center'>
    <Icon type='pin-hover-indigo' size={20} className='mb--4' />
    <Text level={3} className='ml--4'>
      {groupInfo.GroupName}
    </Text>
  </div>
);

GroupName.propTypes = {
  groupInfo: propTypes.shape({
    GroupName: propTypes.string,
  }),
};

export default memo(GroupName);
