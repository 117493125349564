import React from 'react';
import { Typography } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import propTypes from 'prop-types';
import { useOptionsShoppingCart } from './_hooks';
import './_styles/index.less';
import ItemBadge from './_partials/ItemBadge';

const { Text } = Typography;

const ShoppingCartButton = ({ inDrawer = false }) => {
  const { shoppingCartNotEmpty, shoppingCartQuantity, toggleCart } =
    useOptionsShoppingCart();

  return (
    <div
      className={
        inDrawer && 'shopping-cart__item flex-display flex-align-items-center'
      }
    >
      <Button
        type='text'
        className='shopping-cart__button pointer'
        aria-label='shopping cart'
        onClick={() => shoppingCartNotEmpty && toggleCart(true)}
      >
        <ItemBadge shoppingCartQuantity={shoppingCartQuantity} />
        {inDrawer && <Text className='ml--24'>Shopping Cart</Text>}
      </Button>
    </div>
  );
};

ShoppingCartButton.propTypes = {
  inDrawer: propTypes.bool,
};

export default ShoppingCartButton;
