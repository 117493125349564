import React from 'react';
import propTypes from 'prop-types';

import { InputNumber as Component } from 'antd';
import styled from 'styled-components';
import themes from 'md-styled-components/Themes';
import useDisableScrollOnWheel from './useDisableScrollOnWheel';
import { defaultStyles } from './Input';

const StyledInputPrice = styled(Component)`
  height: ${themes['input-height-base']} !important;
  padding: 0 !important;
  display: flex;

  .ant-input-number {
    display: flex;
    align-items: center;

    &-handler-wrap {
      border-start-end-radius: 0;
      border-end-end-radius: 0;
    }

    &-input-wrap {
      display: flex;
      font-size: 16px;
    }

    &-wrapper {
      display: flex;
    }

    &-group-addon {
      display: flex;
      align-items: center;
      width: auto;
      color: ${themes.I90};
      font-size: 16px;
      border: none;
      background-color: inherit;
      padding: 0 4px 0 16px;
    }

    &-outlined {
      border: none;
    }

    &-focused {
      box-shadow: none;
    }
  }

  ${defaultStyles}
`;

const Price = React.forwardRef(({ allowClear, ...props }, ref) => {
  useDisableScrollOnWheel();
  return <StyledInputPrice {...props} ref={ref} />;
});

Price.propTypes = {
  formatter: propTypes.func,
  parser: propTypes.func,
  min: propTypes.number,
  precision: propTypes.number,
  addonBefore: propTypes.string,
};

Price.defaultProps = {
  formatter: (value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  parser: (value) => value?.replace(/\$\s?|(,*)/g, ''),
  min: 0,
  precision: 2,
  addonBefore: '$',
};

export default Price;
