import { createSelector } from 'reselect';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import {
  requestGroupLogo,
  requestShareCart,
  requestGroupUserAllHospitalsApi,
} from 'actions/groups';
import createLoadingSelector from './create-loading-selector';

export const groups = (state) => state.groups;

export const getFeaturedSpecialties = createSelector(
  groups,
  (data) => data.featuredSpecialties
);

export const getSystemLocations = createSelector(
  groups,
  (data) => data.systemLocations.providers || []
);

export const getProviderImages = createSelector(
  groups,
  (data) => data.providerImages
);

export const getGroupsMapData = createSelector(
  groups,
  (data) => data.groupsMapData || {}
);

export const getGroupMapsProviders = createSelector(
  getGroupsMapData,
  (groupsMapData) => groupsMapData.providers || []
);

export const getGroupSpecialties = createSelector(
  getGroupsMapData,
  (groupsMapData) => groupsMapData.specialties || []
);

export const getAllGroups = createSelector(
  getGroupsMapData,
  (item) => item.groups
);

export const getSearchAddresses = createSelector(
  getGroupsMapData,
  (groupsMapData) =>
    groupsMapData.searchAddresses
      ? mapValues(
          mapKeys(groupsMapData.searchAddresses, (value) => value.id),
          (value) => ({
            ...value,
            latitude: Number(value.latitude),
            longitude: Number(value.longitude),
          })
        )
      : {}
);

export const getAllSpecialties = createSelector(
  groups,
  (data) => data.allSpecialties
);

export const getShareCart = createSelector(groups, (data) => data.shareCart);

export const groupUserHospitalList = createSelector(
  groups,
  (data) => data.hospitalList
);

export const groupLogo = createSelector(groups, (data) => data.groupLogo);

export const groupUserAllHospitals = createSelector(groups, (data) =>
  Object.values(data.allHospitals.systemList)
);

export const getGroupName = createSelector(
  groups,
  (data) => data.allHospitals.groupName
);

export const getThirdPartyName = createSelector(
  groups,
  (data) => data.allHospitals.thirdPartyName
);

export const groupUserAccountManagerInfo = createSelector(
  groups,
  (data) => data.accountManager
);

export const hasGroupUserMultipleHospitals = createSelector(
  groupUserAllHospitals,
  (hospitals) => hospitals && Object.keys(hospitals).length > 1
);

export const createGroupUserAllHospitalsLoading = createLoadingSelector(
  requestGroupUserAllHospitalsApi.actionName
)();

export const createGetShareCartLoading = createLoadingSelector(
  requestShareCart.actionName
)();

export const groupLogoLoading = createLoadingSelector(
  requestGroupLogo.actionName
)();
