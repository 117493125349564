import React, { Fragment } from 'react';
import { Typography, Icon } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import {
  getCompanyUserCompanies,
  getCurrentCompanyInfo,
} from 'selectors/users';
import { useSelector } from 'react-redux';
import { useCompanyPricingGroupSwitch } from 'hooks/Company';

const { Paragraph } = Typography;

const Title = ({ text, index }) => (
  <div className={`mb--24 ${index && 'mt--32'}`}>
    <Paragraph level={1} color='grey'>
      {text}
    </Paragraph>
  </div>
);

const AccountWithNetwork = () => {
  const companies = useSelector(getCompanyUserCompanies);
  const { currentCompany, currentPricingGroup } = useSelector(
    getCurrentCompanyInfo
  );
  const onSwitch = useCompanyPricingGroupSwitch();

  return (
    <div>
      {companies.companiesList.map((item, index) => (
        <Fragment key={item.id}>
          <Title text={item.name} index={index} />
          {item.pricingGroups.map((childData) => {
            const isActive =
              currentCompany.id === item.id &&
              currentPricingGroup.id === childData.id;
            return (
              <div key={childData.id} className='flex-display mb--24'>
                {isActive ? (
                  <Icon type='check-bordered' size={20} className='mr--16' />
                ) : (
                  <div className='ml--32 pl--4' />
                )}
                <Button
                  type='link'
                  onClick={() =>
                    onSwitch([item.id, childData.id, childData.narrow_network])
                  }
                >
                  {childData.name}
                </Button>
              </div>
            );
          })}
        </Fragment>
      ))}
    </div>
  );
};

export default AccountWithNetwork;
