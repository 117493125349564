import { createSelector } from 'reselect';
import { requestPageInstance } from 'actions/support';
import createLoadingSelector from './create-loading-selector';

export const support = (state) => state.support;

export const getPageInstance = createSelector(
  support,
  (data) => data.pageInstance || {}
);

export const createPageInstanceLoading = createLoadingSelector(
  requestPageInstance.actionName
)();

export const getPageData = createSelector(getPageInstance, (item) => item.data);

export const getPageName = createSelector(getPageInstance, (item) => item.page);

export const getGroupsGroup = createSelector(
  getPageInstance,
  (item) => item?.groups?.group
);

export const getProceduresCount = createSelector(
  getPageData,
  (item) => (item && item?.procedureCount) || 0
);

export const getProcedures = createSelector(
  getPageData,
  (item) => item && item?.data
);

export const getPageGroup = createSelector(
  getPageInstance,
  (item) => item.group
);

export const getPriceListGroupIds = createSelector(
  getPageInstance,
  (item) => item.priceListGroupIds
);

export const getGroup = createSelector(getPageData, (data) => data?.groupData);

export const getSystem = createSelector(
  getPageInstance,
  (item) => item.groups?.system
);

export const getSystemProperties = createSelector(
  getPageGroup,
  // eslint-disable-next-line camelcase
  (group) => group?.system_properties
);

export const getPageGroupSystem = createSelector(
  getPageInstance,
  (item) => item.groupSystem
);

export const getGroupsGroupGroupSystem = createSelector(
  getGroupsGroup,
  (item) => item?.groupSystem
);
