import React, { lazy, useState } from 'react';
import HeaderSearchWrapper from 'components/HeaderSearch/_partials/HeaderSearchWrapper';
import { searchTypes } from '../../constants';
import ProcedureForm from './_partials/ProcedureForm';
import ProviderForm from './_partials/ProviderForm';
import './_styles/index.less';

const PrefixSelect = lazy(() => import('./_partials/PrefixSelect'));

const { PROCEDURES, PROVIDERS } = searchTypes;

const Select = () => {
  const [searchType, setSearchType] = useState(PROCEDURES);

  const searchForms = {
    [PROCEDURES]: <ProcedureForm />,
    [PROVIDERS]: <ProviderForm />,
  };

  return (
    <div className='fields-select-search mds-full--width'>
      <div className='flex-display flex-align-items-center'>
        <div className='fields-select-search__wrapper'>
          <HeaderSearchWrapper>
            <div className='flex-wrapper'>
              <PrefixSelect
                searchType={searchType}
                setSearchType={setSearchType}
              />
              {searchForms[searchType]}
            </div>
          </HeaderSearchWrapper>
        </div>
      </div>
    </div>
  );
};

export default Select;
