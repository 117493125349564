import { useSelector } from 'react-redux';
import {
  getCompanyUserCompanies,
  getCurrentCompanyInfo,
} from 'selectors/users';

export const useViewCompaniesForCascader = () => {
  const companies = useSelector(getCompanyUserCompanies);
  const networks = companies?.companiesList.filter(
    (item) => item.pricingGroups.length > 1
  );
  const { currentCompany, currentPricingGroup } = useSelector(
    getCurrentCompanyInfo
  );

  switch (true) {
    case !networks.length:
      return {
        title: ['Account'],
        option: companies.companiesList,
        defaultValue: [currentCompany.id],
        fieldNames: {
          label: 'name',
          value: 'id',
        },
        changeValue: (val) => {
          const company = companies.companiesList.filter(
            (item) => item.id === val[0]
          );
          return [val[0], company[0].pricingGroups[0].id, false];
        },
      };
    case companies?.companiesList.length === 1: {
      const { pricingGroups } = companies.companiesList[0];
      return {
        title: ['Network'],
        option: pricingGroups,
        defaultValue: currentPricingGroup.id,
        fieldNames: {
          label: 'name',
          value: 'id',
        },
        changeValue: (val) => {
          const pricingGroup = pricingGroups.find((item) => item.id === val[0]);
          return [currentCompany.id, val[0], pricingGroup.narrow_network];
        },
      };
    }
    case networks.length && companies?.companiesList.length > 1:
      return {
        title: ['Account', 'Network'],
        option: companies.companiesList,
        defaultValue: [currentCompany.id, currentPricingGroup.id],
        fieldNames: {
          label: 'name',
          value: 'id',
          children: 'pricingGroups',
        },
        changeValue: (val) => {
          const company = companies.companiesList.find(
            (item) => item.id === val[0]
          );
          const pricingGroup = company.pricingGroups.find(
            (item) => item.id === val[1]
          );
          return [val[0], val[1], pricingGroup.narrow_network];
        },
      };
    default:
      return {
        title: [],
        option: [],
        defaultValue: [],
        fieldNames: {},
        changeValue: () => {},
      };
  }
};
