import React from 'react';
import { Breadcrumb, Row } from 'md-styled-components';
import propTypes from 'prop-types';
import { itemRender } from 'components/BreadCrumb';
import Content from './Content';

const HeroSection = ({
  hero,
  content,
  contentParentOptions,
  wrapperOptions,
  footer,
  onClick,
}) => (
  <>
    {hero instanceof Array ? (
      <Breadcrumb
        itemRender={itemRender}
        items={[{ title: 'Home', href: 'site', onClick }, ...hero]}
        className='mt--16'
      />
    ) : (
      hero
    )}
    {!!content && (
      <div className='pt--24 pb--16' {...wrapperOptions}>
        <Row {...contentParentOptions}>
          {content.map(({ inputType, name, colOptions, ...otherOptions }) => (
            <Content
              inputType={inputType}
              name={name}
              colOptions={colOptions}
              otherOptions={otherOptions}
              key={`${inputType}-${name}`}
            />
          ))}
        </Row>
      </div>
    )}
    {footer || null}
  </>
);

HeroSection.propTypes = {
  hero: propTypes.any.isRequired,
  content: propTypes.array,
  footer: propTypes.any,
  contentParentOptions: propTypes.object.isRequired,
  wrapperOptions: propTypes.object,
  onClick: propTypes.func,
};

export default HeroSection;
