import { formatQuery } from 'utils/url';
import { setSesStorageItem } from 'utils/storage';
import { sesStorage } from 'constants';

const checkMatchInData = (form, procedure) => {
  if (procedure?.searchData?.length) {
    procedure.searchData.forEach((item) => {
      if (item.name.toLowerCase() === procedure.searchText.toLowerCase()) {
        form.setFields([{ name: 'procedure', value: item, errors: [] }]);
      }
    });
  }
};

const useOnFinish = (form) => {
  const { setFields } = form;

  return (values) => {
    const { procedure, location } = values;
    let error = false;
    const selectError = 'Select an option from the dropdown.';

    if (!procedure) {
      setFields([
        {
          name: 'procedure',
          value: { placeholder: 'Please enter a procedure' },
        },
      ]);
      error = true;
    }
    if (!location.searchText) {
      setFields([
        {
          name: 'location',
          value: { ...location, placeholder: 'Please enter a location' },
        },
      ]);
      error = true;
    }
    if (!procedure?.name) {
      checkMatchInData(form, procedure);
    }
    if (
      (!procedure?.name && procedure?.searchData?.length) ||
      (procedure?.searchText && procedure?.searchData?.length === 0)
    ) {
      setFields([{ name: 'procedure', errors: [selectError] }]);
      error = true;
    }
    if (!location.locationUri) {
      if (!location.searchData?.length) {
        return;
      }
      setFields([{ name: 'location', errors: [selectError] }]);
      error = true;
    }
    if (!error) {
      let encodedQuery = formatQuery(procedure.name);
      if (procedure.cpt) {
        encodedQuery = procedure.cpt[0].mainCPT;
      }
      let url = `${procedure.uri}/${
        location.placeId
      }?q=${encodedQuery}&name=${encodeURI(procedure.name)}${
        location.locationUri
      }`;
      if (procedure.keyword) {
        url += `&keyword=${encodeURI(procedure.keyword)}`;
      }
      setSesStorageItem(sesStorage.locationKey, JSON.stringify(location));
      window.location.href = url;
    }
  };
};

export default useOnFinish;
