import { Highlighter, Typography } from 'md-styled-components';

const { Text } = Typography;

export const renderMenu = (results, inputValue) =>
  results.map((item) => ({
    value: item.name,
    label: (
      <div className='info-wrapper' key={item.key}>
        <Text level={3}>
          <Highlighter
            searchWords={[inputValue]}
            textToHighlight={item.name}
            autoEscape
          />
        </Text>
      </div>
    ),
    data: item,
  }));
