import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import {
  requestWithoutInsuranceProcedures,
  requestWithInsuranceProcedures,
  requestHasWithoutInsurance,
  requestInsuranceProviders,
  requestGetProcedureName,
  requestGetProviderName,
  requestGetPriceUpdateDate,
  requestWithInsProcDetails,
  requestWithoutInsProcDetails,
} from 'actions/transparency';
import createLoadingSelector from './create-loading-selector';

export const transparency = (state) => state.transparency;
export const getWithoutInsuranceProcedures = createSelector(
  transparency,
  (data) => data.withoutInsuranceProcedures
);

export const getWithInsuranceProcedures = createSelector(
  transparency,
  (data) => data.withInsuranceProcedures
);

export const getHasWithoutInsurance = createSelector(
  transparency,
  (data) => data.hasWithoutInsurance
);

export const getInsuranceProviders = createSelector(
  transparency,
  (data) => data.insuranceProviders
);

export const getSuggestedInsuranceProviders = createSelector(
  getInsuranceProviders,
  (data) => data?.insuranceProviders || null
);

export const getProcedureName = createSelector(
  transparency,
  (data) => data.procedureName
);

export const getProviderName = createSelector(
  transparency,
  (data) => data.providerName
);

export const getPriceUpdateDate = createSelector(
  transparency,
  (data) =>
    data.priceUpdateDate &&
    dayjs(data.priceUpdateDate, 'YYYY-MM-DD').format('MMMM D, YYYY')
);

export const getWithoutInsuranceProcedureDetails = createSelector(
  transparency,
  (data) => data.withoutInsuranceProcedureDetails
);

export const getWithInsuranceProcedureDetails = createSelector(
  transparency,
  (data) => data.withInsuranceProcedureDetails
);

export const createWithoutInsuranceLoading = createLoadingSelector(
  requestWithoutInsuranceProcedures.actionName
)();

export const createWithInsuranceLoading = createLoadingSelector(
  requestWithInsuranceProcedures.actionName
)();

export const createHasWithoutInsuranceLoading = createLoadingSelector(
  requestHasWithoutInsurance.actionName
)();

export const createInsuranceProvidersLoading = createLoadingSelector(
  requestInsuranceProviders.actionName
)();

export const createProcedureNameLoading = createLoadingSelector(
  requestGetProcedureName.actionName
)();

export const createProviderNameLoading = createLoadingSelector(
  requestGetProviderName.actionName
)();

export const createPriceUpdateDateLoading = createLoadingSelector(
  requestGetPriceUpdateDate.actionName
)();

export const createWithoutInsuranceDetailsLoading = (state, params) =>
  createLoadingSelector(requestWithoutInsProcDetails.actionName)(params)(state);

export const createWithInsuranceDetailsLoading = (state, params) =>
  createLoadingSelector(requestWithInsProcDetails.actionName)(params)(state);
