import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Themes, Typography } from 'md-styled-components';
import { Input, Form, Button } from 'md-styled-components-v2';
import { MaskedInput } from 'components/Form/Phone';
import { requestSaveGroupUserData } from 'actions/shopping-cart';
import { getContactInformation } from 'selectors/shopping-cart';
import { phoneValidation } from 'utils/phoneHelper';
import errors from 'utils/messages/errors';
import { useBreakpoint } from '../../../../hooks';

const { Paragraph, Title } = Typography;

export default (props) => {
  const [form] = Form.useForm();
  const isMobile = useBreakpoint(Themes.breakpoints.xs, Themes.breakpoints.sm);
  const { visible, onCancel } = props;
  const inputEl = useRef(null);
  const initialInfo = useSelector(getContactInformation);
  const dispatch = useDispatch();
  const manageCart = () => {
    window.open('/price-quote-output/?send=yes', '_blank');
    onCancel();
  };
  const onSave = (values) => {
    const { name, phone } = values;
    dispatch(
      requestSaveGroupUserData.request({
        hospital_contact_person: name,
        hospital_phone_number: phone,
      })
    );
  };

  useEffect(() => {
    dispatch(requestSaveGroupUserData.request({ getUserData: true }));
  }, [dispatch]);

  useEffect(() => () => form.resetFields(), [visible]);

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      centered
      closable
      footer={null}
      className='contact-info'
      width={isMobile ? '100%' : '480px'}
    >
      <Title className='text-center mt--12' level={5}>
        Your Contact Information
      </Title>
      <Paragraph color='grey' className='mt--8 mb--16'>
        Please add your contact information so the patient can reach you to
        complete their purchase.
      </Paragraph>
      <Form
        form={form}
        hideRequiredMark
        colon={false}
        onFinish={onSave && manageCart}
      >
        <Form.Item
          label='Name of Contact Person'
          name='name'
          initialValue={initialInfo.hospitalContactPerson}
          validateTrigger='onBlur'
          rules={[
            { required: true, message: 'Name of Contact Person required.' },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Phone Number'
          name='phone'
          initialValue={initialInfo.hospitalPhoneNumber}
          validateTrigger='onBlur'
          rules={[
            { required: true, message: 'Phone Number required.' },
            {
              validator() {
                return phoneValidation(inputEl)
                  ? Promise.reject(errors.phoneNumberNotValid)
                  : Promise.resolve();
              },
            },
          ]}
          hasFeedback
        >
          <MaskedInput ref={inputEl} name='phone' />
        </Form.Item>
        <Button
          className='contact-info__save mt--24'
          type='primary'
          htmlType='submit'
        >
          Save
        </Button>
      </Form>
      <Button
        onClick={() =>
          dispatch(
            requestSaveGroupUserData.request({
              manageCart,
            })
          )
        }
        type='link'
        className='text-center mt--16'
        block
      >
        <Paragraph>Skip</Paragraph>
      </Button>
    </Modal>
  );
};
