import { createSelector } from 'reselect';
import {
  requestGetPatientList,
  requestSaveCart,
  requestPatientInfo,
} from 'actions/patients';
import createLoadingSelector from './create-loading-selector';

export const patients = (state) => state.patients;

export const getPatientList = createSelector(patients, (data) => data.list);

export const createGetPatientListLoading = createLoadingSelector(
  requestGetPatientList.actionName
)();

export const getSaveCart = createSelector(patients, (data) => data.saveCart);

export const createGetSaveCartLoading = createLoadingSelector(
  requestSaveCart.actionName
)();

export const createGetPatientInfoLoading = createLoadingSelector(
  requestPatientInfo.actionName
)();
