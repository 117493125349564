import React, { useCallback, useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon, Themes, Header, Typography } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import {
  getPrivateHospitalData,
  isPrivateNotAllHospital,
  privateHospitalIds,
} from 'selectors/privateHospitalData';
import { SEARCH_MIN_LENGTH, allProcedures } from 'constants/procedures';
import {
  clearSuggestedProceduresHeader,
  requestSuggestedProceduresHeader,
} from 'actions/procedures';
import {
  createSuggestedProceduresHeaderLoading,
  getSuggestedProceduresHeader,
} from 'selectors/procedures';
import { getPageName } from 'selectors/support';
import { getProcedureName } from 'selectors/transparency';
import { useGetParams, formatQuery, recoverQuery } from 'utils/url';
import { checkQueryName } from 'pages/GroupPriceList/_partials/Search';
import { pageTypes, common } from 'constants/index';
import AutoComplete from '../SearchAutocomplete';
import { useSearchProcedure } from '../../../contexts/SearchProcedureProvider';

const { GROUP_PRICE_LIST } = pageTypes;
const { proceduresField } = common.heroSearchFields;

const { AutoComplete: HeaderAutoComplete } = Header;
const { Paragraph, Text } = Typography;
const { Menu, MenuItem, Highlighter } = HeaderAutoComplete;

const renderProceduresMenu = (results, menuProps, isSmall, inputValue) => (
  <Menu {...menuProps}>
    {results.map((item, itemIndex) => (
      <MenuItem
        key={item.id}
        option={item}
        position={itemIndex}
        className='procedure-items'
      >
        <div className='info-wrapper'>
          <Paragraph className='wrap-text'>
            <Highlighter
              highlightClassName='rbt-highlight-strong'
              search={inputValue}
            >
              {item.name}
            </Highlighter>
          </Paragraph>
          {item.drgCodes && (
            <Text level={4} color='paleSky'>
              DRG Code: {item.drgCodes.join(', ')}
            </Text>
          )}
          {item.keyword && (
            <Paragraph level={4} color='paleSky'>
              Also Known As:{' '}
              <Highlighter
                highlightClassName='rbt-highlight-strong'
                search={inputValue}
              >
                {item.keyword}
              </Highlighter>
            </Paragraph>
          )}
          {item.cpt && (
            <Text level={4} color='paleSky'>
              CPT Code :{' '}
              {item.cpt.map((cpt, index) => (
                <span key={`${cpt}-${index}`}>
                  {`${cpt.mainCPT}${item.cpt[index + 1] ? ' + ' : ''}`}
                </span>
              ))}
            </Text>
          )}
        </div>
      </MenuItem>
    ))}
  </Menu>
);

export const SearchButton = React.memo(({ refProcedure }) => (
  <Button
    className='search-btn ant-btn-header-search'
    type='text'
    htmlType='submit'
    onClick={(e) => {
      e.stopPropagation();
      refProcedure && refProcedure.current.hideMenu();
    }}
  >
    <Icon type='icon-search2' size='16px' color={Themes.I90} />
  </Button>
));

export const useDefaultOptions = (
  clearRequestData,
  requestData,
  dataField,
  dataFieldName,
  setFieldsValue,
  clearSearchData,
  setSearchData
) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const groupId = useSelector(privateHospitalIds);
  const isHospital = useSelector(isPrivateNotAllHospital);

  useEffect(() => {
    if (!search) {
      dispatch(clearRequestData());
    }
  }, [dispatch, search, clearRequestData]);

  const validateOnChange = useCallback(
    (value, type, dataSourceLen, keyword = '', selected) => {
      if (value && value.startsWith('allResults')) {
        return false;
      }
      if (!value || (value.length < SEARCH_MIN_LENGTH && dataSourceLen)) {
        dispatch(clearRequestData());
        clearSearchData();
        setFieldsValue({
          [dataFieldName]: null,
        });
        return false;
      }
      const data = {
        count: 10,
        data: value,
      };
      if (value.length >= SEARCH_MIN_LENGTH && !selected) {
        dispatch(requestData(data, groupId, isHospital));
      }
      return true;
    },
    [isHospital, dispatch, groupId, requestData, clearRequestData]
  );

  return useCallback(
    (value, type, dataSourceLen, keyword = '', selected) => {
      const isFieldValid = validateOnChange(
        value,
        dataField,
        dataSourceLen,
        keyword,
        selected
      );
      if (isFieldValid) {
        setFieldsValue({
          [dataFieldName]: value,
        });
        setSearchData && setSearchData(dataField, type, value);
      }
    },
    [validateOnChange, setSearchData, dataField, setFieldsValue, dataFieldName]
  );
};

const Procedures = React.memo(
  ({
    proceduresFieldName,
    suffixBtn,
    heroSearch,
    onOptionSelected,
    procedureError,
    searchData,
    setSearchData,
    setFieldsValue,
    resetFieldsData,
  }) => {
    const proceduresData = useSelector(getSuggestedProceduresHeader);
    const proceduresLoading = useSelector(
      createSuggestedProceduresHeaderLoading
    );
    const privateHospitalData = useSelector(getPrivateHospitalData);
    const isHospital = useSelector(isPrivateNotAllHospital);
    const [autocompleteClass, setAutocompleteClass] = useState('');
    const refProcedure = useRef();
    const { q: queryNameEncoded, p: procedureId } = useGetParams();
    const { searchText } = useSearchProcedure();
    const procedureName = useSelector(getProcedureName);
    const pageName = useSelector(getPageName);
    const isPriceList = pageName === GROUP_PRICE_LIST && isHospital;
    const queryNameRecovered =
      queryNameEncoded && queryNameEncoded !== allProcedures
        ? recoverQuery(queryNameEncoded)
        : '';

    const deftValue = isPriceList
      ? queryNameRecovered.toLowerCase() === procedureName?.toLowerCase() &&
        procedureName
      : searchText;

    const queryName = deftValue || queryNameRecovered;
    const navigate = useNavigate();

    useEffect(() => {
      if (queryName && !searchData.searchText) {
        setFieldsValue({
          [proceduresFieldName]: queryName,
        });
        setSearchData((oldSearchData) => ({
          ...oldSearchData,
          type: proceduresField,
          searchType: proceduresFieldName,
          searchText: queryName,
        }));
        onOptionSelected(proceduresFieldName, {
          id: procedureId,
          name: queryName,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (proceduresData?.length && searchData.initialData) {
        const procedureItem = proceduresData.find(
          (procedure) =>
            procedure.name.toLowerCase() === searchData.searchText.toLowerCase()
        );
        if (procedureItem) {
          setSearchData((oldSearchData) => ({
            ...oldSearchData,
            searchText: procedureItem.name,
            initialData: false,
          }));
          onOptionSelected(proceduresFieldName, procedureItem);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proceduresData, searchData]);

    const searchByProcedureText = useDefaultOptions(
      useCallback(() => clearSuggestedProceduresHeader.request(), []),
      useCallback(
        (data, groupId) =>
          requestSuggestedProceduresHeader.request(
            isHospital ? { ...data, group_id: groupId } : data
          ),
        [isHospital]
      ),
      proceduresField,
      proceduresFieldName,
      setFieldsValue,
      useCallback(() => {
        setSearchData({});
        onOptionSelected(proceduresFieldName, {});
      }, [setSearchData, onOptionSelected, proceduresFieldName]),
      useCallback(
        (type, searchType, searchTxt) =>
          setSearchData({
            type,
            searchType,
            searchText: searchTxt,
            initialData: !proceduresData,
          }),
        [setSearchData, proceduresData]
      )
    );

    const optionsSelectedWhiteLabel = useCallback(
      (_, selected) => {
        navigate(
          `/${
            privateHospitalData.privateHospitalSlug
          }-price-list?q=${formatQuery(selected.name)}&p=${selected.id}`
        );
      },
      [navigate, privateHospitalData]
    );

    useEffect(() => {
      checkQueryName(queryName) &&
        searchByProcedureText(queryName, proceduresFieldName);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <AutoComplete
        onChange={searchByProcedureText}
        optionSelected={
          isHospital && !privateHospitalData.showTransparencyPrice
            ? optionsSelectedWhiteLabel
            : onOptionSelected
        }
        dataSource={proceduresData || []}
        notFound={proceduresData && proceduresData.length === 0}
        loading={proceduresLoading}
        iconType='magnifying-glass'
        placeholder={
          procedureError
            ? 'Please enter a procedure'
            : 'Office Visit, Procedure, or CPT Code'
        }
        emptyLabel='Sorry, we could not find a match for that procedure'
        fieldName={proceduresFieldName}
        rounded=''
        labelKey='name'
        renderMenu={renderProceduresMenu}
        suffix={
          suffixBtn &&
          isHospital && <SearchButton refProcedure={refProcedure} />
        }
        size={heroSearch && 'large'}
        takeFirstOnEnter
        deftValue={queryName}
        ref={refProcedure}
        setAutocompleteClass={setAutocompleteClass}
        autocompleteClass={autocompleteClass}
        clearSuggested={clearSuggestedProceduresHeader}
        resetForm={() => resetFieldsData(proceduresFieldName, 'searchText')}
      />
    );
  }
);

Procedures.defaultProps = {
  suffixBtn: false,
};

Procedures.propTypes = {
  proceduresFieldName: propTypes.string.isRequired,
  suffixBtn: propTypes.bool.isRequired,
  heroSearch: propTypes.bool,
  onOptionSelected: propTypes.func.isRequired,
  procedureError: propTypes.number.isRequired,
  setSearchData: propTypes.func.isRequired,
  setFieldsValue: propTypes.func.isRequired,
};

export default Procedures;
