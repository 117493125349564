import Theme from 'md-styled-components/Themes';

export default `
  &&& {
    color: ${Theme['primary-cta']};
        
    &:hover {
      color: ${Theme['mdsave-blue']};
    }
      
    &[disabled] {
      color: ${Theme.I50};
    }
  }
`;
