import { useMobileNavbar } from 'components/NavbarSearch/_context/NavbarMobileProvider';

export const useContainerClass = () => {
  const { searchVisible } = useMobileNavbar();
  const className = `hospital-navigation hospital-navigation--fixed pr--16 pr-lg--0 pr-md--0 pr-sm--0
      ${searchVisible ? '' : 'hospital-navigation--shadow'}
    `;

  return { className };
};
